import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ActionsModal from "./ActionsModal";
import EvalImage from "./png_2.png";

const Evaluation = () => {
  const [action, setAction] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const setActionNumber = (number) => {
    setAction(number);
    setShowModal(true);
  };

  const keyUpSetActionNumber = (event, number) => {
    if (event.key === "Enter" && action !== number) {
      setAction(number);
      setShowModal(true);
    } else {
      setAction(0);
    }
  };

  return (
    <Container fluid>
      <ActionsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        actionNumber={action}
      />
      <Row className="mt-5 mb-3">
        <Col sm={12}>
          <h3 className="text-bright-navy">Training Center</h3>
        </Col>
      </Row>
      <Row className="mt-5 mb-3">
        <Col sm={12} className="text-center">
          <img
            src={EvalImage}
            id="ConvertedImage"
            alt="Eval Phase"
            name="RasterImage"
            border="0"
            useMap="#visImageMap"
          />
          <map name="visImageMap">
            <area
              shape="polygon"
              tabIndex="1"
              alt="23 Report Weekly Evaluation Progress"
              origtitle="23 Report Weekly Evaluation Progress"
              title="23 Report Weekly Evaluation Progress"
              coords="254,375,349,375,349,329,254,329,254,375"
              onClick={() => setActionNumber(23)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 23)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="40 Resolve Triage &#38; ECR Comments"
              origtitle="40 Resolve Triage &#38; ECR Comments"
              title="40 Resolve Triage &#38; ECR Comments"
              coords="455,516,523,516,523,359,455,359,455,516"
              onClick={() => setActionNumber(40)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 40)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="COMPLETED"
              origtitle="COMPLETED"
              title="COMPLETED"
              coords="971,86,974,79,966,77,967,68,959,69,957,61,949,65,944,59,939,65,932,61,929,69,921,68,922,77,914,79,917,86,917,87,911,92,917,97,917,98,914,104,922,107,921,115,929,114,932,122,939,118,944,125,949,118,957,122,959,114,967,115,966,107,974,104,971,98,971,97,977,92,971,87,971,86"
              onClick={() => setActionNumber(82)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 82)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="FINALIZING"
              origtitle="FINALIZING"
              title="FINALIZING"
              coords="704,163,699,169,692,165,689,173,681,172,682,181,674,183,678,190,672,196,678,201,674,208,682,211,681,219,689,218,692,226,699,222,704,228,710,222,717,226,719,218,728,219,727,211,735,208,731,201,737,196,731,190,735,183,727,181,728,172,719,173,717,165,710,169,704,163"
              onClick={() => setActionNumber(78)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 78)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="IN PROGRESS"
              origtitle="IN PROGRESS"
              title="IN PROGRESS"
              coords="79,479,74,485,66,481,64,489,56,488,57,497,49,499,53,506,46,512,53,517,49,524,57,527,56,535,64,534,66,542,74,538,79,544,84,538,91,542,94,534,102,535,101,527,109,524,105,517,112,512,105,506,109,499,101,497,102,488,94,489,91,481,84,485,79,479"
              onClick={() => setActionNumber(69)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 69)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="42 Pass ECR?"
              origtitle="42 Pass ECR?"
              title="42 Pass ECR?"
              coords="631,236,645,227,610,205,574,227,588,236,588,239,595,239,610,249,625,239,626,239,631,239,631,236"
              onClick={() => setActionNumber(42)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 42)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="41 Evaluation Consistency Review of Check-out Package"
              origtitle="41 Evaluation Consistency Review of Check-out Package"
              title="41 Evaluation Consistency Review of Check-out Package"
              coords="690,308,787,308,787,242,690,242,690,308"
              onClick={() => setActionNumber(41)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 41)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="7"
              origtitle="7"
              title="7"
              coords="775,383,793,367,775,351,758,367,775,383"
              onClick={() => setActionNumber(80)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 80)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="46 Vendor Section of Check-out"
              origtitle="46 Vendor Section of Check-out"
              title="46 Vendor Section of Check-out"
              coords="936,666,908,666,908,722,914,726,921,728,928,728,935,726,941,722,948,719,955,717,962,717,969,719,975,722,975,666,947,666,947,664,936,664,936,666"
              onClick={() => setActionNumber(46)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 46)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="6"
              origtitle="6"
              title="6"
              coords="657,572,675,556,657,540,639,556,657,572"
              onClick={() => setActionNumber(74)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 74)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="10 Monitor NIST Evaluation"
              origtitle="10 Monitor NIST Evaluation"
              title="10 Monitor NIST Evaluation"
              coords="142,718,190,718,196,717,202,715,206,711,210,706,213,700,213,693,213,687,210,681,206,676,202,672,196,669,190,668,142,668,136,669,130,672,126,676,122,681,119,687,119,693,119,700,122,706,126,711,130,715,136,717,142,718"
              onClick={() => setActionNumber(10)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 10)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="47 Check-out Package"
              origtitle="47 Check-out Package"
              title="47 Check-out Package"
              coords="894,249,901,253,909,255,918,255,926,255,934,253,941,249,949,246,957,243,965,243,973,243,981,246,989,249,989,205,894,205,894,249"
              onClick={() => setActionNumber(47)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 47)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="8"
              origtitle="8"
              title="8"
              coords="793,141,811,125,793,109,775,125,793,141"
              onClick={() => setActionNumber(81)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 81)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="5"
              origtitle="5"
              title="5"
              coords="284,630,301,614,284,598,266,614,284,630"
              onClick={() => setActionNumber(73)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 73)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="4"
              origtitle="4"
              title="4"
              coords="242,630,260,614,242,598,224,614,242,630"
              onClick={() => setActionNumber(72)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 72)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="3"
              origtitle="3"
              title="3"
              coords="201,630,218,614,201,598,183,614,201,630"
              onClick={() => setActionNumber(71)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 71)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="34 Prepare NIST Evaluation Evidence"
              origtitle="34 Prepare NIST Evaluation Evidence"
              title="34 Prepare NIST Evaluation Evidence"
              coords="519,712,528,712,528,675,482,675,482,673,471,673,471,675,425,675,425,712,433,712,433,712,519,712,519,712"
              onClick={() => setActionNumber(34)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 34)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="48 Close Out Evaluation"
              origtitle="48 Close Out Evaluation"
              title="48 Close Out Evaluation"
              coords="708,176,787,176,787,125,708,125,708,176"
              onClick={() => setActionNumber(48)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 48)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="44 Validator Section of Check-out"
              origtitle="44 Validator Section of Check-out"
              title="44 Validator Section of Check-out"
              coords="842,369,813,369,813,426,819,429,826,431,834,431,841,429,847,426,853,422,860,420,867,420,874,422,881,426,881,369,852,369,852,368,842,368,842,369"
              onClick={() => setActionNumber(44)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 44)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="27 Return/Resolve/Resume"
              origtitle="27 Return/Resolve/Resume"
              title="27 Return/Resolve/Resume"
              coords="337,161,467,161,467,128,337,128,337,161"
              onClick={() => setActionNumber(27)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 27)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="26 Evaluation Issue  Resolution Decision"
              origtitle="26 Evaluation Issue  Resolution Decision"
              title="26 Evaluation Issue  Resolution Decision"
              coords="197,167,204,171,212,173,221,174,229,173,237,171,244,167,252,163,260,160,268,159,277,160,285,163,292,167,292,115,197,115,197,167"
              onClick={() => setActionNumber(26)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 26)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="25 Plan Resolution of Evaluation Issue"
              origtitle="25 Plan Resolution of Evaluation Issue"
              title="25 Plan Resolution of Evaluation Issue"
              coords="134,169,171,169,171,120,129,120,129,118,118,118,118,120,76,120,76,169,113,169,113,170,134,170,134,169"
              onClick={() => setActionNumber(25)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 25)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="24 Monitor Evaluation Progress"
              origtitle="24 Monitor Evaluation Progress"
              title="24 Monitor Evaluation Progress"
              coords="76,243,171,243,171,192,76,192,76,243"
              onClick={() => setActionNumber(24)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 24)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="37 Evaluator Section of Check-out"
              origtitle="37 Evaluator Section of Check-out"
              title="37 Evaluator Section of Check-out"
              coords="631,528,640,529,649,531,657,535,657,491,615,491,615,489,604,489,604,491,562,491,562,535,570,538,578,540,586,541,594,540,602,538,610,535,616,532,623,530,629,528,631,528,631,528"
              onClick={() => setActionNumber(37)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 37)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="35 NIST Evaluation Evidence"
              origtitle="35 NIST Evaluation Evidence"
              title="35 NIST Evaluation Evidence"
              coords="570,716,576,720,583,722,590,723,597,722,603,720,610,716,616,713,622,710,629,710,636,710,643,713,649,716,649,664,570,664,570,716"
              onClick={() => setActionNumber(35)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 35)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="33 NIST Evaluation completed?"
              origtitle="33 NIST Evaluation completed?"
              title="33 NIST Evaluation completed?"
              coords="332,707,356,693,342,686,342,685,342,685,306,665,257,693,281,707,281,712,290,712,306,721,322,712,327,712,332,712,332,707"
              onClick={() => setActionNumber(33)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 33)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="49 Feedback to NVLAP"
              origtitle="49 Feedback to NVLAP"
              title="49 Feedback to NVLAP"
              coords="604,72,562,72,562,112,657,112,657,72,615,72,615,71,604,71,604,72"
              onClick={() => setActionNumber(49)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 49)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="39 Pass Triage?"
              origtitle="39 Pass Triage?"
              title="39 Pass Triage?"
              coords="636,341,645,335,610,310,574,335,583,341,583,347,592,347,610,359,627,347,631,347,636,347,636,341"
              onClick={() => setActionNumber(39)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 39)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="36 Prepare Evaluator Section of Check-out"
              origtitle="36 Prepare Evaluator Section of Check-out"
              title="36 Prepare Evaluator Section of Check-out"
              coords="562,613,657,613,657,559,562,559,562,613"
              onClick={() => setActionNumber(36)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 36)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="51 End Evaluation"
              origtitle="51 End Evaluation"
              title="51 End Evaluation"
              coords="983,114,979,111,974,109,969,109,964,111,960,113,956,117,954,122,953,127,953,174,954,180,956,184,960,188,964,191,969,192,974,192,979,190,983,187,985,187,985,185,988,180,989,174,989,127,988,121,985,116,985,114,983,114"
              onClick={() => setActionNumber(51)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 51)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="50 Evaluation Certificate"
              origtitle="50 Evaluation Certificate"
              title="50 Evaluation Certificate"
              coords="904,163,913,164,922,166,930,170,930,125,887,125,887,124,877,124,877,125,835,125,835,170,842,173,850,175,858,176,867,175,875,173,882,170,889,166,897,164,904,163,904,163,904,163"
              onClick={() => setActionNumber(50)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 50)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="45 Prepare Vendor Section of Check-out"
              origtitle="45 Prepare Vendor Section of Check-out"
              title="45 Prepare Vendor Section of Check-out"
              coords="787,722,882,722,882,672,787,672,787,722"
              onClick={() => setActionNumber(45)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 45)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="43 Prepare Validator Section of Check-out"
              origtitle="43 Prepare Validator Section of Check-out"
              title="43 Prepare Validator Section of Check-out"
              coords="698,479,778,479,778,369,698,369,698,479"
              onClick={() => setActionNumber(43)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 43)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="38 Triage Review Evaluator Section of Check-out"
              origtitle="38 Triage Review Evaluator Section of Check-out"
              title="38 Triage Review Evaluator Section of Check-out"
              coords="560,442,659,442,659,383,560,383,560,442"
              onClick={() => setActionNumber(38)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 38)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="30 Lab Status Updates"
              origtitle="30 Lab Status Updates"
              title="30 Lab Status Updates"
              coords="197,236,205,239,213,241,221,242,229,241,237,239,244,236,252,233,260,230,268,230,276,230,284,233,292,236,292,192,197,192,197,236"
              onClick={() => setActionNumber(30)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 30)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="29 Prepare Periodic Lab  Status Updates"
              origtitle="29 Prepare Periodic Lab  Status Updates"
              title="29 Prepare Periodic Lab  Status Updates"
              coords="183,507,301,507,301,461,183,461,183,507"
              onClick={() => setActionNumber(29)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 29)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="32 Sync Session Notes"
              origtitle="32 Sync Session Notes"
              title="32 Sync Session Notes"
              coords="309,262,319,266,329,267,339,267,349,266,358,262,368,259,378,257,388,257,397,259,407,262,407,227,309,227,309,262"
              onClick={() => setActionNumber(32)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 32)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="31 Sync Session (if needed)"
              origtitle="31 Sync Session (if needed)"
              title="31 Sync Session (if needed)"
              coords="324,525,392,525,392,423,324,423,324,525"
              onClick={() => setActionNumber(31)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 31)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="22 Begin Evaluation"
              origtitle="22 Begin Evaluation"
              title="22 Begin Evaluation"
              coords="107,545,103,541,98,539,93,539,88,540,83,542,79,546,77,551,76,556,76,616,77,621,79,626,83,630,88,632,93,633,98,633,103,631,107,627,108,627,108,626,111,621,112,616,112,556,111,551,108,546,108,545,107,545"
              onClick={() => setActionNumber(22)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 22)}
            />
            <area
              shape="polygon"
              tabIndex="1"
              alt="28 Evaluate Product"
              origtitle="28 Evaluate Product"
              title="28 Evaluate Product"
              coords="183,633,301,633,301,539,183,539,183,633"
              onClick={() => setActionNumber(28)}
              onKeyUp={(event) => keyUpSetActionNumber(event, 28)}
            />
          </map>
        </Col>
      </Row>
    </Container>
  );
};

export default Evaluation;
