import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState, useEffect } from "react";
import { Modal, Button, Accordion } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { useInterval } from "../../../../hooks/useInterval";

import NotesForm from "./NotesForm";

export default function NISTNotes({ item, note_type, user, id, refetchNotes }) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState({});
  const [inResponseTo, setInResponseTo] = useState();
  const [toDeleteNote, setToDeleteNote] = useState();
  const [totalNotes, setTotalNotes] = useState();
  const [expandAll, setExpandAll] = useState(false);

  const openNoteModal = (replyTo = null, note = null) => {
    setInResponseTo(replyTo);
    setCurrentNote(note);
    setShowNoteModal(true);
  };

  const closeNoteModal = () => {
    setShowNoteModal(false);
    fetchNotes();
  };

  const openDeleteModal = (note) => {
    setToDeleteNote(note);
    setShowDeleteModal(true);
  };

  const deleteNote = (note_id) => {
    axios
      .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/${note_id}/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(() => {
        fetchNotes();
        setShowDeleteModal(false);
      })
      .catch((error) => alert("Unable to delete note: " + error));
  };

  const url = () => {
    if (note_type === "nist") {
      return `get_nist_notes_by_nist_id/?nist_id=${id}`;
    } else {
      return `get_notes_by_type_and_type_id/?note_type=${note_type}&note_type_id=${id}`;
    }
  };

  const fetchNotes = async () => {
    await axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/${url()}`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        const unfilteredNotes = response.data;
        setTotalNotes(unfilteredNotes.length);
        const filteredNotes = [];
        const responses = {};
        unfilteredNotes.forEach((note) => {
          if (!note.in_response_to) {
            filteredNotes.push(note);
          } else {
            if (!responses[note.in_response_to.note_id]) {
              responses[note.in_response_to.note_id] = [];
            }
            responses[note.in_response_to.note_id].push(note);
          }
        });
        for (let key in responses) {
          const children = responses[key];
          children.forEach((note, idx) => {
            if (responses[note.note_id]) {
              note["responses"] = responses[note.note_id];
              responses[key][idx] = note;
            }
          });
        }
        filteredNotes.forEach((note) => {
          if (responses[note.note_id]) {
            note["responses"] = responses[note.note_id];
          }
        });
        setNotes(filteredNotes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNotes();
  }, [refetchNotes]);

  useInterval(() => {
    fetchNotes();
  }, 180000); //3 min

  const childNotes = (note) => {
    return (
      <>
        <div className="text-end">
          <button
            className="btn btn-link d-inline-block"
            style={{ minWidth: 10 }}
            onClick={() => openNoteModal(note.note_id)}
          >
            Reply
          </button>
          {note?.submitted_by?.id === user?.id && (
            <>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal(null, note)}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 ms-3">
          <p className="text-end">
            Note by: {note.submitted_by?.first_name}{" "}
            {note.submitted_by?.last_name} <br />
            {note.submitted_on &&
              moment(note.submitted_on).format("YYYY.MM.DD [at] HHmm")}
            <br />
            Visible to Labs:{note.visible_to_labs ? " Yes" : " No"}
          </p>
          <p className="fw-bold">{note.title}</p>
          <div>{note.note && parse(note.note)}</div>
          {note.responses && (
            <Accordion defaultActiveKey={note.note_id}>
              <Accordion.Item eventKey={expandAll ? note.note_id : null}>
                <Accordion.Header>
                  {note.responses.length} Responses
                </Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.note_id}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  const displayNotes = () => {
    return notes.map((note) => (
      <div key={note.note_id}>
        <div className="text-end">
          <button
            className="btn btn-link d-inline-block"
            style={{ minWidth: 10 }}
            onClick={() => openNoteModal(note.note_id)}
          >
            Reply
          </button>
          {note?.submitted_by?.id === user?.id && (
            <>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal(null, note)}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 m-4">
          <p className="text-end">
            Note by: {note.submitted_by?.first_name}{" "}
            {note.submitted_by?.last_name} <br />
            {note.submitted_on &&
              moment(note.submitted_on).format("YYYY.MM.DD [at] HHmm")}
            <br />
            Visible to Labs:{note.visible_to_labs ? " Yes" : " No"}
          </p>
          <p className="fw-bold">{note.title}</p>
          <div>{note.note && parse(note.note)}</div>
          {note.responses && (
            <Accordion defaultActiveKey={note.note_id}>
              <Accordion.Item eventKey={expandAll ? note.note_id : null}>
                <Accordion.Header>
                  {note.responses.length} Responses
                </Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.note_id}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
    ));
  };

  return (
    <>
      {notes?.length > 0 && (
        <div>
          <button
            className="btn btn-primary d-inline-block w-sm-100"
            style={{ minWidth: 10 }}
            onClick={() => setExpandAll(!expandAll)}
          >
            Expand All
          </button>
        </div>
      )}
      <div className="text-end">
        <button
          className="btn btn-link d-inline-block"
          style={{ minWidth: 10 }}
          onClick={() => openNoteModal()}
        >
          Add
        </button>
      </div>
      {notes?.length > 0 && displayNotes()}
      <NotesForm
        show={showNoteModal}
        note_type={note_type}
        currentNote={currentNote}
        item={item}
        user={user?.username}
        handleCloseNotesForm={closeNoteModal}
        replyTo={inResponseTo}
        id={id}
      />
      {showDeleteModal && (
        <Modal
          size="md"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Delete Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center">
              Are You Sure You Want to Delete this By: <br />
              {toDeleteNote.submitted_by?.first_name}{" "}
              {toDeleteNote.submitted_by?.last_name} on: <br />
              {toDeleteNote.submitted_on &&
                moment(toDeleteNote.submitted_on).format(
                  "YYYY.MM.DD [at] HHmm",
                )}
            </h4>
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="primary"
                className="m-1"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="m-1"
                onClick={() => deleteNote(toDeleteNote.note_id)}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
