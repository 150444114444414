import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Resources,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  DateNavigator,
  EditRecurrenceMenu,
  AppointmentForm,
  AppointmentTooltip,
  AllDayPanel,
  DragDropProvider,
} from "@devexpress/dx-react-scheduler-material-ui";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useCookies } from "react-cookie";

import "./Calendar.css";

const Calendar = ({
  statuses,
  products,
  appointments,
  loadAppointments,
  setStartDate,
  setEndDate,
}) => {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [currentView, setCurrentView] = useState("Month");

  const moveToDay = (date) => {
    setCurrentDate(moment.utc(date).format("YYYY-MM-DD"));
    setCurrentView("Day");
  };

  const moveToWeek = (date) => {
    setCurrentDate(moment.utc(date).format("YYYY-MM-DD"));
    setCurrentView("Week");
  };

  const handleEdit = ({ added, changed, deleted }) => {
    if (changed) {
      const id = Object.keys(changed)[0];
      const updates = changed[id];
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${id}/`,
          updates,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          },
        )
        .then((response) => {
          loadAppointments();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (deleted) {
      axios
        .delete(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/${deleted}/`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          },
        )
        .then((response) => {
          loadAppointments();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/`, added, {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        })
        .then((response) => {
          loadAppointments();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const resources = [
    {
      fieldName: "statusId",
      title: "Status",
      instances: statuses,
    },
    {
      fieldName: "productId",
      title: "Project",
      instances: products,
    },
  ];

  const onCurrentDateChange = (event) => {
    setCurrentDate(event);
    const startDate = moment(event).startOf(currentView.toLowerCase()).toJSON();
    const endDate = moment(event).endOf(currentView.toLowerCase()).toJSON();
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onCurrentViewNameChange = (event) => {
    setCurrentView(event);
    const startDate = moment(currentDate).startOf(event.toLowerCase()).toJSON();
    const endDate = moment(currentDate).endOf(event.toLowerCase()).toJSON();
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const MonthTimeTableCell = (props) => (
    <MonthView.TimeTableCell
      {...props}
      // onDoubleClick = {() => moveToDay(props.startDate)}
      style={{ height: 200 }}
    />
  );

  const MonthDayScaleCell = (props) => (
    <MonthView.DayScaleCell
      {...props}
      onClick={() => moveToWeek(props.startDate)}
    />
  );

  const WeekDayScaleCell = (props) => (
    <WeekView.DayScaleCell
      {...props}
      onClick={() => moveToDay(props.startDate)}
    />
  );

  const WeekTimeTableCell = (props) => (
    <WeekView.TimeTableCell
      {...props}
      onDoubleClick={() => moveToDay(props.startDate)}
      //   style={{height: 200}}
    />
  );

  const WeekTimeScaleLabel = (props) => (
    <WeekView.TimeScaleLabel {...props} style={{ height: 200 }} />
  );

  const DayTimeTableCell = (props) => (
    <DayView.TimeTableCell {...props} style={{ height: 200 }} />
  );

  const DayTimeScaleLabel = (props) => (
    <DayView.TimeScaleLabel {...props} style={{ height: 200 }} />
  );

  const AllDayCell = (props) => (
    <AllDayPanel.Cell {...props} style={{ height: 200 }} />
  );

  const AllDayTItleCell = (props) => (
    <AllDayPanel.TitleCell {...props} style={{ height: 200 }} />
  );

  const StyledAppoinment = (props) => (
    <Appointments.AppointmentContent {...props} style={{ color: "#000" }} />
  );

  const CustomAppointmentForm = (props) => (
    <AppointmentForm.Layout {...props} isRecurrence={false} />
  );

  return (
    <Scheduler data={appointments}>
      <EditingState onCommitChanges={handleEdit} />
      <ViewState
        currentDate={currentDate}
        onCurrentDateChange={onCurrentDateChange}
        currentViewName={currentView}
        onCurrentViewNameChange={onCurrentViewNameChange}
      />
      <DayView
        startDayHour={6}
        endDayHour={19}
        // timeTableCellComponent={DayTimeTableCell}
        // timeScaleLabelComponent={DayTimeScaleLabel}
      />
      <WeekView
        startDayHour={6}
        endDayHour={19}
        // timeTableCellComponent={WeekTimeTableCell}
        dayScaleCellComponent={WeekDayScaleCell}
        // timeScaleLabelComponent={WeekTimeScaleLabel}
      />
      <MonthView
        timeTableCellComponent={MonthTimeTableCell}
        dayScaleCellComponent={MonthDayScaleCell}
      />
      <AllDayPanel
        cellComponent={AllDayCell}
        titleCellComponent={AllDayTItleCell}
      />
      <Toolbar />
      <DateNavigator />
      <ViewSwitcher />
      <Appointments appointmentContentComponent={StyledAppoinment} />
      <EditRecurrenceMenu />
      <DragDropProvider />
      <Resources data={resources} mainResourceName="statusId" />
      <AppointmentTooltip showCloseButton showDeleteButton showOpenButton />
      <AppointmentForm layoutComponent={CustomAppointmentForm} />
    </Scheduler>
  );
};

export default Calendar;
