import moment from "moment";
import { Row, Col, Form } from "react-bootstrap";

export default function CAVPCertificateFormSection({
  product,
  editedProduct,
  handleChange,
}) {
  return (
    <Row className="mt-4">
      <Col sm={6}>
        <p className="fw-bold form-title">CAVP CERTIFICATE</p>
        <Form.Group className="mb-3" controlId="nist_required">
          <Form.Check
            inline
            label={<span className="text-dark">CAVP Certificate Required</span>}
            name="nist_required"
            type="checkbox"
            defaultChecked={product?.nist_required === true ? true : false}
            value={editedProduct?.nist_required}
            onChange={handleChange}
            id="nist_required"
          />
        </Form.Group>
        {(product?.nist_required === true ||
          editedProduct?.nist_required === true) && (
          <Form.Group className="mb-3" controlId="nist_completion">
            <Form.Label>Estimated CAVP Certificate completed date:*</Form.Label>
            <Form.Control
              type="date"
              name="nist_completion"
              defaultValue={moment
                .utc(product?.nist_completion)
                .format("YYYY-MM-DD")}
              onChange={handleChange}
              required
            />
          </Form.Group>
        )}
      </Col>
    </Row>
  );
}
