import moment from "moment";
import { Row, Col, Form } from "react-bootstrap";

export default function ProjectedDatesFormSection({ product, handleChange }) {
  return (
    <Row className="mb-3">
      <Row>
        <Col lg={12} className="mt-3">
          <p className="fw-bold form-title">PROJECTED COMPLETION DATES</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="st_evaluation_date">
            <Form.Label>Progress Point 3: ST Evaluation Completed*</Form.Label>
            <Form.Control
              type="date"
              name="st_evaluation_date"
              defaultValue={moment
                .utc(product?.st_evaluation_date || "")
                .format("YYYY-MM-DD")}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="test_ready_date">
            <Form.Label>Progress Point 4: Test Ready*</Form.Label>
            <Form.Control
              type="date"
              name="test_ready_date"
              defaultValue={moment
                .utc(product?.test_ready_date || "")
                .format("YYYY-MM-DD")}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="testing_complete_date">
            <Form.Label>Progress Point 5: Testing Complete*</Form.Label>
            <Form.Control
              type="date"
              name="testing_complete_date"
              defaultValue={moment
                .utc(product?.testing_complete_date || "")
                .format("YYYY-MM-DD")}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3" controlId="evaluator_checkout_date">
            <Form.Label>Progress Point 6: Evaluator Check-out*</Form.Label>
            <Form.Control
              type="date"
              name="evaluator_checkout_date"
              defaultValue={moment
                .utc(product?.evaluator_checkout_date || "")
                .format("YYYY-MM-DD")}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
    </Row>
  );
}
