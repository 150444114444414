import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
  name: "role",
  initialState: {
    value: {},
  },
  reducers: {
    addRole: (state, role) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = role.payload;
    },
    removeRole: (state) => {
      state.value = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { addRole, removeRole } = roleSlice.actions;

export default roleSlice.reducer;
