import axios from "axios";
import FileDownload from "js-file-download";
import Button from "react-bootstrap/Button";

import { useAuth } from "../../../hooks/useAuth";

const PfaNameCell = ({ file, setOverlayActive, setOverlayText }) => {
  const { authToken } = useAuth();

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);

    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        setOverlayActive(false);
        console.log("Error");
      });
  };

  return (
    <Button
      style={{ overflowWrap: "break-word", textAlign: "left" }}
      variant="link"
      onClick={() => downloadFile(file)}
    >
      {file.file_new_name || file.file_name}
    </Button>
  );
};

export default PfaNameCell;
