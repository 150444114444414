import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useContext } from "react";

import { PfaContext } from "../..";

const PfaActionMenuItem = ({ file, Icon, label, onClick }) => {
  const { setActiveFile, modals } = useContext(PfaContext);
  const modal = modals[label.toLowerCase()];

  return (
    <MenuItem
      onClick={() => {
        if (onClick) {
          onClick(file);
        }
        setActiveFile(file);
        modal && modal.open();
      }}
    >
      <ListItemIcon>
        <Icon color="action" />
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
};

export default PfaActionMenuItem;
