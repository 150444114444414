const moment = require("moment");
// Due to server timezone being in utc, we need to convert the dates to utc
const momentConvertUTC = (date) => moment.utc(date).startOf("day");

// Function is used to calculate the days in progress for a project
export const getDaysInProgressProduct = (product) => {
  const { kicked_off_date, posted_date, termination_date } = product;
  if (!kicked_off_date) return 0;

  const getEndDate = () => {
    if (posted_date) return momentConvertUTC(kicked_off_date);
    else if (termination_date) return momentConvertUTC(termination_date);
    else return momentConvertUTC(moment());
  };

  const startDate = momentConvertUTC(kicked_off_date);
  const endDate = getEndDate();

  return calculateDaysInProgress(startDate, endDate);
};

// Function is used to calculate the days in progress for a project from the kicked_off_date to the current date
export const calculateDaysInProgressProduct = (product) => {
  const { kicked_off_date } = product;
  return calculateDaysInProgress(kicked_off_date, moment.utc());
};

// Function is used to calculate the  days from start to end date
export const calculateDaysInProgress = (start, end) => {
  const startDate = momentConvertUTC(start);
  const endDate = momentConvertUTC(end);
  return endDate.diff(startDate, "days");
};

// Function is used to calculate the Current date with the time offset needed
// Note: Due to our server being in UTC, time is converted 4 hours behind the EST to be post/put as UTC
// Example: 10:30am EST -> 6:30am EST to Server -> 10:30am UTC
export const getCurrentDate = () => {
  // Convert the current date to fake EST time (For UTC server)
  const now = new Date();
  const offset = now.getTimezoneOffset();
  now.setMinutes(now.getMinutes() - offset);
  return now;
};

// Function is used to get the Current date in ISO format
//   This is identical to the following snippet. Pasting here for searchability.
//   > new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString();
export const getISOCurrentDate = () => {
  const now = getCurrentDate();
  return now.toISOString();
};

// Function is used to check if date is a weekend and if so, convert it to the next Monday
export const convertWeekend = (inputDate) => {
  let date, isMomentObj;

  // Check if the input is a Moment.js object
  if (moment.isMoment(inputDate)) {
    date = inputDate.clone().utc(); // Clone to avoid mutating the original Moment object
    isMomentObj = true;
  }
  // Check if the input is a native Date object
  else if (inputDate instanceof Date) {
    date = moment.utc(inputDate); // Convert Date to Moment in UTC
    isMomentObj = false;
  }
  // If the input is neither, throw an error
  else {
    return inputDate; // Return the input as is if it's not a valid date
  }

  const day = date.day(); // Get the day of the week (0 - Sunday, 6 - Saturday)

  // Adjust the date if it's a weekend
  if (day === 0) {
    // Sunday
    date.add(1, "days"); // Move to Monday
  } else if (day === 6) {
    // Saturday
    date.subtract(1, "days"); // Move to Friday
  }

  // Return the date in the same format as the input
  return isMomentObj ? date : date.toDate();
};

// function will return the current date in UTC without any time
export const getCurrentUTCDate = () => {
  return momentConvertUTC(moment()).format("YYYY-MM-DD");
};
