import axios from "axios";
import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

const AddEventLogModal = ({ show, onHide, product }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const currentUser = useSelector((state) => state.user.value);
  const [eventDetails, setEventDetails] = useState({}); //will hold detail and entrydate from form
  const [formKey, setFormKey] = useState("");

  const handleChange = (e) => {
    setEventDetails({ ...eventDetails, [e.target.name]: e.target.value });
  };

  const handleAddEvent = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}event/log/`,
        {
          ...eventDetails,
          event_type: "Ad-hoc Event",
          creator: currentUser.id,
          objid: product?.v_id,
          obj: "Product",
          coreelem: "VID",
          coreid: product?.v_id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        onHide();
      })
      .catch((error) => {
        console.log("Unable to add event log item: ", error);
      });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setEventDetails({});
    setFormKey(Date.now());
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add an Ad-hoc Event for VID {product?.v_id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form key={formKey}>
          <Form.Group controlId="eventDetails">
            <Form.Label>Event Including Details</Form.Label>
            <Form.Control
              type="text"
              name="detail"
              value={eventDetails.detail}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="eventDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="datetime-local"
              name="entrydate"
              value={eventDetails.entrydate}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="primary" onClick={handleAddEvent}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEventLogModal;
