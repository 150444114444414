import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../../UI/UnauthorizedView";

import TechnicalQueriesTable from "./TechnicalQueriesTable";
import TRRTDetailsPage from "./TRRTDetailsPage";

export default function TechnicalQueryManager({
  setOverlayActive,
  setOverlayText,
}) {
  const permissions = useSelector((state) => state.role.value);

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Technical Questions",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<TechnicalQueriesTable />} />
      <Route
        path="details/:id"
        element={
          <TRRTDetailsPage
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
    </Routes>
  );
}
