const COLORS = { GREY: "#cbcbcb", BLUE: "#12315f", GOLD: "#f8c95a" };
export const Award = ({ votes = [] }) => {
  const count = votes.length;
  const niapApproval = votes.find(
    (vote) =>
      vote.approved === true && vote.voter.user_role.role_type === "NIAP",
  );

  const fill =
    count > 0
      ? niapApproval || count >= 3
        ? COLORS.GOLD
        : COLORS.BLUE
      : COLORS.GREY;

  return (
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="22.283" height="31.241">
        <g data-name="Group 1023">
          <g id="award-badge" fill={fill}>
            <path
              data-name="Path 1122"
              d="M11.141 0 14.4 1.687l3.628.551 1.644 3.28L22.283 8.1l-.6 3.62.6 3.62-2.611 2.577-1.644 3.28-3.628.551-3.259 1.687-3.259-1.687-3.628-.551-1.648-3.284L0 15.336l.6-3.62-.6-3.62 2.606-2.578 1.644-3.28 3.628-.551Z"
            />
            <path
              id="award-ribbon"
              data-name="Path 1123"
              d="M3.331 23.029v8.213l7.81-1.953 7.81 1.953v-8.213l-3.94.6-3.87 2-3.87-2Z"
            />
          </g>
          <text
            data-name="text"
            transform="translate(-0.25 -2.5)"
            fill="#fff"
            fontSize="14"
            fontFamily="sans-serif"
            textAnchor="middle"
            dominantBaseline="middle"
            x="50%"
            y="50%"
          >
            <tspan>{count}</tspan>
          </text>
        </g>
      </svg>
    </span>
  );
};
