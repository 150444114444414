import axios from "axios";
import { useState, useEffect } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import EARReviewsTable from "../Dashboard/ProductsModule/EARReviewsTable";
import UnauthorizedView from "../UI/UnauthorizedView";

import EARDetails from "./EARDetails";

export default function CheckPermEARManager({
  setOverlayActive,
  setOverlayText,
}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [ertMember, setERTMember] = useState();
  const permissions = useSelector((state) => state.role.value);
  const user = useSelector((state) => state.user.value);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member?user=${user.id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setERTMember(response.data.is_member);
        })
        .catch((err) =>
          console.log("unable to get member's virtual teams", err),
        );
    }
  }, [user]);

  if (permissions?.role_type === "NIAP") {
    return (
      <EARManager
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    );
  } else if (
    permissions?.role_type === "Validator" &&
    permissions?.role_permissions?.find(
      (permission) => permission.area === "EAR Reviews",
    )?.read
  ) {
    return (
      <EARManager
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    );
  } else if (permissions?.role_type === "SME" && ertMember === true) {
    return (
      <EARManager
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    );
  } else if (
    permissions?.role_type === "Lab" ||
    (permissions?.role_type !== "NIAP" &&
      permissions?.role_type !== "Validator" &&
      !permissions?.role_permissions?.find(
        (permission) => permission.area === "EAR Reviews",
      )?.read &&
      permissions?.role_type !== "SME" &&
      ertMember === false)
  ) {
    return <UnauthorizedView />;
  }

  return <div></div>;
}

function EARManager({ setOverlayActive, setOverlayText }) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [jumpEar, setJumpEar] = useState({});
  const [alert, setAlert] = useState({});

  const navigate = useNavigate();

  const handleChange = (e) => {
    setJumpEar({ ...jumpEar, [e.target.name]: e.target.value });
  };

  const jump = (e) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${jumpEar.earid}`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setAlert({});
        navigate(`/community/ear-reviews/details/${response.data.earid}`);
      })
      .catch((error) => {
        console.log(error.response.data.detail);
        setAlert({
          message: `Error completing Request: ${error.response.data.detail}`,
          variant: "warning",
        });
      });
  };

  return (
    <>
      {alert?.message && (
        <Alert
          variant={alert?.variant}
          dismissible
          onClose={() => setAlert({})}
        >
          {alert?.message}
        </Alert>
      )}
      <Form onSubmit={jump}>
        <Row className="justify-content-end mb-2">
          <Col xs={12} md={3}>
            <Form.Group controlId="jumpbox">
              <Form.Label className="visually-hidden">Jump to EAR</Form.Label>
              <Form.Control
                name="earid"
                onChange={handleChange}
                placeholder="Jump to EAR"
              />
            </Form.Group>
          </Col>
          <Col xs={12} md="auto">
            <Button type="submit" className="w-100 w-md-auto mt-2 mt-md-0">
              Jump
            </Button>
          </Col>
        </Row>
      </Form>
      <Routes>
        <Route path="/" element={<EARReviewsTable filter={true} />} />
        <Route
          path="details/:id"
          element={
            <EARDetails
              setOverlayActive={setOverlayActive}
              setOverlayText={setOverlayText}
            />
          }
        />
      </Routes>
    </>
  );
}
