import Input from "@mui/material/Input";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import MaterialTable from "material-table";
import qs from "qs";
import { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  Form,
  Alert,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { handleAlertAndMailer } from "../../Products/Helper/functions";
import ReactSelect from "../../ReactSelect";

export default function ManageAMValidationTeamModal({
  show,
  setShow,
  currentProductInEval,
  refetch,
  alertView,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [availableValidators, setAvailableValidators] = useState([]);
  const [selectedValidationMembers, setSelectedValidationMembers] = useState(
    [],
  );
  const [alert, setAlert] = useState({});
  const [myOrg, setMyOrg] = useState({});
  const [newValidator, setNewValidator] = useState({});
  const [validations, setValidations] = useState([]);
  const [contractVehicles, setContractVehicles] = useState([]);
  const tableRef = useRef();
  const teamApprovalStatus =
    currentProductInEval?.current_assurance_maintenance
      ?.validation_team_approval_status;

  function refreshPage() {
    window.location.reload(false);
  }

  const checkTeamMemberRequirements = () => {
    //role here is a char field we are setting
    const hasSeniorValidator = (member) =>
      member.role === "Sr. Validator" || member.role === "Senior Validator";
    const hasLeadValidator = (member) => member.role === "Lead Validator";

    const seniorExists = selectedValidationMembers?.some(hasSeniorValidator);
    const leadExists = selectedValidationMembers?.some(hasLeadValidator);

    return seniorExists && leadExists;
  };

  const handleClose = () => {
    setShow(false);
    setAlert({});
    setNewValidator({});
  };

  //get currentUser's Org
  const getUsersOrg = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setMyOrg(response.data);
        tableRef.current.onQueryChange();
      })
      .catch((error) => {
        console.log("error getting user org", error);
      });
  };

  //get validation team from am maintenance
  const fetchSelectedAMPersonnel = useCallback(() => {
    if (myOrg?.org_id) {
      let currentProductInEvalID =
        currentProductInEval?.current_assurance_maintenance?.maintenance_id ??
        currentProductInEval?.current_assurance_maintenance;
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/all_product_validators/?maintenance=${currentProductInEvalID}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          if (response.data?.length > 0) {
            setSelectedValidationMembers(response.data);
          } else {
            getPreSetValidationMembers();
          }
        })
        .catch((error) => {
          console.log("error getting validation members", error);
        });
    }
  }, [myOrg, currentProductInEval]);

  useEffect(() => {
    if (show) {
      getUsersOrg();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      fetchSelectedAMPersonnel();
    }
  }, [show, myOrg, fetchSelectedAMPersonnel]);

  const getPreSetValidationMembers = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/get_pre_set_validation_members?product_id=${currentProductInEval?.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        handleSaveAllMembers(response.data);
      })
      .catch((error) => {
        console.log("error getting members to AM Team", error);
      });
  };

  const fetchAvailableValidators = useCallback(() => {
    let currentProductInEvalID =
      currentProductInEval?.current_assurance_maintenance?.maintenance_id ??
      currentProductInEval?.current_assurance_maintenance;
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/available_validators/?maintenance=${currentProductInEvalID}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        let availableMemberOptions = response.data.map((member) => ({
          value: member?.user_id?.id,
          label: member?.user_id?.first_name + " " + member?.user_id?.last_name,
        }));
        setAvailableValidators(availableMemberOptions);
      })
      .catch((error) => {
        console.log("error getting available members", error);
      });
  }, [currentProductInEval?.current_assurance_maintenance, show]);

  const fetchValidations = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/filter_validations/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setValidations(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const fetchContractVehicles = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}settings/vehicles/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setContractVehicles(response.data.results);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (show) {
      fetchContractVehicles();
    }
  }, [show]);

  useEffect(() => {
    if (show && currentProductInEval.current_assurance_maintenance) {
      fetchAvailableValidators();
    }
  }, [show, currentProductInEval.current_assurance_maintenance]);

  useEffect(() => {
    if (show) {
      fetchValidations();
    }
  }, [show, fetchValidations]);

  const refetchValInfo = () => {
    tableRef.current.onQueryChange();
    fetchAvailableValidators();
    fetchSelectedAMPersonnel();
  };

  const refetchSelectedInfo = () => {
    tableRef.current.onQueryChange();
  };

  const handleChange = (e) => {
    if (tableRef.current.state.lastEditingRow === undefined) {
      setNewValidator({ ...newValidator, [e.target.name]: e.target.value });
    }
  };

  const updateAMProgressPoint = () => {
    if (checkTeamMemberRequirements() === false) {
      setAlert({
        type: "Senior Validator and Lead Validator Required For Validation Team Submission.",
        variant: "danger",
      });
    } else {
      setAlert({});
      let currentProductInEvalID =
        currentProductInEval?.current_assurance_maintenance?.maintenance_id ??
        currentProductInEval?.current_assurance_maintenance;
      //only update progress point 2 if Not started
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_progress_point/?maintenance_id=${currentProductInEvalID}&progress_point=2`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          if (response.data.status === "Not Started") {
            //if not started, we update the progress point
            axios
              .put(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/update_progress/`,
                {
                  maintenance: currentProductInEvalID,
                  progress_point: 2,
                  status: "In Progress",
                },
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                if (response.data.status === "Update Already Exists") {
                  refreshPage();
                }
              })
              .catch(() => {
                setAlert({
                  type: "Error updating progress point.",
                  variant: "danger",
                });
              });
          }
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/${currentProductInEvalID}/`,
              {
                validation_team_approval_status: "Submitted",
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then(() => {
              setAlert({
                type: "Successfully sent for review!",
                variant: "success",
              });
              handleClose();
              handleSendAlertAndMail();
              refetch();
            })
            .catch((error) => {
              console.log("error getting user", error);
              setAlert({
                type: "Error submitting team for review",
                variant: "danger",
              });
            });
        })
        .catch((error) => {
          console.log("error getting user", error);
        });
    }
  };

  const handleAddMember = async () => {
    let currentProductInEvalID =
      currentProductInEval?.current_assurance_maintenance?.maintenance_id ??
      currentProductInEval?.current_assurance_maintenance;
    if (newValidator["role"] && newValidator["validator"]) {
      let data = {
        pp_team_id: myOrg.org_id,
        assigned_on: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ),
        role: newValidator["role"],
        user_id: newValidator["validator"],
        contract_vehicle: newValidator["contract_vehicle"],
        assigned_by: user?.id,
        team_type: "Validation",
        maintenance_id: currentProductInEvalID,
      };
      //if NIAP, auto approve
      if (
        permissions?.role_type === "NIAP" ||
        teamApprovalStatus === "Approved"
      ) {
        data["approved"] = true;
      }

      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/`,
          data,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          setAlert({
            type: "Successfully Added a New Member!",
            variant: "success",
          });
          setNewValidator({});
          refetchValInfo();
        })
        .catch(() =>
          setAlert({ type: "Error Adding Member.", variant: "danger" }),
        );
    }
  };

  const handleUpdateMember = async (member) => {
    let currentProductInEvalID =
      currentProductInEval?.current_assurance_maintenance?.maintenance_id ??
      currentProductInEval?.current_assurance_maintenance;
    let data = {
      pp_team_id: myOrg.org_id,
      assigned_on: new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ),
      role: member.role,
      user_id: member.user_id?.id,
      contract_vehicle: member.contract_vehicle?.veid,
      assigned_by: user?.id,
      team_type: "Validation",
      maintenance_id: currentProductInEvalID,
    };
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/${member?.id}/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        setAlert({
          type: "Successfully Updated a Member!",
          variant: "success",
        });
        setNewValidator({});
        refetchValInfo();
      })
      .catch(() =>
        setAlert({ type: "Error Adding Member.", variant: "danger" }),
      );
  };

  const handleSaveAllMembers = (members) => {
    let currentProductInEvalID =
      currentProductInEval?.current_assurance_maintenance?.maintenance_id ??
      currentProductInEval?.current_assurance_maintenance;
    if (members?.length > 0) {
      members?.forEach((member) => {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/`,
            {
              pp_team_id: myOrg.org_id,
              assigned_on: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
              role: member?.role_val,
              contract_vehicle: member?.contract_vehicle_val,
              user_id: member?.user_id_val,
              assigned_by: user?.id,
              team_type: "Validation",
              maintenance_id: currentProductInEvalID,
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then(() => {
            refetchValInfo();
          })
          .catch(() =>
            setAlert({ type: "Error Adding Member.", variant: "danger" }),
          );
      });
      tableRef.current.onQueryChange();
    }
  };

  const handleRemoveMember = async (rowData) => {
    await axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/${rowData.id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        refetchSelectedInfo();
        setAlert({
          type: "Successfully Removed a Member!",
          variant: "success",
        });
      })
      .catch(() =>
        setAlert({ type: "Error Removing Member.", variant: "danger" }),
      );
  };

  const getValidationsByUser = (user_id) => {
    let validationLength = validations.filter(
      (val) => val.user_id === user_id,
    ).length;
    return validationLength;
  };

  const getPreRoleOfUser = (user_id) => {
    let preRole = validations.filter((val) => val.user_id === user_id);
    if (preRole.length > 0) {
      return preRole[0]?.role;
    } else {
      return "No Pre-Role";
    }
  };

  const allowedRolesAM = [
    "Lead Validator",
    "Senior Validator",
    "ECR Team",
    "ECR Team(Trainee)",
    "Lead Validator(Trainee)",
    "Staff Liaison",
  ];

  let filteredAvailableValidators = availableValidators.filter(
    (validator) =>
      !selectedValidationMembers?.some(
        (member) => member?.user_id?.id === validator.value,
      ),
  );

  let currentValidators = selectedValidationMembers.map((validator) => {
    return {
      value: validator?.user_id?.id,
      label:
        validator?.user_id?.first_name + " " + validator?.user_id?.last_name,
    };
  });

  const columns = [
    {
      title: "Name",
      field: "user_id.id",
      render: (rowData) =>
        rowData.user_id?.first_name + " " + rowData.user_id?.last_name,
      editComponent: (props) => {
        let availableValidatorOptions = props.value
          ? currentValidators
          : filteredAvailableValidators;
        return (
          <ReactSelect
            options={availableValidatorOptions}
            newObj={
              newValidator?.validator
                ? newValidator
                : { validator: props.value }
            }
            handleChange={handleChange}
            name={"validator"}
            isValid={true}
            editComponent={true}
            readOnly={props.value ? true : false}
          />
        );
      },
    },
    {
      title: "Active Validations",
      field: "count_validations",
      editComponent: (props) => {
        if (newValidator["validator"]) {
          return (
            <p className="m-0">
              {getValidationsByUser(newValidator["validator"])}
            </p>
          );
        } else if (props.value) {
          return <p className="m-0">{props.value}</p>;
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Pre-Roles",
      field: "existing_role",
      editComponent: (props) => {
        if (newValidator["validator"]) {
          return (
            <p className="m-0">{getPreRoleOfUser(newValidator["validator"])}</p>
          );
        } else if (props.value) {
          return <p className="m-0">{props.value}</p>;
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Contract Vehicle",
      field: "contract_vehicle.veid",
      render: (rowData) =>
        rowData.contract_vehicle &&
        (rowData?.contract_vehicle?.desig
          ? rowData?.contract_vehicle?.desig
          : rowData?.contract_vehicle?.vehicle),
      editComponent: (props) => {
        const changeFunction = (e) =>
          props.rowData.id ? props.onChange(e.target.value) : handleChange(e);
        return (
          <>
            <Form.Select
              name="contract_vehicle"
              onChange={changeFunction}
              value={newValidator?.contract_vehicle ?? props.value}
            >
              <option value={""}>Select</option>
              {contractVehicles?.map((vehicle, idx) => (
                <option value={vehicle.veid} key={idx}>
                  {vehicle.desig ? vehicle.desig : vehicle.vehicle}
                </option>
              ))}
            </Form.Select>
          </>
        );
      },
    },
    {
      title: "Assigned",
      field: "role",
      editComponent: (props) => {
        const changeFunction = (e) =>
          props.rowData.id ? props.onChange(e.target.value) : handleChange(e);
        return (
          <>
            <Form.Select
              name="role"
              onChange={changeFunction}
              value={newValidator?.role ?? props.value}
            >
              <option value={""}>Select</option>
              {allowedRolesAM?.map((role, idx) => (
                <option value={role} key={idx}>
                  {role}
                </option>
              ))}
            </Form.Select>
          </>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    pageSize: 20,
  };

  const handleSendAlertAndMail = () => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: currentProductInEval?.product_id,
      alert_type: "Product",
      alert_source: "AM Validation Team Recommended",
      subject: `AM Validation Team Recommended for Maintenance for VID${currentProductInEval.v_id}.`,
      recipients: { to: ["NIAP Management"], cc: ["Validator Resource Team"] },
      alert_text: `AM Validation Team Recommended for Maintenance for VID${currentProductInEval.v_id}.`,
    });
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase me-2">
          Manage and Assign Assurance Maintenance Team{" "}
          {currentProductInEval ? "VID " + currentProductInEval.v_id : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.type && (
          <Alert
            variant={alert.variant}
            className="m-3"
            onClose={() => setAlert({})}
            dismissible
          >
            {alert.type}
          </Alert>
        )}
        <Container>
          {teamApprovalStatus === "Rejected" && (
            <Row>
              <Col sm={6}>
                <Row>
                  <h5 className="fw-bold">Rejection Rationale:</h5>
                </Row>
                <Row className="border border-dark p-3">
                  <p>
                    {
                      currentProductInEval?.current_assurance_maintenance
                        ?.rejection_rationale
                    }
                  </p>
                </Row>
              </Col>
            </Row>
          )}
          {!alertView && permissions?.role_type !== "NIAP" && (
            <Row className="mb-2 mt-3">
              <Col sm={10}>
                <p className="fst-italic mb-0">
                  Senior Validator and Lead Validator Required Before Submission
                  of Validation Team.
                </p>
              </Col>
              <Col
                sm={2}
                style={{ width: "fit-content" }}
                className="d-flex justify-content-end"
              >
                {teamApprovalStatus !== "Approved" && (
                  <Button variant="success" onClick={updateAMProgressPoint}>
                    {teamApprovalStatus === "Rejected" ? "Resubmit" : "Submit"}{" "}
                    Team
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Container>
        <MaterialTable
          title="Validation Team"
          columns={columns}
          tableRef={tableRef}
          data={(query) =>
            new Promise((resolve, reject) => {
              // Extract the necessary information from the query object
              const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
              } = query;

              let newFilters = filters.map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
              });
              let newOrderBy = orderBy?.field;
              if (orderBy?.title === "Active Validations") {
                newOrderBy = "count_validations";
              } else if (orderBy?.title === "Pre-Roles") {
                newOrderBy = "existing_role";
              } else if (orderBy?.title === "Contract Vehicle") {
                newOrderBy = "contract_vehicle.vehicle";
              } else if (orderBy?.title === "Name") {
                newOrderBy = "user_id.first_name";
              }
              const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: newOrderBy,
                orderDirection: orderDirection,
                org: myOrg.org_id,
                maintenance:
                  currentProductInEval?.current_assurance_maintenance
                    ?.maintenance_id ??
                  currentProductInEval?.current_assurance_maintenance,
              };
              axios
                .get(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/my_product_active_validators/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                  {
                    withCredentials: true,
                    headers: {
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then((response) => {
                  resolve({
                    data: response.data.results,
                    page: page,
                    totalCount: response.data.count,
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          }
          options={options}
          editable={
            alertView
              ? false
              : {
                  onRowAdd: () => handleAddMember(),
                  onRowDelete: (rowData) => handleRemoveMember(rowData),
                  onRowUpdate: (newData) => handleUpdateMember(newData),
                }
          }
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                labelRowsPerPage={
                  <label htmlFor="rowsPerPage">Rows Per Page</label>
                }
                SelectProps={{ input: <Input id="rowsPerPage" /> }}
              />
            ),
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
