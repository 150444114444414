import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Alert,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { useAuth } from "../../../hooks/useAuth";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const siteKey = "6Lf29aAjAAAAAIagG9XUSNUz_nXKnwxrK_fDcGf5";

export const SignupCard = () => {
  const { csrfToken } = useAuth();

  const [recaptcha_token, setToken] = useState();
  const [email, setEmail] = useState();
  const [signupStatus, setSignupStatus] = useState("initial");
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = () => {
    if (!emailRegex.test(email)) {
      return;
    }

    if (!recaptcha_token) {
      return;
    }

    sendEmail();
  };

  const sendEmail = async () => {
    setSignupStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}mailer/announcements-signup`,
        { recaptcha_token, email },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) setSignupStatus("success");
      })
      .catch((error) => {
        const { error: errorMessage, details } = error.response.data;
        setSignupStatus("error");
        setErrorMessage(errorMessage);
        console.error(details);
      });
  };

  if (signupStatus === "success") return <SignupSuccess />;

  const invalidEmail = Boolean(email) && !emailRegex.test(email);

  const disableSubscribe =
    !recaptcha_token || !email || invalidEmail || signupStatus === "loading";

  return (
    <Card sx={{ margin: "1em auto", width: "fit-content" }} elevation={3}>
      <CardHeader
        component="h5"
        title="Get Email Updates"
        sx={{ marginBottom: 0 }}
      />

      <CardContent sx={{ padding: "0 2em" }}>
        <p>
          Want to stay up to date with the latest announcements?
          <br />
          Subscribe now to receive email alerts whenever new updates are posted!
        </p>

        <TextField
          id="subscribe-email"
          label="Enter email"
          variant="outlined"
          error={invalidEmail}
          placeholder="example@niap-ccevs.org"
          helperText={invalidEmail && "Please enter a valid email"}
          type="email"
          sx={{ width: "100%" }}
          onChange={(e) => setEmail(e.target.value)}
        />

        {signupStatus === "error" && (
          <Alert
            variant="standard"
            severity="error"
            sx={{
              marginTop: "1em",
            }}
          >
            {errorMessage}
          </Alert>
        )}
      </CardContent>

      <CardActions sx={{ justifyContent: "space-around", padding: "1em 0em" }}>
        <ReCAPTCHA
          id="announcements-recaptcha"
          sitekey={siteKey}
          onChange={(e) => setToken(e)}
          onExpired={(e) => setToken(null)}
        />
        <Button
          variant="contained"
          sx={{ color: "white", padding: ".75em", width: "150px" }}
          onClick={handleSubmit}
          disabled={disableSubscribe}
        >
          Subscribe
        </Button>
      </CardActions>
    </Card>
  );
};

const SignupSuccess = () => {
  return (
    <Card sx={{ margin: "1em auto", width: "fit-content" }} elevation={3}>
      <CardHeader
        avatar={<MarkEmailReadIcon color="success" fontSize="large" />}
        title="Thanks for subscribing!"
        titleTypographyProps={{ variant: "h5" }}
        sx={{ marginBottom: 0 }}
      />

      <CardContent sx={{ padding: "0 2em", margin: 0 }}>
        <p style={{ margin: 0 }}>
          We'll keep you posted with the latest updates, news, and
          announcements!
        </p>
      </CardContent>
    </Card>
  );
};
