import { Step, StepLabel, Stepper } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

export default function StageTracker({ tableRef, projects, rowData, isCCTL }) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [progressPoints, setProgressPoints] = useState({});
  const [maintenanceProgressPoints, setMaintenanceProgressPoints] = useState(
    {},
  );
  const currentRole = useSelector((state) => state.role.value);

  const draftSteps = [
    "Checkin Package saved as Internal Draft. Not sent to Vendors.",
  ];

  const proposedSteps = [
    "Submitted Checkin Package for Review",
    "Checkin Package Signed",
    "NIAP Approved and Ready for Assignment",
    "Validator Team Submitted",
  ];

  const preEvalSteps = [
    "Validator Team Approved by NIAP",
    "Kickoff Recommended by Validator",
    "Kickoff Approved/Evaluation Initiated",
    // "Checkin Meeting Requested",
  ];

  const inProgressSteps = [
    // "Checkin Meeting Completed",
    "Evaluator Checkout Package Received",
    "Validator Checkout Complete",
    "Validator Recommend for Posting",
  ];

  const finalizingSteps = [
    "Validator Checkout Package Accepted",
    "PCL Posting",
  ];

  const certifiedSteps = ["Completed"];

  const terminatedSteps = ["Terminated"];

  const withdrawnSteps = ["Withdrawn"];

  const archivedSteps = ["Archived"];

  const assuranceMaintenanceSteps = [
    "AM Requested",
    "Released By NIAP",
    "Approved by Validators",
    "Team Assigned",
    "ACMR Posted",
    "Approved",
  ];

  const getPhaseSteps = (status) => {
    switch (status) {
      case "Internal Draft":
        return draftSteps;
      case "Draft":
        return proposedSteps;
      case "Proposed":
        return proposedSteps;
      case "Pre-Evaluation":
        return preEvalSteps;
      case "In Progress":
        return inProgressSteps;
      case "Finalizing":
        return finalizingSteps;
      case "Certified":
        return certifiedSteps;
      case "Terminated":
        return terminatedSteps;
      case "Withdrawn":
        return withdrawnSteps;
      case "Archived":
        return archivedSteps;
      case "In Assurance Maintenance":
        return assuranceMaintenanceSteps;
      default:
        return ["Other"];
    }
  };

  const getPhaseName = (status) => {
    switch (status) {
      case "Internal Draft":
        return "Internal Draft";
      case "Draft":
        return "In Draft";
      case "Proposed":
        return "Phase 1: Proposed";
      case "Pre-Evaluation":
        return "Phase 2: Pre-Evaluation";
      case "In Progress":
        return "Phase 3: In Progress";
      case "Finalizing":
        return "Phase 4: Finalizing";
      case "Certified":
        return "Phase 4: Completed";
      case "Terminated":
        return "Terminated";
      case "Withdrawn":
        return "Withdrawn";
      case "Archived":
        return "Archived";
      case "In Assurance Maintenance":
        return "In Assurance Maintenance";
      default:
        return "Terminated";
    }
  };

  const getPhaseColor = (status) => {
    switch (status) {
      case "Internal Draft":
        return {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "warning.main",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "warning.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        };
      case "Pre-Evaluation":
        return {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "info.main",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "info.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        };
      case "In Progress":
        return {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "warning.main",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "warning.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        };
      case "Certified":
      case "Finalizing":
        return {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "success.main",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "success.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        };
      case "Withdrawn":
        return {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "warning.main",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "warning.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        };
      case "Archived":
        return {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "info.main",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "info.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        };
      case "Terminated":
        return {
          "& .MuiStepLabel-root .Mui-completed": {
            color: "danger.main",
          },
          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active": {
            color: "danger.main",
          },
          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: "black",
          },
          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
            fill: "black",
          },
        };
      default:
        break;
    }
  };

  const getStage = (product) => {
    let relaventPPs = progressPoints[product?.product_id]?.sort(
      (a, b) => a?.progress_point - b?.progress_point,
    );
    let relaventMaintenancePPs = maintenanceProgressPoints[
      product?.current_assurance_maintenance?.maintenance_id
    ]?.sort((a, b) => a?.progress_point - b?.progress_point);
    if (!relaventPPs) {
      relaventPPs = [];
    }
    if (!relaventMaintenancePPs) {
      relaventMaintenancePPs = [];
    }
    switch (product.status) {
      case "Draft":
        return 1;
      case "Proposed":
        if (product?.pre_eval_status === "Proposed") {
          return 1; //lab submits checkin package
        } else if (product?.pre_eval_status === "Signed") {
          return 2; //vendor signs
        } else if (product?.pre_eval_status === "Ready for Assignment") {
          return 3; //niap accepts checkin package
        } else if (product?.pre_eval_status === "Submitted") {
          return 4; //validator assigns team
        }
        return 0;
      case "Pre-Evaluation":
        if (
          product?.pre_eval_status === "Approved" &&
          product?.kickoff_status === "Ready for Recommendation"
        ) {
          return 1; //niap approved validation team
        } else if (product?.kickoff_status === "Recommended for Kickoff") {
          return 2; //kickoff recommended by validator
        } else if (
          product?.kickoff_status === "Kicked Off" &&
          relaventPPs[1]?.status !== "In Progress"
        ) {
          return 3; //niap approved kickoff
        }
        return 0;
      case "In Progress":
        if (relaventPPs[6]?.status === "Completed") {
          return 3; //validator recommended for posting
        } else if (relaventPPs[6]?.status === "Ready for Release") {
          return 2; //validator checkout complete
        } else if (relaventPPs[5]?.status === "Awaiting Checkout") {
          return 1; //Evaluator Checkout Package Received
        }
        // else if (relaventPPs[1]?.status === "Completed"){
        //   return 1; //check in meeting completed
        // }
        return 0;
      case "Finalizing":
        if (relaventPPs[7]?.status === "In Progress") {
          return 1; //validator Checkout Package accepted
        } else if (relaventPPs[7]?.status === "Completed") {
          return 2; //pcl posting
        }
        return 0;
      case "Certified":
        return 1; //certified
      case "Withdrawn":
        return 1; //withdrawn
      case "Archived":
        return 1; //archived
      case "Terminated":
        return 1; //terminated
      case "In Assurance Maintenance":
        if (product?.current_assurance_maintenance?.status === "Pending") {
          return 1; //am requested
        } else if (
          product?.current_assurance_maintenance?.status === "Released"
        ) {
          return 2; //released by NIAP
        } else if (
          product?.current_assurance_maintenance?.status ===
            "In Assurance Maintenance" &&
          relaventMaintenancePPs[1]?.status !== "Completed"
        ) {
          return 3; //approved by Validators
        } else if (relaventMaintenancePPs[2]?.status === "In Progress") {
          return 5; //ACMR Posted
        } else if (relaventMaintenancePPs[1]?.status === "Completed") {
          return 4; //Team Assigned
        } else if (relaventMaintenancePPs[3]?.status === "Completed") {
          return 6; //Approved
        } else {
          return 1;
        }
      default:
        return 1;
    }
  };

  const fetchProgressPoints = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_all_grouped_progress_points/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setProgressPoints(response.data))
      .catch((error) => console.log(error));

    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_all_grouped_maintenance_progress_points/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setMaintenanceProgressPoints(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchProgressPoints();
  }, []);

  const options = {
    sorting: true,
    columnsButton: true,
    pageSize: 20,
  };

  return (
    <>
      <div className="p-3 fw-bold">
        The Project State Tracker is designed to help you track the progress of
        projects as they flow through the Evaluation Process.
      </div>
      {!rowData ? (
        <ResponsiveMaterialTable
          title={"Project State Tracker"}
          tableRef={tableRef}
          data={(query) =>
            new Promise((resolve, reject) => {
              // Extract the necessary information from the query object
              const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
              } = query;

              let newFilters = filters.map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
              });
              const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
              };
              axios
                .get(
                  isCCTL
                    ? `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/stage_tracker_products/?${qs.stringify(params, { arrayFormat: "comma" })}`
                    : `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/my_validations/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                  {
                    withCredentials: true,
                    headers: {
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then((response) => {
                  resolve({
                    data: response.data.results,
                    page: page,
                    totalCount: response.data.count,
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          }
          options={options}
          columns={[
            {
              title: "VID",
              render: (rowData) =>
                currentRole.role_type === "Vendor" ? (
                  <strong>{rowData.v_id}</strong>
                ) : (
                  <Link to={`products/details/${rowData.product_id}`}>
                    <strong>{rowData.v_id}</strong>
                  </Link>
                ),
            },
            {
              title: "Product",
              field: "product_name",
            },
            { title: "CCTL", field: "assigned_lab.name" },
            {
              title: "Project State",
              field: "stage",
              cellStyle: { width: "80%" },
              render: (rowData) => (
                <>
                  <h5 className="text-center">
                    {getPhaseName(rowData?.status)}
                  </h5>
                  <Stepper activeStep={getStage(rowData)} alternativeLabel>
                    {getPhaseSteps(rowData?.status).map((label) => (
                      <Step key={label} sx={getPhaseColor(rowData?.status)}>
                        <StepLabel icon={" "}>
                          {label}
                          <br />
                          {label === "Kickoff" && rowData?.kicked_off_date
                            ? moment
                                .utc(rowData?.kicked_off_date)
                                .format("MM/DD/YYYY")
                            : ""}
                          {label === "PCL Posting" && rowData?.posted_date
                            ? moment
                                .utc(rowData?.posted_date)
                                .format("MM/DD/YYYY")
                            : ""}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </>
              ),
            },
          ]}
        />
      ) : (
        <>
          <h5 className="text-center">{getPhaseName(rowData?.status)}</h5>
          <Stepper activeStep={getStage(rowData)} alternativeLabel>
            {getPhaseSteps(rowData?.status).map((label) => (
              <Step key={label} sx={getPhaseColor(rowData?.status)}>
                <StepLabel icon={" "}>
                  {label}
                  <br />
                  {label === "Kickoff" && rowData?.kicked_off_date
                    ? moment.utc(rowData?.kicked_off_date).format("MM/DD/YYYY")
                    : ""}
                  {label === "PCL Posting" && rowData?.certification_date
                    ? moment
                        .utc(rowData?.certification_date)
                        .format("MM/DD/YYYY")
                    : ""}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      )}
    </>
  );
}
