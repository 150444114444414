import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";

export default function BecomeInternationalTcForm({ show, setShowModal }) {
  const [communities, setCommunities] = useState([]);
  const handleClose = () => setShowModal(false);
  const params = {
    is_international: "true",
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}tech/communities/get_all?${qs.stringify(params, { arrayFormat: "comma" })}`,
      )
      .then((response) => {
        setCommunities(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary">
          <h3>BECOME AN INTERNATIONAL TC PARTICIPANT</h3>
        </Modal.Title>
      </Modal.Header>
      <p className="mx-4 mt-3">
        Select an International Technical Community below and be redirected to
        the Common Criteria Website
      </p>
      <Modal.Body>
        <Row className="px-5 pb-5">
          {communities.map((itc) => (
            <Col
              sm={6}
              className="d-flex flex-column p-2"
              key={itc.community_id}
            >
              <a href={itc.url} rel="noreferrer" target="_blank">
                {itc.community_name}
              </a>
            </Col>
          ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
}
