import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { Stack, Accordion, Button, Container, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { useInterval } from "../../hooks/useInterval";
import FileUpload from "../Products/FileUpload";

export default function EARSideInfo({
  review,
  id,
  ERTMember,
  setOverlayActive,
  setOverlayText,
}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const csrfToken = cookies["csrftoken"];
  const [showUpload, setShowUpload] = useState(false);
  const permissions = useSelector((state) => state.role.value);
  const [files, setFiles] = useState([]);

  const fileInfoButton = (file) => {
    return (
      <Row
        className="d-flex justify-content-left align-items-start"
        key={file?.file_id}
      >
        <Col>
          {file.file_mime_type === "application/pdf" && (
            <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
          )}
          {file.file_mime_type !== "application/pdf" && (
            <FontAwesomeIcon icon={faFileLines} color="gray" size="xl" />
          )}
        </Col>
        <Col md={8}>
          <Button
            style={{ overflowWrap: "break-word" }}
            className="text-start m-0 p-0"
            variant="link"
            onClick={() => downloadFile(file, authToken)}
          >
            {file.file_display_name
              ? file.file_display_name
              : file.file_label
                ? file.file_label
                : file.file_new_name
                  ? file.file_new_name
                  : file.file_name}
          </Button>
          <p className="mb-0 ms-3">
            {file.file_new_name ? file.file_new_name : file.file_name}
          </p>
          <p className="mb-0 ms-3">
            Attached by {file?.uploaded_by?.first_name}{" "}
            {file?.uploaded_by?.last_name} on{" "}
            {file?.uploaded_on &&
              moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
          </p>
        </Col>
        {(ERTMember ||
          permissions?.role_type === "NIAP" ||
          permissions?.role_type === "Validator") && (
          <Col>
            <span
              style={{ float: "right", cursor: "pointer" }}
              className="mx-2"
              onClick={() => removeFile(file)}
            >
              X
            </span>
          </Col>
        )}
      </Row>
    );
  };

  const removeFile = async (file) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
        {
          active: false,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => pullFiles())
      .catch((error) => console.log("Unable to update file", error));
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pullFiles = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=ear-review&file_type_id=${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        let activeFiles = response.data?.filter((file) => file.active);
        setFiles(activeFiles);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken, id]);

  const updateFiles = () => {
    setShowUpload(false);
    pullFiles();
  };

  useEffect(() => {
    pullFiles();
  }, [pullFiles]);

  useInterval(() => {
    pullFiles();
  }, 180000); //3 min

  const sortedFiles = files?.sort(
    (a, b) => new Date(b.uploaded_on) - new Date(a.uploaded_on),
  );

  return (
    <div>
      <FileUpload
        show={showUpload}
        updateFiles={updateFiles}
        type="ear-review"
        id={id}
      />
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item>
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">IMPORTANT DATES</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container className="">
              <Row>
                <p>
                  Submission Date:{" "}
                  {review?.entrydate
                    ? moment.utc(review?.entrydate).format("MM/DD/YYYY")
                    : "---"}
                </p>
              </Row>
              <Row>
                <p>
                  Escalation Date:{" "}
                  {review?.release_date
                    ? moment.utc(review?.release_date).format("MM/DD/YYYY")
                    : "---"}
                </p>
              </Row>
              <Row>
                <p>
                  Deadline Date:{" "}
                  {review?.due
                    ? moment.utc(review?.due).format("MM/DD/YYYY")
                    : "---"}
                </p>
              </Row>
              <Row>
                <p>
                  Completion or Termination Date:{" "}
                  {review?.enddate
                    ? moment.utc(review?.enddate).format("MM/DD/YYYY")
                    : "---"}
                </p>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item>
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">ATTACHED DOCUMENTS</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            {sortedFiles?.map((file) => fileInfoButton(file))}
            <div className="text-center">
              {permissions?.role_permissions?.find(
                (permission) => permission.area === "EAR Reviews",
              )?.update && (
                <Button
                  variant="outline-primary rounded-pill mb-2 mt-2"
                  onClick={() => setShowUpload(true)}
                >
                  Upload Files
                </Button>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item>
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">ATTRIBUTION INFORMATION</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container className="">
              <Row className="border-dark border-bottom">
                <p>
                  Record Created:{" "}
                  {moment.utc(review?.entrydate).format("YYYY.MM.DD [at] HHmm")}{" "}
                  by {review?.creator?.first_name} {review?.creator?.last_name}
                </p>
              </Row>
              <Row className="border-dark border-bottom">
                <p>
                  Last Modified:{" "}
                  {review?.moddate
                    ? `${moment.utc(review?.moddate).format("YYYY.MM.DD [at] HHmm")} by
                  ${review?.editor?.first_name} ${review?.editor?.last_name}`
                    : ""}
                </p>
              </Row>
              <Row>
                <p>
                  Official Response Sent to Lab:{" "}
                  {review?.response && review?.response?.creator !== null
                    ? `${moment(review?.response?.entrydate).format(
                        "YYYY.MM.DD [at] HHmm",
                      )} by ${
                        review?.response?.creator?.first_name
                      } ${review?.response?.creator?.last_name}`
                    : ""}
                </p>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
