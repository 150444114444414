import { useEffect, useState } from "react";
import { Button, Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AccountFilter from "./AccountFilter";
import AccountForm from "./AccountForm";
import AccountTable from "./AccountTable";
import UserRolesManager from "./UserRolesManager";

import "./AccountManager.css";
import axios from "axios";

export default function AccountManager() {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];

  const [showModal, setShowModal] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentAccount, setCurrentAccount] = useState({});
  const currentUser = useSelector((state) => state.user.value);
  const [roles, setRoles] = useState([]);
  const permissions = useSelector((state) => state.role.value);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const [filtersActive, setFiltersActive] = useState(false);
  const [updateRefs, setUpdateRefs] = useState();

  useEffect(() => {
    document.title = "NIAP Community - Account Manager";
  }, []);

  const handleShowModal = (e, rowData) => {
    setCurrentAccount({ ...rowData });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseFiltering = () => setFiltering(false);

  const handleArchive = async (e, rowData) => {
    try {
      const is_member_of_org = await axios.get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/personnel/get_personnel_by_user_id/?find_user_id=${rowData.id}`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      );
      if (is_member_of_org.length > 0) {
        const personnel_deactivated = await axios.put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}org/personnel/${is_member_of_org.data[0]?.personnel_id}/`,
          { deleted: !is_member_of_org.data[0].deleted },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        );
        const user_deactivated = await axios.put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${rowData.id}/`,
          {
            ...rowData,
            is_active: !personnel_deactivated.data[0].deleted,
            user_role: rowData.user_role?.role_id,
            company: rowData.company?.org_id,
            creator: rowData.creator?.id,
            editor: rowData.editor?.id,
            scheme: rowData.scheme?.sid,
            account_group: rowData.account_group?.group_id,
            affiliation: rowData?.affiliation?.afid,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        );
        setUpdateRefs(user_deactivated.data.is_active);
      } else {
        const user_deactivated = await axios.put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${rowData.id}/`,
          {
            ...rowData,
            is_active: !rowData.is_active,
            user_role: rowData.user_role?.role_id,
            company: rowData.company?.org_id,
            creator: rowData.creator?.id,
            editor: rowData.editor?.id,
            scheme: rowData.scheme?.sid,
            account_group: rowData.account_group?.group_id,
            affiliation: rowData?.affiliation?.afid,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        );
        setUpdateRefs(user_deactivated.data.is_active);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadRoles = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/get_all_roles`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) =>
        setRoles(response.data.filter((role) => role.deleted === false)),
      )
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadRoles();
  }, []);

  const loadOrgs = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/all_orgs/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => setCompanies(response.data))
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadOrgs();
  }, []);

  const getDefaultActiveKey = () => {
    if (permissions.role_type === "NIAP") {
      return "NIAP";
    } else {
      return permissions?.role_type?.toLowerCase();
    }
  };

  return (
    (permissions?.role_permissions?.find(
      (permission) => permission.area === "Accounts",
    )?.read ||
      permissions?.role_permissions?.find(
        (permission) => permission.area === "Organization Members",
      )?.read) && (
      <Container fluid>
        <Row className="pt-3">
          <Row className="justify-content-end">
            {currentUser?.user_role?.role_type === "NIAP" && (
              <Col xs={12} md={2} className="mb-xs-3">
                <Button
                  className="account-btn w-100"
                  variant={filtering ? "primary" : "light"}
                  onClick={(e) => setFiltering(true)}
                >
                  Filter
                </Button>
              </Col>
            )}
            {permissions?.role_permissions?.find(
              (permission) => permission.area === "Accounts",
            )?.create && (
              <Col xs={12} md={2}>
                <Button
                  className="account-btn me-4 w-100 mb-xs-3"
                  variant="primary"
                  onClick={(e) => handleShowModal(e, {})}
                >
                  + Add Account
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Tabs
                id="account-manager-tabs"
                className="mb-3"
                mountOnEnter
                animation="true"
                defaultActiveKey={() => getDefaultActiveKey()}
              >
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Accounts",
                )?.read &&
                  permissions?.role_type === "NIAP" && (
                    <Tab eventKey="all" title="Accounts">
                      <AccountTable
                        handleShowModal={handleShowModal}
                        handleArchive={handleArchive}
                        accountType="All"
                        sidebarFilters={filters}
                        permissions={permissions}
                        currentUser={currentUser}
                        filtersActive={filtersActive}
                        updateRefs={updateRefs}
                      />
                    </Tab>
                  )}
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Accounts",
                )?.read &&
                  permissions?.role_type === "NIAP" && (
                    <Tab eventKey="NIAP" title="NIAP">
                      <AccountTable
                        handleShowModal={handleShowModal}
                        handleArchive={handleArchive}
                        accountType="NIAP"
                        sidebarFilters={filters}
                        permissions={permissions}
                        currentUser={currentUser}
                        filtersActive={filtersActive}
                        updateRefs={updateRefs}
                      />
                    </Tab>
                  )}
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Accounts",
                )?.read &&
                  (permissions?.role_type === "NIAP" ||
                    permissions?.role_type === "Vendor") && (
                    <Tab eventKey="vendor" title="Vendor">
                      <AccountTable
                        handleShowModal={handleShowModal}
                        handleArchive={handleArchive}
                        accountType="Vendor"
                        sidebarFilters={filters}
                        permissions={permissions}
                        currentUser={currentUser}
                        filtersActive={filtersActive}
                        updateRefs={updateRefs}
                      />
                    </Tab>
                  )}
                {(permissions?.role_type === "NIAP" ||
                  permissions?.role_type === "Lab") &&
                  permissions?.role_permissions?.find(
                    (permission) => permission.area === "Organization Members",
                  )?.read && (
                    <Tab eventKey="lab" title="Lab">
                      <AccountTable
                        handleShowModal={handleShowModal}
                        handleArchive={handleArchive}
                        accountType="Lab"
                        sidebarFilters={filters}
                        permissions={permissions}
                        currentUser={currentUser}
                        filtersActive={filtersActive}
                        updateRefs={updateRefs}
                      />
                    </Tab>
                  )}
                {(permissions?.role_type === "NIAP" ||
                  permissions?.role_type === "Validator") &&
                  permissions?.role_permissions?.find(
                    (permission) => permission.area === "Organization Members",
                  )?.read && (
                    <Tab eventKey="validator" title="Validator">
                      <AccountTable
                        handleShowModal={handleShowModal}
                        handleArchive={handleArchive}
                        accountType="Validator"
                        sidebarFilters={filters}
                        permissions={permissions}
                        currentUser={currentUser}
                        filtersActive={filtersActive}
                        updateRefs={updateRefs}
                      />
                    </Tab>
                  )}
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Accounts",
                )?.read &&
                  permissions?.role_type === "NIAP" && (
                    <Tab eventKey="Tech Community" title="Tech Community">
                      <AccountTable
                        handleShowModal={handleShowModal}
                        handleArchive={handleArchive}
                        accountType="Tech Community"
                        sidebarFilters={filters}
                        permissions={permissions}
                        currentUser={currentUser}
                        filtersActive={filtersActive}
                        updateRefs={updateRefs}
                      />
                    </Tab>
                  )}
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Accounts",
                )?.read &&
                  permissions?.role_type === "NIAP" && (
                    <Tab eventKey="SME" title="SME">
                      <AccountTable
                        handleShowModal={handleShowModal}
                        handleArchive={handleArchive}
                        accountType="SME"
                        sidebarFilters={filters}
                        permissions={permissions}
                        currentUser={currentUser}
                        filtersActive={filtersActive}
                        updateRefs={updateRefs}
                      />
                    </Tab>
                  )}
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Accounts",
                )?.read &&
                  permissions?.role_type === "NIAP" && (
                    <Tab eventKey="user_roles" title="User Roles">
                      <UserRolesManager roles={roles} setRoles={setRoles} />
                    </Tab>
                  )}
              </Tabs>
            </Col>
          </Row>
        </Row>
        <AccountForm
          show={showModal}
          handleCloseModal={handleCloseModal}
          account={currentAccount}
          roles={roles}
          companies={companies}
          reload={setUpdateRefs}
        />
        <AccountFilter
          show={filtering}
          handleCloseFiltering={handleCloseFiltering}
          filters={filters}
          setFilters={setFilters}
          permissions={permissions}
          setFiltersActive={setFiltersActive}
        />
      </Container>
    )
  );
}
