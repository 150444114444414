import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

import HTMLEditor from "../../UI/HTMLEditor";

export default function AddQAUpdateModal({
  show,
  handleClose,
  qa,
  user,
  editQaUpdate,
  setAlert,
  testMock, //to be used in test
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [qaUpdate, setQaUpdate] = useState({});
  const [overviewValid, setOverviewValid] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  useEffect(() => {
    setQaUpdate({});
  }, [show]);

  useEffect(() => {
    if (testMock) {
      setQaUpdate(testMock);
    }
  }, [show]);

  const handleChange = (e) => {
    // if (e.target.name === "shared") {
    //     setQaUpdate({
    //         ...qaUpdate,
    //         [e.target.name]: e.target.value === "true" ? true : false,
    //     });
    // } else {
    //     setQaUpdate({ ...qaUpdate, [e.target.name]: e.target.value });
    // }
    setQaUpdate({ ...qaUpdate, [e.target.name]: e.target.value });
  };

  const shareQAUpdateWithCustomer = async (note) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}mailer/`,
        {
          subject: `New Update for ${qa?.ticket}`,
          message: note.note1,
          sender: user?.id,
          important_only: true,
          recipients: qa?.custemail,
          from_email: "no-reply@niap-ccevs.org",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log("error sending email: " + error));
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!editQaUpdate?.upid) {
      if (form.checkValidity() === false || !qaUpdate.note1) {
        e.stopPropagation();
        if (!qaUpdate.note1) {
          setOverviewValid(false);
        } else {
          setOverviewValid(true);
        }
      } else {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/update/`,
            {
              ...qaUpdate,
              shared: false,
              rid: qa?.rid,
              creator: user?.id,
              entrydate: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            // if (qaUpdate.shared === true) {
            //     shareQAUpdateWithCustomer(response.data);
            // }
            handleClose();
            if (setAlert) {
              setAlert({
                message: "Successfully added QA update!",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log("error sending email: " + error);
            handleClose();
            if (setAlert) {
              setAlert({ message: "Error adding QA update.", type: "danger" });
            }
          });
      }
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/update/${editQaUpdate?.upid}/`,
          {
            // shared: editQaUpdate.shared,
            shared: false,
            note1: editQaUpdate.note1,
            rid: editQaUpdate?.rid__rid,
            ...qaUpdate,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          // if (qaUpdate.shared === true) {
          //     shareQAUpdateWithCustomer(response.data);
          // }
          handleClose();
          setAlert({
            message: "Successfully updated QA update!",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error sending email: " + error);
          handleClose();
          setAlert({ message: "Error updating QA Update.", type: "danger" });
        });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      enforceFocus={enforceFocusModal}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <h2>+ QA UPDATE for {qa?.ticket}</h2>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className="fw-bold">
            Submitter: {user?.first_name} {user?.last_name}
          </Form.Label>
          <Form.Group className="mb-3" controlId="note1">
            <Form.Label className="fw-bold">Overview:*</Form.Label>
            <HTMLEditor
              name="note1"
              handleChange={handleChange}
              setIsValid={setOverviewValid}
              isValid={overviewValid}
              defaultValue={editQaUpdate?.note1}
              setEnforceFocusModal={setEnforceFocusModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" type="reset" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {editQaUpdate?.upid ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
