import Input from "@mui/material/Input";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import MaterialTable from "material-table";
import qs from "qs";
import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

export default function DeletedUserRolesTable({
  setRestoreSuccess,
  roleSuccess,
  deleteSuccess,
  restoreSuccess,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];

  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [roleSuccess, deleteSuccess, restoreSuccess]);

  const handleRestore = async (rowData) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/${rowData.role_id}/`,
        {
          ...rowData,
          deleted: false,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setRestoreSuccess(response.data.role_id))
      .catch((error) => console.log("Unable to restore role: " + error));
  };

  const columns = [
    {
      title: "Role Name",
      field: "role_name",
      cellStyle: { width: "50%", minWidth: "50%" },
    },
    {
      title: "User Type",
      field: "role_type",
    },
    {
      title: "Actions",
      headerStyle: {
        display: "none",
      },
      field: "actions",
      filtering: false,
      sorting: false,
      cellStyle: { textAlign: "center", width: "10%", minWidth: "10%" },
      render: (rowData) => {
        return (
          <Button
            variant="primary"
            className="m-1 p-0"
            onClick={() => handleRestore(rowData)}
          >
            Restore
          </Button>
        );
      },
    },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
    },
    pageSize: 20,
  };

  return (
    <>
      <MaterialTable
        title="DELETED USER ROLES"
        columns={columns}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              deleted: true,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <label htmlFor="rowsPerPage">Rows Per Page</label>
              }
              SelectProps={{ input: <Input id="rowsPerPage" /> }}
            />
          ),
        }}
      />
    </>
  );
}
