import { Row, Col, Form } from "react-bootstrap";

export default function RateFormSection({
  currentFeedbackInfo,
  handleChange,
  viewOnly,
}) {
  return (
    <>
      <Row>
        <Col sm={6}>
          <p>Quality of package (English, technical):*</p>
        </Col>
        <Col>
          <Form.Group controlId="quality" inline>
            {["Poor", "Sufficient", "Excellent"]?.map((quality, idx) => (
              <Form.Check
                type="radio"
                key={`${idx}quality`}
                label={quality}
                name="quality"
                value={quality}
                className="mx-3"
                defaultChecked={currentFeedbackInfo?.quality === quality}
                onChange={handleChange}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                required
                inline
              />
            ))}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <p>Number of Discrepancies/Inconsistences between documentation:*</p>
        </Col>
        <Col>
          <Form.Group controlId="discrepancies" inline>
            {["None", "Low", "Medium", "High", "Excessive"]?.map(
              (quality, idx) => (
                <Form.Check
                  type="radio"
                  key={`${idx}discrepancies`}
                  label={quality}
                  name="discrepancies"
                  value={quality}
                  className="mx-3"
                  defaultChecked={
                    currentFeedbackInfo?.discrepancies === quality
                  }
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  onChange={handleChange}
                  required
                  inline
                />
              ),
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <p>Incomplete Evidence and/or Incorrect Test Performance:*</p>
        </Col>
        <Col>
          <Form.Group controlId="evidence" inline>
            {["None", "Low", "Medium", "High", "Excessive"]?.map(
              (quality, idx) => (
                <Form.Check
                  type="radio"
                  key={`${idx}evidence`}
                  label={quality}
                  name="evidence"
                  value={quality}
                  className="mx-3"
                  defaultChecked={currentFeedbackInfo?.evidence === quality}
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  onChange={handleChange}
                  required
                  inline
                />
              ),
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <p>Clarification Required:*</p>
        </Col>
        <Col>
          <Form.Group controlId="clarification" inline>
            {["None", "Low", "Medium", "High", "Excessive"]?.map(
              (quality, idx) => (
                <Form.Check
                  type="radio"
                  key={`${idx}clarification`}
                  label={quality}
                  name="clarification"
                  value={quality}
                  className="mx-3"
                  defaultChecked={
                    currentFeedbackInfo?.clarification === quality
                  }
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  onChange={handleChange}
                  required
                  inline
                />
              ),
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <p>Missing Items in Documentation (AGD, TSS, others?):*</p>
        </Col>
        <Col>
          <Form.Group controlId="missing_items" inline>
            {["None", "Low", "Medium", "High", "Excessive"]?.map(
              (quality, idx) => (
                <Form.Check
                  type="radio"
                  key={`${idx}missing_items`}
                  label={quality}
                  name="missing_items"
                  value={quality}
                  className="mx-3"
                  defaultChecked={
                    currentFeedbackInfo?.missing_items === quality
                  }
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  onChange={handleChange}
                  required
                  inline
                />
              ),
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
