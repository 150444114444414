import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";

const TechnicalCommunitiesForm = ({ show, onHide, tc, refetch }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [editedTC, setEditedTC] = useState(tc);

  useEffect(() => {
    setEditedTC(tc);
  }, [tc?.community_id]);

  const onChange = (e) => {
    if (e.target.name === "is_international") {
      setEditedTC({ ...editedTC, [e.target.name]: e.target.checked });
    } else {
      setEditedTC({ ...editedTC, [e.target.name]: e.target.value });
    }
  };

  const onClear = () => {
    setEditedTC({});
  };

  const onSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      let formMethod = "post";
      let formUrl = `${process.env.REACT_APP_DJANGO_ENDPOINT}tech/communities/`;
      if (tc?.community_id) {
        formMethod = "put";
        formUrl += `${tc?.community_id}/`;
      }
      axios({
        method: formMethod,
        url: formUrl,
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
        data: { ...editedTC },
      })
        .then((response) => {
          refetch();
          onHide();
        })
        .catch((error) => {
          console.error("Error Updating resource:", error);
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          {tc?.community_id ? "Edit" : "Create"} Technical Community
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="community_name">
            <Form.Label>Community Name *</Form.Label>
            <Form.Control
              type="text"
              name="community_name"
              onChange={onChange}
              value={editedTC?.community_name}
              required
            />
          </Form.Group>
          <Form.Group controlId="contact_email">
            <Form.Label>Community Alias</Form.Label>
            <Form.Control
              type="email"
              name="contact_email"
              onChange={onChange}
              value={editedTC?.contact_email}
            />
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label>Community Website</Form.Label>
            <Form.Control
              type="url"
              name="url"
              onChange={onChange}
              value={editedTC?.url}
            />
          </Form.Group>
          <Form.Group controlId="is_international">
            <Form.Check
              type="checkbox"
              label="Is iTC?"
              name="is_international"
              onChange={onChange}
              checked={editedTC?.is_international}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" onClick={onSubmit}>
            Submit
          </Button>
          <Button type="clear" onClick={onClear}>
            Clear
          </Button>
          <Button type="button" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default TechnicalCommunitiesForm;
