import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import { useInterval } from "../../../../hooks/useInterval";
import NISTDateModal from "../NISTDateModal";
import NISTStatusModal from "../NISTStatusModal";

const NISTHeader = ({ certReview, update, canEditNist }) => {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [productPP, setProductPP] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);

  const fetchProductPP = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/protection_profiles_by_name/?product=${certReview?.pid?.product_id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setProductPP(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [certReview?.pid?.product_id]);

  useEffect(() => {
    if (certReview?.pid?.product_id) {
      fetchProductPP();
    }
  }, [certReview?.pid?.product_id]);

  useInterval(() => {
    if (certReview?.pid?.product_id) {
      fetchProductPP();
    }
  }, 180000); //3 min

  return (
    <>
      <Row>
        <Col className="mt-4 mb-3">
          <h3 className="text-uppercase fw-bold">NIST Certificate Review</h3>
        </Col>
      </Row>
      <Row className="mt-3 border-bottom border-dark">
        <Col sm={12} md={5} className="border-md-left">
          <h3>Details for Cert Review {certReview?.pid?.v_id}</h3>
        </Col>
        <Col sm={12} md={7}>
          <h3>
            <a
              href={`/community/products/details/${certReview?.pid?.product_id}`}
            >
              {certReview?.pid?.product_name}
            </a>
          </h3>
        </Col>
      </Row>
      <Row className="mt-2 border-bottom border-dark">
        <Col xs={12} md={2} className="border-md-left">
          <p className="fw-bold">
            Status{" "}
            {canEditNist && (
              <span
                style={{ cursor: "pointer", float: "right" }}
                onClick={() => setShowStatusModal(true)}
                data-testid="EditIcon"
              >
                <EditIcon />
              </span>
            )}
          </p>
          <p>{certReview?.stid?.status}</p>
        </Col>
        <Col xs={12} md={2} className="border-md-left">
          <p className="fw-bold">PP Claim</p>
          {productPP?.map((projpp) => (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={
                <Popover>
                  <Popover.Body>{projpp?.pp__pp_name}</Popover.Body>
                </Popover>
              }
              key={projpp?.pp__pp_id}
            >
              <Link
                to={`/community/protectionprofiles/details/${projpp?.pp__pp_id}`}
                className="text-secondary"
              >
                <p className="py-0 my-0">{projpp?.pp__pp_short_name}</p>
              </Link>
            </OverlayTrigger>
          ))}
        </Col>
        <Col xs={12} md={3} className="border-md-left">
          <p className="fw-bold">Cert Review Required</p>
          <p>{certReview?.pid?.nist_required ? "Yes" : "No"}</p>
        </Col>
        <Col xs={12} md={2} className="border-md-left">
          <p className="fw-bold">Submission Date</p>
          <p>
            {certReview?.rcvd
              ? moment.utc(certReview.rcvd).format("YYYY.MM.DD")
              : "------"}
          </p>
        </Col>
        <Col xs={12} md={3}>
          <p className="fw-bold">
            Completion or Termination Date{" "}
            {canEditNist && (
              <span
                style={{ cursor: "pointer", float: "right" }}
                onClick={() => setShowDateModal(true)}
                data-testid="EditIcon"
              >
                <EditIcon />
              </span>
            )}
          </p>
          <p>
            {certReview?.enddate
              ? moment.utc(certReview.enddate).format("YYYY.MM.DD")
              : "------"}
          </p>
        </Col>
      </Row>
      <NISTStatusModal
        show={showStatusModal}
        onHide={() => setShowStatusModal(false)}
        certReview={certReview}
        update={update}
      />
      <NISTDateModal
        show={showDateModal}
        onHide={() => setShowDateModal(false)}
        certReview={certReview}
        update={update}
      />
    </>
  );
};

export default NISTHeader;
