import { ListGroup } from "react-bootstrap";

const TqExtendedTeamsCell = ({ tq }) => {
  return (
    <ListGroup>
      {tq.trrt_team?.map((team) => {
        return (
          <ListGroup.Item
            disabled
            key={team.vtid}
            style={{ textAlign: "center" }}
          >
            {team.team}
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};
export default TqExtendedTeamsCell;
