import ErrorIcon from "@mui/icons-material/Error";

const TqColorKeyCell = ({ tq }) => {
  const calculateColor = (deadline) => {
    const newDeadline = new Date(deadline);
    const newCurrent = new Date();
    if (newDeadline < newCurrent) {
      return "red";
    } else if (newDeadline > newCurrent) {
      const diffTime = Math.abs(newCurrent - newDeadline);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays - 1 <= 2) {
        return "yellow";
      }
      return "green";
    }
  };

  return (
    <ErrorIcon
      style={{
        color: `var(--bs-${calculateColor(tq.deadline)})`,
      }}
    />
  );
};
export default TqColorKeyCell;
