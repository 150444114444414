import moment from "moment";

const TqDeadlineCell = ({ tq }) => {
  const { deadline, requestedby } = tq;

  const formattedDeadline = moment.utc(deadline).format("MM/DD/YYYY");
  const formattedRequestedBy = moment.utc(requestedby).format("MM/DD/YYYY");

  const displayText = deadline
    ? formattedDeadline
    : requestedby
      ? formattedRequestedBy
      : "";

  return <>{displayText}</>;
};

export default TqDeadlineCell;
