import { ThemeProvider, createTheme } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { InstantSearch } from "react-instantsearch-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import QAFollowUpResponsePage from "../src/components/QA/QAFollowUpResponsePage";

import { removeRole } from "./app/role";
import { removeUser } from "./app/user";
import EventsPage from "./components/Announcements/EventsPage";
import NewsPage from "./components/Announcements/NewsPage";
import AppLogout from "./components/AppLogout";
import Ccra from "./components/Ccra";
import CCTL from "./components/CCTL";
import Community from "./components/Community";
import EvaluationProcess from "./components/EvaluationProcess";
import FAQs from "./components/FAQs";
import Footer from "./components/Footer";
import FormsAndTemplatesPage from "./components/FormsAndTemplates/FormsAndTemplatesPage";
import Homepage from "./components/Homepage";
import LabgramDetails from "./components/Labgrams/LabgramDetails";
import LabgramsPage from "./components/Labgrams/LabgramsPage";
import Login from "./components/Login";
import NavigationBar from "./components/NavigationBar";
import PCMAssuranceMaintenancePage from "./components/NIAPAdmin/PCM/PCMAssuranceMaintenancePage";
import NiapCcevs from "./components/NiapCcevs";
import PoliciesPage from "./components/Policies/PoliciesPage";
import PolicyLetterView from "./components/Policies/PolicyLetterView";
import AssuranceMaintenancePage from "./components/Products/AssuranceMaintenancePage";
import Products from "./components/Products/ProductsPage";
import PCMDetailsPage from "./components/Products/ProductsPage/PCMDetailsPage";
import ProductDetails from "./components/Products/ProductsPage/ProductDetails";
import Profile from "./components/Profile";
import ProtectionProfiles from "./components/ProtectionProfiles/PPPage";
import ProtectionProfileDetails from "./components/ProtectionProfiles/PPPage/ProtectionProfileDetails";
import PublicationsPage from "./components/Publications/PublicationsPage";
import ScrollToTop from "./components/ScrollToTop";
import Search from "./components/Search";
import SiteMap from "./components/SiteMap";
import TechCommunities from "./components/TechCommunities";
import TechnicalDecisions from "./components/TechnicalDecisions";
import TechDecisionDetails from "./components/TechnicalDecisions/TechDecisionDetails";
import TermsAcronyms from "./components/TermsAcronyms";
import TRRTSPage from "./components/TRRTS/TRRTSPage";

import "./App.css";
// import UnderMaintenance from "./components/UnderMaintenance";
// import UnderMaintenanceBanner from "./components/UnderMaintenance/banner";
import DailyDeployBanner from "./components/DailyDeployBanner";

const Logout = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();

  useEffect(() => {
    removeCookie("auth_token");
    dispatch(removeRole());
    dispatch(removeUser());
  }, []);

  return !cookies["auth_token"] && <Navigate replace to="/" />;
};

const searchClient = algoliasearch(
  "31P4SHL05P",
  "3e9d823d46542e1e805f9f12cce0ade1",
);

function App() {
  const [cookies] = useCookies();
  // const [oldAuthToken, setOldAuthToken] = useState(cookies['old_auth_token'])
  const [overlayActive, setOverlayActive] = useState(false);
  const [overlayText, setOverlayText] = useState("");
  const [maintenanceBanner, setMaintenanceBanner] = useState(false);
  const [refreshMaintenanceBanner, setRefreshMaintenanceBanner] =
    useState(false);

  // if (undermaintenace && !banneronly) {
  //   return <UnderMaintenance />
  // }
  // const useEffect(() => {
  //   setOldAuthToken(cookies['old_auth_token'])
  // }, [cookies])

  const checkMaintenanceBanner = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}settings/settings/get_status_maintenance_banner`,
        {
          withCredentials: true,
        },
      )
      .then((response) => {
        setMaintenanceBanner(response.data.maintenance_banner);
      })
      .catch((error) => {
        setMaintenanceBanner(false);
      });
  };

  const refreshBanner = () => {
    setRefreshMaintenanceBanner(!refreshMaintenanceBanner);
  };

  useEffect(() => {
    checkMaintenanceBanner();
  }, [refreshMaintenanceBanner]);

  const defaultReactTheme = createTheme({
    palette: {
      primary: {
        main: "rgb(var(--bs-primary-rgb))",
      },
      secondary: {
        main: "rgb(var(--bs-secondary-rgb))",
      },
      success: {
        main: "rgb(var(--bs-success-rgb))",
      },
      info: {
        main: "rgb(var(--bs-info-rgb))",
      },
      warning: {
        main: "rgb(var(--bs-warning-rgb))",
      },
      danger: {
        main: "rgb(var(--bs-danger-rgb))",
      },
      light: {
        main: "rgb(var(--bs-light-rgb))",
      },
      dark: {
        main: "rgb(var(--bs-dark-rgb))",
      },
    },
  });
  return (
    <div
      className={
        cookies["old_auth_token"] ? "border border-5 border-danger" : ""
      }
    >
      <LoadingOverlay
        active={overlayActive}
        spinner
        text={overlayText}
        styles={{
          overlay: (base) => ({
            ...base,
            zIndex: 4000000,
          }),
        }}
      >
        <InstantSearch searchClient={searchClient} indexName="test_HBD">
          <ThemeProvider theme={defaultReactTheme}>
            <Router>
              <AppLogout>
                {/* {undermaintenace && banneronly && <UnderMaintenanceBanner />} */}
                {maintenanceBanner &&
                  !window.location.href.includes("login") && (
                    <DailyDeployBanner />
                  )}
                <ScrollToTop />
                <div className="header">
                  <NavigationBar />
                </div>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Homepage
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route path="/search" element={<Search />} />
                  <Route path="/niap-ccevs" element={<NiapCcevs />} />
                  <Route path="/ccra" element={<Ccra />} />
                  <Route
                    path="/evaluation-process"
                    element={<EvaluationProcess />}
                  />
                  <Route
                    path="/cctl"
                    element={
                      <CCTL
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route path="/terms-acronyms" element={<TermsAcronyms />} />
                  <Route path="/faqs" element={<FAQs />} />
                  <Route path="/products" element={<Products />} />
                  <Route
                    path="/products/:id"
                    element={
                      <ProductDetails
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route
                    path="/products/international-product/:id"
                    element={
                      <PCMDetailsPage
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route
                    path="/products/international-product/:ccid/assurancemaintenance/:id"
                    element={
                      <PCMAssuranceMaintenancePage
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route
                    path="/products/:product_id/assurancemaintenance/:id"
                    element={
                      <AssuranceMaintenancePage
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route
                    path="/protectionprofiles"
                    element={<ProtectionProfiles />}
                  />
                  <Route
                    path="/protectionprofiles/:id"
                    element={
                      <ProtectionProfileDetails
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route path="/trrt" element={<TRRTSPage />} />
                  <Route
                    path="/question-answer/response/:id"
                    element={<QAFollowUpResponsePage />}
                  />
                  <Route path="/policies" element={<PoliciesPage />} />
                  <Route path="/policies/:id" element={<PolicyLetterView />} />
                  <Route
                    path="/formsandtemplates"
                    element={
                      <FormsAndTemplatesPage
                        setOverlayActive={setOverlayActive}
                        setOverlayText={setOverlayText}
                      />
                    }
                  />
                  <Route path="/labgrams" element={<LabgramsPage />} />
                  <Route path="/labgrams/:id" element={<LabgramDetails />} />
                  <Route path="/events" element={<EventsPage />} />
                  <Route path="/announcements" element={<NewsPage />} />
                  <Route path="/publications" element={<PublicationsPage />} />
                  <Route
                    path="/technical-communities"
                    element={<TechCommunities />}
                  />
                  <Route
                    path="/technical-decisions"
                    element={<TechnicalDecisions />}
                  />
                  <Route
                    path="/technical-decisions/:id"
                    element={<TechDecisionDetails />}
                  />
                  <Route path="/sitemap" element={<SiteMap />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/login/*" element={<Login />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/community/*"
                    element={<Community refreshBanner={refreshBanner} />}
                  />
                </Routes>
                <Footer />
              </AppLogout>
            </Router>
          </ThemeProvider>
        </InstantSearch>
      </LoadingOverlay>
    </div>
  );
}

export default App;
