// ResponsiveMaterialTable.js
import Input from "@mui/material/Input";
import TablePagination from "@mui/material/TablePagination";
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";

import MobileMaterialTablePagination from "./MobileMaterialTablePagination";

const ResponsiveMaterialTable = (props) => {
  // State to determine if the screen is small
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Override or set additional options here
  const responsiveOptions = {
    ...props.options,
    search: isSmallScreen ? false : props.options.search,
    pageSize: isSmallScreen ? 5 : (props.options.pageSize ?? 20),
  };

  const tableId = props.id;

  return (
    <MaterialTable
      {...props}
      icons={{ Filter: () => <div /> }} // removing filter icon
      options={responsiveOptions}
      components={{
        ...props.components,
        Pagination: (props) =>
          isSmallScreen ? (
            <MobileMaterialTablePagination {...props} />
          ) : (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <label htmlFor={`rowsPerPage-${tableId}`}>Rows Per Page</label>
              }
              SelectProps={{
                input: <Input id={`rowsPerPage-${tableId}`} />,
                MenuProps: {
                  style: {
                    zIndex: 1301,
                  },
                },
              }}
              sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                  {
                    margin: 0,
                  },
              }}
            />
          ),
      }}
    />
  );
};

export default ResponsiveMaterialTable;
