import { createStore, combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user";
import roleReducer from "./role";

import { baseApi } from "../api/BaseApi.ts";

const rootReducer = combineReducers({
  user: userReducer,
  role: roleReducer,
  niapApi: baseApi.reducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [baseApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = () => {
  let store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(baseApi.middleware),
  });
  let persistor = persistStore(store);
  return { store, persistor };
};

export default store;
