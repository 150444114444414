import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";

import ResponsiveMaterialTable from "../UI/MaterialTable/ResponsiveMaterialTable";
import UnauthorizedView from "../UI/UnauthorizedView";

import ParticipationLevelsTable from "./LevelParticipationKey";
import AddTeamMemberModal from "./Modals/AddTeamMemberModal";

export default function VirtualTeamInfoTable() {
  const { id } = useParams();
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.user.value);
  const [teamName, setTeamName] = useState("");
  const [levels, setLevels] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [refetch, setRefetch] = useState(false);
  const tableRef = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refetch]);

  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/${id}/`, {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        })
        .then((res) => {
          setTeamName(res.data.team + ` (${res.data.abbrev})`);
        })
        .catch((err) => console.log("unable to get virtual team name", err));

      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/levels/get_all_levels`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((res) => {
          setLevels(res.data);
        })
        .catch((err) => console.log("unable to get levels", err));
    }
  }, [authToken, id]);

  const handleUpdateMember = async (editedMember, index) => {
    if (editedMember[index].lvl.lvl === null) {
      return;
    }
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/${editedMember[index].xid}/`,
        {
          lvl: editedMember[index].lvl.lvl.vtlid,
          editor: currentUser.id,
          moddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setRefetch(!refetch))
      .catch((error) => console.log("Unable to Update Member: " + error));
  };

  const handleDelete = async (editedMember) => {
    await axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/${editedMember.xid}/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .catch((error) => console.log("Unable to Delete Member: " + error));
  };

  const levelOptions = levels.map((level) => {
    return { value: level.vtlid, label: level?.lvl };
  });

  const reload = () => {
    tableRef.current.onQueryChange();
  };

  const columns = [
    {
      title: "Level",
      field: "lvl.lvl",
      render: (rowData) => <>{rowData.lvl?.lvl}</>,
      editComponent: (props) => (
        <Select
          id="select-component-lvls"
          options={levelOptions}
          defaultValue={levelOptions.find(
            (option) => option?.label === props.rowData?.lvl?.lvl,
          )}
          onChange={(e) => props.onChange({ vtlid: e.value, lvl: e.label })}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      ),
    },
    {
      title: "Name",
      field: "account_id.first_name",
      editable: "never",
      render: (rowData) => (
        <Link to={`/community/virtualteams/${id}/${rowData.account_id.id}`}>
          {rowData.account_id.first_name + " " + rowData.account_id.last_name}
        </Link>
      ),
    },
    {
      title: "Last Modified",
      editable: "never",
      field: "moddate",
      type: "date",
      render: (rowData) => (
        <>
          {rowData?.moddate
            ? moment.utc(rowData?.moddate).format("MM/DD/YYYY HH:mm")
            : "Not Modified"}
        </>
      ),
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    actionsColumnIndex: -1,
    pageSize: 20,
  };

  return permissions?.role_permissions?.find(
    (permission) => permission.area === "Virtual Teams",
  )?.read ? (
    <>
      <AddTeamMemberModal
        show={show}
        setShow={setShow}
        reload={reload}
        id={id}
        currentUser={currentUser}
        levels={levels}
        refetch={() => setRefetch(!refetch)}
      />
      <Container fluid>
        <Col>
          <Row className="align-items-center">
            <Col
              xs={12}
              md={4}
              className="d-flex justify-content-md-start mb-2 mb-md-0"
            >
              <Button onClick={() => navigate(-1)} className="m-3 w-sm-100">
                <ArrowBackIosIcon />
                Back to Teams
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                key="bottom"
                overlay={
                  <Popover>
                    <Popover.Body>
                      <ParticipationLevelsTable />
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button variant="link">
                  Click Here to View Team Participations Levels
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs={12} md={4} className="d-flex justify-content-md-end">
              <Button
                variant="primary"
                onClick={handleShow}
                className="m-3 w-sm-100"
              >
                + Add Team Member
              </Button>
            </Col>
          </Row>
        </Col>
      </Container>
      <ResponsiveMaterialTable
        title={teamName}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              vt: id,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
                setData(response.data.results);
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        tableRef={tableRef}
        options={options}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);
                handleUpdateMember(dataUpdate, index);
                resolve();
              }, 1000);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              handleDelete(oldData);
              resolve();
            }),
        }}
        localization={{
          body: {
            editRow: {
              deleteText: "Are you sure you want to Delete this Member?",
            },
          },
        }}
      />
    </>
  ) : (
    <UnauthorizedView />
  );
}
