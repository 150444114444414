import { Container, Row, Col } from "react-bootstrap";

import PPCard from "../PPCard";

import PPPagination from "./PPPagination";

export default function PPGridView({
  items,
  setOffset,
  offset,
  total,
  PAGE_SIZE,
  filters,
  search,
}) {
  return (
    <Container className="p-4" fluid>
      <Row className="g-4 mb-5">
        {items.map((card, idx) => (
          <Col sm={6} key={idx}>
            <PPCard card={card} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <PPPagination
            setOffset={setOffset}
            offset={offset}
            total={total}
            PAGE_SIZE={PAGE_SIZE}
          />
        </Col>
      </Row>
    </Container>
  );
}
