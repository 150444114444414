import moment from "moment";

const Upcoming = ({ statuses, appointments }) => {
  const statusesMap = {};
  statuses.forEach((status) => {
    statusesMap[status.id] = status;
  });

  const interpretRRuleEndDate = (startDate, rrule) => {
    const data = rrule.split(":")[1];
    const items = data.split(";");
    const freq = items.find((item) => item.includes("FREQ"))?.split("=")[1];
    const interval = items
      .find((item) => item.includes("INTERVAL"))
      ?.split("=")[1];
    const count = items.find((item) => item.includes("COUNT"))?.split("=")[1];
    if (freq === "WEEKLY") {
      const sDate = new Date(startDate);
      const weeks = interval * (count - 1);
      const days = weeks * 7;
      return sDate.setDate(sDate.getDate() + days);
    } else if (freq === "DAILY") {
      const sDate = new Date(startDate);
      const days = interval * count;
      return sDate.setDate(sDate.getDate() + days);
    } else if (freq === "MONTHLY") {
      const sDate = new Date(startDate);
      const months = interval * (count - 1);
      return sDate.setMonth(sDate.getMonth() + months);
    } else {
      const sDate = new Date(startDate);
      const years = interval * (count - 1);
      return sDate.setFullYear(sDate.getFullYear() + years);
    }
  };

  let upcomingAppointments = appointments.filter(
    (appointment) =>
      new Date(appointment.endDate) >= new Date() || appointment.rRule,
  );
  upcomingAppointments = upcomingAppointments.filter((appointment) =>
    appointment.rRule
      ? interpretRRuleEndDate(appointment.startDate, appointment.rRule) >=
        new Date()
      : true,
  );
  upcomingAppointments = upcomingAppointments.sort(
    (a, b) => new Date(a.startDate) > new Date(b.startDate),
  );
  upcomingAppointments = upcomingAppointments.slice(0, 50);

  return (
    <div>
      <h4>Upcoming</h4>
      {upcomingAppointments.map((appointment) => (
        <div
          key={appointment?.id}
          className="d-flex flex-direction-row align-items-center"
        >
          <p
            style={{
              height: "15px",
              width: "15px",
              borderRadius: "50%",
              background: statusesMap[appointment?.statusId]?.color,
              marginBottom: "1rem",
              marginRight: ".5rem",
              float: "left",
            }}
          >
            <span className="visually-hidden">
              {statusesMap[appointment?.statusId]?.text}
            </span>
          </p>
          <p>
            {appointment?.title}{" "}
            {appointment?.rRule
              ? "Reccurs"
              : `due ${moment.utc(appointment?.startDate).format("YYYY.MM.DD")}`}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Upcoming;
