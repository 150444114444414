import { Modal } from "react-bootstrap";

import steeringImg from "../../../images/tech-communities/steering-comm.png";

export default function SteeringCommBody({ showModal, handleShowModal }) {
  return (
    <Modal show={showModal} onHide={handleShowModal} size="lg">
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-5">
        <div className="text-center">
          <img src={steeringImg} height="150px" alt="Steering Committee Logo" />
        </div>
        <h2 className="text-uppercase text-center fw-bold text-primary pb-3">
          Steering Committee
        </h2>
        <p>
          The Steering Committee (SC) facilitates the work of individual
          Technical Communities and performs oversight of all TCs.
        </p>
        <p>
          The Steering Committee has responsibility for forming and overseeing
          the Technical Communities to ensure consistency and completeness of
          the PPs developed by each community. The Steering Committee is the
          approving authority for PPs developed by the TCs. Initially, NIAP will
          largely fulfill the role of the Steering Committee until the formation
          process is mature. Over time, however, it is envisioned that the
          Steering Committee could evolve to include other partners.
        </p>
      </Modal.Body>
    </Modal>
  );
}
