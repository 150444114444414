import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import ResponsiveMaterialTable from "../UI/MaterialTable/ResponsiveMaterialTable";
import UnauthorizedView from "../UI/UnauthorizedView";

import ParticipationLevelsTable from "./LevelParticipationKey";

export default function MemberVirtTeamsTable() {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const { member_id } = useParams();
  const permissions = useSelector((state) => state.role.value);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const getLocalTime = (time) => {
    if (!time) {
      return "Not Modified";
    }
    const newTime = new Date(time);
    return newTime.toLocaleDateString();
  };

  const getUserInfo = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${member_id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.log("unable to get user info", err));
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const columns = [
    {
      title: "Level",
      field: "lvl.lvl",
      render: (rowData) => <>{rowData.lvl?.lvl}</>,
    },
    {
      title: "Team",
      field: "vtid.team",
    },
    {
      title: "Last Modified",
      field: "vtid.moddate",
      type: "date",
      filtering: false,
      render: (rowData) => <>{getLocalTime(rowData.vtid.moddate)}</>,
    },
  ];

  const options = {
    sorting: true,
    filtering: true,
    columnsButton: true,
    search: false,
    pageSize: 20,
  };

  return permissions?.role_permissions?.find(
    (permission) => permission.area === "Virtual Teams",
  )?.read ? (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Col>
              <Button onClick={() => navigate(-1)} className="m-3 w-sm-100">
                <ArrowBackIosIcon />
                Back to Member List
              </Button>
            </Col>
          </Col>
          <Col>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              key="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    <ParticipationLevelsTable />
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link">
                Click Here to View Team Participations Levels
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
      <ResponsiveMaterialTable
        title={user?.first_name + " " + user?.last_name + "'s Virtual Teams"}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              user: member_id,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        columns={columns}
        options={options}
      />
    </>
  ) : (
    <UnauthorizedView />
  );
}
