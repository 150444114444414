import { Modal, Form, Button } from "react-bootstrap";

export default function AccountFilterOrgs({
  role,
  labs,
  filters,
  handleChange,
}) {
  return (
    role != "NIAP" && (
      <Form.Group className="mb-3 p-2">
        <Form.Label htmlFor="role">
          <strong>{role} Selector</strong>
        </Form.Label>
        <div className="text-center">
          {labs
            .filter((lab) => lab.orgType === role)
            .map((lab, key) => {
              return (
                <Button
                  key={key}
                  className="m-1 rounded-pill border border-dark lab-btn"
                  variant={
                    filters.org_id?.indexOf(lab.name) > -1 ? "primary" : "light"
                  }
                  onClick={handleChange}
                >
                  {lab.name}
                </Button>
              );
            })}
        </div>
      </Form.Group>
    )
  );
}
