import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import FlagIcon from "@mui/icons-material/Flag";
import TeamIcon from "@mui/icons-material/GroupAdd";
import RateReviewIcon from "@mui/icons-material/RateReview";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useRef, useEffect } from "react";
import {
  Button,
  Popover,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useInterval } from "../../../hooks/useInterval";
import {
  calculateDaysInProgress,
  calculateDaysInProgressProduct,
} from "../../../utils/timeUtils";
import ManageAMValidationTeamModal from "../../Dashboard/ProductsModule/ManageAMValidationTeamModal";
import ManageValidationTeamModal from "../../Dashboard/ProductsModule/ManageValidationTeamModal";
import StageTracker from "../../Dashboard/StageTracker/StageTracker";
import DeleteModal from "../../UI/DeleteModal";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

export default function ProductsTable({
  sidebarFilters,
  refreshKey,
  projpps,
  refetch,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [product, setProduct] = useState({});
  const permissions = useSelector((state) => state.role.value);
  const [showValTeamModal, setShowValTeamModal] = useState(false);
  const [showAMValTeamModal, setShowAMValTeamModal] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [sidebarFilters]);

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  const fetchProductURL = () => {
    if (
      permissions?.role_permissions?.find(
        (permission) => permission.area === "Project",
      )?.local_only
    ) {
      if (permissions?.role_type === "Lab") {
        return "project/product/current_user_products/";
      } else {
        return "project/product/table_view/";
      }
    } else {
      if (permissions?.role_permissions) {
        return "project/product/table_view/";
      }
    }
  };

  const handleDeleteDraft = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
        {
          deleted: true,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        refetch();
        setShowDeleteModal(false);
      })
      .catch((error) => console.log("Unable to update product: " + error));
  };

  const productStatusOptions = {
    "In Draft": "In Draft",
    Proposed: "Proposed",
    // 'Submitted': 'Submitted',
    "Pre-Evaluation": "Pre-Evaluation",
    // 'Evaluation': 'Evaluation',
    // 'In Evaluation': 'In Evaluation',
    // 'Validation': 'Validation',
    "In Progress": "In Progress",
    Finalizing: "Finalizing",
    Certified: "Completed",
    Terminated: "Terminated",
    Withdrawn: "Withdrawn",
    "In Assurance Maintenance": "In Assurance Maintenance",
    // 'Active': 'Active',
    // 'Certified': 'Certified',
    Archived: "Archived",
    // 'Approved': 'Approved'
  };

  const daysPassed = (inputDate) => {
    if (inputDate) {
      const startDate = moment(inputDate);
      const endDate = moment();
      const daysSinceStartDate = endDate
        .startOf("day")
        .diff(startDate.startOf("day"), "days");
      return daysSinceStartDate;
    } else {
      return 0;
    }
  };

  const buttonTitle = (rowData) => {
    if (rowData?.status === "Draft") {
      return "In Draft";
    } else if (rowData?.status === "Certified") {
      return "Completed";
    } else {
      return rowData?.status;
    }
  };

  const columns = [
    {
      title: "Resubmission",
      field: "is_resubmission",
      lookup: { true: "True", false: "False" },
      render: (rowData) => (
        <>
          {rowData?.is_resubmission && (
            <FlagIcon
              sx={{ color: "var(--bs-red)", fontSize: 20 }}
              data-testid="FlagIcon"
            />
          )}
        </>
      ),
    },
    {
      title: "VID",
      field: "v_id",
      cellStyle: {
        width: "40%",
        minWidth: "40%",
      },
      render: (rowData) => (
        <Link
          to={`details/${rowData.product_id}`}
          className="text-decoration-underline"
        >
          {rowData?.v_id}
        </Link>
      ),
    },
    {
      title: "Vendor",
      field: "vendor_id.name",
      cellStyle: {
        width: "40%",
        minWidth: "40%",
      },
    },
    { title: "Product", field: "product_name" },
    {
      title: "CCTL",
      field: "assigned_lab.name",
      cellStyle: {
        width: "40%",
        minWidth: "40%",
      },
      render: (rowData) => {
        return <span>{rowData.assigned_lab?.name}</span>;
      },
    },
    {
      title: "Kicked Off",
      field: "kicked_off_date",
      type: "date",
      render: (rowData) => {
        if (!rowData.kicked_off_date) {
          return "Pending";
        }
        return moment.utc(rowData.kicked_off_date).format("MM/DD/YYYY");
      },
    },
    {
      title: "Product Status",
      field: "status",
      lookup: productStatusOptions,
      render: (rowData) => {
        return (
          <div style={{ width: "max-content" }}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              key="bottom"
              overlay={
                <Popover
                  style={{
                    minWidth: "200px",
                    maxWidth: "600px",
                    width: "max-content",
                  }}
                >
                  <Popover.Body>
                    <StageTracker rowData={rowData} />
                  </Popover.Body>
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-bright-navy small-font"
                style={{ width: "max-content" }}
              >
                {buttonTitle(rowData)}
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      title: "Archived Date",
      field: "sunset_date",
      hidden: sidebarFilters.status?.indexOf("Archived") < 0,
      type: "date",
      render: (rowData) => {
        if (!rowData.sunset_date || rowData.status !== "Archived") {
          return "Pending";
        }
        return moment.utc(rowData.sunset_date).format("MM/DD/YYYY");
      },
    },
    {
      title: "PP Claim",
      render: (rowData) => (
        <ListGroup>
          {projpps &&
            projpps[rowData?.product_id]?.map((projpp, idx) => (
              <OverlayTrigger
                key={idx}
                placement="top"
                overlay={<Tooltip>{projpp.name}</Tooltip>}
              >
                <Link
                  to={`/community/protectionprofiles/details/${projpp.id}`}
                  className="text-decoration-underline"
                >
                  <ListGroup.Item disabled>
                    {projpp.pp_short_name}
                  </ListGroup.Item>
                </Link>
              </OverlayTrigger>
            ))}
        </ListGroup>
      ),
    },
    {
      title: "Days in Progress",
      field: "days_in_progress",
      render: (rowData) => {
        return getDaysInProgress(rowData) > 0 ? (
          <p>{getDaysInProgress(rowData)}</p>
        ) : (
          <p>Pending</p>
        );
      },
    },
    {
      title: "Actions",
      field: "actions",
      headerStyle: { background: "white", position: "sticky", right: 0 },
      cellStyle: { background: "white", position: "sticky", right: 0 },
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          key="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                {rowData?.status === "Draft" && (
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link"
                    onClick={() => {
                      setProduct(rowData);
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon color="action" /> Delete Draft
                  </Button>
                )}
                <Link
                  to={`details/${rowData.product_id}`}
                  className="text-decoration-none"
                >
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link text-start"
                  >
                    <VisibilityIcon color="action" /> View
                  </Button>
                </Link>
                <Link
                  to={`/community/ecr-comments/${rowData.v_id}`}
                  className="text-decoration-none"
                >
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link text-start"
                  >
                    <RateReviewIcon color="action" /> ECR Comments
                  </Button>
                </Link>
                <Link
                  to={`/community/products/details/${rowData?.product_id}/product_files`}
                  className="text-decoration-none"
                >
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link text-start"
                  >
                    <FileCopyIcon color="action" /> Files
                  </Button>
                </Link>
                {permissions.role_type === "NIAP" && (
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link text-start"
                    onClick={() => {
                      setProduct(rowData);
                      if (rowData?.status === "In Assurance Maintenance") {
                        setShowAMValTeamModal(true);
                      } else {
                        setShowValTeamModal(true);
                      }
                    }}
                  >
                    <TeamIcon color="action" /> Validation Team
                  </Button>
                )}
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link">
            <MoreVertIcon className="float-end" />
            <span className="visually-hidden">Table Row Actions</span>
          </Button>
        </OverlayTrigger>
      ),
    },
  ];

  const getDaysInProgress = (rowData) => {
    if (rowData?.kicked_off_date) {
      if (rowData?.posted_date) {
        let daysInProgress = calculateDaysInProgress(
          rowData?.kicked_off_date,
          rowData?.posted_date,
        );
        return daysInProgress;
      } else if (rowData?.termination_date) {
        let daysInProgress = calculateDaysInProgress(
          rowData?.kicked_off_date,
          rowData?.termination_date,
        );
        return daysInProgress;
      } else {
        let daysInProgress = calculateDaysInProgressProduct(rowData);
        return daysInProgress;
      }
    } else {
      return 0;
    }
  };

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    columnsButton: true,
    search: true,
    pageSizeOptions: [25, 50, 75, 100],
    pageSize: 50,
    debounceInterval: 500,
    emptyRowsWhenPaging: false,
  };

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        type="Draft"
        deleteFunction={handleDeleteDraft}
      />
      <ResponsiveMaterialTable
        title="Evaluation Projects"
        columns={columns}
        key={refreshKey}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () =>
              tableRef.current &&
              tableRef.current.dataManager.columns.forEach((item) => {
                tableRef.current.onFilterChange(item.tableData.id, "");
              }),
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters
              .map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else if (
                  ["kicked_off_date"]?.includes(filter?.column?.field)
                ) {
                  const dateObj = new Date(filter?.value);
                  const isoStr = dateObj.toISOString();
                  const formattedStr = isoStr.split("T")[0];
                  value = formattedStr;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                if (filter.column.field === "status") {
                  if (value?.length > 0) {
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  } else {
                    return null;
                  }
                } else {
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                }
              })
              .filter((filter) => filter !== null);

            const params = {
              ...sidebarFilters,
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };

            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}${fetchProductURL()}?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
      <ManageValidationTeamModal
        show={showValTeamModal}
        setShow={setShowValTeamModal}
        currentProductInEval={product}
      />
      <ManageAMValidationTeamModal
        show={showAMValTeamModal}
        setShow={setShowAMValTeamModal}
        currentProductInEval={product}
      />
    </>
  );
}
