import { Card, Col, Row } from "react-bootstrap";

export default function AMProgressPoints({
  progressPoints,
  showButtons,
  maintenance,
}) {
  const progressPointTitles = {
    1: "AM Requested",
    2: "Team Assigned",
    3: "ACMR Approved",
    4: "ACMR Posted",
  };

  const style = {
    height: "112px",
    width: "112px",
    borderWidth: 3,
    margin: "auto",
  };

  const inReviewStyle = {
    height: "112px",
    width: "112px",
    margin: "auto",
    border: "3px solid #aa66cc",
  };

  return (
    <Col className="mt-3">
      <Row>
        {progressPoints?.map((pp, idx) => (
          <Col key={idx} className="p-0">
            <Card
              border={
                pp.status === "Completed"
                  ? "success"
                  : pp.status === "In Progress" ||
                      pp.status === "Ready for Release"
                    ? "warning"
                    : pp.status === "Rejected"
                      ? "danger"
                      : pp.status === "Resubmitted"
                        ? "info"
                        : pp.status === "Awaiting Checkout"
                          ? false
                          : "dark"
              }
              bg="light"
              className="rounded-circle justify-content-center"
              style={pp.status === "Awaiting Checkout" ? inReviewStyle : style}
            >
              <Card.Body className="text-center active-bar d-flex align-items-center justify-content-center">
                <div>
                  <Card.Subtitle className="progress-point-heading fw-bold my-0 small-font">
                    {progressPointTitles[pp.progress_point]}
                  </Card.Subtitle>
                  <Card.Text className="small-font">
                    {pp.status === "In Progress"
                      ? "Initialized"
                      : pp.status === "Awaiting Checkout"
                        ? "In Review"
                        : pp.status}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
            <Row className="mt-2 text-center justify-content-center" xs="auto">
              {maintenance?.status !== "Terminated" && showButtons(pp)}
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  );
}
