import { Modal, Container, Row, Col } from "react-bootstrap";

import approvedCCTLImg from "../../../images/cctl_page/approvedCCTL.png";

export default function ApprovedCCTLBody({ show, setShowApprovedModal }) {
  const handleClose = () => {
    setShowApprovedModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-5">
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="d-flex justify-content-center">
                    <img
                      src={approvedCCTLImg}
                      alt="approved cctl image"
                      className="w-auto"
                      height="120px"
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-uppercase fw-bold mt-3 mb-4 text-primary">
                      Approved CCTL
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <p>
                  NIAP-approved Common Criteria Testing Laboratories (CCTLs) are
                  IT security testing laboratories that are accredited by the
                  NIST National Voluntary Laboratory Accreditation Program
                  (NVLAP) and meet CCEVS-specific requirements to conduct IT
                  security evaluations for conformance to the Common Criteria
                  for Information Technology Security Evaluation, International
                  Standard ISO/IEC 15408.
                </p>
              </Row>
              <Row>
                <p>These laboratories must meet the requirements of:</p>
                <div>
                  <ul className="mx-5">
                    <li>
                      NIST Handbook 150, NVLAP Procedures and General
                      Requirements;
                    </li>
                    <li>
                      NIST Handbook 150-20, NVLAP Information Technology
                      Security Testing - Common Criteria;
                    </li>
                    <li>
                      Specific criteria for IT security evaluations and other
                      requirements of the scheme as defined by the NIAP
                      Validation Body.
                    </li>
                  </ul>
                </div>
              </Row>
              <Row>
                <p>
                  CCTLs enter into contractual agreements with sponsors to
                  conduct security evaluations of IT products and protection
                  profiles using NIAP-approved test methods derived from the
                  Common Criteria, Common Methodology and other technology-based
                  sources. The IT security evaluations are carried out in
                  accordance with the policies and procedures of the scheme.
                  CCTLs must observe the highest standards of impartiality,
                  integrity, and commercial confidentiality, and operate within
                  the guidelines established by the scheme.
                </p>
              </Row>
              <Row>
                <div>
                  <ul className="mx-5">
                    <li>
                      Must reside within the U.S. and be a legal entity, duly
                      organized and incorporated, validly existing, and in good
                      standing under the laws of the state where the laboratory
                      intends to do business;
                    </li>
                    <li>
                      Must agree to accept U.S. Government technical oversight
                      and validation of evaluation-related activities in
                      accordance with the policies and procedures established by
                      the NIAP Common Criteria Scheme;
                    </li>
                    <li>
                      Must agree to accept U.S. Government participants in
                      selected Common Criteria evaluations conducted by the
                      laboratory in accordance with the policies and procedures
                      established by the NIAP Common Criteria Scheme.
                    </li>
                  </ul>
                </div>
              </Row>
              <Row>
                <p>
                  A testing laboratory becomes a CCTL when the laboratory is
                  approved by the Validation Body and is listed on the NIAP
                  Approved Laboratories List.
                </p>
                <p>
                  To avoid unnecessary expense and delay in becoming a
                  NIAP-approved testing laboratory, it is strongly recommended
                  that prospective CCTLs ensure that they are able to satisfy
                  the scheme-specific requirements prior to seeking
                  accreditation from NVLAP. This can be accomplished by sending
                  a letter of interest to the NIAP Validation Body prior to
                  entering the NVLAP process.
                </p>
                <p>
                  Additional laboratory-related information can be found in
                  Scheme Publication #1 Common Criteria Evaluation and
                  Validation Scheme for Information Technology Security —
                  Organization, Management, and Concept of Operations and Scheme
                  Publication #4 Common Criteria Evaluation and Validation
                  Scheme for Information Technology Security - Guidance to
                  Common Criteria Testing Laboratories.
                </p>
              </Row>
              <Row className="mt-3 mb-3" sm={1} md={2}>
                <Col className="mb-3">
                  <a
                    href="https://www.nist.gov/nvlap"
                    target="_blank"
                    role="button"
                    className="btn-warning me-4 btn btn-primary fw-bold"
                    rel="noreferrer"
                  >
                    NIST HANDBOOK AND OTHER NVLAP INFORMATION
                  </a>
                </Col>
                <Col>
                  <a
                    href="https://www.niap-ccevs.org/Documents_and_Guidance/nvlap-announcement.pdf"
                    target="_blank"
                    role="button"
                    className="btn-warning me-4 btn btn-primary fw-bold"
                    rel="noreferrer"
                  >
                    NVLAP IT SECURITY TESTING LAB ANNOUNCEMENT
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
