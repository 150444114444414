import moment from "moment";

const MultipleEstimatedEventDatesColumn = ({ ppData, date, index }) => {
  return (
    <>
      {ppData && date && (
        <>
          <span style={{ marginRight: "3px" }}>
            {index === 0
              ? `${ppData.progress_point}.`
              : `${ppData.progress_point}${String.fromCharCode(64 + index).toLowerCase()}.`}
          </span>
          Estimated:
          <span style={{ marginLeft: "3px" }}>
            {moment.utc(date).format("MM/DD/YYYY")}
          </span>
        </>
      )}
    </>
  );
};

export default MultipleEstimatedEventDatesColumn;
