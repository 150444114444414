import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

import HTMLEditor from "../../UI/HTMLEditor";

export default function AddPCMUpdateModal({
  show,
  handleClose,
  pcm,
  user,
  editPcmUpdate,
  setAlert,
  testMock, //only to be used in AddPCMUpdateModal.test.js
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [pcmUpdate, setPcmUpdate] = useState({});
  const [overviewValid, setOverviewValid] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  useEffect(() => {
    if (show) {
      setPcmUpdate({});
    }
  }, [show]);

  useEffect(() => {
    if (testMock) {
      setPcmUpdate(testMock);
    }
  }, [show]);

  const handleChange = (e) => {
    if (e.target.name === "shared") {
      setPcmUpdate({
        ...pcmUpdate,
        [e.target.name]: e.target.value === "true" ? true : false,
      });
    } else {
      setPcmUpdate({ ...pcmUpdate, [e.target.name]: e.target.value });
    }
  };

  const sharePCMUpdateWithCustomer = async (note) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
        {
          subject: `PCM Request - "PCM-${new Date().getFullYear()}-${pcm?.ticket}" Update Shared`,
          recipients: { to: ["Customer"], cc: ["NIAP Management"] },
          note: note?.note1,
          alert_type: "PCM",
          alert_text: `PCM Request - ${pcm?.ticket} Update Shared`,
          alert_type_id: pcm?.rid,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        console.log("email sent");
      })
      .catch((error) =>
        console.log("Unable to create alert and email.", error),
      );
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (
      form.checkValidity() === false ||
      (editPcmUpdate ? editPcmUpdate.note1 === "" : !pcmUpdate.note1)
    ) {
      e.stopPropagation();
      if (editPcmUpdate ? editPcmUpdate.note1 === "" : !pcmUpdate.note1) {
        setOverviewValid(false);
      } else {
        setOverviewValid(true);
      }
    } else {
      if (!editPcmUpdate) {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/update/`,
            {
              ...pcmUpdate,
              rid: pcm?.rid,
              creator: user?.id,
              entrydate: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            if (pcmUpdate.shared === true) {
              sharePCMUpdateWithCustomer(response.data);
            }
            handleClose();
            if (setAlert) {
              setAlert({
                message: "Successfully added PCM update!",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log("error sending email: " + error);
            handleClose();
            if (setAlert) {
              setAlert({ message: "Error adding PCM update.", type: "danger" });
            }
          });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/update/${editPcmUpdate?.upid}/`,
            {
              ...pcmUpdate,
              editor: user?.id,
              moddate: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            if (pcmUpdate.shared === true) {
              sharePCMUpdateWithCustomer(response.data);
            }
            handleClose();
          })
          .catch((error) => {
            console.log("error sending email: " + error);
            handleClose();
          });
      }
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      enforceFocus={enforceFocusModal}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <h2>+ PCM UPDATE for {pcm?.ticket}</h2>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className="fw-bold">
            Submitter: {user?.first_name} {user?.last_name}
          </Form.Label>
          <Form.Group controlId="shared" className="mb-3 mt-3">
            <Form.Label className="fw-bold">
              Share this update with the customer?*
            </Form.Label>
            <div>
              <Form.Check
                type="radio"
                name="shared"
                value="true"
                inline
                label="Yes"
                onChange={handleChange}
                defaultChecked={editPcmUpdate?.shared === true}
                id="shared1"
                required
              />
              <Form.Check
                type="radio"
                name="shared"
                value="false"
                inline
                label="No"
                onChange={handleChange}
                defaultChecked={editPcmUpdate?.shared === false}
                id="shared2"
                required
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="note1">
            <Form.Label className="fw-bold">Overview:*</Form.Label>
            <HTMLEditor
              name="note1"
              handleChange={handleChange}
              setIsValid={setOverviewValid}
              isValid={overviewValid}
              defaultValue={editPcmUpdate?.note1}
              setEnforceFocusModal={setEnforceFocusModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" type="reset" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {editPcmUpdate?.upid ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
