import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const TqAssociatedTdsCell = ({ tds = [] }) => {
  return (
    <ListGroup>
      {tds.map((td) => (
        <Link
          to={`/community/technical-decisions/details/${td}`}
          className="text-decoration-underline"
          key={td}
        >
          <ListGroup.Item disabled style={{ textAlign: "center" }}>
            {td}
          </ListGroup.Item>
        </Link>
      ))}
    </ListGroup>
  );
};
export default TqAssociatedTdsCell;
