import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "@mui/material/Input";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import MaterialTable from "material-table";
import moment from "moment";
import qs from "qs";
import { useRef, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PPTableView({ sidebarFilters, tablePageSize }) {
  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [sidebarFilters, tablePageSize]);

  const statusColors = {
    Archived: "var(--bs-orange)",
    "In Development": "var(--bs-yellow)",
    "In Evaluation": "var(--bs-yellow)",
    Approved: "var(--bs-green)",
    Certified: "var(--bs-red)",
  };

  const columns = [
    // {
    //     title: "ID",
    //     field: "pp_id",
    //     render: (rowData) => (
    //         <Link to={`/protectionprofiles/${rowData?.pp_id}`}>
    //             {rowData?.pp_id}
    //         </Link>
    //     ),
    // },
    { title: "Short Name", field: "pp_short_name" },
    {
      title: "Profile Name",
      field: "pp_name",
      cellStyle: { width: "30%", minWidth: "30%" },
    },
    { title: "Technology Type", field: "tech_type" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <>
          <FontAwesomeIcon
            icon={faCircle}
            className="me-1"
            color={
              rowData?.sunset_date &&
              moment(rowData?.sunset_date).isBefore(moment())
                ? "var(--bs-orange)"
                : rowData?.status === "Publishing"
                  ? "var(--bs-green)"
                  : "var(--bs-yellow)"
            }
            size="xs"
          />
          {rowData?.sunset_date &&
          moment(rowData?.sunset_date).isBefore(moment())
            ? "Archived"
            : "Approved"}
        </>
      ),
    },
    {
      title: "Approved",
      field: "pp_date",
      type: "date",
      render: (rowData) =>
        rowData?.pp_date
          ? moment.utc(rowData.pp_date).format("MM/DD/YYYY")
          : "",
    },
    { title: "CC Version", field: "cc_version" },
    { title: "Sponsor", field: "pp_sponsor_id" },
    {
      title: "Sunset Date",
      field: "sunset_date",
      type: "date",
      render: (rowData) =>
        rowData?.sunset_date
          ? moment.utc(rowData.sunset_date).format("MM/DD/YYYY")
          : "",
    },
    {
      title: "Transition Date",
      field: "pp_transition",
      type: "date",
      hidden: sidebarFilters?.archived === "true",
      render: (rowData) =>
        rowData?.pp_transition
          ? moment.utc(rowData.pp_transition).format("MM/DD/YYYY")
          : "",
    },
    {
      render: (rowData) => {
        return (
          // !moment(rowData?.sunset_date).isBefore(moment()) && rowData?.status === "Publishing" && (
          <Link to={`/protectionprofiles/${rowData?.pp_id}`}>
            <Button variant="light" size="sm" className="border-dark btn-hover">
              Full Details
            </Button>
          </Link>
        );
        // );
      },
    },
  ];

  const options = {
    headerStyle: {
      fontWeight: "bold",
      paddingBottom: "0",
    },
    pageSizeOptions: [5, 10, 20, 50, 100],
    pageSize: tablePageSize ?? 20,
  };

  return (
    <Container className="mt-4" fluid>
      <MaterialTable
        title="Showing"
        columns={columns}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });

            let conditionalPageSize = pageSize;

            if (tablePageSize) {
              conditionalPageSize = tablePageSize;
            }

            let orderByField = orderBy?.field;
            if (sidebarFilters.status === "Publishing" && !orderBy?.field) {
              orderByField = "pp_date";
            } else if (sidebarFilters.archived === "true" && !orderBy?.field) {
              orderByField = "tech_type";
            }
            let orderByDirection = orderDirection;
            if (sidebarFilters.status === "Publishing" && !orderBy?.field) {
              orderByDirection = "desc";
            } else if (sidebarFilters.archived === "true" && !orderBy?.field) {
              orderByDirection = "asc";
            }
            const params = {
              ...sidebarFilters,
              offset: page * conditionalPageSize,
              limit: conditionalPageSize,
              search: search,
              filters: newFilters,
              orderBy: orderByField,
              orderDirection: orderByDirection,
            };

            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/frontend_pps/?${qs.stringify(params, { arrayFormat: "comma" })}`,
              )
              .then((response) => {
                resolve({
                  data: response.data.results.pps,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <label htmlFor="rowsPerPage">Rows Per Page</label>
              }
              SelectProps={{ input: <Input id="rowsPerPage" /> }}
            />
          ),
        }}
      />
    </Container>
  );
}
