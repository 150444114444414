import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import Lists from "./Lists";
import Members from "./Members";

const MailingList = () => {
  const role = useSelector((state) => state.role.value);

  if (role.role_type !== "NIAP") {
    return (
      <Container className="text-center mt-4">
        <h2>Unauthorized</h2>
        <p>You do not have permission to access this area</p>
      </Container>
    );
  }

  return (
    <Routes>
      <Route path="" element={<Lists />} />
      <Route path="members/:id" element={<Members />} />
    </Routes>
  );
};

export default MailingList;
