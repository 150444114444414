import axios from "axios";
import { useEffect, useCallback, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { useInterval } from "../../../hooks/useInterval";

export default function CCTLMetricsGroup({
  refetchMetrics,
  currentRole,
  handleShowModal,
  handleShowVendorModal,
}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [productMetrics, setProductMetrics] = useState({});

  const fetchProductMetrics = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/cctl_product_metrics/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setProductMetrics(response.data);
      })
      .catch((error) =>
        console.log("Unable to get current products: " + error),
      );
  }, []);

  useEffect(() => {
    fetchProductMetrics();
  }, [refetchMetrics]);

  useInterval(() => {
    fetchProductMetrics();
  }, 300000); //5 min

  return (
    <Container className="mt-4">
      <Row>
        {currentRole?.role_permissions?.find(
          (permission) => permission.area === "Project",
        )?.create &&
          currentRole?.role_type === "Lab" && (
            <Col
              className="text-center m-3 rounded-0 shadow clickable"
              onClick={handleShowModal}
            >
              <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
                +
              </h2>
              <p>Propose an Evaluation</p>
            </Col>
          )}
        <Col
          className="text-center m-3 rounded-0 shadow clickable"
          onClick={handleShowVendorModal}
        >
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            +
          </h2>
          <p>Associate a Vendor</p>
        </Col>
        <Col className="text-center m-3 rounded-0 shadow">
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            {productMetrics?.certified_products}
          </h2>
          <p>Certified Products</p>
        </Col>
        <Col className="text-center m-3 rounded-0 shadow">
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            {productMetrics?.in_progress_products}
          </h2>
          <p>Products In Progress</p>
        </Col>
        <Col className="text-center m-3 rounded-0 shadow">
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            {productMetrics?.in_draft_products}
          </h2>
          <p>Products In Draft</p>
        </Col>
        {/* <Col className='text-center m-3 rounded-0 shadow'>
          <h2 style={{ color: 'var(--bs-gray-500)' }} className='mt-2'>
            {productMetrics?.submitted_products}
          </h2>
          <p>Products In Evaluation</p>
        </Col> */}
      </Row>
    </Container>
  );
}
