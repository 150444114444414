import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";

const NISTStatusModal = ({ show, onHide, certReview, update }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [statuses, setStatuses] = useState([]);
  const [newStatus, setNewStatus] = useState({});

  const handleChange = (event) => {
    setNewStatus({ ...newStatus, [event.target.name]: event.target.value });
  };

  const handleReset = () => {
    setNewStatus({});
  };

  const loadStatuses = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/status/get_all_statuses`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        let statuses = response.data;
        if (certReview?.response === null || certReview?.response === "") {
          statuses = response.data.filter(
            (status) =>
              ![
                "Rejected - Revision Required",
                "Approved",
                "Rejected, revisions needed",
              ]?.includes(status?.status),
          );
        }
        setStatuses(statuses);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    loadStatuses();
  }, [certReview?.response]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      ...newStatus,
    };

    let found = statuses?.find((element) => element.stid === newStatus.stid);
    let statusName = found ? found.status : null;
    if (
      [
        "Rejected - Revision Required",
        "Approved",
        "Rejected, revisions needed",
      ]?.includes(statusName)
    ) {
      data["enddate"] = new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      );
    }

    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/${certReview?.crid}/`,
        {
          ...data,
          moddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        update();
        onHide();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          Edit NIST Status Details for VID {certReview?.pid?.v_id}
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="note">
            <Form.Label>Status</Form.Label>
            <Form.Select
              name="stid"
              onChange={handleChange}
              defaultValue={certReview?.stid?.stid}
            >
              <option value="">Select a Status</option>
              {statuses.map((status, idx) => (
                <option key={idx} value={status.stid}>
                  {status.status}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {(certReview?.response === null || certReview?.response === "") && (
            <p className="text-secondary fst-italic mt-2">
              **Note: Response needed to set status to Approved or Rejected**
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" onClick={handleReset} variant="primary">
            Reset
          </Button>
          <Button onClick={onHide} variant="danger">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="success">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NISTStatusModal;
