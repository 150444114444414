import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import FlagIcon from "@material-ui/icons/Flag";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useEffect, useState, useRef } from "react";
import { ButtonGroup, Button, Popover, OverlayTrigger } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useInterval } from "../../../hooks/useInterval";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import PPForm from "./PPForm";

export default function PPTable() {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [currentPp, setcurrentPp] = useState({});
  const [show, setShowModal] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const permissions = useSelector((state) => state.role.value);
  const handleFiltering = () => setFiltering(!filtering);
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  const handleShowModal = (e, rowData) => {
    setcurrentPp({ ...rowData });
    setShowModal(true);
  };

  const handleCloseModal = async (e) => {
    setRefresh(!refresh);
    setShowModal(false);
    setcurrentPp({});
  };

  const handleArchive = async (e, archivedPp) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/${archivedPp.pp_id}/`,
        {
          archived: true,
          status: "Archived",
          pp_name: archivedPp.pp_name,
          csfceligible: archivedPp.csfceligible,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        setRefresh(!refresh);
      })
      .catch((error) => console.log("Unable to archive pp: " + error));
  };

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: filtering,
    columnsButton: true,
    pageSize: 20,
  };

  const columns = [
    {
      title: "ID",
      field: "pp_id",
      render: (rowData) => (
        <Link
          to={`details/${rowData.pp_id}`}
          className="text-decoration-underline"
        >
          {rowData?.pp_id}
        </Link>
      ),
    },
    { title: "Short Name", field: "pp_short_name" },
    { title: "Profile Name", field: "pp_name" },
    { title: "Technology Type", field: "tech_type" },
    { title: "Status", field: "status" },
    {
      title: "Archived",
      field: "archived",
      defaultFilter: ["false"],
      lookup: { true: "True", false: "False" },
      render: (rowData) => (rowData.archived ? <CheckIcon /> : <CloseIcon />),
    },
    {
      title: "Est. Completion Date",
      field: "pp_estcompletiondate",
      type: "date",
      render: (rowData) =>
        rowData?.pp_estcompletiondate
          ? moment.utc(rowData.pp_estcompletiondate).format("YYYY-MM-DD")
          : "",
    },
    {
      title: "Approval Date",
      field: "pp_date",
      type: "date",
      render: (rowData) =>
        rowData?.pp_date
          ? moment.utc(rowData.pp_date).format("YYYY-MM-DD")
          : "",
    },
    { title: "CC Version", field: "cc_version" },
    {
      title: "Sunset Date",
      field: "sunset_date",
      type: "date",
      render: (rowData) =>
        rowData?.sunset_date
          ? moment.utc(rowData.sunset_date).format("YYYY-MM-DD")
          : "",
    },
    {
      title: "Transition Date",
      field: "pp_transition",
      type: "date",
      render: (rowData) =>
        rowData?.pp_transition
          ? moment.utc(rowData.pp_transition).format("YYYY-MM-DD")
          : "",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      headerStyle: { background: "white", position: "sticky", right: 0 },
      cellStyle: { background: "white", position: "sticky", right: 0 },
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          key="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                <Link
                  to={`details/${rowData.pp_id}`}
                  className="text-decoration-none"
                >
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <VisibilityIcon color="action" /> View
                  </Button>
                </Link>
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Protection Profile",
                )?.update && (
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link"
                    onClick={(e) => handleShowModal(e, rowData)}
                  >
                    <EditIcon color="action" /> Edit
                  </Button>
                )}
                {permissions?.role_permissions?.find(
                  (permission) => permission.area === "Protection Profile",
                )?.destroy && (
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link"
                    onClick={(e) => handleArchive(e, rowData)}
                  >
                    <FlagIcon color="action" /> Archive
                  </Button>
                )}
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link">
            <MoreVertIcon className="float-end" />
            <span className="visually-hidden">Action</span>
          </Button>
        </OverlayTrigger>
      ),
    },
  ];

  useInterval(() => {
    setRefresh(!refresh);
  }, 600000); //10 min

  return (
    permissions?.role_permissions?.find(
      (permission) => permission.area === "Protection Profile",
    )?.read && (
      <>
        <div className="py-4 text-end">
          <ButtonGroup>
            {permissions?.role_permissions?.find(
              (permission) => permission.area === "Protection Profile",
            )?.create && (
              <Button
                variant="primary"
                className="me-3 rounded-0"
                onClick={(e) => handleShowModal(e, {})}
              >
                + Add New PP
              </Button>
            )}
            <Button
              variant={filtering ? "primary" : "light"}
              className="rounded-0"
              onClick={handleFiltering}
            >
              Filter <FilterListIcon />
            </Button>
          </ButtonGroup>
        </div>
        <ResponsiveMaterialTable
          title={"Protection Profiles"}
          columns={columns}
          tableRef={tableRef}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                tableRef.current &&
                tableRef.current.dataManager.columns.forEach((item) => {
                  console.log(item);
                  tableRef.current.onFilterChange(item.tableData.id, "");
                }),
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              // Extract the necessary information from the query object
              const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
              } = query;

              let newFilters = filters.map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else if (
                  [
                    "pp_estcompletiondate",
                    "pp_date",
                    "sunset_date",
                    "pp_transition",
                  ]?.includes(filter?.column?.field)
                ) {
                  const dateObj = new Date(filter?.value);
                  const isoStr = dateObj.toISOString();
                  const formattedStr = isoStr.split("T")[0];
                  value = formattedStr;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
              });

              const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
                filter_out_ccp: true,
              };

              axios
                .get(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                  {
                    withCredentials: true,
                    headers: {
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then((response) => {
                  resolve({
                    data: response.data.results,
                    page: page,
                    totalCount: response.data.count,
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          }
          options={options}
        />
        <PPForm
          show={show}
          handleCloseModal={handleCloseModal}
          pp={currentPp}
          refetch={() => setRefresh(!refresh)}
        />
      </>
    )
  );
}
