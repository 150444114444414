import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../../UI/UnauthorizedView";

import CommunityTechDecisionDetails from "./CommunityTechDecisionDetails";
import CommunityTechDecisionTable from "./CommunityTechDecisionTable";

export default function TechDecisionManager({}) {
  const permissions = useSelector((state) => state.role.value);

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Technical Decisions",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<CommunityTechDecisionTable />} />
      <Route path="details/:id" element={<CommunityTechDecisionDetails />} />
    </Routes>
  );
}
