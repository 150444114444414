import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import parse from "html-react-parser";
import FileDownload from "js-file-download";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Accordion,
  Button,
  Breadcrumb,
} from "react-bootstrap";
import { useParams, Link } from "react-router-dom";

import "../Products.css";
import { useInterval } from "../../../hooks/useInterval";
import amFileIcon from "../../../images/assurance-maintenance-file-icon.svg";
import Subheader from "../../Subheader";

export default function CheckAssuranceMaintenancePermDetails({
  setOverlayActive,
  setOverlayText,
}) {
  const { product_id, id } = useParams();
  const [hasPerm, setHasPerm] = useState();

  useEffect(() => {
    if (product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/is_pcl_product/?product_id=${product_id}`,
        )
        .then((response) => {
          if (response.data?.is_pcl_product === false) {
            setHasPerm(false);
          } else {
            setHasPerm(true);
          }
        })
        .catch(() => {
          setHasPerm(false);
        });
    }
  }, [product_id]);

  if (hasPerm === true) {
    return (
      <AssuranceMaintenancePage
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
      />
    );
  } else if (hasPerm === false) {
    return (
      <div>
        Assurance Maintenance does not exist or is not viewable on the PCL.
      </div>
    );
  }
  return <div></div>;
}

function AssuranceMaintenancePage({ setOverlayActive, setOverlayText }) {
  const { product_id, id } = useParams();
  const [maintenance, setMaintenance] = useState({});
  const [project, setProject] = useState({});
  const [projpps, setProjpps] = useState([]);
  const [files, setFiles] = useState([]);
  const [validationReportNumber, setValidationReportNumber] = useState("");

  useEffect(() => {
    document.title = "NIAP - Assurance Maintenance Details";
  }, []);

  const loadProtectionProductId = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_projpp_by_product_id/?product_id=${product_id}`,
      )
      .then((response) => {
        setProjpps(response.data);
      })
      .catch((error) => {
        setProjpps([]);
      });
  }, [product_id]);

  useEffect(() => {
    loadProtectionProductId();
  }, [loadProtectionProductId]);

  useInterval(() => {
    loadProtectionProductId();
  }, 180000); //3 min

  const fetchProduct = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_product_by_product_id/?product_id=${product_id}`,
      )
      .then((response) => {
        setProject(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [product_id]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  useInterval(() => {
    fetchProduct();
  }, 60000); //1 min

  const fetchMaintenance = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/get_frontend_maintenance_by_maintenance_id/?maintenance_id=${id}`,
      )
      .then((response) => {
        setMaintenance(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    fetchMaintenance();
  }, [fetchMaintenance]);

  useInterval(() => {
    fetchMaintenance();
  }, 60000); //1 min

  const pullFiles = useCallback(() => {
    if (project?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_pcl_files/?product_id=${project?.product_id}`,
        )
        .then((response) => {
          setFiles(response.data);
          let validationReport = response.data?.eval_files?.find(
            (file) => file?.file_display_name === "Validation Report",
          );
          if (validationReport) {
            setValidationReportNumber(
              `CCEVS-VR-VID${product_id}-${new Date(validationReport?.uploaded_on).getFullYear()}`,
            );
          }
        })
        .catch((error) => {
          setFiles([]);
        });
    }
  }, [project, maintenance.maintenance_id]);

  useEffect(() => {
    pullFiles();
  }, [project, maintenance.maintenance_id]);

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_public_file/?file_id=${file.file_id}`,
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Subheader pageName={"Products"} />
      <Container className="p-5" fluid>
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item href={`/products/${product_id}`}>
              VID {project?.v_id} Details
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{maintenance?.title}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row>
          <Col className="d-flex">
            <h3 className="fw-bold">
              PRODUCTS -{" "}
              <span className="text-primary">
                {project?.certification_status === "Certified"
                  ? project?.certification_status.toUpperCase()
                  : "EVALUATION"}{" "}
                PRODUCT - ASSURANCE CONTINUITY
              </span>
            </h3>
            {/* <img className='ms-auto' src={require('../../../images/card-logo.png')} height='60px' alt='Product Logo' /> */}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Stack>
              <h6 className="fw-bold">VENDOR INFORMATION</h6>
              <h6 className="fw-bold mb-2 mx-2">{project?.vendor_id?.name}</h6>
              <h6 className="mx-2">{project?.vendor_poc}</h6>
              <h6 className="mx-2">{project?.vendor_phone}</h6>
              <h6 className="text-primary mx-2">{project?.vendor_email}</h6>
              <a
                className="mx-2"
                href={
                  project?.vendor_id?.website?.includes("https://")
                    ? project?.vendor_id?.website
                    : "https://" + project?.vendor_id?.website
                }
                target="_blank"
                rel="noreferrer"
              >
                {project?.vendor_id?.website}
              </a>
            </Stack>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <h3 className="fw-bold">{maintenance?.title}</h3>
          </Col>
        </Row>
        <Row className="gx-2">
          <Col sm={12} md={3} lg={2} className="bg-primary p-4 text-white">
            <Stack className="border-bottom pb-4">
              <Accordion className="border-0" defaultActiveKey={"1"}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="custom-accordion-header">
                    <small className="p-0 text-white">Documents</small>
                  </Accordion.Header>
                  <Accordion.Body className="p-0 mt-3">
                    {files?.am_files?.map((file) => (
                      <Row className="mt-3">
                        <Stack direction="horizontal">
                          <img
                            src={amFileIcon}
                            alt="am-file-icon"
                            className="me-2"
                          />
                          <Button
                            variant="link"
                            onClick={() => downloadFile(file)}
                            className="text-white d-flex justify-content-start p-0 m-0 small-font"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p className="m-0">{file?.file_label}</p>
                          </Button>
                        </Stack>
                      </Row>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Stack>
            {maintenance?.completion_date && (
              <Stack className="border-bottom pt-4">
                <small className="pb-2">Maintenance Completion Date</small>
                <p>
                  {moment
                    .utc(maintenance?.completion_date)
                    .format("MM/DD/YYYY")}
                </p>
              </Stack>
            )}
            <Stack className="border-bottom pt-4">
              <small className="pb-2">Validation Report Number</small>
              <p>{validationReportNumber}</p>
            </Stack>
            <Stack className="border-bottom pt-4">
              <small className="pb-2">Product Type</small>
              <p>{project?.tech_type}</p>
            </Stack>
            <Stack className="border-bottom pt-4">
              <small className="pb-2">Conformance Claim</small>
              {projpps?.map((projpp) => (
                <div
                  key={projpp?.projpp_id}
                  className="d-flex align-items-center py-2 pb-3"
                >
                  <FontAwesomeIcon
                    icon={faFileLines}
                    color="var(--bs-yellow)"
                    className="me-2"
                    size="lg"
                  />
                  <Link
                    to={`/protectionprofiles/${projpp?.pp__pp_id}`}
                    className="text-white"
                  >
                    <p className="py-0 my-0">{projpp?.pp__pp_name}</p>
                  </Link>
                </div>
              ))}
            </Stack>
            {project?.assigned_lab && (
              <Stack className="pt-4">
                <small className="pb-2">CC Testing Lab</small>
                <p>{project?.assigned_lab?.name}</p>
              </Stack>
            )}
          </Col>
          <Col sm={12} md={9} lg={10} className="px-4">
            <Accordion className="mb-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">
                    MAINTENANCE DESCRIPTION
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  {maintenance?.maintenance_description &&
                    parse(maintenance?.maintenance_description)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="my-4" flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="text-primary fw-bold">SUMMARY OF CHANGES</h6>
                </Accordion.Header>
                <Accordion.Body>
                  {maintenance?.change_summary &&
                    parse(maintenance?.change_summary)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <div className="mt-5">
            <p>
              <span className="fw-bold">
                Readers are reminded that the certification of this product
                (TOE) is the result of maintenance, rather than an actual
                re-evaluation of the product.
              </span>
              &nbsp; Maintenance only considers the affect of TOE changes on the
              assurance baseline (i.e. the original evaluated TOE); maintenance
              is not intended to provide assurance in regard to the resistance
              of the TOE to new vulnerabilities or attack methods discovered
              since the date of the initial certificate. Such assurance can only
              be gained through re-evaluation.
            </p>
            <p>
              Using a security impact analysis of the changes made to the TOE,
              which was provided by the developer, the CCEVS has determined that
              the impact of changes on the TOE are considered minor and that
              independent evaluator analysis was not necessary. A summary of the
              results can be found in the Maintenance Report, which is written
              in relation to the product's original validation report and
              Security Target.
              <span className="fw-bold">
                Readers are therefore reminded to read the Security Target,
                Validation Report, and the Assurance Maintenance Report to fully
                understand the meaning of what a maintained certificate
                represents.
              </span>
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
}
