import EditIcon from "@material-ui/icons/Edit";
import FlagIcon from "@material-ui/icons/Flag";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TourIcon from "@mui/icons-material/Tour";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { MTableBodyRow, MTableCell } from "material-table";
import moment from "moment";
import qs from "qs";
import { useCallback, useState, useEffect } from "react";
import {
  Button,
  Popover,
  OverlayTrigger,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import EventDatesColumn from "./EventDatesColumn";
import EventsDatesColumnAccordion from "./EventsDatesColumnAccordion";
import ManageAMValidationTeamModal from "./ManageAMValidationTeamModal";
import ManageValidationTeamModal from "./ManageValidationTeamModal";
import MultipleTrrtsAccordionColumn from "./MultipleTrrtsAccordionColumn";
import TrrtsLinkView from "./TrrtsLinkView";
import { getDaysSinceStartDate } from "./ValidatorProductsService";

import "./ValidatorProductsTable.css";

export default function ValidatorProductsTable({
  tableRef,
  projpps,
  trrts,
  currentRole,
  progressPoints,
  progressPointsData,
  isValidatorResponseMember,
  setAlertTable,
}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [showManageValTeamModal, setShowManageValTeamModal] = useState(false);
  const [showManageAMValTeamModal, setShowManageAMValTeamModal] =
    useState(false);
  const [currentProductInEval, setCurrentProductInEval] = useState({});
  const [earStatuses, setEarStatuses] = useState({});
  const [nistStatuses, setNistStatuses] = useState({});
  const [activeProducts, setActiveProducts] = useState([]);
  const [userPersonnelInfo, setUserPersonnelInfo] = useState({});

  const fetchPersonnelInfo = useCallback(() => {
    const productIds = activeProducts.map((product) => product.product_id);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/get_all_personnel_from_products/?product_id_list=[${productIds}]`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setUserPersonnelInfo(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeProducts]);

  useEffect(() => {
    fetchPersonnelInfo();
  }, [fetchPersonnelInfo]);

  const handleShowManageTeamModal = (rowData) => {
    setCurrentProductInEval(rowData);
    if (rowData?.submission_type === "Assurance Maintenance") {
      setShowManageAMValTeamModal(true);
    } else {
      setShowManageValTeamModal(true);
    }
  };

  const getEarStatuses = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/get_all_ear_statuses/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setEarStatuses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getEarStatuses();
  }, [getEarStatuses]);

  const getNistStatuses = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/get_all_nist_statuses/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setNistStatuses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getNistStatuses();
  }, [getNistStatuses]);

  const getEarFlagColor = (product) => {
    let color = "secondary";
    if (product?.product_id) {
      if (earStatuses[product?.product_id]) {
        if (earStatuses[product?.product_id].status === "Pending Review") {
          color = "warning";
        } else if (
          earStatuses[product?.product_id].status?.includes("Provisions")
        ) {
          color = "info";
        } else if (
          earStatuses[product?.product_id].status?.includes("Completed")
        ) {
          color = "success";
        } else if (
          earStatuses[product?.product_id].status?.includes("Rejected")
        ) {
          color = "danger";
        }
      }
    }
    return color;
  };

  const getNistFlagColor = (product) => {
    let color = "secondary";
    if (product?.product_id) {
      if (nistStatuses[product?.product_id]) {
        if (nistStatuses[product?.product_id].status === "Pending Review") {
          color = "warning";
        } else if (
          nistStatuses[product?.product_id].status === "Pending Final Review"
        ) {
          color = "info";
        } else if (nistStatuses[product?.product_id].status === "Approved") {
          color = "success";
        } else if (
          nistStatuses[product?.product_id].status?.includes("Rejected")
        ) {
          color = "danger";
        }
      }
    }
    return color;
  };

  const columns = [
    {
      title: "VID No.",
      field: "v_id",
      type: "numeric",
      render: (rowData) => (
        <Link
          to={`products/details/${rowData.product_id}`}
          className="text-decoration-underline"
          data-cy={`My Projects-vid_select-${rowData?.product_id}`}
        >
          {rowData?.v_id}
        </Link>
      ),
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
    },
    {
      title: "Vendor",
      field: "vendor_id.name",
      cellStyle: {
        width: "15%",
        minWidth: "15%",
      },
    },
    {
      title: "Product",
      field: "product_name",
    },
    {
      title: "Conformance",
      render: (rowData) => (
        <ListGroup>
          {projpps &&
            projpps[rowData?.product_id]?.map((projpp, idx) => (
              <OverlayTrigger
                key={idx}
                placement="top"
                overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
              >
                <Link
                  to={`/community/protectionprofiles/details/${projpp.id}`}
                  className="text-decoration-underline"
                >
                  <ListGroup.Item disabled>
                    {projpp.pp_short_name}
                  </ListGroup.Item>
                </Link>
              </OverlayTrigger>
            ))}
        </ListGroup>
      ),
    },
    {
      title: "CCTL",
      field: "assigned_lab.name",
    },
    {
      title: "Vehicle & Role",
      render: (rowData) => {
        const personnelInfo = userPersonnelInfo.find(
          (personnel) => personnel.product_id.product_id === rowData.product_id,
        );
        let vehicleAndRole = "";
        vehicleAndRole = personnelInfo?.role;
        if (personnelInfo?.contract_vehicle) {
          const { desig, vehicle } = personnelInfo.contract_vehicle;
          vehicleAndRole = `${vehicleAndRole} - ${desig || vehicle}`;
        }
        return vehicleAndRole;
      },
    },
    {
      title: "Estimated Evaluator Checkout Date",
      render: (rowData) => (
        <>
          {rowData?.evaluator_checkout_date
            ? moment.utc(rowData.evaluator_checkout_date).format("MM/DD/YYYY")
            : "No Date Set"}
        </>
      ),
    },
    {
      title: "Days in Progress",
      field: "check_in_date",
      render: (rowData) => {
        const startDate = moment(rowData?.kicked_off_date);
        const endDate = rowData?.posted_date
          ? moment(rowData?.posted_date)
          : moment();
        const daysSinceStartDate = endDate.diff(startDate, "days");
        return <>{rowData?.kicked_off_date ? daysSinceStartDate : "0"}</>;
      },
    },
    {
      title: "Days in AM",
      field: "days_in_am",
      render: (rowData) => {
        return (
          <>
            {
              <div data-testid="daysInAM">
                {rowData?.current_assurance_maintenance
                  ? getDaysSinceStartDate(rowData)
                  : "0"}
              </div>
            }
          </>
        );
      },
    },
    {
      title: "EAR",
      field: "ear",
      render: (rowData) => {
        return (
          earStatuses[rowData?.product_id] && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>{earStatuses[rowData?.product_id].status}</Tooltip>
              }
            >
              <FlagIcon
                data-testid="earFlag"
                color={getEarFlagColor(rowData)}
              />
            </OverlayTrigger>
          )
        );
      },
    },
    {
      title: "NCR",
      field: "ncr",
      render: (rowData) => {
        return (
          nistStatuses[rowData?.product_id] && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>{nistStatuses[rowData?.product_id].status}</Tooltip>
              }
            >
              <TourIcon
                color={getNistFlagColor(rowData)}
                data-testid="ncrFlag"
                title={nistStatuses[rowData?.product_id].status}
              />
            </OverlayTrigger>
          )
        );
      },
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <Chip
          label={
            rowData?.status === "Certified" ? "Completed" : rowData?.status
          }
        />
      ),
    },
    {
      title: "Current Progress Point",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
      render: (rowData) =>
        rowData.status === "In Assurance Maintenance" ? (
          "PP8"
        ) : progressPoints[rowData?.product_id] ? (
          progressPoints && progressPoints[rowData?.product_id][0]
        ) : (
          <></>
        ),
    },
    {
      title: "Event Dates",
      field: "event_dates",
      render: (rowData) => {
        let ppDataObj;
        if (progressPointsData?.[rowData?.product_id]) {
          ppDataObj = {
            pp6: progressPointsData?.[rowData?.product_id][6],
            pp7: progressPointsData?.[rowData?.product_id][7],
          };
        }
        return (
          <>
            <div className="d-flex">
              {ppDataObj &&
                Object.keys(ppDataObj).map((ppIndex, index) => (
                  <div key={index}>
                    {ppDataObj[ppIndex]?.estimated_completion?.includes(
                      null,
                    ) && (
                      // displays when there are no estimated completion dates for pp6 and/or pp7
                      <span className="mx-1">
                        {ppDataObj[ppIndex]?.progress_point}. Estimated:{"  "}
                        {moment
                          .utc(
                            ppDataObj[ppIndex]?.progress_point === 6
                              ? rowData?.evaluator_checkout_date
                              : rowData?.validator_checkout_date,
                          )
                          .format("MM/DD/YYYY") !== "Invalid date"
                          ? moment
                              .utc(
                                ppDataObj[ppIndex]?.progress_point === 6
                                  ? rowData?.evaluator_checkout_date
                                  : rowData?.validator_checkout_date,
                              )
                              .format("MM/DD/YYYY")
                          : "No Date"}
                      </span>
                    )}
                    {ppDataObj[ppIndex]?.estimated_completion?.[0] &&
                      ppDataObj[ppIndex]?.estimated_completion?.length ===
                        1 && (
                        // displays when there are single estimated completion dates for pp6 and/or pp7
                        <div
                          data-testid={`pp${ppDataObj?.[ppIndex]?.progress_point}_estimated`}
                        >
                          <EventDatesColumn
                            ppData={ppDataObj[ppIndex]}
                            dateType={"Estimated"}
                          />
                        </div>
                      )}
                    {ppDataObj[ppIndex]?.estimated_completion?.length > 1 && (
                      // displays when there's multiple estimated completion dates for pp6 and/or pp7
                      <EventsDatesColumnAccordion
                        ppData={ppDataObj[ppIndex]}
                        rowData={rowData}
                      />
                    )}
                    {ppDataObj[ppIndex]?.submission?.[0] && (
                      // handles displaying completed on dates for pp6 and pp7
                      <div
                        data-testid={`pp${ppDataObj?.[ppIndex]?.progress_point}_completed`}
                        className="mt-1"
                      >
                        <EventDatesColumn
                          ppData={ppDataObj[ppIndex]}
                          dateType={"Completed"}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </>
        );
      },
    },
    {
      title: "Technical Queries",
      field: "technical_queries",
      render: (rowData) => {
        const getConsolidatedTrrts = () => {
          const accordionKey = trrts?.results?.map((trrt) =>
            trrt?.product_evaluation?.reduce((accumulator, currentProduct) => {
              accumulator = {
                id: currentProduct.product_id,
                name: currentProduct.product_name,
                tq_id: trrt.tq_id,
                title: trrt.title,
              };
              return accumulator;
            }, {}),
          );
          return accordionKey;
        };
        const getFilteredTrrts = () => {
          return getConsolidatedTrrts()?.filter(
            (tq_data) => tq_data.id === rowData?.product_id,
          );
        };
        const filteredTrrts = getFilteredTrrts();

        return (
          <>
            <div className="d-inline-flex tq_container">
              {filteredTrrts && filteredTrrts?.length === 1 && (
                <TrrtsLinkView
                  tq_id={filteredTrrts?.[0]?.tq_id}
                  tq_title={filteredTrrts?.[0]?.title}
                />
              )}
              {filteredTrrts?.length > 1 && (
                <MultipleTrrtsAccordionColumn
                  rowData={rowData}
                  filteredTrrts={filteredTrrts}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            key="bottom"
            overlay={
              <Popover>
                <Popover.Body>
                  <div className="d-flex-column text-center">
                    {currentRole?.role_permissions?.find(
                      (permission) => permission.area === "Project",
                    )?.read && (
                      <>
                        <Link
                          to={
                            rowData?.submission_type === "Assurance Maintenance"
                              ? `products/details/${rowData.product_id}/assurancemaintenance/${rowData?.current_assurance_maintenance?.maintenance_id}`
                              : `products/details/${rowData.product_id}`
                          }
                          className="text-decoration-none"
                        >
                          <Button variant="link" className="m-1 p-0 btn-link">
                            <RemoveRedEyeIcon color="action" />
                            Details
                          </Button>
                        </Link>
                        <Link
                          to={
                            rowData?.submission_type === "Assurance Maintenance"
                              ? `/community/products/details/${rowData?.product_id}/assurancemaintenance/${rowData?.current_assurance_maintenance?.maintenance_id}/am_files`
                              : `/community/products/details/${rowData?.product_id}/product_files`
                          }
                          className="text-decoration-none"
                        >
                          <Button variant="link" className="m-1 p-0 btn-link">
                            <FolderIcon color="action" />
                            Files
                          </Button>
                        </Link>
                      </>
                    )}
                    {isValidatorResponseMember?.is_member && (
                      <Button
                        variant="link"
                        className="m-1 p-0 btn-link"
                        onClick={() => handleShowManageTeamModal(rowData)}
                      >
                        <EditIcon color="action" />
                        Validation Team
                      </Button>
                    )}
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="link">
              <MoreVertIcon className="float-end" />
            </Button>
          </OverlayTrigger>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: "dense",
    pageSize: 20,
    pageSizeOptions: [20, 50, 75, 100],
  };
  return (
    <>
      <ManageValidationTeamModal
        show={showManageValTeamModal}
        setShow={setShowManageValTeamModal}
        currentProductInEval={currentProductInEval}
        setAlertTable={setAlertTable}
        refetch={() => tableRef.current.onQueryChange()}
      />
      <ManageAMValidationTeamModal
        show={showManageAMValTeamModal}
        setShow={setShowManageAMValTeamModal}
        currentProductInEval={currentProductInEval}
        refetch={() => tableRef.current.onQueryChange()}
      />
      <ResponsiveMaterialTable
        title="My Projects"
        columns={columns}
        components={{
          Row: (props) => (
            <MTableBodyRow
              {...props}
              data-cy={`My Projects-row-${props.data.product_id}`}
            />
          ),
          Cell: (props) => (
            <MTableCell
              {...props}
              data-cy={`My Projects-col-${props?.columnDef?.title}-${props.rowData?.product_id}`}
            />
          ),
        }}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/my_validations/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .then((response) => setActiveProducts(response.data))
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
    </>
  );
}
