import moment from "moment";

export default function VotingOverlay({ voter, date, divider }) {
  return (
    <div className={`m-0${divider ? " pb-1 mb-1 border-bottom" : ""}`}>
      <div className="d-flex justify-content-between align-items-baseline">
        <span>{`${voter?.first_name} ${voter?.last_name} (${voter?.user_role?.role_name})`}</span>
        <i style={{ fontSize: ".75em", marginLeft: "1em" }}>{`${
          date && moment.utc(date).format("MM/DD/YYYY hh:mm A")
        }`}</i>
      </div>
    </div>
  );
}
