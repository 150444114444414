import parse from "html-react-parser";
import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import actions from "./Actions.json";

const ActionsModal = ({ actionNumber, show, onHide }) => {
  const [currentAction, setCurrentAction] = useState(
    actions[String(actionNumber)],
  );
  const scrollRef = useRef();

  useEffect(() => {
    setCurrentAction(actions[String(actionNumber)]);
  }, [actionNumber]);

  const changeAction = (number) => {
    setCurrentAction(actions[String(number)]);
    scrollRef.current?.scrollIntoView({ block: "nearest" });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header ref={scrollRef} closeButton>
        {currentAction?.title}
      </Modal.Header>
      <Modal.Body>
        <p>
          {currentAction?.type} {currentAction?.id}
        </p>
        {(currentAction?.type === "Action" ||
          currentAction?.type === "Decision") && (
          <p>Actor: {currentAction?.actor}</p>
        )}
        {currentAction?.type === "Reference" && (
          <p>Guide: {currentAction?.actor && parse(currentAction?.actor)}</p>
        )}
        {currentAction?.type === "Status" && (
          <p>Status: {currentAction?.actor && parse(currentAction?.actor)}</p>
        )}
        {currentAction?.type === "Progress Point" && (
          <p>Owner: {currentAction?.actor}</p>
        )}
        <div className="pb-3">
          {currentAction?.description && parse(currentAction?.description)}
        </div>

        {currentAction?.relatedArtifact?.length > 0 && (
          <>
            <h4>Artifacts</h4>
            {currentAction?.relatedArtifact?.map((number) => (
              <div key={actions[String(number)]?.id}>
                <p>
                  {actions[String(number)]?.type} {actions[String(number)]?.id}
                </p>
                <div className="pb-3">
                  {actions[String(number)]?.description &&
                    parse(actions[String(number)]?.description)}
                </div>
              </div>
            ))}
          </>
        )}

        {currentAction?.relatedReferences?.length > 0 && (
          <>
            <h4>References</h4>
            {currentAction?.relatedReferences?.map((number) => (
              <div key={actions[String(number)]?.id}>
                <p>
                  {actions[String(number)]?.type} {actions[String(number)]?.id}
                </p>
                {actions[String(number)]?.actor !== "N/A" && (
                  <p>
                    Guide:{" "}
                    {actions[String(number)]?.actor &&
                      parse(actions[String(number)]?.actor)}
                  </p>
                )}
                <div className="pb-3">
                  {actions[String(number)]?.description?.map((relNum) => (
                    <Button
                      key={`${number}-${relNum}`}
                      className="p-0"
                      style={{ minWidth: 25 }}
                      variant="link"
                      onClick={() => changeAction(relNum)}
                    >
                      {relNum},
                    </Button>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}

        {currentAction?.relatedStatus?.length > 0 && (
          <>
            <h4>Statuses</h4>
            {currentAction?.relatedStatus?.map((number) => (
              <div key={actions[String(number)]?.id}>
                <p>
                  {actions[String(number)]?.type} {actions[String(number)]?.id}
                </p>
                {actions[String(number)]?.actor !== "N/A" && (
                  <p>
                    Status:{" "}
                    {actions[String(number)]?.actor &&
                      parse(actions[String(number)]?.actor)}
                  </p>
                )}
                <div className="pb-3">
                  {actions[String(number)]?.description &&
                    parse(actions[String(number)]?.description)}
                </div>
              </div>
            ))}
          </>
        )}

        {currentAction?.relatedProgress?.length > 0 && (
          <>
            <h4>Progress Points</h4>
            {currentAction?.relatedProgress?.map((number) => (
              <div key={actions[String(number)]?.id}>
                <p>
                  {actions[String(number)]?.type} {actions[String(number)]?.id}
                </p>
                {actions[String(number)]?.actor !== "N/A" && (
                  <p>Owner: {actions[String(number)]?.actor}</p>
                )}
                <div className="pb-3">
                  {actions[String(number)]?.description &&
                    parse(actions[String(number)]?.description)}
                </div>
              </div>
            ))}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ActionsModal;
