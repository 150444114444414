import MoreVertIcon from "@material-ui/icons/MoreVert";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";

export default function ViewPolicyModal({ show, setShow, currPolicy }) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];

  const downloadFile = (fileName) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_policy_letter/?file_name=${fileName}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        FileDownload(response.data, fileName);
      })
      .catch((error) => {
        console.log("Error");
      });
  };
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setShow(false);
      }}
      size="lg"
    >
      <Form>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            POLICY INFORMATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Row className="fw-bold mb">POLICY NO:</Row>
              <Row className="mb-3">{currPolicy?.policy_num}</Row>
            </Col>
            <Col>
              <Row className="fw-bold">UPDATE NO:</Row>
              <Row>{currPolicy?.update_num}</Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="fw-bold">PUBLICATION DATE:</Row>
              <Row className="mb-3">
                {moment.utc(currPolicy?.policy_date).format("MM/DD/YYYY")}
              </Row>
            </Col>
            <Col>
              <Row className="fw-bold">ADDENDUM NO:</Row>
              <Row>{currPolicy?.addendum_num}</Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="fw-bold">AUTHOR:</Row>
              <Row className="mb-3">{currPolicy?.author}</Row>
            </Col>
            <Col>
              <Row className="fw-bold">SUPERSEDED NO:</Row>
              <Row>{currPolicy?.superseded_num}</Row>
            </Col>
          </Row>
          <Row>
            <Row className="fw-bold">TITLE:</Row>
            <Row className="mb-3">{currPolicy?.policy_title}</Row>
          </Row>
          <Row>
            <Row className="fw-bold">URL:</Row>

            <Row className="mb-3">
              <Button
                variant="link"
                className="text-bright-navy text-decoration-underline"
                onClick={() => downloadFile(currPolicy?.filename)}
              >
                {currPolicy?.filename}{" "}
              </Button>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
