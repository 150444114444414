import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, OverlayTrigger, Popover } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { getCurrentDate } from "../../../../utils/timeUtils";
import { Award } from "../../../TRRTS/TRRTSManager/TQVoting/Award";
import VotingOverlay from "../../../TRRTS/TRRTSManager/VotingOverlay";

export default function TDVoting({ techDecision, currentUser, refetchVotes }) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [votes, setVotes] = useState([]);
  const [currentUserVote, setCurrentUserVote] = useState();

  const getVotes = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/voting/td_votes_by_td_id/?td_id=${techDecision.td_id}`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setVotes(response.data); //we set all the votes
        setCurrentUserVote(
          response.data.find((vote) => vote.voter.id === currentUser?.id),
        ); //we set only the current user's vote
      })
      .catch((error) => console.log("Unable to get votes: " + error));
  };

  useEffect(() => {
    if (techDecision.td_id) {
      getVotes();
    }
  }, [techDecision.td_id]);

  const handleVote = async (vote) => {
    if (currentUserVote) {
      if (vote === currentUserVote.approved) {
        await axios
          .delete(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/voting/${currentUserVote.id}/`,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then(() => {
            getVotes();
            if (refetchVotes) refetchVotes();
          })
          .catch((error) => console.log("Unable to delete vote: " + error));
      } else {
        await axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/voting/${currentUserVote.id}/`,
            {
              approved: vote,
              vote_date: getCurrentDate(),
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then(() => {
            getVotes();
            if (refetchVotes) refetchVotes();
          })
          .catch((error) => console.log("Unable to update vote: " + error));
      }
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/voting/`,
          {
            td_id: techDecision.td_id,
            voter: currentUser?.id,
            approved: vote,
            vote_date: getCurrentDate(),
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          getVotes();
          if (refetchVotes) refetchVotes();
        })
        .catch((error) => console.log("Unable to add vote: " + error));
    }
  };

  const getVoteCount = (approved) => {
    return votes.filter((v) => v.approved === approved).length;
  };

  return (
    <Col xs={1} className="d-flex justify-content-center w-auto">
      <>
        {/* negative vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                {votes
                  .filter((vote) => vote.approved === false)
                  .map((vote, idx) => (
                    <div key={vote.voter.id}>
                      <VotingOverlay voter={vote.voter} date={vote.vote_date} />
                    </div>
                  ))}
              </Popover.Body>
            </Popover>
          }
        >
          <div className="d-flex">
            {currentUserVote?.approved === false ? (
              <ThumbDownIcon
                data-testid="thumb-down-icon"
                style={{
                  color: "var(--bs-danger)",
                }}
                className="clickable"
                onClick={() => handleVote(false)}
              />
            ) : (
              <ThumbDownOffAltIcon
                data-testid="thumb-down-off-icon"
                className="clickable"
                onClick={() => handleVote(false)}
              />
            )}
            <h4 className="mx-3">{getVoteCount(false)}</h4>
          </div>
        </OverlayTrigger>

        {/* positive vote overlay */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                {votes
                  .filter((vote) => vote.approved === true)
                  .map((vote, idx) => (
                    <div key={vote.voter.id}>
                      <VotingOverlay voter={vote.voter} date={vote.vote_date} />
                    </div>
                  ))}
              </Popover.Body>
            </Popover>
          }
        >
          <div className="d-flex">
            {currentUserVote?.approved ? (
              <ThumbUpIcon
                data-testid="thumb-up-icon"
                style={{
                  color: "var(--bs-success)",
                }}
                className="clickable"
                onClick={() => handleVote(true)}
              />
            ) : (
              <ThumbUpOffAltIcon
                data-testid="thumb-up-off-icon"
                className="clickable"
                onClick={() => handleVote(true)}
              />
            )}
            <h4 className="mx-3">{getVoteCount(true)}</h4>
          </div>
        </OverlayTrigger>

        {/* Award Icon */}
        <Award votes={votes} />
      </>
    </Col>
  );
}
