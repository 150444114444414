import axios from "axios";
import { useState, useCallback, useEffect } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import { useInterval } from "../../hooks/useInterval";

import QADetailsAccordions from "./QADetailsAccordions";
import QADetailsHeaderInfo from "./QADetailsHeaderInfo";
import QADetailsSideInfo from "./QADetailsSideInfo";

export default function QADetails() {
  const { id } = useParams();
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [qa, setQa] = useState({});
  const [qaStatuses, setQaStatuses] = useState([]);
  const [alert, setAlert] = useState({ message: "", type: "" });

  const fetchQA = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/get_children/?rid=${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setQa(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchQA();
  }, [fetchQA]);

  useInterval(() => {
    fetchQA();
  }, 180000); //3 min

  const fetchQaStatuses = useCallback(() => {
    const headers = {};
    if (authToken) {
      headers["Authorization"] = `Token ${authToken}`;
    }
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/status/get_all_statuses/`,
        {
          withCredentials: true,
          headers: headers,
        },
      )
      .then((response) => {
        const statuses = response.data
          ?.filter(
            (status) => !["Cancelled", "Published"]?.includes(status.status),
          )
          .map((status) => {
            return { value: status.stid, label: status.status };
          });

        setQaStatuses(statuses);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchQaStatuses();
  }, []);

  return (
    <>
      <Container className="py-5" fluid>
        <Row>
          <Col sm={9}>
            {alert?.message && (
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            )}
            <QADetailsHeaderInfo
              showButton={true}
              qa={qa}
              qaStatuses={qaStatuses}
              refetch={fetchQA}
              setAlert={setAlert}
            />
            <QADetailsAccordions
              qa={qa}
              refetch={fetchQA}
              setDetailPageAlert={setAlert}
            />
          </Col>
          <Col>
            <QADetailsSideInfo qa={qa} refetch={fetchQA} setAlert={setAlert} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
