import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [cookies, setCookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const [userEmail, setUserEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}password-reset/`,
        { email: userEmail },
        { withCredentials: true, headers: { "X-CSRFToken": csrfToken } },
      )
      .then((response) => {
        navigate("/login/check-email", { state: { email: userEmail } });
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  };

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Forgot Password?</Card.Title>
        <p className="m-4 text-secondary">
          Please enter your registered email below and we will email you a link
          to reset your password.
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="email">
            <Form.Label className="small text-secondary">Email*</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={handleChange}
              required={true}
              placeholder="Enter your email"
            />
          </Form.Group>
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          <Button variant="warning" type="submit">
            Reset Password
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ForgotPassword;
