import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../../UI/UnauthorizedView";

import AllArtifacts from "./AllArtifacts";
import Evaluation from "./Evaluation";
import Glossary from "./Glossary";
import PreEvaluation from "./PreEvaluation";
import References from "./References";
import TrainingHome from "./TrainingHome";

export default function TrainingCenter() {
  const permissions = useSelector((state) => state.role.value);

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="all-artifacts" element={<AllArtifacts />} />
      <Route path="references" element={<References />} />
      <Route path="glossary" element={<Glossary />} />
      <Route path="p1-pre-evaluation" element={<PreEvaluation />} />
      <Route path="p2-evaluation" element={<Evaluation />} />
      <Route path="/" element={<TrainingHome />} />
    </Routes>
  );
}
