import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../../UI/UnauthorizedView";

import LabgramDetails from "./LabgramDetails";
import LabgramsTable from "./LabgramsTable";

export default function LabgramsManager({ setOverlayActive, setOverlayText }) {
  const permissions = useSelector((state) => state.role.value);

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <LabgramsTable
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
      <Route
        path="details/:id"
        element={
          <LabgramDetails
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
    </Routes>
  );
}
