import React from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";

const ProjectConfirmSaveModal = ({
  show,
  handleClose,
  handleSave,
  vid,
  editedHTMLChanges,
}) => {
  const failedFields = Object.keys(editedHTMLChanges).filter(
    (key) => editedHTMLChanges[key]?.length < 50,
  );

  const editableHTMLKeys = {
    product_description: "Product Description",
    security_evaluation_summary: "Security Evaluation Summary",
    environmental_strengths: "Environmental Strengths",
    evaluation_configuration: "Evaluation Configuration",
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className="text-bright-navy">
          Save changes to a Posted Product VID {vid}
        </h3>
        <p>Following changes made will be visible to the public:</p>
        <div className="">
          {Object.keys(editedHTMLChanges).map((key) => {
            return (
              <Row key={key}>
                <Col sm={6}>
                  <p
                    className={
                      editedHTMLChanges[key]?.length >= 50
                        ? "text-bright-navy"
                        : "text-danger"
                    }
                  >
                    {editableHTMLKeys[key]}
                  </p>
                </Col>
                <Col sm={6}></Col>
              </Row>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleSave}
          disabled={failedFields.length > 0}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectConfirmSaveModal;
