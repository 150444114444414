import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Container, Row, Col } from "react-bootstrap";

export default function ColorList() {
  return (
    <Container>
      <Col>
        <Row>
          <Col lg={1}>
            <ErrorIcon style={{ color: "var(--bs-red)" }} />
          </Col>
          <Col>
            <>
              The question is overdue and the TRRT needs to respond immediately.
            </>
          </Col>
        </Row>
        <Row>
          <Col lg={1}>
            <ErrorIcon style={{ color: "var(--bs-green)" }} />
          </Col>
          <Col>
            <>The TRRT has more than two days to respond to the question.</>
          </Col>
        </Row>
        <Row>
          <Col lg={1}>
            <ErrorIcon style={{ color: "var(--bs-yellow)" }} />
          </Col>
          <Col>
            <>
              A response is due to the customer within two days and the TRRT
              should begin finalizing the response.
            </>
          </Col>
        </Row>
        <Row>
          <Col lg={1}>
            <ErrorOutlineIcon style={{ color: "var(--bs-black)" }} />
          </Col>
          <Col>
            <>
              The question has been cancelled and no further action is needed
              from the TRRT.
            </>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}
