import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

export const useAuth = () => {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const currentUser = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);

  return {
    authToken,
    csrfToken,
    currentUser,
    permissions,
  };
};
