import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { useInterval } from "../../hooks/useInterval";
import UnauthorizedView from "../UI/UnauthorizedView";

import Calendar from "./Calendar";
import Filter from "./Filters";
import Upcoming from "./Upcoming";

export default function CheckPermEvaluationCalendarDetails() {
  const permissions = useSelector((state) => state.role.value);

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  } else {
    return <EvaluationCalendar />;
  }
}

const EvaluationCalendar = () => {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [appointments, setAppointments] = useState([]);
  const [yearAppointments, setYearAppointments] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({ statusId: [] });
  const startOfMonth = moment().startOf("month").toJSON();
  const endOfMonth = moment().endOf("month").toJSON();
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);

  const loadAppointments = useCallback(() => {
    const params = {
      ...filters,
      startDate: startDate,
      endDate: endDate,
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/get_all_events_in_range?${qs.stringify(params, { arrayFormat: "comma" })}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setAppointments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filters, startDate, endDate]);

  const loadYearAppointments = useCallback(() => {
    const startOfYear = moment().startOf("year").toJSON();
    const endOfYear = moment().endOf("year").toJSON();
    const params = {
      ...filters,
      startDate: startOfYear,
      endDate: endOfYear,
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/get_all_events_in_range?${qs.stringify(params, { arrayFormat: "comma" })}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setYearAppointments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [filters]);

  useEffect(() => {
    loadAppointments();
  }, [loadAppointments]);

  useEffect(() => {
    loadYearAppointments();
  }, [loadYearAppointments]);

  useInterval(() => {
    loadAppointments();
  }, 60000); //1 min

  const loadStatuses = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}calendar/status/get_all_statuses/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setStatuses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    loadStatuses();
  }, []);

  const loadProducts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_instances/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    loadProducts();
  }, []);

  useInterval(() => {
    loadProducts();
  }, 180000); //3 min

  return (
    <>
      <Card>
        <Card.Header as="h3" className="bg-dark text-white">
          Evaluation Calendar
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xl={3} lg={4} md={4} sm={12} xs={12}>
              <Upcoming statuses={statuses} appointments={yearAppointments} />
            </Col>
            <Col xl={9} lg={8} md={8} sm={12} xs={12}>
              <Calendar
                statuses={statuses}
                products={products}
                appointments={appointments}
                loadAppointments={loadAppointments}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Filter
                statuses={statuses}
                filters={filters}
                setFilters={setFilters}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="mt-5">
        <h3>Methods and Assumptions</h3>
        <ul>
          <li>
            The data are pulled real-time. Deleted records will not be
            reflected.{" "}
          </li>
          <li>
            The Termination Warning dates (TermWarn), if shown, are calculated
            as 120 days from the kickoff date and not necessarily the official
            dates according to the Progress Points. The dates set in the
            Progress Points are authoritative and may be adjusted for various
            reasons.
          </li>
          <li>
            The Final Package due dates (FPDue), if shown, are calculated as 159
            days from the kickoff date and not necessarily the official dates
            according to the Progress Points. The dates set in the Progress
            Points are authoritative and may be adjusted for various reasons.
          </li>
          <li>
            The Termination dates (TermLtr), if shown, are calculated as 180
            days from the kickoff date and not necessarily the official dates
            according to the Progress Points. The dates set in the Progress
            Points are authoritative and may be adjusted for various reasons.
          </li>
        </ul>
      </div>
    </>
  );
};
