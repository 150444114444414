import moment from "moment";
import { Row, Col } from "react-bootstrap";

export default function PackageInfoSection({
  currentUser,
  product,
  validationReport,
  currentFeedbackInfo,
}) {
  return (
    <Row className="mt-3">
      <Col>
        <Row className="mb-2"></Row>
        <Row>
          <p>
            <span className="fw-bold">DATE:</span>{" "}
            {currentFeedbackInfo?.edited_on
              ? moment.utc(currentFeedbackInfo.edited_on).format("MM/DD/YYYY")
              : moment().format("MM/DD/YYYY")}
          </p>
        </Row>
        <Row>
          <p>
            <span className="fw-bold">CCTL:</span> {product?.assigned_lab?.name}
          </p>
        </Row>
      </Col>
      <Col>
        <Row className="mb-2">
          <p className="m-0 fw-bold">PRODUCT INFORMATION</p>
        </Row>
        <Row>
          <p>
            <span className="fw-bold">Product Name:</span>{" "}
            {product?.product_name}
          </p>
        </Row>
        <Row>
          <p>
            <span className="fw-bold">VID: </span>
            {product?.v_id}
          </p>
        </Row>
        <Row>
          <p>
            <span className="fw-bold">NIAP Record ID: </span>
            {validationReport?.file_label}
          </p>
        </Row>
      </Col>
    </Row>
  );
}
