import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import Select from "react-select";

export default function AssociatedEvaluations({
  show,
  handleShowEval,
  pp_id,
  user,
  associatedEvaluations,
  setAssociatedEvaluations,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [projPps, setProjPps] = useState([]);
  const [associatedProjs, setAssociatedProjs] = useState({});

  const handleChange = (e) => {
    const selectedProducts = { associated_products: [] };
    e?.map((product) =>
      selectedProducts.associated_products.push({
        product: product.value,
        pp: pp_id,
        creator: user.id,
        editor: user.id,
      }),
    );
    setAssociatedProjs(selectedProducts);
  };

  const handleAddEvaluations = async () => {
    if (associatedProjs?.associated_products?.length > 0) {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/set_association/`,
          { ...associatedProjs },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          const newAssociatedEvals = associatedEvaluations.slice();
          response.data.map((projpp) => newAssociatedEvals.push(projpp));
          setAssociatedEvaluations(newAssociatedEvals);
        })
        .catch((error) => console.log("Unable to add associations: " + error));
    }
    handleShowEval();
  };

  const loadProjectProduct = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const currentAssociatedProjects = associatedEvaluations.map(
          (projpp) => projpp?.product__product_id,
        ); // product_ids of associations
        const projectOptions = response.data.filter(
          (proj) => !currentAssociatedProjects.includes(proj.product_id),
        ); // Only add projects not already associated
        setProjPps(projectOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [show]);

  useEffect(() => {
    loadProjectProduct();
  }, [loadProjectProduct]);

  const productOptions = projPps.map((projpp) => ({
    value: projpp.product_id,
    label: projpp.product_name,
  }));

  return (
    <Modal size="lg" show={show} onHide={handleShowEval}>
      <Modal.Header closeButton>
        <Modal.Title>+ Associate a Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="product_name">
            <Select isMulti onChange={handleChange} options={productOptions} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleShowEval}>
          Cancel
        </Button>
        <Button variant="warning" onClick={handleAddEvaluations}>
          Enter Selected Evaluations
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
