import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useRef } from "react";
import { Alert, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import { useInterval } from "../../../../hooks/useInterval";
import DeleteModal from "../../../UI/DeleteModal.js";
import ResponsiveMaterialTable from "../../../UI/MaterialTable/ResponsiveMaterialTable";

export default function ProductEventLog() {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [refreshKey, setRefreshKey] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [alert, setAlert] = useState({});
  const tableRef = useRef();
  const { vid } = useParams();

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  const handleShowDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}event/log/${selectedRow.elid}/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFTOKEN": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setRefreshKey((prevKey) => prevKey + 1);
        setShowDeleteModal(false);
        setAlert({ message: "Event Log Item Deleted", variant: "success" });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          message: "Error Deleting Event Log Item",
          variant: "danger",
        });
      });
  };

  const columns = [
    { title: "ID", field: "elid" },
    {
      title: "Event",
      field: "ecode",
      render: (rowData) => <>{rowData?.ecode?.ecat}</>,
    },
    {
      title: "Agent",
      field: "creator",
      render: (rowData) => (
        <>
          {rowData?.creator?.first_name} {rowData?.creator?.last_name}
        </>
      ),
    },
    {
      title: "Date",
      field: "entrydate",
      render: (rowData) => (
        <>{moment(rowData?.entrydate).format("YYYY.MM.DD [at] HHmm")}</>
      ),
    },
    { title: "Details", field: "detail" },
    { title: "Record ID", field: "objid" },
    // { title: "Core Element", field: "coreelem" },
    // { title: "Core ID", field: "coreid" },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <Button
          variant="link"
          className="m-1 p-0 btn-link"
          onClick={() => handleShowDeleteModal(rowData)}
        >
          <DeleteIcon color="action" /> Delete
        </Button>
      ),
    },
  ];

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    columnsButton: true,
    search: true,
    pageSizeOptions: [25, 50, 75, 100],
    pageSize: 50,
  };

  return (
    <div className="mt-3">
      {alert.message && (
        <Alert variant={alert.variant} onClose={() => setAlert({})} dismissible>
          {alert.message}
        </Alert>
      )}
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        type={`Event Log Item - ${selectedRow?.elid}`}
        deleteFunction={handleDelete}
      />
      <ResponsiveMaterialTable
        title="Event Log"
        columns={columns}
        key={refreshKey}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              // ...sidebarFilters,
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              coreid: vid,
            };

            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}event/log/?${qs.stringify(
                  params,
                  {
                    arrayFormat: "comma",
                  },
                )}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
    </div>
  );
}
