import axios from "axios";
import { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { addUser } from "../../../app/user";

const UpdateContactInformationModal = ({
  showModal,
  closeModal,
  currentUser,
  setCurrentUser,
}) => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [updatedInformation, setUpdatedInformation] = useState({
    username: currentUser.username,
    password: currentUser.password,
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "phone_number" || e.target.name === "fax") {
      setErrors({
        ...errors,
        [e.target.name]:
          !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            e.target.value,
          ),
      });
    }
    setUpdatedInformation({
      ...updatedInformation,
      [e.target.name]: e.target.value,
    });
  };

  const checkPhones = () => {
    if (
      updatedInformation?.phone_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        updatedInformation?.phone_number,
      )
    ) {
      return false;
    } else if (
      updatedInformation?.fax &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        updatedInformation?.fax,
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() || !checkPhones()) {
      e.stopPropagation();
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${currentUser.id}/`,
          updatedInformation,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
              {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then((response) => {
              setCurrentUser(response.data);
              dispatch(addUser(response.data));
              closeModal();
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }
  };

  return (
    <Modal size="lg" show={showModal} onHide={closeModal}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Contact Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Personal Information</h5>
          <Row>
            <Col md={6} sm={12}>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="first_name">
                    <Form.Label>First Name:</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      onChange={handleChange}
                      defaultValue={currentUser.first_name}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="last_name">
                    <Form.Label>Surname:</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      onChange={handleChange}
                      defaultValue={currentUser.last_name}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="email">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleChange}
                      defaultValue={currentUser.email}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={6} sm={12}>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="position">
                    <Form.Label>Position: </Form.Label>
                    <Form.Control
                      type="text"
                      name="position"
                      onChange={handleChange}
                      defaultValue={currentUser.position}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="phone_number">
                    <Form.Label>Phone:</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone_number"
                      onChange={handleChange}
                      isInvalid={errors.phone_number}
                      defaultValue={currentUser.phone_number}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group controlId="fax">
                    <Form.Label>Fax:</Form.Label>
                    <Form.Control
                      type="tel"
                      name="fax"
                      onChange={handleChange}
                      isInvalid={errors.fax}
                      defaultValue={currentUser.fax}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit">Update Account</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UpdateContactInformationModal;
