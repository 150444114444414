import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const TqProductEvaluationCell = ({ tq }) => {
  const { product_evaluation = [] } = tq;
  if (product_evaluation.length === 0) return null;

  return (
    <ListGroup>
      {product_evaluation?.map((product) => (
        <Link
          to={`/community/products/details/${product.product_id}`}
          className="text-decoration-underline text-center"
          key={product.product_evaluation || product.v_id}
        >
          <ListGroup.Item disabled>VID{product.v_id}</ListGroup.Item>
        </Link>
      ))}
    </ListGroup>
  );
};
export default TqProductEvaluationCell;
