import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";

import PfaActionMenuItem from "./PfaActionMenuItem";

const PfaRenameAction = ({ file }) => {
  return (
    <PfaActionMenuItem
      file={file}
      Icon={DriveFileRenameOutlineRoundedIcon}
      label="Rename"
    />
  );
};

export default PfaRenameAction;
