import { Row, Form } from "react-bootstrap";

export default function StatementOfProficiencySection({
  currentFeedbackInfo,
  handleChange,
  viewOnly,
}) {
  return (
    <Row className="px-3">
      <Form.Group className="my-3" controlId="statement_of_proficiency">
        <Form.Check
          type="radio"
          label="The CCTL has satisfactorily completed the evaluation or it has been withdrawn. No comments or potential non-conformities are reported."
          name="statement_of_proficiency"
          value="The CCTL has satisfactorily completed the evaluation or it has been withdrawn. No comments or potential non-conformities are reported."
          defaultChecked={
            currentFeedbackInfo?.statement_of_proficiency ===
            "The CCTL has satisfactorily completed the evaluation or it has been withdrawn. No comments or potential non-conformities are reported."
          }
          style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
          onChange={handleChange}
          required
          className="mb-3"
          id="statement_of_proficiency1"
        />
        <Form.Check
          type="radio"
          label="The CCTL has satisfactorily completed the evaluation. Comments or potential non-conformities are being reported. All potential, technical and management non-conformities were resolved sufficiently to complete the evaluation. (NVLAP follow-up with the CCTL may be requested, if non-conformities are reported, NVLAP follow-up is required)"
          name="statement_of_proficiency"
          value="The CCTL has satisfactorily completed the evaluation. Comments or potential non-conformities are being reported. All potential, technical and management non-conformities were resolved sufficiently to complete the evaluation. (NVLAP follow-up with the CCTL may be requested, if non-conformities are reported, NVLAP follow-up is required)"
          defaultChecked={
            currentFeedbackInfo?.statement_of_proficiency ===
            "The CCTL has satisfactorily completed the evaluation. Comments or potential non-conformities are being reported. All potential, technical and management non-conformities were resolved sufficiently to complete the evaluation. (NVLAP follow-up with the CCTL may be requested, if non-conformities are reported, NVLAP follow-up is required)"
          }
          style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
          onChange={handleChange}
          required
          id="statement_of_proficiency2"
        />
        <div className="mx-5 mb-3">
          <Form.Check
            type="checkbox"
            name="requested_nvlap_follow_up"
            label="Was NVLAP follow-up requested/required?"
            defaultChecked={
              currentFeedbackInfo?.requested_nvlap_follow_up === true
            }
            onChange={handleChange}
            style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
            id="requested_nvlap_follow_up"
          />
        </div>
        <Form.Check
          type="radio"
          label="The CCTL has failed to satisfactorily complete the evaluation. Potential non-Conformities and any additional comments are reported below. (NVLAP follow-up with the CCTL is required)"
          name="statement_of_proficiency"
          value="The CCTL has failed to satisfactorily complete the evaluation. Potential non-Conformities and any additional comments are reported below. (NVLAP follow-up with the CCTL is required)"
          defaultChecked={
            currentFeedbackInfo?.statement_of_proficiency ===
            "The CCTL has failed to satisfactorily complete the evaluation. Potential non-Conformities and any additional comments are reported below. (NVLAP follow-up with the CCTL is required)"
          }
          style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
          onChange={handleChange}
          required
          id="statement_of_proficiency3"
        />
        <div className="mx-5 mb-3">
          <Form.Check
            type="checkbox"
            name="nvlap_follow_up"
            label="NVLAP follow-up required?"
            defaultChecked={currentFeedbackInfo?.nvlap_follow_up === true}
            onChange={handleChange}
            style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
            id="nvlap_follow_up"
          />
        </div>
      </Form.Group>
    </Row>
  );
}
