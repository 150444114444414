import axios from "axios";
import qs from "qs";
import { useState, useEffect } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";

import Subheader from "../Subheader";

import TechDecisionsTable from "./TechDecisionsTable";

export default function TechnicalDecisions() {
  const [filters, setFilters] = useState({ status: "Published,Valid" });
  const [tdCount, setTdCount] = useState({});

  useEffect(() => {
    document.title = "NIAP - Technical Decisions";
    loadTDCount();
  }, []);

  const loadTDCount = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_tds_count/`,
      )
      .then((response) => {
        setTdCount(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChangeFilters = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  return (
    <>
      <Subheader pageName={"Technical Decisions"} />
      <Container className="my-4">
        <Row>
          <Col sm={12} className="border-bottom border-dark">
            <h5 className="fw-bold">
              PROTECTION PROFILES{" "}
              <span className="text-primary">- TECHNICAL DECISIONS</span>
            </h5>
            <p className="ps-4">
              Technical Decisions (TDs) are issued to correct errors or clarify
              Security Functional Requirements (SFRs) and Assurance Activities
              within NIAP-approved Protection Profiles (PPs). Technical
              Decisions are effective upon publication and must be incorporated
              into all current and future evaluations. Current evaluations
              include all evaluations except those for which a complete
              Check-Out package has been submitted to NIAP for final validation
              team review.
            </p>
            <p className="ps-4">
              In the rare case that a TD is issued which includes new SFRs or
              AAs, NIAP will make the determination for when products in
              evaluation must comply and include an effective date in the TD.
            </p>
          </Col>
        </Row>
        <Row className="px-5 pb-5 justify-content-between align-items-center">
          <Col className="mt-4" lg="auto">
            {filters.status === "Published,Valid" && (
              <h3 className="fw-bold text-primary text-uppercase">
                Active Technical Decisions - <span>{tdCount?.published}</span>
              </h3>
            )}
            {filters.status === "Archived" && (
              <h3 className="fw-bold text-primary text-uppercase">
                Archived Technical Decisions - <span>{tdCount?.archived}</span>
              </h3>
            )}
          </Col>
          <Col className="mt-4 text-end">
            <ButtonGroup size="md">
              <Button
                variant={
                  filters.status === "Published,Valid" ? "primary" : "light"
                }
                className="me-4 rounded-0 shadow"
                onClick={() => handleChangeFilters("status", "Published,Valid")}
              >
                <h2 className="text-gray-400">{tdCount?.published}</h2>
                <p className="m-0">Active TDs</p>
              </Button>
              <Button
                variant={filters.status === "Archived" ? "primary" : "light"}
                className="me-4 rounded-0 shadow"
                onClick={() => handleChangeFilters("status", "Archived")}
              >
                <h2 className="text-gray-400">{tdCount?.archived}</h2>
                <p className="m-0">Archived TDs</p>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <Row>
          <Col className="mt-5">
            <TechDecisionsTable sidebarFilters={filters} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
