import moment from "moment";

import PfaActionsCell from "./PfaActions.cell";
import PfaDispositionsCell from "./PfaDispositions.cell";
import PfaNameCell from "./PfaName.cell";

export const buildPfaColumns = ({
  setOverlayActive,
  setOverlayText,
  product,
}) => {
  return [
    { title: "Label", field: "file_label" },
    {
      title: "Name",
      field: "file_name",
      render: (rowData) => (
        <PfaNameCell
          file={rowData}
          setOverlayActive={setOverlayActive}
          setOverlayText={setOverlayText}
        />
      ),
    },
    { title: "Type", field: "file_display_name" },
    {
      title: "Visibility",
      field: "private",
      render: (rowData) => (rowData.private ? "Proprietary" : "Public"),
    },
    {
      title: "Uploaded By",
      field: "uploaded_by",
      render: ({ uploaded_by }) =>
        uploaded_by?.first_name + " " + uploaded_by?.last_name,
    },
    {
      title: "Uploaded On",
      field: "uploaded_on",
      render: ({ uploaded_on }) =>
        uploaded_on && moment.utc(uploaded_on).format("MM/DD/YYYY [at] HH:mm"),
    },
    {
      title: "Disposition",
      field: "published",
      render: (rowData) => <PfaDispositionsCell file={rowData} />,
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => <PfaActionsCell file={rowData} product={product} />,
    },
  ];
};
