import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { addRole } from "../../../app/role";
import { addUser } from "../../../app/user";

const LoginSuccess = () => {
  const [cookies, setCookies, removeCookie] = useCookies();
  const [currentRole, setCurrentRole] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const currentDate = new Date();
    const expiresDate = new Date();
    expiresDate.setHours(currentDate.getHours() + 3);
    removeCookie("temp_token");
    const redirect = async () => {
      const userData = await axios.get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${state?.auth_token}` },
        },
      );
      dispatch(addUser(userData.data));
      const roleData = await axios.get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/getRole/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${state?.auth_token}` },
        },
      );
      dispatch(addRole(roleData.data));
      setCookies("auth_token", state?.auth_token, { path: "/" });
      if (roleData.data.role_type === "External") navigate("/");
      else navigate("/community/");
    };
    if (state?.is_login) {
      redirect();
    } else {
      setTimeout(() => {
        navigate("/login/");
      }, 1000);
    }
  }, []);

  return (
    <Card className="login-card success">
      <Card.Body>
        <CheckBoxIcon color="primary" sx={{ fontSize: 100 }} />
        <Card.Title as="h4">Success</Card.Title>
        <p className="small">You are being redirected...</p>
      </Card.Body>
    </Card>
  );
};

export default LoginSuccess;
