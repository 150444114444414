import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../scss/custom-radio-button.css";

const MFAselect = () => {
  const [cookies, setCookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["temp_token"];
  const [formErrors, setFormErrors] = useState({});
  const [user, setUser] = useState({});
  const [method, setMethod] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setMethod({ ...method, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mfaMethod = method.method;
    if (mfaMethod === "email" && !user.email) {
      navigate("/login/add-details?method=email");
    } else if (mfaMethod === "sms_twilio" && !user.phone_number) {
      navigate("/login/add-details?method=sms_twilio");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${mfaMethod}/activate/`,
          method,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          if (mfaMethod === "app") {
            const qrCode = response.data.details;
            navigate(`/login/mfa-qr/?qr-code=${qrCode}`);
          } else {
            navigate(`/login/mfa-confirm?mfa-method=${mfaMethod}`);
          }
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        });
    }
  };

  useEffect(() => {
    const loadUser = () => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    };
    loadUser();
  }, []);

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Choose Verification Method</Card.Title>
        <Card.Subtitle as="p">
          For additional security NIAP requires you select a method to recieve
          one time codes
        </Card.Subtitle>
        <Form className="centered-form" onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="method">
            <Form.Check
              type="radio"
              label="Email"
              name="method"
              value="email"
              onChange={handleChange}
              id="method1"
            />
            <p className="small">Your Email: {user.email}</p>
            <Form.Check
              type="radio"
              label="SMS"
              name="method"
              value="sms_twilio"
              onChange={handleChange}
              id="method2"
            />
            <span className="small">
              By selecting to recieve MFA codes by SMS you consent to recieve
              text messages from us. Message and data rates may apply. Messages
              will be sent on each login.
            </span>
            <p className="small">Your Phone Number: {user.phone_number}</p>
            <Form.Check
              type="radio"
              label="Application"
              name="method"
              value="app"
              onChange={handleChange}
              id="method3"
            />
            <p className="small">Such as Authy or Google Authenticator</p>
            {/* <Form.Check type="radio" label="YubiKey" name='yubi' onChange={handleChange} /> */}
          </Form.Group>
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          <Button variant="warning" type="submit">
            Continue
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MFAselect;
