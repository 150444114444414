const csfcClaimOptions = [
  {
    name: "Authentication Server",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#authentication-server",
  },
  {
    name: "End User Device / Mobile Platform",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#End%20User%20Device%20/%20Mobile%20Platfor,",
  },
  {
    name: "Hardware Full Drive Encryption",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#hw-fde",
  },
  {
    name: "IPsec VPN Gateway",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#ipsec-vpn-gateway",
  },
  {
    name: "Session Border Controller",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#session-border-controller",
  },
  {
    name: "TLS Software Applications",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#tls-software-applications",
  },
  {
    name: "Web Browsers",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#web-browsers",
  },
  {
    name: "Certificate Authority",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#certificate-authority",
  },
  {
    name: "Enterprise Session Controller",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#enterprise-session-controller",
  },
  {
    name: "IPS",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#ips",
  },
  {
    name: "MACSEC Ethernet Encryption Devices",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#macsec-ethernet-encryption-devices",
  },
  {
    name: "Software Full Drive Encryption",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#sw-fde",
  },
  {
    name: "Traffic Filtering Firewall",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#traffic-filtering-firewall",
  },
  {
    name: "WLAN Access System",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#wlan-access",
  },
  {
    name: "E-mail Clients",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#email-clients",
  },
  {
    name: "MDM",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#mdm",
  },
  {
    name: "File Encryption",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#file-encryption",
  },
  {
    name: "IPsec VPN Client MDM",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#ipsec-vpn-client",
  },
  {
    name: "TLS Protected Servers",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#tls-protected-servers",
  },
  {
    name: "VoIP Applications",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#voip-applications",
  },
  {
    name: "WLAN Client",
    link: "https://www.nsa.gov/Resources/Commercial-Solutions-for-Classified-Program/Components-List/#wlan-client",
  },
];

export default csfcClaimOptions;
