import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import UnauthorizedView from "../../UI/UnauthorizedView";

const AtypicalFilesManager = () => {
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const permissions = useSelector((state) => state.role.value);
  const [fileData, setFileData] = useState({});
  const [file, setFile] = useState({});

  if (permissions.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  const handleChange = (event) => {
    setFileData({ ...fileData, [event.target.name]: event.target.value });
  };

  const handleFile = (event) => {
    setFile({ ...file, [event.target.name]: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const submittedData = new FormData();
      submittedData.append("file", file.file);
      submittedData.append("base_path", fileData.base_path);
      if (fileData.subfolder) {
        submittedData.append("subfolder", fileData.subfolder);
      }

      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/post_atypical_file/`,
          submittedData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          const link = response.data.file_link;
          setFileData({ ...fileData, link: link });
        })
        .catch((error) => {
          console.log("Upload Error", error);
        });
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} className="text-center">
          <h2>Upload Atypical File</h2>
        </Col>
      </Row>
      {fileData?.link && (
        <Row>
          <Col xs={12}>
            <h3>
              <a href={fileData.link} target="_blank" rel="noreferrer">
                {fileData.link}
              </a>
            </h3>
          </Col>
        </Row>
      )}
      <Form onSubmit={handleSubmit} aria-label="upload-file">
        <Row>
          <Col xs={12}>
            <Form.Group controlId="base_path">
              <Form.Label>Select the Base Path*</Form.Label>
              <Form.Select
                name="base_path"
                onChange={handleChange}
                required={true}
              >
                <option value={""}>Select a Path</option>
                <option value={"Documents_and_Guidance"}>
                  Documents_and_Guidance
                </option>
                <option value={"MMO"}>MMO</option>
                <option value={"logos"}>logos</option>
                <option value={"static_html"}>static_html</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="subfolder">
              <Form.Label>Enter a Folder Path</Form.Label>
              <Form.Control
                type="text"
                name="subfolder"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="file">
              <Form.Label>Select a File*</Form.Label>
              <Form.Control
                type="file"
                name="file"
                onChange={handleFile}
                required={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={!file?.file || !fileData?.base_path}
            >
              Upload File
            </Button>
            <Button type="reset">Cancel</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AtypicalFilesManager;
