import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RestoreIcon from "@material-ui/icons/Restore";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  OverlayTrigger,
  Popover,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { useInterval } from "../../../hooks/useInterval";
import DeleteModal from "../../UI/DeleteModal";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import ExternalLinkForm from "./ExternalLinkForm";

export default function ExternalLinksTable() {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [extlinks, setExtlinks] = useState([]);
  const [currentExtlink, setCurrentExtlink] = useState({});
  const [showModal, setShowModal] = useState(false);
  const currentUser = useSelector((state) => state.user.value);
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  const fetchExtLinks = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}extsite/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setExtlinks(response.data.results);
      })
      .catch((error) => {
        console.log("error getting external sites: ", error);
      });
  };

  useEffect(() => {
    fetchExtLinks();
  }, []);

  useInterval(() => {
    setRefresh(!refresh);
    fetchExtLinks();
  }, 600000); //10 min

  const getLocalTime = (time) => {
    return moment.utc(time).format("MM/DD/YYYY");
  };

  const handleShowModal = (e, rowData) => {
    setCurrentExtlink({ ...rowData });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setRefresh(!refresh);
    fetchExtLinks();
    setCurrentExtlink({});
    setShowModal(false);
  };

  const handleDelete = () => {
    let currentTime = new Date(
      new Date().setMinutes(
        new Date().getMinutes() - new Date().getTimezoneOffset(),
      ),
    );
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}extsite/${linkId}/`,
        {
          editor: currentUser.id,
          deleted: true,
          active: false,
          moddate: currentTime,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        setRefresh(!refresh);
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.log("Unable to remove external site: " + error);
      });
  };

  const columns = [
    {
      title: "Site Name",
      field: "site",
      cellStyle: {
        width: "40%",
        minWidth: "40%",
      },
    },
    {
      title: "Match Site",
      field: "tid.type",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
    },
    {
      title: "Last Modified",
      field: "moddate",
      filtering: false,
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
      render: (rowData) => <>{getLocalTime(rowData.moddate)}</>,
      customFilterAndSearch: (input, rowData) => {
        let matcher = new RegExp(input, "i");
        if (matcher.test(getLocalTime(rowData.moddate))) {
          return true;
        }
      },
      type: "date",
    },

    {
      title: "Active",
      field: "active",
      lookup: { true: "True", false: "False" },
      render: (rowData) => (rowData.active ? <CheckIcon /> : <CloseIcon />),
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            key="bottom"
            overlay={
              <Popover>
                <Popover.Body>
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link mr-auto"
                    onClick={(e) => handleShowModal(e, rowData)}
                  >
                    <EditIcon color="action" /> Edit
                  </Button>
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link mr-auto"
                    onClick={() => {
                      setLinkId(rowData.esid);
                      setShowDeleteModal(true);
                    }}
                  >
                    {rowData.deleted ? (
                      <>
                        <RestoreIcon color="action" /> Restore
                      </>
                    ) : (
                      <>
                        <DeleteIcon color="action" /> Delete
                      </>
                    )}
                  </Button>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="link">
              <MoreVertIcon className="float-end" />
              <span className="visually-hidden">Actions</span>
            </Button>
          </OverlayTrigger>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    columnsButton: true,
    pageSize: 20,
  };

  useEffect(() => {
    document.title = "NIAP - Approved External Links";
  }, []);

  return (
    <Container fluid className="pt-5">
      <Row>
        <Col className={"text-end mb-3"}>
          <Button
            variant="primary"
            className="me-3 rounded-0"
            onClick={(e) => handleShowModal(e, {})}
          >
            + New Site
          </Button>
        </Col>
      </Row>
      <ResponsiveMaterialTable
        title={"Approved External Sites"}
        columns={columns}
        tableRef={tableRef}
        options={options}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${
                  process.env.REACT_APP_DJANGO_ENDPOINT
                }extsite/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        className="my-3"
      />
      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        type={"External Link"}
        deleteFunction={handleDelete}
      />
      <ExternalLinkForm
        show={showModal}
        handleCloseModal={handleCloseModal}
        extLink={currentExtlink}
        currentUser={currentUser}
        refetch={() => {
          setRefresh(!refresh);
        }}
      />
    </Container>
  );
}
