import axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";
import { Row, Modal, Button, Col, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { handleAlertAndMailer } from "../../../Helper/functions";

export default function VendorProficiencyFeedbackForm({
  show,
  handleClose,
  product,
  currForm,
  viewOnly,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const currentUser = useSelector((state) => state.user.value);
  const [formInfo, setFormInfo] = useState({});
  const [validated, setValidated] = useState(false);
  const [vulnerabilitiesDetected, setVulnerabilitiesDetected] = useState(false);
  const [userOrg, setUserOrg] = useState({});
  const saveDraft = useRef(false);

  const handleChange = (e) => {
    if (e.target.name === "vulnerabilities_detected") {
      if (e.target.checked && e.target.id === "vulnerabilities_detected1") {
        setVulnerabilitiesDetected(true);
      } else {
        setVulnerabilitiesDetected(false);
      }
    }
    if (e.target.value === "") {
      setFormInfo({ ...formInfo, [e.target.name]: null });
    } else {
      setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (show === false) {
      setFormInfo({ ...currForm });
    }
  }, [currForm, show]);

  const handleSubmit = (e, is_draft) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (is_draft === false && form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (!currForm.id) {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}proficiency-feedback/`,
            {
              ...formInfo,
              filled_out_by: currentUser?.id,
              product_id: product?.product_id,
              company: userOrg?.org_id,
              created_on: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
              edited_on: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
              is_final_submission: !is_draft, // true if not draft
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            handleClose();
            handleAlertAndMailer(csrfToken, "Vendor", product, "submitted");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        delete formInfo["company"];
        delete formInfo["product_id"];
        delete formInfo["filled_out_by"];

        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}proficiency-feedback/${currForm.id}/`,
            {
              ...formInfo,
              edited_on: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
              is_final_submission: !is_draft, // if not draft
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            handleClose();
            handleAlertAndMailer(csrfToken, "Vendor", product, "updated");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    setValidated(true);
  };

  const fetchUserOrg = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setUserOrg(response.data);
      })
      .catch((error) => {
        console.log("error getting user org", error);
      });
  }, []);

  useEffect(() => {
    fetchUserOrg();
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => handleSubmit(e, false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            NIAP END OF EVALUATION SURVEY FOR VID:{product?.v_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            To assist us in our on-going efforts to improve the quality,
            efficiency and effectiveness of NIAP evaluations, please take a few
            minutes to complete and submit this survey
          </h6>
          <Row>
            <h4 className="fw-bold">CONTACT INFORMATION</h4>
            <Col>
              <Row>
                <Col>Name:</Col>
                <Col>{`${currentUser?.first_name} ${currentUser?.last_name}`}</Col>
              </Row>
              <Row>
                <Col>Organization:</Col>
                <Col>{userOrg?.name}</Col>
              </Row>
              <Row>
                <Col>Email:</Col>
                <Col>{currentUser?.email}</Col>
              </Row>
              <Row>
                <Col>Phone Number:</Col>
                <Col>{currentUser?.phone_number}</Col>
              </Row>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="can_contact">
                <Row>
                  <Form.Label>
                    May we contact you if we have questions?
                  </Form.Label>
                </Row>
                <Form.Check
                  inline
                  label="Yes"
                  type="radio"
                  name="can_contact"
                  value={true}
                  onChange={handleChange}
                  checked={
                    formInfo?.can_contact === true ||
                    formInfo?.can_contact === "true"
                      ? true
                      : false
                  }
                  required
                  disabled={viewOnly}
                  id="can_contact1"
                />
                <Form.Check
                  inline
                  label="No"
                  type="radio"
                  name="can_contact"
                  value={false}
                  onChange={handleChange}
                  checked={
                    formInfo?.can_contact === false ||
                    formInfo?.can_contact === "false"
                      ? true
                      : false
                  }
                  required
                  disabled={viewOnly}
                  id="can_contact2"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="remain_anonymous">
                <Row>
                  <Form.Label>Would you like to remain anonymous?</Form.Label>
                </Row>
                <Form.Check
                  inline
                  label="Yes"
                  type="radio"
                  name="remain_anonymous"
                  value={true}
                  onChange={handleChange}
                  checked={
                    formInfo?.remain_anonymous === true ||
                    formInfo?.remain_anonymous === "true"
                      ? true
                      : false
                  }
                  required
                  disabled={viewOnly}
                  id="remain_anonymous1"
                />
                <Form.Check
                  inline
                  label="No"
                  type="radio"
                  name="remain_anonymous"
                  value={false}
                  onChange={handleChange}
                  checked={
                    formInfo?.remain_anonymous === false ||
                    formInfo?.remain_anonymous === "false"
                      ? true
                      : false
                  }
                  disabled={viewOnly}
                  id="remain_anonymous2"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h4 className="fw-bold">PRODUCT INFORMATION</h4>
            <Row sm={6} className="mb-3">
              <Col>Product Name:</Col>
              <Col>{product?.product_name}</Col>
            </Row>
            <Row sm={6} className="mb-3">
              <Col>VID:</Col>
              <Col>{product?.v_id}</Col>
            </Row>
          </Row>
          <Row>
            <h4 className="fw-bold">PLEASE FILL OUT ALL INFORMATION BELOW</h4>
            <p>
              1. Please rate your experience with the NIAP program using a scale
              of 1 to 5 or N/A <span>&#40;</span>1 = unsatisfactory and 5 =
              outstanding<span>&#41;</span>
            </p>
            <Col>
              <Form.Group className="mb-3" controlId="eval_satisfaction">
                <Row>
                  <Col>
                    <Form.Label>
                      Satisfaction with CC Lab evaluation process*
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="eval_satisfaction"
                      size="sm"
                      value={formInfo?.eval_satisfaction}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="pp_satisfaction">
                <Row>
                  <Col>
                    <Form.Label>
                      Satisfaction with usefulness & applicability of Protection
                      Profile*
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="pp_satisfaction"
                      size="sm"
                      value={formInfo?.pp_satisfaction}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="personnel_satisfaction">
                <Row>
                  <Col>
                    <Form.Label>
                      Satisfaction with technical expertise of CC Lab personnel*
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="personnel_satisfaction"
                      size="sm"
                      value={formInfo?.personnel_satisfaction}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="timeliness_satisfaction">
                <Row>
                  <Col>
                    <Form.Label>
                      Satisfaction with timeliness of the CC Lab evaluation*
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="timeliness_satisfaction"
                      size="sm"
                      value={formInfo?.timeliness_satisfaction}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="additional_information">
                <Form.Label>
                  Please share any specific good or bad experiences with this
                  evaluation:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="additional_information"
                  onChange={handleChange}
                  required={true}
                  value={formInfo?.additional_information}
                  disabled={viewOnly}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="val_satisfaction">
                <Row>
                  <Col>
                    <Form.Label>
                      Satisfaction with Government validation process*
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="val_satisfaction"
                      size="sm"
                      value={formInfo?.val_satisfaction}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="gov_personnel_satisfaction"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Satisfaction with technical expertise of Government
                      validation personnel
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="gov_personnel_satisfaction"
                      size="sm"
                      value={formInfo?.gov_personnel_satisfaction}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="gov_timeliness_satisfaction"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Satisfaction with Government validation timeliness
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="gov_timeliness_satisfaction"
                      size="sm"
                      value={formInfo?.gov_timeliness_satisfaction}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <p>
              2. Please rank in order of importance the areas your Common
              Criteria Certificate provides value <span>&#40;</span>1 = most
              important and 4 = least important<span>&#42;</span>
            </p>
            <Form.Group className="mb-3" controlId="gov_sales_rank">
              <Row xs={6}>
                <Col xs={5}>
                  <Form.Label>U.S. Government/DoD NSS Sales*</Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required
                    onChange={handleChange}
                    name="gov_sales_rank"
                    size="sm"
                    value={formInfo?.gov_sales_rank}
                    disabled={viewOnly}
                  >
                    <option> </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="comm_sales_rank">
              <Row xs={6}>
                <Col xs={5}>
                  <Form.Label>U.S. Government/DoD NSS Sales*</Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required
                    onChange={handleChange}
                    name="comm_sales_rank"
                    size="sm"
                    value={formInfo?.comm_sales_rank}
                    disabled={viewOnly}
                  >
                    <option> </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="other_rank">
              <Row xs={6}>
                <Col xs={1}>
                  <Form.Label>Other:</Form.Label>
                </Col>
                <Col xs={4}>
                  <Form.Control
                    type="text"
                    name="other_text"
                    onChange={handleChange}
                    value={formInfo?.other_text}
                    disabled={viewOnly}
                  />
                </Col>
                <Col>
                  <Form.Select
                    onChange={handleChange}
                    name="other_rank"
                    size="sm"
                    value={formInfo?.other_rank}
                    disabled={viewOnly}
                  >
                    <option> </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="vulnerabilities_detected">
              <Row>
                <Form.Label>
                  3. Did the evaluation detect any vulnerabilities in your
                  product?
                </Form.Label>
              </Row>
              <Form.Check
                inline
                label="Yes"
                type="radio"
                name="vulnerabilities_detected"
                value={true}
                onChange={handleChange}
                checked={
                  formInfo?.vulnerabilities_detected === true ||
                  formInfo?.vulnerabilities_detected === "true"
                    ? true
                    : false
                }
                required
                disabled={viewOnly}
                id="vulnerabilities_detected1"
              />
              <Form.Check
                inline
                label="No"
                type="radio"
                name="vulnerabilities_detected"
                value={false}
                checked={
                  formInfo?.vulnerabilities_detected === false ||
                  formInfo?.vulnerabilities_detected === "false"
                    ? true
                    : false
                }
                onChange={handleChange}
                disabled={viewOnly}
                id="vulnerabilities_detected2"
              />
            </Form.Group>
            {vulnerabilitiesDetected && (
              <Form.Group
                className="ms-3"
                controlId="vulnerabilities_got_corrected"
              >
                <Row>
                  <Form.Label>
                    If yes, were they corrected prior to the conclusion of the
                    evaluation?
                  </Form.Label>
                </Row>
                <Form.Check
                  inline
                  label="Yes"
                  type="radio"
                  name="vulnerabilities_got_corrected"
                  value={true}
                  onChange={handleChange}
                  checked={
                    formInfo?.vulnerabilities_got_corrected === true ||
                    formInfo?.vulnerabilities_got_corrected === "true"
                      ? true
                      : false
                  }
                  required
                  disabled={viewOnly}
                  id="vulnerabilities_got_corrected1"
                />
                <Form.Check
                  inline
                  label="No"
                  type="radio"
                  name="vulnerabilities_got_corrected"
                  value={false}
                  checked={
                    formInfo?.vulnerabilities_got_corrected === false ||
                    formInfo?.vulnerabilities_got_corrected === "false"
                      ? true
                      : false
                  }
                  onChange={handleChange}
                  disabled={viewOnly}
                  id="vulnerabilities_got_corrected2"
                />
              </Form.Group>
            )}
          </Row>
          <Row>
            <p>
              4. Please rate your knowledge of the following areas using a scale
              of 1 to 5 <span>&#40;</span>1 = no knowledge and 5 = very
              knowledgeable<span>&#41;</span>
            </p>

            <Col>
              <Form.Group className="mb-3" controlId="knowledge_of_eval_rank">
                <Row>
                  <Col>
                    <Form.Label>CC Lab evaluation process</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="knowledge_of_eval_rank"
                      size="sm"
                      value={formInfo?.knowledge_of_eval_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="knowledge_of_pp_rank">
                <Row>
                  <Col>
                    <Form.Label>
                      CC Protection Profiles <span>&#40;</span>PP
                      <span>&#41;</span>s
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="knowledge_of_pp_rank"
                      size="sm"
                      value={formInfo?.knowledge_of_pp_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="knowledge_of_pl_rank">
                <Row>
                  <Col>
                    <Form.Label>NIAP policy letters</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="knowledge_of_pl_rank"
                      size="sm"
                      value={formInfo?.knowledge_of_pl_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="knowledge_of_dod_policies_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Department of Defense Policies <span>&#40;</span>e.g.,
                      DODD 8500.1, DODI 8500.20<span>&#41;</span>
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="knowledge_of_dod_policies_rank"
                      size="sm"
                      value={formInfo?.knowledge_of_dod_policies_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="knowledge_of_am_rank">
                <Row>
                  <Col>
                    <Form.Label>
                      CC assurance continuity process <span>&#40;</span>Also
                      known as assurance maintenance or certificate maintenance
                      <span>&#41;</span>
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="knowledge_of_am_rank"
                      size="sm"
                      value={formInfo?.knowledge_of_am_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="knowledge_of_val_process_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>NIAP validation process</Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="knowledge_of_val_process_rank"
                      size="sm"
                      value={formInfo?.knowledge_of_val_process_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="knowledge_of_acquis_policy_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      National Acquisition Policy CNSSP No. 11
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="knowledge_of_acquis_policy_rank"
                      size="sm"
                      value={formInfo?.knowledge_of_acquis_policy_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <p>
              5. We would like your opinion on the relative importance of each
              of the following proposed improvements to the CC process. Please
              rate the following five items using a scale of 1 to 5{" "}
              <span>&#40;</span>1 = extremely important and 5 = not important
              <span>&#41;</span>
            </p>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="importance_of_product_inclusion_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Including evaluated product on Common Criteria Portal
                      Product listing in addition to NIAP Product Compliant List
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="importance_of_product_inclusion_rank"
                      size="sm"
                      value={formInfo?.importance_of_product_inclusion_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="importance_of_doc_eval_results_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Document evaluation results in terms of security strengths
                      and weaknesses of the product
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="importance_of_doc_eval_results_rank"
                      size="sm"
                      value={formInfo?.importance_of_doc_eval_results_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="importance_of_procedure_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Provide a well-defined procedure for vulnerability
                      analysis that is properly scoped & bounded, and can be
                      consistently applied
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="importance_of_procedure_rank"
                      size="sm"
                      value={formInfo?.importance_of_procedure_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="importance_of_automation_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Update configuration guidance produced during CC
                      evaluations to make it fully automated and consistent for
                      use in the operational environment
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="importance_of_automation_rank"
                      size="sm"
                      value={formInfo?.importance_of_automation_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="importance_of_alignment_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Alignment of U.S. Government Product Validation
                      requirement processes among agencies
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="importance_of_alignment_rank"
                      size="sm"
                      value={formInfo?.importance_of_alignment_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="importance_of_standerdization_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Include the use of standardized tools throughout the
                      course of the evaluation to increase consistency and
                      efficiency
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="importance_of_standerdization_rank"
                      size="sm"
                      value={formInfo?.importance_of_standerdization_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="importance_of_improvement_rank"
              >
                <Row>
                  <Col>
                    <Form.Label>
                      Improve and expand scope of CC evaluations to include
                      Software & cloud-based systems
                    </Form.Label>
                  </Col>
                  <Col xs={3}>
                    <Form.Select
                      required
                      onChange={handleChange}
                      name="importance_of_improvement_rank"
                      size="sm"
                      value={formInfo?.importance_of_improvement_rank}
                      disabled={viewOnly}
                    >
                      <option> </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="recommended_cc_changes">
              <Form.Label>
                6. ISO is currently updating the Common Criteria standards. We
                encourage your participation in that effort. Alternatively,
                please share with us any other change<span>&#40;</span>s
                <span>&#41;</span> you would like to see in the next version of
                the CC
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                required={true}
                name="recommended_cc_changes"
                onChange={handleChange}
                value={formInfo?.recommended_cc_changes}
                disabled={viewOnly}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="additional_comments">
              <Form.Label>
                7. Thank you for participating in this survey. Please add any
                comments you feel would improve the process, provide greater
                value to the end consumer of your product, or increase your
                return on investment. Your input will be used to improve NIAP
                processes
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="additional_comments"
                onChange={handleChange}
                required={true}
                value={formInfo?.additional_comments}
                disabled={viewOnly}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Close
          </Button>
          {!viewOnly && (
            <Button
              type="submit"
              onClick={(e) => {
                handleSubmit(e, true);
              }}
            >
              Save as Draft
            </Button>
          )}
          {!viewOnly && (
            <Button variant="success" type="submit">
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Form>
      <p style={{ fontSize: "0.8em" }}>
        NIAP Form F8004, Version 3 <span>&#40;</span>January 2020
        <span>&#41;</span> This form is considered “company proprietary” when
        filled in.
      </p>
    </Modal>
  );
}
