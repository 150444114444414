import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import parse from "html-react-parser";
import FileDownload from "js-file-download";
import moment from "moment";
import { useCallback, useState, useEffect } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";

export default function EventsAndNewsModal({
  currentAnnouncement,
  show,
  handleClose,
  setOverlayActive,
  setOverlayText,
}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [files, setFiles] = useState([]);

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_public_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const fetchFiles = useCallback(() => {
    if (currentAnnouncement?.id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=announcement&file_source_type=Other&file_type_id=${currentAnnouncement?.id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setFiles(response.data.filter((f) => f.active === true));
        })
        .catch((error) => setFiles([]));
    }
  }, [currentAnnouncement?.id]);

  useEffect(() => {
    if (currentAnnouncement?.types === "1") {
      fetchFiles();
    }
  }, [show]);

  const currentAnnouncementTypes = () => {
    let fieldName = ["Announcements NIAP", "Evolution", "Partner"];
    let fieldData = currentAnnouncement?.types?.split(",");
    let mappedData = fieldData?.map((item) => fieldName[item - 1]);
    return mappedData?.join(", ");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="pb-0 border-0"></Modal.Header>
      <Modal.Body>
        <div>
          <h3 className="fw-bold mb-3">{currentAnnouncement?.title}</h3>
          {currentAnnouncement?.types === "4" && (
            <>
              <p>
                <span className="fw-bold">Start Date: </span>
                {currentAnnouncement?.begins
                  ? moment.utc(currentAnnouncement?.begins).format("MM/DD/YYYY")
                  : "No Start Date"}
              </p>
              <p>
                <span className="fw-bold">End Date: </span>
                {currentAnnouncement?.expires
                  ? moment
                      .utc(currentAnnouncement?.expires)
                      .format("MM/DD/YYYY")
                  : "No End Date"}
              </p>
            </>
          )}
          <p>
            <span className="fw-bold">Type: </span>
            {currentAnnouncementTypes()}
          </p>
          {currentAnnouncement?.link && (
            <p>
              <span className="fw-bold">Link: </span>
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={currentAnnouncement?.link}
                >
                  {currentAnnouncement?.link}
                </a>
              </span>
            </p>
          )}
          {currentAnnouncement?.types === "4" && (
            <>
              <p>
                <span className="fw-bold">Venue: </span>
                {currentAnnouncement?.venue}
              </p>
              <p>
                <span className="fw-bold">Location: </span>
                {currentAnnouncement?.location}
              </p>
            </>
          )}
          <p className="fw-bold">
            {currentAnnouncement?.types === "4"
              ? "Event Information:"
              : "News Information:"}{" "}
          </p>
          <p>
            {currentAnnouncement?.announcement
              ? parse(currentAnnouncement?.announcement)
              : ""}
          </p>
        </div>
        {currentAnnouncement?.types?.includes("1") && (
          <Container>
            <p className="fw-bold mb-4">Attached Files:</p>
            {files?.map((file, idx) => (
              <Row className="border-bottom text-center" key={idx}>
                <Col xs={12} sm={1}>
                  <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
                </Col>
                <Col xs={12} sm={6}>
                  <p
                    className="text-decoration-underline clickable"
                    onClick={() => downloadFile(file)}
                  >
                    {file.file_display_name}
                  </p>
                </Col>
                <Col xs={12} sm={5}>
                  <p>{file.file_name}</p>
                </Col>
              </Row>
            ))}
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button variant="warning" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
