import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Button,
  Stack,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Products.css";
import moment from "moment";

export default function ProductListItem({ listItem, projpps, ccPps }) {
  const getStatusColor = () => {
    if (
      listItem?.certification_status === "Certified" &&
      listItem?.status !== "Archived" &&
      !listItem?.is_archived
    ) {
      return "bg-green";
    } else if (listItem?.is_archived || listItem?.status === "Archived") {
      return "bg-archived";
    } else {
      return "bg-yellow";
    }
  };

  const getCardName = () => {
    if (
      listItem?.certification_status === "Certified" &&
      listItem?.status !== "Archived" &&
      !listItem?.is_archived
    ) {
      return "Certified";
    } else if (listItem?.is_archived || listItem?.status === "Archived") {
      return "Archived";
    } else {
      return listItem?.status;
    }
  };

  return (
    <Card className="shadow bg-product">
      <Row>
        <Col xs={2} sm={2} md={1}>
          <div className={"list-card-bar " + getStatusColor()}></div>
        </Col>
        <Col xs={6} sm={7} md={8}>
          <Card.Body>
            <Card.Text>
              <small>{listItem?.vendor_id_name}</small>
            </Card.Text>
            <Card.Title
              className="pb-3 fw-bold"
              data-testid="productListItemName"
            >
              {listItem?.product_name}
            </Card.Title>
            <Row className="d-flex flex-wrap" direction="horizontal">
              {/* {(listItem?.status === "Archived" || listItem?.is_archived )&&<Col>
                                <p className='mb-0'>Archived Date</p>
                                <strong>{listItem?.sunset_date? moment.utc(listItem?.sunset_date).format('MM/DD/YYYY'): "Pending"}</strong>
                            </Col>} */}
              {listItem?.certification_date && (
                <Col>
                  <p className="mb-0">Certification Date</p>
                  <strong>
                    {moment
                      .utc(listItem?.certification_date)
                      .format("MM/DD/YYYY")}
                  </strong>
                </Col>
              )}
              {listItem?.status !== "Archived" &&
                !listItem?.is_archived &&
                listItem?.status !== "In Progress" && (
                  <Stack className="card-info">
                    <p className="mb-0">Assurance Maintenance Date</p>
                    <strong>
                      {moment.utc(listItem?.am_sort).format("MM/DD/YYYY")}
                    </strong>
                  </Stack>
                )}
              {listItem?.status === "In Progress" && (
                <Col>
                  <p className="mb-0">Kickoff Date</p>
                  <strong>
                    {moment.utc(listItem?.kicked_off_date).format("MM/DD/YYYY")}
                  </strong>
                </Col>
              )}
              <Col className="card-info">
                <p className="mb-0">Conformance Claims:</p>
                <ListGroup>
                  {listItem?.from_cc_portal
                    ? ccPps &&
                      ccPps[listItem?.product_id]?.map((projpp, idx) => (
                        <OverlayTrigger
                          key={idx}
                          placement="left"
                          overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
                        >
                          <div key={idx}>
                            <Link to={`/protectionprofiles/${projpp?.pp_id}`}>
                              <strong className="py-0 my-0 overflow-ellipsis">
                                {projpp?.pp_short_name
                                  ? projpp?.pp_short_name
                                  : projpp?.pp_name}
                              </strong>
                            </Link>
                          </div>
                        </OverlayTrigger>
                      ))
                    : projpps &&
                      projpps[listItem?.product_id]?.map((projpp, idx) => (
                        <OverlayTrigger
                          key={idx}
                          placement="left"
                          overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
                        >
                          <div key={idx}>
                            <Link to={`/protectionprofiles/${projpp?.id}`}>
                              <strong className="py-0 my-0 overflow-ellipsis">
                                {projpp?.pp_short_name
                                  ? projpp?.pp_short_name
                                  : projpp?.pp_name}
                              </strong>
                            </Link>
                          </div>
                        </OverlayTrigger>
                      ))}
                </ListGroup>
              </Col>
              <Col>
                <p className="mb-0">CCTL</p>
                <strong>{listItem?.assigned_lab_name}</strong>
              </Col>
              {!listItem?.v_id ? (
                <div></div>
              ) : (
                <Col>
                  <p className="mb-0">VID</p>
                  <strong>{listItem?.v_id}</strong>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Col>
        <Col xs={4} sm={3} md={3} className="ms-auto pe-5">
          <Stack>
            <h6 className="text-sm-end text-xs-start my-3">
              <FontAwesomeIcon
                icon={faCircle}
                className="me-1"
                color={
                  listItem?.status === "Archived" || listItem?.is_archived
                    ? "var(--bs-orange)"
                    : listItem?.certification_status === "Certified"
                      ? "var(--bs-green)"
                      : "var(--bs-yellow)"
                }
                size="xs"
              />
              {getCardName()}
            </h6>
            {listItem?.submitting_country_id_code && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {listItem?.submitting_country_id_name}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div className="text-end pe-1">
                    <img
                      ref={ref}
                      {...triggerHandler}
                      src={require(
                        `../../../images/flags2/${listItem?.submitting_country_id_code?.toLowerCase()}.png`,
                      )}
                      height="50px"
                      width="50px"
                      alt="Country Flag"
                    />
                  </div>
                )}
              </OverlayTrigger>
            )}
            {listItem?.status !== "Archived" &&
              !listItem?.is_archived &&
              listItem?.certification_status === "Certified" && (
                <Link
                  to={
                    !("from_cc_portal" in listItem)
                      ? `/products/${listItem?.product_id}`
                      : `/products/international-product/${listItem?.product_id}`
                  }
                  className="text-sm-end text-xs-start mt-3 mb-3"
                >
                  <Button
                    variant="light"
                    size="sm"
                    className="border-dark btn-hover"
                  >
                    Full Details
                  </Button>
                </Link>
              )}
          </Stack>
        </Col>
      </Row>
    </Card>
  );
}
