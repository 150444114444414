import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

export default function FAQCategoryModal({
  show,
  handleClose,
  categoryToEdit,
  setAlert,
  refetch,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const user = useSelector((state) => state.user.value);
  const [category, setCategory] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
  }, [show]);

  const handleChange = (e) => {
    if (e.target.name === "active") {
      setCategory({
        ...category,
        [e.target.name]: e.target.value === "true" ? true : false,
      });
    } else {
      setCategory({ ...category, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (categoryToEdit?.cid) {
        handleSave();
      } else {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/cat/`,
            { ...category },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            refetch();
            handleClose();
            setAlert({
              message: "Successfully created new category!",
              type: "success",
            });
          })
          .catch((error) => {
            console.error("Error submitting resource:", error);
            setAlert({
              message: "Error creating new category.",
              type: "danger",
            });
          });
      }
    }
  };

  const handleSave = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/cat/${categoryToEdit?.cid}/`,
        { ...category },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        refetch();
        handleClose();
        setAlert({ message: "Category Updated", type: "success" });
      })
      .catch((error) => {
        console.error("Error Updating resource:", error);
        setAlert({ message: "Error Updating Category.", type: "danger" });
      });
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {categoryToEdit?.cid ? "EDIT CATEGORIES" : "+NEW CATEGORY"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={8} sm={12}>
              <Form.Group className="mb-3" controlId="category">
                <Form.Label>
                  {categoryToEdit?.cid ? "New Category Name*" : "Category:*"}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="cat"
                  onChange={handleChange}
                  required
                  defaultValue={categoryToEdit?.cat}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="active">
            <Form.Label>Active*</Form.Label>
            <div key="inline-radio">
              <Form.Check
                inline
                type="radio"
                label="Yes"
                name="active"
                value={"true"}
                defaultChecked={categoryToEdit?.active === true}
                onChange={handleChange}
                required
              />
              <Form.Check
                inline
                type="radio"
                label="No"
                name="active"
                value={"false"}
                defaultChecked={categoryToEdit?.active === false}
                onChange={handleChange}
                required
              />
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Cancel
          </Button>

          {categoryToEdit?.cid ? (
            <Button type="submit">Submit Revisions</Button>
          ) : (
            <Button type="submit">Add</Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
