import axios from "axios";
import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { handleAlertAndMailer } from "../../Products/Helper/functions";

export default function RejectTQModal({
  show,
  handleClose,
  technicalQuery,
  refetch,
}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const csrfToken = cookies["csrftoken"];
  const currentUser = useSelector((state) => state.user.value);
  const [rejection, setRejection] = useState("");

  const handleSendAlertAndMail = () => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: technicalQuery?.tq_id,
      alert_type: "Technical Query",
      subject: "Technical Query Rejected",
      recipients: { to: ["Customer"], cc: [] },
      alert_text: `Technical Query Rejected, TQ ID: ${technicalQuery?.tq_id}`,
    });
  };

  const handleDeleteTQ = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${technicalQuery?.tq_id}/`,
        {
          status: "Rejected",
          response: rejection,
          response_by: currentUser.id,
          response_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          recaptcha_token: "member_portal",
          modified_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        handleClose();
        handleSendAlertAndMail();
        refetch();
      })
      .catch((err) => console.log("unable to get technical query", err));
  };

  return (
    <Modal size="md" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          Reject Technical Query - {technicalQuery?.title}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Label>
            <h4>Rationale for Rejection</h4>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            name="response"
            onChange={(e) => setRejection(e.target.value)}
            required
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            variant="outline-primary"
            className="m-1"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            id={"reject-tq-button"}
            variant="danger"
            className="m-1"
            disabled={rejection === ""}
            onClick={handleDeleteTQ}
          >
            Reject
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
