import axios from "axios";
import { useState, useEffect } from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Subheader from "../Subheader";
import "./ccra.css";

const EvaluationProcess = () => {
  const [schemes, setSchemes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "NIAP - CCRA";
  }, []);

  const loadSchemes = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/scheme/get_frontend_producers_consumers`,
      )
      .then((response) => {
        setSchemes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    loadSchemes();
  }, []);

  return (
    <>
      <Subheader pageName={"CCRA"} />
      <Container className="mb-5 py-3">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate(`/`)}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>CCRA</Breadcrumb.Item>
        </Breadcrumb>
        <div className="p-4 border-bottom border-dark">
          <h4 className="text-uppercase fw-bold pb-3">
            About Us -{" "}
            <span className="text-uppercase fw-bold text-primary">
              Common Criteria Recognition Arrangement (CCRA)
            </span>
          </h4>
          <p>
            The National Information Assurance Partnership (NIAP) serves as the
            U.S. representative to the Common Criteria Recognition Arrangement
            (CCRA), composed of 31 member nations. The purpose of this
            Arrangement is to ensure IT products evaluated according to the
            terms of the CCRA are mutually recognized by all member nations,
            allowing industry to evaluate products once and sell to many
            nations.
          </p>
          <p>
            The U.S. Government and its foreign partners in the Arrangement
            share the following objectives with regard to evaluations of IT
            products and protection profiles:
          </p>
          <ul>
            <li>
              Ensure evaluation of IT products and protection profiles are
              performed to high and consistent standards and contribute
              significantly to confidence in the security of those products and
              profiles.
            </li>
            <li>
              Increase the availability of evaluated, security-enhanced IT
              products and protection profiles for national use.
            </li>
            <li>
              Eliminate duplicate evaluations of IT products and protection
              profiles.
            </li>
            <li>
              Continuously improve the efficiency and cost-effectiveness of
              security evaluations and the certification/validation process for
              IT products and protection profiles.
            </li>
          </ul>
          <p>
            For more information about CCRA:{" "}
            <a href="https://www.commoncriteriaportal.org">
              https://www.commoncriteriaportal.org
            </a>
          </p>
        </div>
        <div className="p-4">
          <h4 className="text-uppercase fw-bold pb-3 text-primary">
            CCRA PARTICIPANTS
          </h4>
          <div className="no-overflow">
            <div className="flag-items">
              <h5 className="text-uppercase fw-bold text-primary">
                Certificate Producers
              </h5>
              <div className="row">
                {schemes?.producers?.map((scheme, key) => (
                  <div className="col-md-6-3 col-sm-4 col-xs-6" key={key}>
                    <a
                      href={scheme.url}
                      className="d-flex flex-column text-center text-decoration-none"
                    >
                      <img
                        src={require(
                          `../../images/flags2/${scheme?.cid__code2_iso?.toLowerCase()}.png`,
                        )}
                        alt={`${scheme?.cid?.code2_iso} Flag`}
                      />
                      <small className="margin-neg">{scheme?.cid__name}</small>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="flag-items">
              <h5 className="text-uppercase fw-bold text-primary">
                Certificate Consumers
              </h5>
              <div className="row">
                {schemes?.consumers?.map((scheme, key) => (
                  <div className="col-md-6-3 col-sm-4 col-xs-6" key={key}>
                    <a
                      href={scheme.url}
                      className="d-flex flex-column text-center text-decoration-none"
                    >
                      <img
                        src={require(
                          `../../images/flags2/${scheme?.cid__code2_iso?.toLowerCase()}.png`,
                        )}
                        alt={`${scheme?.cid?.code2_iso} Flag`}
                      />
                      <small className="margin-neg">{scheme?.cid__name}</small>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EvaluationProcess;
