import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../../UI/UnauthorizedView";

import PolciyLettersTable from "./PolicyLettersTable";

export default function PolicyLettersManager() {
  const permissions = useSelector((state) => state.role.value);

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<PolciyLettersTable />} />
    </Routes>
  );
}
