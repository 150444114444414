import Pagination from "react-bootstrap/Pagination";

export default function PaginationComponent({
  tabAlertsLength,
  alertsPerPage,
  currentPage,
  paginate,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(tabAlertsLength / alertsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const pageItems = [];
    for (let i = 1; i <= pageNumbers.length; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          id={i}
          onClick={() => paginate(i)}
          active={i === currentPage}
        >
          {i}
        </Pagination.Item>,
      );
    }
    return pageItems;
  };

  const getPaginatedPageNumbers = () => {
    const startIndex = (Math.ceil(currentPage / 5) - 1) * 5;
    const endIndex = startIndex + 5;
    const paginatedPageNumbers = renderPageNumbers().slice(
      startIndex,
      endIndex,
    );
    paginatedPageNumbers.unshift(
      <Pagination.Prev
        key="prev"
        onClick={() => paginate(currentPage - 1, pageNumbers.length)}
      />,
    );
    paginatedPageNumbers.push(
      <Pagination.Next
        key="next"
        onClick={() => paginate(currentPage + 1, pageNumbers.length)}
      />,
    );

    return paginatedPageNumbers;
  };

  return <Pagination>{getPaginatedPageNumbers()}</Pagination>;
}
