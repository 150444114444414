import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import qs from "qs";
import { useEffect, useState, useRef } from "react";
import { Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import FAQModal from "./FAQModal";

export default function FAQTable() {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [filtering, setFiltering] = useState(false);
  const [showFAQModal, setShowFAQModal] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const currentUser = useSelector((state) => state.user.value);
  const [selectedFAQ, setSelectedFAQ] = useState({});
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  const columns = [
    {
      title: "Question",
      field: "question",
      render: (rowData) => {
        const maxChars = 100;
        const question = rowData?.question;
        if (!question) return "";
        const displayText =
          question.length > maxChars
            ? question.substring(0, maxChars) + "..."
            : question;
        return displayText;
      },
    },
    {
      title: "Answer",
      field: "answer",
      render: (rowData) => {
        const maxChars = 100;
        const answer = rowData?.answer;
        if (!answer) return "";
        let displayText =
          answer.length > maxChars
            ? answer.substring(0, maxChars) + "..."
            : answer;
        displayText = parse(
          displayText.replace(/<a[^>]*>Send a Response<\/a>/g, ""),
        );
        return displayText;
      },
    },
    {
      title: "Category",
      field: "submission_category",
      render: (rowData) => {
        return (
          rowData?.category_id?.category_name ?? rowData?.submission_category
        );
      },
    },
    {
      title: "Created",
      field: "created",
      render: (rowData) => {
        return moment.utc(rowData?.created).format("MM/DD/YYYY");
      },
    },
    { title: "Author", field: "submitter_name" },
    { title: "Published", field: "publish" },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <Button
            variant="link"
            className="m-1 p-0 btn-link"
            onClick={() => {
              setSelectedFAQ(rowData);
              setShowFAQModal(true);
            }}
          >
            <EditIcon color="action" /> Edit
          </Button>
        );
      },
    },
  ];

  const options = {
    showTitle: false,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: filtering,
    pageSize: 20,
  };

  return (
    <Container fluid>
      <FAQModal
        show={showFAQModal}
        handleClose={() => {
          setShowFAQModal(false);
          setSelectedFAQ({});
        }}
        selectedFAQ={selectedFAQ}
        setAlert={setAlert}
        currentUser={currentUser}
        refetch={() => {
          setRefresh(!refresh);
        }}
      />

      <Row>
        {alert.message !== "" && (
          <Row className="d-flex justify-content-center mt-3">
            <Col sm={8}>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Col>
          <Row className="mt-5 mb-3">
            <Col sm={12} md={7} className="mb-xs-3">
              <h3 className="text-bright-navy">FREQUENTLY ASKED QUESTIONS</h3>
            </Col>
            <Col sm={12} md={3} className="d-flex justify-content-end mb-xs-3">
              <Button
                variant="primary"
                href="faqs/categories"
                className="rounded-0 w-100"
              >
                Categories
              </Button>
            </Col>
            <Col sm={12} md={2} className="d-flex justify-content-end mb-xs-3">
              <Button
                className="account-btn w-100"
                variant="primary"
                onClick={() => {
                  setSelectedFAQ({});
                  setShowFAQModal(true);
                }}
              >
                + New FAQ
              </Button>
            </Col>
          </Row>
          <Row>
            <ResponsiveMaterialTable
              columns={columns}
              tableRef={tableRef}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                  } = query;

                  let newFilters = filters.map((filter) => {
                    let value = "";
                    if (Array.isArray(filter.value)) {
                      value = filter.value;
                    } else {
                      value = filter.value.replace(/['"]+/g, "");
                    }
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                  };
                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then((response) => {
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              }
              options={options}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
