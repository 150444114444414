import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { useContext } from "react";

import { PfaContext } from "../..";
import { useAuth } from "../../../../hooks/useAuth";

import PfaActionMenuItem from "./PfaActionMenuItem";

const PfaApprovalAction = ({ file }) => {
  const {
    permissions: { role_type = "" },
    authToken,
    csrfToken,
  } = useAuth();

  const { tableRef } = useContext(PfaContext);

  const handleClick = () => {
    const approved = (!file.approved).toString();
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
        {
          approved: approved,
          published: "false",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        tableRef.current.onQueryChange();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (file.private || file.published || role_type !== "NIAP") return null;

  const icon = file.approved ? CancelIcon : CheckIcon;
  const label = file.approved ? "Disapprove" : "Approve";

  return (
    <PfaActionMenuItem
      file={file}
      Icon={icon}
      label={label}
      onClick={handleClick}
    />
  );
};

export default PfaApprovalAction;
