import moment from "moment";
import { Accordion } from "react-bootstrap";

export default function SrValidatorRecAccordion({ product }) {
  return (
    <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
      <Accordion.Item eventKey="1">
        <Accordion.Header className="bg-secondary">
          <h6 className="sidebar-title">Sr. Validator Recommendations</h6>
        </Accordion.Header>
        <Accordion.Body className="bg-light p-1">
          <p className="m-0 fw-bold">Recommendation for Kickoff: </p>
          {product?.kickoff_recommended_by ? (
            <p className="m-0 mx-2">
              {product?.kickoff_recommended_by?.first_name}{" "}
              {product?.kickoff_recommended_by?.last_name}, on{" "}
              {moment
                .utc(product?.kickoff_recommended_date)
                .format("MM/DD/YYYY")}
            </p>
          ) : (
            <p className="m-0 mx-2">No recommendation has been made</p>
          )}
          <p className="m-0 mt-3 fw-bold">Recommendation for PCL Posting:</p>
          {product?.validator_posting_verifier ? (
            <p className="m-0 mx-2">
              {product?.validator_posting_verifier?.first_name}{" "}
              {product?.validator_posting_verifier?.last_name}, on{" "}
              {moment.utc(product?.validator_posting_date).format("MM/DD/YYYY")}
            </p>
          ) : (
            <p className="m-0 mx-2">No recommendation has been made</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
