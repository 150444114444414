import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";

import HTMLEditor from "../../../UI/HTMLEditor";

const NISTCommentModal = ({
  show,
  onHide,
  certReview,
  update,
  selectedUpdate,
}) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [newComment, setNewComment] = useState({});
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const handleChange = (event) => {
    setNewComment({ ...newComment, [event.target.name]: event.target.value });
  };

  const handleReset = () => {
    setNewComment({});
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/comments/${selectedUpdate?.upid}/`,
        {
          note1: newComment?.note1,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        update();
        onHide();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/comments/`,
        {
          ...newComment,
          crid: certReview?.crid,
          pid: certReview?.pid?.product_id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        update();
        onHide();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal show={show} onHide={onHide} enforceFocus={enforceFocusModal}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          {selectedUpdate?.upid ? "Update" : "Add"} Response to NIST Draft for
          VID {certReview?.pid?.v_id}
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="note">
            <Form.Label>Comment</Form.Label>
            <HTMLEditor
              id="note"
              name="note1"
              handleChange={handleChange}
              value={newComment?.note1}
              defaultValue={selectedUpdate?.note1}
              setIsValid={() => {}}
              isValid={true}
              setEnforceFocusModal={setEnforceFocusModal}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" onClick={handleReset} variant="primary">
            Reset
          </Button>
          <Button onClick={onHide} variant="danger">
            Cancel
          </Button>
          {selectedUpdate?.upid ? (
            <Button onClick={handleUpdate} variant="success">
              Update
            </Button>
          ) : (
            <Button type="submit" onClick={handleSubmit} variant="success">
              Post
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NISTCommentModal;
