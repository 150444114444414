import { Tab, Tabs } from "react-bootstrap";

import VendorActiveProductsTable from "./VendorActiveProductsTable";
import VendorArchivedProductsTable from "./VendorArchivedProductsTable";
import VendorCertifiedProductsTable from "./VendorCertifiedProductsTable";

const VendorProductTabs = ({
  setOverlayActive,
  setOverlayText,
  setThirtyDayAlert,
  setAlertInfo,
}) => {
  return (
    <Tabs defaultActiveKey="active_products">
      <Tab eventKey="active_products" title="Active Products">
        <VendorActiveProductsTable
          setOverlayActive={setOverlayActive}
          setOverlayText={setOverlayText}
          setAlertInfo={setAlertInfo}
        />
      </Tab>
      <Tab eventKey="certified_products" title="Certified Products">
        <VendorCertifiedProductsTable
          setThirtyDayAlert={setThirtyDayAlert}
          setAlertInfo={setAlertInfo}
          setOverlayActive={setOverlayActive}
          setOverlayText={setOverlayText}
        />
      </Tab>
      <Tab eventKey="archived_products" title="Archived Products">
        <VendorArchivedProductsTable />
      </Tab>
    </Tabs>
  );
};

export default VendorProductTabs;
