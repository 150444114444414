import axios from "axios";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useCookies } from "react-cookie";
import { useSearchParams, useNavigate, Link } from "react-router-dom";

const MFAConfirm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["temp_token"];
  const [mfaInfo, setMfaInfo] = useState({});
  const [formErrors, setFormErrors] = useState(false);
  const [backupCodes, setBackupCodes] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const method = searchParams.get("mfa-method");
  const ephemeralToken = searchParams.get("ephemeral-token");

  useEffect(() => {
    if (ephemeralToken) {
      setMfaInfo({ ...mfaInfo, ephemeral_token: ephemeralToken });
    }
  }, []);

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleChange = (e) => {
    setMfaInfo({ ...mfaInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (!ephemeralToken) {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${method}/activate/confirm/`,
          mfaInfo,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setBackupCodes(response.data.backup_codes);
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/login/code/`,
          mfaInfo,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          navigate("/login/success/", {
            state: { auth_token: response.data.auth_token, is_login: true },
          });
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">
          Check Your{" "}
          {method !== "undefined"
            ? method !== "sms_twilio"
              ? capitalize(method)
              : "Phone"
            : ""}
        </Card.Title>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="code">
            <Form.Label className="small text-secondary">MFA Code*</Form.Label>
            <Form.Control
              type="text"
              name="code"
              onChange={handleChange}
              required={true}
            />
          </Form.Group>
          {formErrors && <Alert variant="danger">{formErrors.error}</Alert>}
          {backupCodes && (
            <div className="backup-codes mb-4">
              <h5>Backup Codes</h5>
              {backupCodes.map((code, idx) => (
                <p key={idx}>{code}</p>
              ))}
            </div>
          )}
          <br />
          <p className="text-secondary small">
            Please be patient. You will receive your code shortly.
          </p>
          {!backupCodes && !ephemeralToken && (
            <Link to={`/login/mfa-select/?ephemeral-token=${ephemeralToken}`}>
              Choose Another Method/Resend Code
            </Link>
          )}
          {ephemeralToken && (
            <Link to={`/login/resend-code/?ephemeral-token=${ephemeralToken}`}>
              Choose Another Method/Resend Code
            </Link>
          )}
          <br />
          {backupCodes && (
            <Button
              variant="warning"
              onClick={() =>
                navigate("/login/reset-password/", {
                  state: { is_continue: true },
                })
              }
            >
              Continue
            </Button>
          )}
          <br />
          <br />
          {backupCodes && (
            <Button
              variant="warning"
              onClick={() => navigate("/login/mfa-select")}
            >
              Add Another Method
            </Button>
          )}
          {!backupCodes && !ephemeralToken && (
            <Button variant="warning" type="submit">
              Submit
            </Button>
          )}
          {ephemeralToken && (
            <Button
              variant="warning"
              disabled={loading}
              type={loading ? "button" : "submit"}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> Login
                </>
              ) : (
                "Login"
              )}
            </Button>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MFAConfirm;
