import { Modal, Container, Row, Col } from "react-bootstrap";

import stepTwoImage from "../../../images/evaluation-process/step-two.png";
import CICOPdf from "../../Publications/PublicationPDFs/CCEVSGuidance/CICO.pdf";

export default function StepTwoModal({ show, setShowStepTwoModal }) {
  const handleClose = () => {
    setShowStepTwoModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-5">
        <Container>
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="d-flex justify-content-center">
                    <img
                      src={stepTwoImage}
                      alt="step two image"
                      className="w-auto"
                      height="120px"
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-uppercase fw-bold mt-3 mb-4 text-primary">
                      Step 2
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <h5 className="fw-bold">
                  <a href={CICOPdf}>Check-In Process</a>
                </h5>
              </Row>
              <Row className="mt-4">
                <h5 className="fw-bold">
                  a. CCTL Proposes new Evaluation to NIAP
                </h5>
              </Row>
              <Row>
                <p className="mx-3">
                  i. Include: Entropy Assessment Report (EAR), Draft Security
                  Target, Claimed Protection Profile(s), Estimated Timeline
                </p>
              </Row>
              <Row className="mt-4">
                <h5 className="fw-bold">
                  b. Evaluation "Checked In" with NIAP
                </h5>
              </Row>
              <Row>
                <ul className="remove-dots mx-3">
                  <li>i. NIAP Validators Assigned</li>
                  <li>
                    ii. Deliverables Reviewed/PP Conformance Claims Analyzed
                  </li>
                  <li>iii. Check-in Telecon</li>
                </ul>
              </Row>
              <Row className="mt-4 mb-4">
                <h5 className="fw-bold">Timeline: 2 weeks (Estimated)</h5>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
