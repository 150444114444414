import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { useInterval } from "../../../hooks/useInterval";
import CertificateModal from "../../Products/ProductManager/CertificateModal";
import ReadOnlyProductForm from "../../Products/ProductManager/ProductForm/ReadOnlyProductForm";
import VendorCheckOutModal from "../../Products/ProductManager/VendorCheckOutModal";
import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

const VendorActiveProductsTable = ({
  setOverlayActive,
  setOverlayText,
  setAlertInfo,
}) => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const [currentProduct, setCurrentProduct] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [groupedCheckInFiles, setGroupedCheckInFiles] = useState({});
  const [progressPoints, setProgressPoints] = useState({});
  const [showCertificate, setShowCertificate] = useState(false);
  const [showROIModal, setShowROIModal] = useState(false);
  const tableRef = useRef();

  const refetchProducts = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const getGroupedFiles = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_grouped_signed_check_in_files/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setGroupedCheckInFiles(response.data);
      })
      .catch(() => {
        console.log("Error getting files");
      });
  }, []);

  useEffect(() => {
    getGroupedFiles();
  }, []);

  useInterval(() => {
    tableRef.current.onQueryChange();
    getGroupedFiles();
  }, 300000); //5 min

  const getProgressPoints = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/progress/get_all_grouped_progress_points/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setProgressPoints(response.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getProgressPoints();
  }, []);

  useInterval(() => {
    tableRef.current.onQueryChange();
    getProgressPoints();
  }, 300000); //5 min

  const daysPassed = (inputDate) => {
    if (inputDate) {
      const startDate = moment(inputDate);
      const endDate = moment();
      const daysSinceStartDate = endDate.diff(startDate, "days");
      return daysSinceStartDate;
    } else {
      return 0;
    }
  };

  const handleOpenModal = (data) => {
    setShowModal(true);
    setCurrentProduct(data);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: "VID No.",
      field: "v_id",
      type: "numeric",
    },
    {
      title: "Product",
      field: "product_name",
    },
    {
      title: "Product Status",
      field: "status",
    },
    {
      title: "",
      render: (rowData) => (
        <div>
          {!groupedCheckInFiles[rowData?.product_id] &&
          rowData?.pre_eval_status === "Proposed" ? (
            <Button
              size="sm"
              className="review"
              onClick={() => handleOpenModal(rowData)}
            >
              Review & Sign
            </Button>
          ) : progressPoints[rowData?.product_id]?.sort(
              (a, b) => a?.progress_point - b?.progress_point,
            )[6].status === "Ready for Release" ? (
            <>
              {rowData?.vendors_cc_draft_cert_verifier === null && (
                <Button
                  size="sm"
                  className="review bg-warning"
                  style={{ minWidth: 120 }}
                  onClick={() => {
                    setCurrentProduct(rowData);
                    setShowCertificate(true);
                  }}
                >
                  Review Draft Certificate
                </Button>
              )}
              <br />
              <br />
              {rowData?.vendor_checkout_signer === null && (
                <Button
                  size="sm"
                  className="review bg-success"
                  style={{ minWidth: 120 }}
                  onClick={() => {
                    setCurrentProduct(rowData);
                    setShowROIModal(true);
                  }}
                >
                  Sign Release Form
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: "Days in Progress",
      field: "check_in_date",
      render: (rowData) => <>{daysPassed(rowData?.kicked_off_date)}</>,
    },
    {
      title: "CCTL",
      field: "assigned_lab.name",
    },
    {
      title: "POC Name",
      field: "assigned_lab.poc",
    },
    {
      title: "POC Email",
      field: "assigned_lab.poc_email",
    },
    {
      title: "Submitted As",
      field: "submission_type",
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: "dense",
    pageSize: 20,
  };

  return (
    <>
      <ResponsiveMaterialTable
        columns={columns}
        title="Active Products"
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };

            axios
              .get(
                `${
                  process.env.REACT_APP_DJANGO_ENDPOINT
                }project/product/vendor_active_products/?${qs.stringify(
                  params,
                  {
                    arrayFormat: "comma",
                  },
                )}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
      <ReadOnlyProductForm
        show={showModal}
        handleCloseModal={() => handleCloseModal(1)}
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
        product={currentProduct}
        refetch={refetchProducts}
        review={true}
      />
      <CertificateModal
        show={showCertificate}
        handleClose={() => setShowCertificate(false)}
        product={currentProduct}
        refetch={refetchProducts}
      />
      <VendorCheckOutModal
        show={showROIModal}
        handleClose={() => setShowROIModal(false)}
        product={currentProduct}
        setOverlayActive={setOverlayActive}
        setOverlayText={setOverlayText}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default VendorActiveProductsTable;
