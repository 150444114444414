import { Input, TablePagination } from "@material-ui/core";
import axios from "axios";
import parse from "html-react-parser";
import MaterialTable from "material-table";
import { Modal, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import { handleAlertAndMailer } from "../Products/Helper/functions";

export default function ECRReleaseModal({
  show,
  handleClose,
  toRelease,
  refetch,
  project_id,
  vidNumber,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];

  const columns = [
    { title: "ID", field: "ecrid" },
    {
      title: "Author",
      render: (rowData) => (
        <div>
          {rowData.creator?.first_name} {rowData.creator?.last_name}
        </div>
      ),
    },
    {
      title: "VID",
      field: "pid.v_id",
      render: (rowData) => (
        <Link
          to={`/community/products/details/${rowData.pid?.product_id}`}
          className="text-decoration-underline"
        >
          {rowData?.pid?.v_id}
        </Link>
      ),
    },
    { title: "Type", field: "type" },
    { title: "Status", field: "status" },
    {
      title: "Comment",
      field: "cmnt",
      render: (rowData) => rowData?.cmnt && <p>{parse(rowData?.cmnt)}</p>,
    },
    { title: "Requirement", field: "req" },
    { title: "Added", field: "entrydate", type: "date" },
    { title: "Modified", field: "moddate", type: "date" },
  ];

  const handleSendAlertAndMail = () => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: project_id,
      alert_type: "Product",
      alert_source: "ECR",
      subject: `ECR Comments have been released for Product VID${vidNumber}`,
      recipients: { to: ["Lab"], cc: ["Validators"] },
      alert_text: `ECR Comments have been released for Product VID${vidNumber}`,
    });
  };

  const releaseComments = () => {
    toRelease.forEach((ecr) => {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${ecr?.ecrid}/`,
          { status: "Released" },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          console.log("ECR Comment Released");
        })
        .catch((e) => console.log(e));
    });
    handleClose();
    refetch();
    handleSendAlertAndMail();
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="me-2">Release ECR Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MaterialTable
          data={toRelease}
          columns={columns}
          components={{
            Pagination: (props) => (
              <TablePagination
                {...props}
                labelRowsPerPage={
                  <label htmlFor="rowsPerPage">Rows Per Page</label>
                }
                SelectProps={{ input: <Input id="rowsPerPage" /> }}
              />
            ),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="warning"
          onClick={() => releaseComments()}
        >
          Release Comments
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
