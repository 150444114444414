import { Container, Row, Col } from "react-bootstrap";

export default function Subheader({ pageName }) {
  return (
    <div className="subheader">
      <Container fluid className="text-white">
        <Row className="justify-content-start p-4 mx-3">
          <Col xs={12} sm={12} md={12} lg={6} className="text-center">
            <div className="p-4 mt-5 mb-5 subheader-row">
              <h1
                className="border-top border-5 border-warning fw-bold text-uppercase p-1 m-4"
                style={{ width: "fit-content" }}
              >
                {pageName}
              </h1>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
