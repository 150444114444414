import Input from "@mui/material/Input";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import MaterialTable from "material-table";
import qs from "qs";
import { useRef, useEffect } from "react";
import { Container } from "react-bootstrap";

export default function CCTLTableView({ sidebarFilters }) {
  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [sidebarFilters]);

  const getFormattedAddress = (rowData) => {
    return (
      <>
        {rowData?.address_1 && <>{rowData?.address_1}</>}
        {rowData?.address_2 && <>{" " + rowData?.address_2}</>}
        {rowData?.city && <>{" " + rowData?.city}</>}
        {rowData?.state_id && <>{", " + rowData?.state_id.us_state_abbrv}</>}
        {rowData?.zip && <>{" " + rowData?.zip}</>}
      </>
    );
  };

  const columns = [
    { title: "ID", field: "cctl_id" },
    {
      title: "Name",
      field: "cctl_name",
      render: (rowData) => <a href={rowData?.cctl_url}>{rowData?.cctl_name}</a>,
      defaultSort: "asc",
    },
    {
      title: "Address",
      field: "address_1",
      render: (rowData) => getFormattedAddress(rowData),
    },
    { title: "Test Methods", field: "lab_test_methods" },
    { title: "POC", field: "poc" },
    { title: "Phone", field: "work_phone" },
    { title: "Email", field: "poc_email" },
    { title: "NVLAP Lab Code", field: "nvlap_lab_code" },
  ];

  const options = {
    headerStyle: {
      fontWeight: "bold",
      paddingBottom: "0",
    },
    pageSize: 20,
  };

  return (
    <Container className="mt-4" fluid>
      <MaterialTable
        title="Showing"
        columns={columns}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              ...sidebarFilters,
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}cctl/directory/frontend_cctls/?${qs.stringify(params, { arrayFormat: "comma" })}`,
              )
              .then((response) => {
                resolve({
                  data: response.data.results.cctls,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <label htmlFor="rowsPerPage">Rows Per Page</label>
              }
              SelectProps={{ input: <Input id="rowsPerPage" /> }}
            />
          ),
        }}
      />
    </Container>
  );
}
