const TqAuthorCell = ({ tq }) => {
  const { submitted_by, name } = tq;

  const displayName =
    submitted_by === null
      ? name
      : `${submitted_by.first_name} ${submitted_by.last_name}`;

  return <>{displayName}</>;
};

export default TqAuthorCell;
