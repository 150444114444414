import { Accordion, Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Teams.css";

export default function Teams({ projectPersonnel, assuranceMaintenance }) {
  const navigate = useNavigate();

  const toEmailForm = (toEmail) => {
    navigate("/community/email", { state: { email: toEmail } });
  };

  const teamMembersList = (teamType) => {
    let members = projectPersonnel;
    if (teamType === "Validation") {
      members = projectPersonnel?.filter(
        (personnel) =>
          personnel?.team_type === "Validation" && personnel?.approved === true,
      );
    } else if (teamType === "Evaluation") {
      members = projectPersonnel?.filter(
        (personnel) => personnel?.team_type === "Evaluation",
      );
    }
    if (members?.length === 0) return <p>No Members Assigned</p>;

    return members?.map((member, idx) => (
      <div key={idx}>
        {/* Member Row */}
        <Row className="mt-2">
          {/* Left Column */}
          <Col lg={6}>
            {/* Member Name */}
            <Row>
              <Col>
                <p className="mb-0" style={{ fontSize: "14px" }}>
                  {member?.user_id
                    ? `${member?.user_id?.first_name} ${member?.user_id?.last_name}`
                    : member?.non_user_member}
                </p>
              </Col>
            </Row>

            {/* Member Company Name */}
            <Row>
              <Col>
                <p className="fw-lighter mb-0" style={{ fontSize: "14px" }}>
                  {member?.user_id?.company?.name}
                </p>
              </Col>
            </Row>

            {/* Member Role */}
            <Row>
              <Col>
                <p className="fw-lighter mb-0" style={{ fontSize: "14px" }}>
                  {member?.role}
                </p>
              </Col>
            </Row>

            {/* Contract Vehicle */}
            {member?.contract_vehicle && (
              <Row>
                <Col>
                  <p className="fw-lighter mb-0" style={{ fontSize: "14px" }}>
                    {member.contract_vehicle.vehicle}
                  </p>
                </Col>
              </Row>
            )}
          </Col>

          {/* Right Column */}
          <Col lg={6}>
            <Row>
              <Col>
                {/* <p className="mb-0" style={{ fontSize: "14px" }}>
                  {member.user_id?.phone_number}
                </p> */}
              </Col>

              {/* Member Email Address */}
              <Col>
                <Button
                  variant="link"
                  className="p-0 text-bright-blue"
                  style={{
                    fontSize: "12px",
                    wordBreak: "break-word",
                  }}
                  onClick={() => toEmailForm(member?.user_id?.email)}
                >
                  {member?.user_id?.email}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    ));
  };

  return (
    <>
      {!assuranceMaintenance && (
        <Accordion
          flush
          className="border border-dark mb-3 hr"
          defaultActiveKey="1"
        >
          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
              <h6 className="sidebar-title">Evaluation Team</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
              <Container>{teamMembersList("Evaluation")}</Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">
              {!assuranceMaintenance ? "Project Team" : "AM Validation Team"}
            </h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>{teamMembersList("Validation")}</Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
