import axios from "axios";
import { useState } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import { useAuth } from "../../hooks/useAuth";
import { getISOCurrentDate } from "../../utils/timeUtils";
import documentTypes from "../Products/FileUpload/DocumentTypes.json";

export default function ReplaceFileModal({
  show,
  file,
  updateFiles,
  setShow,
  type,
}) {
  const { authToken, csrfToken, currentUser } = useAuth();

  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    file_display_name: file.file_display_name,
    file_label: file.file_label,
    file_public_status:
      file.private === true
        ? "Proprietary"
        : file.private === false
          ? "Public"
          : "",
    file: undefined,
  });

  if (!file) return;

  const handleSubmit = (e) => {
    const htmlForm = e.currentTarget;
    e.preventDefault();

    if (htmlForm.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    const submittedData = new FormData();
    submittedData.append("file_type", file.file_type);
    submittedData.append("file_type_id", file.file_type_id);
    submittedData.append(`file`, form.file);
    submittedData.append("file_display_name", form.file_display_name);
    submittedData.append("file_name", form.file.name);
    submittedData.append("file_label", form.file_label);
    submittedData.append("file_location", `/${type}/${file.file_id}/`);
    submittedData.append("file_mime_type", form.file.type);
    submittedData.append("uploaded_on", getISOCurrentDate());
    submittedData.append("file_source_type", "Other");
    submittedData.append("active", "true");
    submittedData.append("replaced", "true");
    submittedData.append("replaced_by", currentUser.id);
    submittedData.append("replaced_on", getISOCurrentDate());

    if (file.file_public_status === "Proprietary") {
      submittedData.append("private", "true");
    }

    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
        submittedData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((response) => {
        updateFiles();
        setShow(false);
      })
      .catch((error) => {
        console.error(error);
      });

    setValidated(true);
  };

  const updateType = (e) => {
    setForm({
      ...form,
      file_display_name: e.target.value,
      file_label: e.target.value,
    });
  };

  const updateVisibility = (e) => {
    setForm({ ...form, file_public_status: e.target.value });
  };

  const updateLabel = (e) => {
    setForm({ ...form, file_label: e.target.value });
  };

  const updateFile = (e) => {
    setForm({ ...form, file: e.target.files[0] });
  };

  return (
    <Modal
      id={`${file.file_display_name}-replace-modal`}
      size="lg"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h1">Replace: {file.file_name}</Modal.Title>
      </Modal.Header>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Container fluid>
            <Row className="pt-2">
              {/* Form Type */}
              <Col xl={3} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_display_name"
                >
                  <Form.Label className="small text-secondary">
                    Type*
                  </Form.Label>
                  <Form.Select
                    name="file_display_name"
                    value={form.file_display_name}
                    onChange={updateType}
                    required
                  >
                    <option value=""></option>
                    {type === "product"
                      ? documentTypes.product[
                          currentUser.user_role.role_type
                        ]?.map((docType) => (
                          <option key={docType} value={docType}>
                            {docType}
                          </option>
                        ))
                      : documentTypes[type]?.map((docType) => (
                          <option key={docType} value={docType}>
                            {docType}
                          </option>
                        ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              {/* Document Visibility */}
              <Col xl={3} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_public_status"
                >
                  <Form.Label className="small text-secondary">
                    Document Visibility*
                  </Form.Label>
                  <Form.Select
                    name="file_public_status"
                    value={form.file_public_status}
                    onChange={updateVisibility}
                    required
                  >
                    <option value=""></option>
                    <option value="Public">Public</option>
                    <option value="Proprietary">Proprietary</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              {/* File Label */}
              <Col xl={5} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_label"
                  required
                >
                  <Form.Label className="small text-secondary">
                    File Label*
                  </Form.Label>
                  <Form.Control
                    value={form.file_label}
                    type="text"
                    name="file_label"
                    onChange={updateLabel}
                    required
                  ></Form.Control>
                </Form.Group>
              </Col>

              {/* Attach Files */}
              <Col xl={5} sm={12}>
                <Form.Group controlId="file">
                  <Form.Label className="small text-secondary">
                    Attach Files*
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={updateFile}
                    disabled={
                      !form.file_display_name ||
                      !form.file_public_status ||
                      !form.file_label
                    }
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="warning" type="submit">
            Replace
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
