import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useCookies } from "react-cookie";
import PasswordStrengthBar from "react-password-strength-bar";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const ResetPassword = () => {
  const [cookies, setCookies] = useCookies();
  const authToken = cookies["temp_token"];
  const csrfToken = cookies["csrftoken"];
  const [newPassword, setNewPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const { token } = useParams();
  const { state } = useLocation();

  console.log("cookies", cookies);

  const handleChange = (e) => {
    setNewPassword(e.target.value);
  };

  useEffect(() => {
    const loadUser = () => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    };
    if (state?.is_continue) {
      loadUser();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state?.is_continue) {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${user.id}/`,
          { username: user?.username, password: newPassword },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          navigate("/login/success");
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}password-reset/confirm/`,
          { token: token, password: newPassword },
          { withCredentials: true, headers: { "X-CSRFToken": csrfToken } },
        )
        .then((response) => {
          navigate("/login/reset-success");
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        });
    }
  };

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Reset Password</Card.Title>
        <p className="m-4 text-secondary">Please enter your new password.</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="password">
            <Form.Label className="small text-secondary">Password*</Form.Label>
            <Form.Control
              type="password"
              name="password"
              onChange={handleChange}
              required={true}
              placeholder="Enter new password"
            />
            <PasswordStrengthBar password={newPassword} minLength="8" />
          </Form.Group>
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          <Button variant="warning" type="submit">
            Reset Password
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ResetPassword;
