import { Modal, Button } from "react-bootstrap";

const SendToCustomerModal = ({ show, handleClose, custInfo, handleSave }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="border-0">
          Would you like to send an email to {custInfo?.custemail} with QA
          details?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="border-0">
        {/* we are passing parameter sendEmailToCustomer to false */}
        <Button variant="danger" onClick={(e) => handleSave(e, false)}>
          No, Do Not Send Email
        </Button>
        {/* we are passing parameter sendEmailToCustomer to true */}
        <Button variant="success" onClick={(e) => handleSave(e, true)}>
          Yes, Send Email
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendToCustomerModal;
