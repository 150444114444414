import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../../UI/UnauthorizedView";

import FAQCategoriesTable from "./FAQCategoriesTable";
import FAQTable from "./FAQTable";

export default function FAQManager() {
  const permissions = useSelector((state) => state.role.value);

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<FAQTable />} />
      <Route path="categories" element={<FAQCategoriesTable />} />
    </Routes>
  );
}
