import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";

import CctlDashboard from "./DashboardTypes/CctlDashboard";
import NIAPDashboard from "./DashboardTypes/NIAPDashboard";
import SMEDashboard from "./DashboardTypes/SMEDashboard";
import ValidatorDashboard from "./DashboardTypes/ValidatorDashboard";
import VendorDashboard from "./DashboardTypes/VendorDashboard";

export default function Dashboard({
  setOverlayActive,
  setOverlayText,
  selectedDashBoard,
}) {
  const currentRole = useSelector((state) => state.role.value);

  return (
    <Container fluid>
      {(currentRole.role_type === "Lab" || selectedDashBoard === "Lab") && (
        <CctlDashboard
          setOverlayActive={setOverlayActive}
          setOverlayText={setOverlayText}
        />
      )}
      {currentRole.role_type === "NIAP" &&
        (selectedDashBoard === "NIAP" || selectedDashBoard === "") && (
          <NIAPDashboard
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
            roleName={currentRole?.role_name}
          />
        )}
      {(currentRole.role_type === "Validator" ||
        selectedDashBoard === "Validator") && (
        <ValidatorDashboard
          setOverlayActive={setOverlayActive}
          setOverlayText={setOverlayText}
        />
      )}
      {(currentRole.role_type === "Vendor" ||
        selectedDashBoard === "Vendor") && (
        <VendorDashboard
          setOverlayActive={setOverlayActive}
          setOverlayText={setOverlayText}
        />
      )}
      {(currentRole.role_type === "SME" || selectedDashBoard === "SME") && (
        <SMEDashboard
          setOverlayActive={setOverlayActive}
          setOverlayText={setOverlayText}
        />
      )}
    </Container>
  );
}
