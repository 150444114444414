const progressPointInfo = {
  3: {
    title: "ST EVALUATION - PROGRESS POINT 3",
    statusText: "Status of ST Evaluation:",
    fileTypes: ["Security Target", "Other Documents"],
    fileSourceType: "Progress Point 3",
    completionDate: "st_evaluation_date",
  },
  4: {
    title: "TEST READY - PROGRESS POINT 4",
    statusText: "Status of Test Ready:",
    fileTypes: ["Test Reports", "Other Documents"],
    fileSourceType: "Progress Point 4",
    completionDate: "test_ready_date",
  },
  5: {
    title: "TESTING COMPLETE - PROGRESS POINT 5",
    statusText: "Status of Testing Complete:",
    fileTypes: ["Test Reports", "Other Documents"],
    fileSourceType: "Progress Point 5",
    completionDate: "testing_complete_date",
  },
  6: {
    title: "EVALUATOR CHECKOUT - PROGRESS POINT 6",
    statusText: "Status of Evaluator Complete:",
    fileTypes: [
      "Administrative Guide (AGD)",
      "Assurance Activity Report (AAR)",
      "Draft CC Certificate",
      "Evaluation Technical Report",
      "Security Target",
      "Sync Meeting Notes",
      "Test Reports",
      "Draft Validation Report",
      "Check-Out Packages",
      "Other Documents",
    ],
    fileSourceType: "Evaluator Check Out",
    completionDate: "evaluator_checkout_date",
  },
  7: {
    title: "VALIDATOR CHECKOUT - PROGRESS POINT 7",
    statusText: "Status of Validator:",
    fileTypes: [
      "Administrative Guide (AGD)",
      "Assurance Activity Report (AAR)",
      "CC Certificate",
      "Entropy Assessment Report (EAR)",
      "Other Documents",
      "Security Target",
      "Sync Meeting Notes",
      "Test Reports",
      "Validation Report",
      "Check-out Packages (Final Pkg)",
      "Evaluation Technical Report",
      "Other Documents",
    ],
    fileSourceType: "Validator Check Out",
    completionDate: "validator_checkout_date",
  },
  8: {
    title: "PCL POSTING - PROGRESS POINT 8",
    completionDate: "pcl_posting_date",
  },
};

export default progressPointInfo;
