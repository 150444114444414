import TqActionsCell from "./TqActions.cell.jsx";
import TqAssociatedTdsCell from "./TqAssociatedTds.cell.jsx";
import TqAuthorCell from "./TqAuthor.cell.jsx";
import TqColorKeyCell from "./TqColorKey.cell.jsx";
import TqDeadlineCell from "./TqDeadline.cell.jsx";
import TqExtendedTeamsCell from "./TqExtendedTeams.cell.jsx";
import TqFormattedDateCell from "./TqFormattedDate.cell.jsx";
import TqIdCell from "./TqId.cell.jsx";
import TqModifiedByCell from "./TqModifiedBy.cell.jsx";
import TqProductEvaluationCell from "./TqProductEvaluation.cell.jsx";
import TqQuestionCell from "./TqQuestion.cell.jsx";
import TqRequirementCell from "./TqRequirement.cell.jsx";

const tqStatusOptions = {
  Proposed: "Proposed",
  "In Progress": "In Progress",
  Rejected: "Rejected",
  "TD Required": "TD Required",
  "TD Proposed": "TD Proposed",
  "Resolution Proposed": "Resolution Proposed",
  Resolved: "Resolved",
  Accepted: "Accepted",
};

export const buildTqColumns = ({
  id,
  setDeleteTQ,
  setShowDeleteModal,
  groupedTds,
}) => {
  return [
    {
      field: "tq_id",
      title: "ID",
      type: "numeric",
      render: (rowData) => <TqIdCell tq={rowData} />,
    },

    {
      title: "",
      filtering: false,
      sorting: false,
      align: "center",
      render: (rowData) => <TqColorKeyCell tq={rowData} />,
    },

    {
      field: "deadline",
      title: "Deadline",
      type: "date",
      hidden: id,
      render: (rowData) => <TqDeadlineCell tq={rowData} />,
    },
    {
      field: "urgency",
      title: "Priority",
      hidden: id,
    },
    {
      field: "status",
      title: "Status",
      lookup: tqStatusOptions,
    },
    {
      field: "team.team",
      title: "Primary Team",
      hidden: id,
    },
    {
      field: "team.trrt_team",
      title: "Extended Teams",
      hidden: id,
      render: (rowData) => <TqExtendedTeamsCell tq={rowData} />,
    },
    {
      field: "full_requirement",
      title: "Requirement",
      hidden: id,
      render: (rowData) => <TqRequirementCell tq={rowData} />,
    },
    {
      field: "question",
      title: "Question",
      hidden: id,
      render: (rowData) => <TqQuestionCell tq={rowData} />,
    },
    {
      field: "td_id",
      title: "Associated TDs",
      filtering: false,
      sorting: false,
      hidden: id,
      render: (rowData) => (
        <TqAssociatedTdsCell tds={groupedTds[rowData.tq_id]} />
      ),
    },
    {
      field: "title",
      title: "Title",
    },
    {
      field: "submitted_by",
      title: "Author",
      hidden: id,
      render: (rowData) => <TqAuthorCell tq={rowData} />,
    },
    {
      field: "product_evaluation",
      title: "Project Evaluations",
      filtering: false,
      sorting: false,
      hidden: id,
      render: (rowData) => <TqProductEvaluationCell tq={rowData} />,
    },
    {
      field: "date_sent",
      type: "date",
      title: "Date Sent to iTC",
      hidden: id,
      render: (rowData) => <TqFormattedDateCell date={rowData.date_sent} />,
    },
    {
      field: "submitted_on",
      type: "date",
      title: "Submitted",
      hidden: id,
      render: (rowData) => <TqFormattedDateCell date={rowData.submitted_on} />,
    },
    {
      field: "modified_on",
      type: "date",
      title: "Modified",
      hidden: id,
      render: (rowData) => <TqFormattedDateCell date={rowData.modified_on} />,
    },
    {
      field: "modified_by",
      title: "Modified By",
      hidden: id,
      render: (rowData) => <TqModifiedByCell tq={rowData} />,
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      cellStyle: {
        position: "sticky",
        right: "0",
        backgroundColor: "var(--bs-white)",
      },

      headerStyle: {
        position: "sticky",
        right: "0",
        textAlign: "center",
      },
      render: (rowData) => {
        return (
          <TqActionsCell
            tq={rowData}
            setDeleteTQ={setDeleteTQ}
            setShowDeleteModal={setShowDeleteModal}
          />
        );
      },
    },
  ];
};
