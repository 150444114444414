import axios from "axios";
import moment from "moment";
import { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./fileDetails.css";

const FileDetails = () => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const { id } = useParams();
  const [fileData, setFileData] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/${id}`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setFileData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <Container fluid className="p-4">
      <Row>
        <Col xs={12} className="text-center">
          <p className="h6">Dynamic Document Distribution 3D</p>
          <p className="h4">Details for File ID {fileData?.file_id}</p>
        </Col>
      </Row>
      <Row className="py-3">
        <Col xs={12}>
          <table role="presentation" id="fileDetails">
            <tr>
              <th>Approved for Publication:</th>
              <td>{fileData.approved ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <th>Published:</th>
              <td>{fileData.published ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <th>Link Label:</th>
              <td>{fileData.file_display_name}</td>
            </tr>
            <tr>
              <th>File Name for Publication:</th>
              <td>
                {fileData.file_new_name
                  ? fileData.file_new_name
                  : fileData.file_name}
              </td>
            </tr>
            <tr>
              <th>File Name for Internal Use:</th>
              <td>{fileData.file_name}</td>
            </tr>
            <tr>
              <th>Relevant Item:</th>
              <td className="text-capitalize">{fileData.file_type}</td>
            </tr>
            <tr>
              <th>Relevant Item ID:</th>
              <td>{fileData.file_type_id}</td>
            </tr>
            <tr>
              <th>Record Created:</th>
              <td>
                {fileData.uploaded_by?.full_name}{" "}
                {fileData.uploaded_on
                  ? moment
                      .utc(fileData.uploaded_on)
                      .format("YYYY.MM.DD [at] HHmm")
                  : ""}
              </td>
            </tr>
          </table>
        </Col>
      </Row>
      <Row className="py-3">
        <Col xs={12} className="border-top border-bottom border-black">
          <p className="h4 p-2 m-0">Notes</p>
        </Col>
      </Row>
      <Row className="py-3">
        <Col xs={12}>
          <ul>
            <li>
              Published items are those currently accessible to anyone on the
              NIAP site.
            </li>
            <li>
              Link labels are the text presented on-screen as links for the
              visitors to click.{" "}
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default FileDetails;
