const PfaDispositionsCell = ({ file }) => {
  if (file.private) return <p className="m-0">Not Approved for Publication</p>;

  return (
    <p className="m-0">
      {!file.approved && "Not"} Approved <br />
      {!file.published && "Not"} Published <br />
    </p>
  );
};

export default PfaDispositionsCell;
