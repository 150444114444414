import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";

import ReactSelect from "../../ReactSelect";

export default function ExternalLinkForm({
  show,
  handleCloseModal,
  extLink,
  currentUser,
  refetch,
}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [validated, setValidated] = useState(false);
  const [editedExtLink, setEditedExtLink] = useState({ ...extLink });
  const [matchTypeOptions, setMatchTypeOptions] = useState([]);
  const [tidSelectValid, setTidSelectValid] = useState(true);
  const [alert, setAlert] = useState({ message: "", type: "" });

  const handleChange = (e) =>
    setEditedExtLink({ ...editedExtLink, [e.target.name]: e.target.value });

  const loadExtsiteMatchtype = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}extsite/matchtype/get_all_match_types`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        var matchTypes = [];
        response.data.forEach((type) =>
          matchTypes.push({ value: type.tid, label: type.type }),
        );
        setMatchTypeOptions(matchTypes);
      })
      .catch((error) => {
        console.log("error getting match types: ", error);
      });
  };

  useEffect(() => {
    loadExtsiteMatchtype();
  }, []);

  const testSelects = () => {
    if ("tid" in editedExtLink) {
      setTidSelectValid(true);
      return true;
    }
    if (!("tid" in editedExtLink)) {
      setTidSelectValid(false);
      setValidated(false);
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    testSelects();
    if (!form.checkValidity() || testSelects() === false) {
      setAlert({
        message: "Please fill out all required fields",
        type: "danger",
      });
      e.stopPropagation();
    } else {
      let currentTime = new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      );
      if (editedExtLink.esid) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}extsite/${editedExtLink.esid}/`,
            {
              ...editedExtLink,
              creator: editedExtLink.creator.id,
              editor: currentUser.id,
              tid: editedExtLink?.tid?.tid
                ? editedExtLink?.tid.tid
                : editedExtLink.tid,
              moddate: currentTime,
            },
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
                "X-CSRFToken": csrfToken,
              },
            },
          )
          .then(() => {
            refetch();
            handleCloseModal();
            setValidated(true);
          })
          .catch((error) => {
            console.log("Unable to create external site: " + error);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}extsite/`,
            {
              ...editedExtLink,
              creator: currentUser.id,
              editor: currentUser.id,
              entrydate: currentTime,
            },
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
                "X-CSRFToken": csrfToken,
              },
            },
          )
          .then(() => {
            refetch();
            handleCloseModal();
            setValidated(true);
          })
          .catch((error) => {
            console.log("Unable to update external site: " + error);
          });
      }
    }
    setValidated(true);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setEditedExtLink({ ...extLink });
    setValidated(false);
  };

  useEffect(() => {
    if (show) {
      setValidated(false);
      setEditedExtLink({ ...extLink });
      setAlert({ message: "", type: "" });
    }
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={handleCloseModal}>
      <Form
        onSubmit={handleSubmit}
        onReset={handleReset}
        validated={validated}
        noValidate
      >
        <Modal.Header closeButton>
          <Modal.Title>+ NEW SITE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.message !== "" && (
            <Alert
              variant={alert.type}
              className="mt-3"
              dismissible
              onClose={() => setAlert({ message: "", type: "" })}
            >
              {alert.message}
            </Alert>
          )}
          <Form.Group className="mb-3" controlId="site">
            <Form.Label>URL*</Form.Label>
            <Form.Control
              type="text"
              name="site"
              value={editedExtLink?.site ?? ""}
              onChange={handleChange}
              placeholder="URL"
              autoFocus
              required
            />
          </Form.Group>
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="tid">
                <Form.Label>Match Type:*</Form.Label>
                <ReactSelect
                  id={"tid"}
                  name="tid"
                  handleChange={handleChange}
                  options={matchTypeOptions}
                  newObj={editedExtLink}
                  isValid={tidSelectValid}
                  defaultValue={matchTypeOptions?.find(
                    (match) => match.value === extLink?.tid?.tid,
                  )}
                  value={matchTypeOptions?.find(
                    (match) => match.value === editedExtLink?.tid?.tid,
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Label>Active:*</Form.Label>
              <Form.Group controlId="active">
                <Form.Check
                  required
                  type="radio"
                  className="form-check-inline"
                  name="active"
                  label="Yes"
                  value={true}
                  onChange={handleChange}
                  checked={
                    editedExtLink.active === true ||
                    editedExtLink.active === "true"
                  }
                  id="active1"
                />
                <Form.Check
                  required
                  type="radio"
                  className="form-check-inline"
                  name="active"
                  label="No"
                  value={false}
                  onChange={handleChange}
                  checked={
                    editedExtLink.active === false ||
                    editedExtLink.active === "false"
                  }
                  id="active2"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button type="reset" variant="warning">
            Reset
          </Button>
          <Button variant="warning" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
