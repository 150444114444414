import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  Card,
  Alert,
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import HTMLEditor from "../UI/HTMLEditor";

import { answersList } from "./QAAnswersList";
import QAResponsesModal from "./QAResponses";
import QAUpdatesSection from "./QAUpdates/QAUpdatesSection";

export default function QADetailsAccordions({
  qa,
  refetch,
  setDetailPageAlert,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const { id } = useParams();
  const user = useSelector((state) => state.user.value);
  const [editedHtmlFields, setEditedHtmlFields] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [editedSelectedAnswer, setEditedSelectedAnswer] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const currentUser = useSelector((state) => state.user.value);
  const [qaHistory, setQaHistory] = useState([]);
  const [qaUpdates, setQaUpdates] = useState([]);
  const [latestResponse, setLatestResponse] = useState({});
  const [alert, setAlert] = useState({ message: "", variant: "" });

  const defaultData = `
    <p>Item Details:<br />
    Number: ${qa?.ticket} <br />
    Customer:  ${qa?.custuname} <br />
    Date Received: ${moment.utc(qa?.entrydate).format("MM/DD/YYYY")} </p>

    <p>Question:</p>
    <p>${qa?.responses?.length > 0 ? qa.responses[qa?.responses?.length - 1]?.req : qa?.req}</p>

    <p>Thank you,<br />
    NIAP Team</p>

    <p>******************************************************************<br />
    (U) This email and any files transmitted with it are intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error please notify the sender.<br />
    (U) The information contained herein that is marked (U//FOUO) is for the exclusive use of Government and Contractor personnel with a need-to-know for NIAP CCEVS information. Such information is specifically prohibited from posting on unrestricted bulletin boards or other unlimited access applications.<br />
    ******************************************************************</p>
    `;

  useEffect(() => {
    if (qa?.rid && selectedAnswer === "") {
      setSelectedAnswer(defaultData);
    }
  }, [qa?.rid]);

  const fetchQaHistory = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/history/get_qa_history/?rid=${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setQaHistory(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchQaHistory();
  }, []);

  const fetchQaUpdates = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/update/get_updates_by_rid/?rid=${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setQaUpdates(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchQaUpdates();
  }, []);

  const fetchLatestDraftResponse = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/get_latest_saved_draft_response/?rid=${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setLatestResponse(response.data);
      })
      .catch((error) => console.log(error));
  };

  const handleAnswerChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = answersList.find(
      (option) => option.id === Number(selectedValue),
    );
    if (selectedOption) {
      setSelectedAnswer(selectedOption.description + defaultData);
    } else {
      setSelectedAnswer("");
    }
  };

  const handleQuestionChange = (event) => {
    setCurrentQuestion({
      ...currentQuestion,
      [event.target.name]: event.target.value,
    });
  };

  const buildQuestionsList = (qa, questions) => {
    questions.push({
      rid: qa.rid,
      req: qa.req,
      answer: qa.answer,
      custemail: qa.custemail,
      cid: qa?.cid__cat,
    });
    if (qa.responses) {
      qa.responses.forEach((item) => {
        buildQuestionsList(item, questions);
      });
    }
  };

  useEffect(() => {
    const questions = [];
    buildQuestionsList(qa, questions);
    setQuestionsList(questions);
  }, [qa]);

  const handleChange = (e) => {
    const answerText = e.target.value;
    setEditedHtmlFields({
      ...editedHtmlFields,
      [e.target.name]: e.target.value,
    });
    setEditedSelectedAnswer(answerText);
  };

  const handleSave = () => {
    let current_id = qa?.rid;
    if (currentQuestion.in_response_to) {
      current_id = currentQuestion.in_response_to;
    }
    let withResponse = "";
    if (latestResponse?.answer) {
      withResponse = latestResponse.answer;
    } else {
      withResponse = editedSelectedAnswer;
      withResponse += `<p><a href=${process.env.REACT_APP_REACT_ENDPOINT}question-answer/response/${current_id}/?send_to=${qa.custemail}>Send a Response</a></p>`;
    }
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/${current_id}/`,
        {
          status: "In Progress",
          answer: withResponse,
          recaptcha_token: "member_portal",
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        refetch();
        fetchQaHistory();
        setAlert({
          message: "Draft response saved successfully!",
          variant: "success",
        });
      })
      .catch((error) => {
        console.log("error updating TQ details", error);
      });
  };

  const handleSendToCustomer = () => {
    let withResponse = "";
    let current_id = qa?.rid;
    if (latestResponse?.answer) {
      withResponse = latestResponse.answer;
      current_id = latestResponse.rid;
    } else {
      withResponse = editedSelectedAnswer;
    }
    if (window.location.href?.includes("https://www.niap-ccevs.org/")) {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}mailer/`,
          {
            subject: `Q&A eQuery ${moment.utc().format("YYYY")}-${qa?.ticket} Response`,
            message: withResponse,
            sender: user?.id,
            recipients: qa?.custemail,
            from_email: "no-reply@niap-ccevs.org",
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then(() => {
          axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/${current_id}/`,
              {
                status: "Completed",
                answer: withResponse,
                enddate: new Date(
                  new Date().setMinutes(
                    new Date().getMinutes() - new Date().getTimezoneOffset(),
                  ),
                ),
                recaptcha_token: "member_portal",
              },
              {
                withCredentials: true,
                headers: {
                  Authorization: `Token ${authToken}`,
                  "X-CSRFToken": csrfToken,
                },
              },
            )
            .then(() => {
              setModalShow(false);
              fetchQaHistory();
              refetch();
            })
            .catch((error) => {
              console.log("error updating QA details", error);
            });
        })
        .catch((error) => {
          console.log("Unable to submit TQ send to customer request: " + error);
        });
    }
  };

  const handleSaveQuestionInput = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/${id}/`,
        {
          req: editedHtmlFields?.req,
          custemail: qa?.custemail ? qa.custemail : user?.email,
          custuname: qa?.custuname
            ? qa.custuname
            : user?.first_name + " " + user?.last_name,
          recaptcha_token: "member_portal",
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        refetch();
        fetchQaHistory();
        setDetailPageAlert({
          message: "Question saved successfully!",
          type: "success",
        });
      })
      .catch((error) => {
        console.log("error updating qa details", error);
        setDetailPageAlert({
          message: "Error saving question.",
          type: "danger",
        });
      });
  };

  const childQuestions = (question) => {
    return (
      <div className="custom-responsive-class p-xs-2 border border-secondary p-md-4 m-md-4">
        <p className="text-end">Question by: {question.custuname} </p>
        <p>
          Question: <br /> {question.req}
        </p>
        <div>
          Answer: <br />
          {question.answer && parse(question.answer)}
        </div>
        {question.responses && (
          <Accordion>
            <Accordion.Item eventKey={question.rid}>
              <Accordion.Header>
                {question.responses.length} Responses
              </Accordion.Header>
              <Accordion.Body>
                {question.responses?.map((child) => (
                  <div key={child.rid}>{childQuestions(child)}</div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
    );
  };

  const displayQA = () => {
    return (
      <div key={qa.rid}>
        <div className="custom-responsive-class p-xs-2 border border-secondary p-md-4 m-md-4">
          <p className="text-end">Question by: {qa.custuname} </p>
          <p>
            Question: <br /> {qa?.req ? parse(qa.req) : ""}
          </p>
          {qa.answer && (
            <div>
              Answer: <br />
              {parse(qa.answer)}
            </div>
          )}
          {qa.responses && (
            <Accordion>
              <Accordion.Item eventKey={qa.rid}>
                <Accordion.Header>
                  {qa.responses.length} Responses
                </Accordion.Header>
                <Accordion.Body>
                  {qa.responses?.map((child) => (
                    <div key={child.rid}>{childQuestions(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
    );
  };

  const responseTypes = [
    { value: 1, text: "NIAP does not approve products" },
    { value: 2, text: "NIAP does not sell products" },
    { value: 3, text: "Up to the organization's Authorizing Official (AO)" },
    { value: 4, text: "Product not evaluated, encourage vendor to submit" },
    { value: 5, text: "NIAP no longer accepts EAL-based evaluations" },
    { value: 6, text: "Archived Products" },
    { value: 7, text: "Assurance Maintenance" },
    { value: 8, text: "NIAP certifies products…" },
  ];

  return (
    <Container>
      <Accordion
        alwaysOpen
        defaultActiveKey={["0", "1"]}
        className="border-top"
      >
        <Accordion.Item eventKey="0" className="border-bottom-dark">
          <Accordion.Header className="bold-header">
            <strong>QUESTION AND ANSWER</strong>
          </Accordion.Header>
          {qa?.req !== null ? (
            <Accordion.Body className="p-0">
              <Container>
                <Row className="mb-3">
                  <Col className="custom-responsive-class">
                    <Card className="custom-responsive-class mb-sm-3 me-md-3 border-0">
                      <Card.Body className="custom-responsive-class">
                        {displayQA()}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {qa?.stid__status !== "Cancelled" && qa.answer && (
                  <Row className="justify-content-end mb-3">
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="success"
                        onClick={() => {
                          fetchLatestDraftResponse();
                          setModalShow(true);
                        }}
                        disabled={qa?.stid__status === "Completed"}
                      >
                        {qa?.stid__status === "Completed"
                          ? "RESPONSE SENT TO CUSTOMER"
                          : "Save & Send to Customer"}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
            </Accordion.Body>
          ) : (
            <Accordion.Body className="p-0">
              <Container className="m-3">
                <Form>
                  <Row>
                    <Form.Group controlId="req">
                      <Form.Label>Question:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        name="req"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="d-flex justify-content-end mt-2">
                    <Col sm={12} md={2} className="w-auto">
                      <Button
                        variant="primary"
                        onClick={handleSaveQuestionInput}
                        disabled={!editedHtmlFields.req}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Accordion.Body>
          )}
        </Accordion.Item>
        {qa?.stid__status !== "Cancelled" && (
          <Accordion.Item
            eventKey="1"
            active="true"
            className="border-bottom-dark"
          >
            <Accordion.Header className="fw-bold " as="h6">
              <strong>DRAFT RESPONSE</strong>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <Container className="my-3">
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Row className="align-items-center">
                          <Col sm={7}>
                            <Form className="my-3 border-0">
                              <Form.Select
                                defaultValue=""
                                onChange={handleAnswerChange}
                                data-testid="response-select"
                              >
                                <option disabled value="">
                                  Select a response...
                                </option>
                                {responseTypes.map((response, idx) => (
                                  <option key={idx} value={response.value}>
                                    {response.text}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form>
                          </Col>
                          <Col md={5}>
                            <Form.Select
                              defaultValue=""
                              onChange={handleQuestionChange}
                              name="in_response_to"
                              data-testid="question-select"
                            >
                              <option disabled value="">
                                Select a question...
                              </option>
                              {questionsList?.map((question, idx) => (
                                <option key={idx} value={question.rid}>
                                  {question?.req ? parse(question?.req) : ""}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                        <HTMLEditor
                          className="my-2"
                          name={""}
                          handleChange={handleChange}
                          setIsValid={() => {}}
                          isValid={true}
                          defaultValue={selectedAnswer}
                        />
                      </Col>
                    </Row>
                    {alert?.message && (
                      <Row>
                        <Alert
                          variant={alert?.variant}
                          dismissible
                          onClose={() => setAlert({ message: "", variant: "" })}
                        >
                          {alert.message}
                        </Alert>
                      </Row>
                    )}
                    <Row className="d-flex justify-content-end mt-4">
                      <Col sm={12} md={2} className="w-auto">
                        <Button
                          onClick={() => {
                            handleSave();
                          }}
                          disabled={!editedSelectedAnswer}
                          variant="primary"
                        >
                          Save and Update Question Response
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        )}
        <Accordion.Item eventKey="2" active="true">
          <Accordion.Header className="fw-bold " as="h6">
            <strong>HISTORY</strong>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <Container className="my-3">
              {qaHistory?.map((history, idx) => (
                <Container key={idx} className="border m-3">
                  <Row className="mt-2">
                    <Col className="text-start">
                      <h5>
                        Updated By: {history?.updated_by?.first_name}{" "}
                        {history?.updated_by?.last_name}
                      </h5>
                    </Col>
                    <Col className="text-end">
                      <h5>
                        Updated On:{" "}
                        {history?.updated_on
                          ? moment
                              .utc(history?.updated_on)
                              .format("YYYY.MM.DD [at] HHmm")
                          : "N/A"}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="fw-bold">Question:</p>
                      <p>{history?.question ? parse(history?.question) : ""}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="fw-bold">Answer:</p>
                      <p>{history?.answer ? parse(history?.answer) : ""}</p>
                    </Col>
                  </Row>
                </Container>
              ))}
            </Container>
          </Accordion.Body>
        </Accordion.Item>
        <QAUpdatesSection
          qaUpdates={qaUpdates}
          qa={qa}
          currentUser={currentUser}
          refetch={fetchQaUpdates}
        />
      </Accordion>
      {latestResponse?.answer && (
        <QAResponsesModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          response={latestResponse.answer}
          handleSendToCustomer={handleSendToCustomer}
        />
      )}
    </Container>
  );
}
