import { Row, Col, Form } from "react-bootstrap";

export default function QuestionsFormSection({
  formData,
  currentFeedbackInfo,
  handleChange,
  viewOnly,
}) {
  return (
    <Row>
      <ol className="mx-3">
        <li>
          <p>
            # of ECRs Total:{" "}
            {formData?.ecr_total
              ? formData?.ecr_total
              : currentFeedbackInfo?.counts?.all_ecrs}
          </p>
        </li>
        <li>
          <p>
            # of ECRs submitted and/or resolved after check-in (PP2):{" "}
            {formData?.ecrs_after_pp2
              ? formData?.ecrs_after_pp2
              : currentFeedbackInfo?.counts?.checkout_ecrs}
          </p>
        </li>
        <li>
          <p>
            # of Check-out packages submitted:{" "}
            {formData?.check_out_package_total
              ? formData?.check_out_package_total
              : currentFeedbackInfo?.counts?.checkout_package_submissions}
          </p>
        </li>
        <li>
          <p>
            Is this a VID Resubmission:{" "}
            {formData?.is_resubmission
              ? formData?.is_resubmission === true
                ? "Yes"
                : "No"
              : currentFeedbackInfo?.product_id?.is_resubmission === true
                ? "Yes"
                : "No"}
          </p>
        </li>
        <Row>
          <Col md={7}>
            <li>
              <p>
                Did the validation team have 2 weeks to review the package
                before termination:*
              </p>
            </li>
          </Col>
          <Col>
            <Form.Group controlId="validation_team_two_weeks" inline>
              <Form.Check
                type="radio"
                label="Yes"
                name="validation_team_two_weeks"
                value={true}
                defaultChecked={
                  currentFeedbackInfo?.validation_team_two_weeks === true
                }
                onChange={handleChange}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                required
                inline
                id="validation_team_two_weeks1"
              />
              <Form.Check
                type="radio"
                label="No"
                name="validation_team_two_weeks"
                value={false}
                defaultChecked={
                  currentFeedbackInfo?.validation_team_two_weeks === false
                }
                onChange={handleChange}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                required
                className="mx-5"
                inline
                id="validation_team_two_weeks2"
              />
            </Form.Group>
          </Col>
        </Row>
        {(currentFeedbackInfo?.validation_team_two_weeks === false ||
          formData?.validation_team_two_weeks === false) && (
          <Row className="w-50 mx-4">
            <Col>
              <p>If no, please include how many days were available?*</p>
            </Col>
            <Col>
              <Form.Group controlId="validation_team_days">
                <Form.Control
                  type="number"
                  name="validation_team_days"
                  placeholder="Enter Day Amount"
                  defaultValue={currentFeedbackInfo?.validation_team_days}
                  onChange={handleChange}
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  min="0"
                  required={formData?.validation_team_two_weeks === false}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={7}>
            <li>
              <p>Did the VID miss any TDs?*</p>
            </li>
          </Col>
          <Col>
            <Form.Group controlId="miss_tds" inline>
              <Form.Check
                type="radio"
                label="Yes"
                name="miss_tds"
                value={true}
                defaultChecked={currentFeedbackInfo?.miss_tds === true}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                onChange={handleChange}
                required
                inline
                id="miss_tds1"
              />
              <Form.Check
                type="radio"
                label="No"
                name="miss_tds"
                value={false}
                defaultChecked={currentFeedbackInfo?.miss_tds === false}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                onChange={handleChange}
                required
                className="mx-5"
                inline
                id="miss_tds2"
              />
            </Form.Group>
          </Col>
        </Row>
        {(currentFeedbackInfo?.miss_tds === true ||
          formData?.miss_tds === true) && (
          <Row className="w-50 mx-4">
            <Col>
              <p>If yes, please list the ECR numbers supporting this:*</p>
            </Col>
            <Col>
              <Form.Group controlId="miss_tds_ecrs">
                <Form.Control
                  type="text"
                  name="miss_tds_ecrs"
                  onChange={handleChange}
                  placeholder="Enter ECR Numbers"
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  defaultValue={currentFeedbackInfo?.miss_tds_ecrs}
                  required={formData?.miss_tds === true}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={7}>
            <li>
              <p>Did the VID misinterpret any policies?*</p>
            </li>
          </Col>
          <Col>
            <Form.Group controlId="misinterpret_policy" inline>
              <Form.Check
                type="radio"
                label="Yes"
                name="misinterpret_policy"
                value={true}
                defaultChecked={
                  currentFeedbackInfo?.misinterpret_policy === true
                }
                onChange={handleChange}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                required
                inline
                id="misinterpret_policy1"
              />
              <Form.Check
                type="radio"
                label="No"
                name="misinterpret_policy"
                value={false}
                defaultChecked={
                  currentFeedbackInfo?.misinterpret_policy === false
                }
                onChange={handleChange}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                required
                className="mx-5"
                inline
                id="misinterpret_policy2"
              />
            </Form.Group>
          </Col>
        </Row>
        {(currentFeedbackInfo?.misinterpret_policy === true ||
          formData?.misinterpret_policy === true) && (
          <Row className="w-50 mx-4">
            <Col>
              <p>
                If yes, please indicate which policy(ies) (e.g., Labgram 116,
                policy 17):*
              </p>
            </Col>
            <Col>
              <Form.Group controlId="misinterpreted_policies">
                <Form.Control
                  type="text"
                  name="misinterpreted_policies"
                  defaultValue={currentFeedbackInfo?.misinterpreted_policies}
                  placeholder="Enter Policy Numbers"
                  onChange={handleChange}
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  required={formData?.misinterpret_policy === true}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={7}>
            <li>
              <p>Did the VID fail to perform required test(s):*</p>
            </li>
          </Col>
          <Col>
            <Form.Group controlId="perform_tests" inline>
              <Form.Check
                type="radio"
                label="Yes"
                name="perform_tests"
                value={true}
                defaultChecked={currentFeedbackInfo?.perform_tests === true}
                onChange={handleChange}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                required
                inline
                id="perform_tests1"
              />
              <Form.Check
                type="radio"
                label="No"
                name="perform_tests"
                value={false}
                defaultChecked={currentFeedbackInfo?.perform_tests === false}
                onChange={handleChange}
                style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                required
                className="mx-5"
                inline
                id="perform_tests2"
              />
            </Form.Group>
          </Col>
        </Row>
        {formData?.perform_tests === true && (
          <Row className="w-50 mx-4">
            <Col>
              <p>If yes, please indicate the ECR numbers supporting this:*</p>
            </Col>
            <Col>
              <Form.Group controlId="perform_tests_ecrs">
                <Form.Control
                  type="text"
                  name="perform_tests_ecrs"
                  onChange={handleChange}
                  defaultValue={currentFeedbackInfo?.perform_tests_ecrs}
                  style={viewOnly ? { pointerEvents: "none", opacity: 1 } : {}}
                  placeholder="Enter ECR Numbers"
                  required={formData?.perform_tests === true}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </ol>
    </Row>
  );
}
