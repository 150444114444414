import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useInterval } from "../../../hooks/useInterval";

import TDDetailsAccordions from "./TDDetailsAccordions";
import TDDetailsHeader from "./TDDetailsHeader";
import TechDecisionSideInfo from "./TechDecisionSideInfo";

const CommunityTechDecisionDetails = () => {
  const currentUser = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const { id } = useParams();
  const [techDecision, setTechDecision] = useState({});
  const [techDecisionHistory, setTechDecisionHistory] = useState([]);
  const [votes, setVotes] = useState([]);

  const fetchTechDecision = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/by_identifier/${id}/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        let modTd = response.data;
        modTd.related_to = modTd.related_to ? modTd.related_to?.split(",") : [];
        setTechDecision(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const fetchTechDecisionHistory = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/history/history_by_td/?identifier=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setTechDecisionHistory(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const getVotes = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/voting/td_votes_by_td_id/?td_id=${techDecision.td_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setVotes(response.data); //we set all the votes
      })
      .catch((error) => console.log("Unable to get votes: " + error));
  };

  useEffect(() => {
    if (techDecision.td_id) {
      getVotes();
    }
  }, [techDecision.td_id]);

  useEffect(() => {
    fetchTechDecision();
    fetchTechDecisionHistory();
  }, []);

  useInterval(() => {
    fetchTechDecision();
    fetchTechDecisionHistory();
    getVotes();
  }, 600000); //1 min

  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={8} lg={9} className="p-0">
          <TDDetailsHeader
            techDecision={techDecision}
            refetch={() => {
              fetchTechDecision();
              fetchTechDecisionHistory();
            }}
            currentUser={currentUser}
            permissions={permissions}
          />
          <TDDetailsAccordions
            techDecision={techDecision}
            refetch={() => {
              fetchTechDecision();
              fetchTechDecisionHistory();
            }}
            techDecisionHistory={techDecisionHistory}
            currentUser={currentUser}
            permissions={permissions}
            votes={votes}
            getVotes={getVotes}
          />
        </Col>
        <Col sm={12} md={4} lg={3}>
          <TechDecisionSideInfo techDecision={techDecision} votes={votes} />
        </Col>
      </Row>
    </Container>
  );
};

export default CommunityTechDecisionDetails;
