import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import ProjectFiles from "../../ProjectFiles";
import UnauthorizedView from "../../UI/UnauthorizedView";

import PPDetails from "./PPDetails";
import PPTable from "./PPTable";

export default function PPManager({ setOverlayActive, setOverlayText }) {
  const permissions = useSelector((state) => state.role.value);

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Protection Profile",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<PPTable />} />
      <Route
        path="details/:id"
        element={
          <PPDetails
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
      <Route
        path="details/:id/:type/files"
        element={
          <ProjectFiles
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
    </Routes>
  );
}
