import moment from "moment";

export function getDaysSinceStartDate(rowData) {
  const startDate = moment(rowData?.current_assurance_maintenance?.entrydate);
  const endDate = rowData?.current_assurance_maintenance?.completion_date
    ? moment(rowData?.current_assurance_maintenance?.completion_date)
    : moment();
  const daysSinceStartDate = endDate.diff(startDate, "days");
  return daysSinceStartDate;
}
