import { useState, useEffect } from "react";
import Select from "react-select";
import "./ReactSelect.css";

export default function ReactSelect({
  isMulti,
  options,
  newObj,
  handleChange,
  name,
  isValid,
  defaultValue,
  editComponent,
  id,
  readOnly,
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue ?? "");

  useEffect(() => {
    if (!(name in newObj)) {
      setSelectedValue(null);
    } else if (editComponent) {
      const updatedValues = newObj[name];

      if (Array.isArray(updatedValues)) {
        setSelectedValue(
          options.filter((option) => updatedValues.includes(option.value)),
        );
      } else {
        setSelectedValue(
          options.find((option) => option.value === updatedValues),
        );
      }
    } else {
      const updatedValue = options.find(
        (option) => option.value === newObj?.name,
      );

      setSelectedValue(updatedValue);
    }
  }, [newObj, editComponent, name, options]);

  const checkColor = () => {
    if (isValid) {
      return "#cccccc";
    } else if (
      !(name in newObj) ||
      newObj[name] === "" ||
      newObj[name] === null ||
      newObj[name] === undefined
    ) {
      return "red";
    } else if (name in newObj || newObj[name] !== "") {
      return "#078c18";
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Select
      inputId={id}
      isMulti={isMulti}
      isSearchable={true}
      options={options}
      isDisabled={readOnly ?? false}
      onChange={(e) => {
        if (isMulti) {
          let valuesArr = [];
          e.map((val) => valuesArr.push(val.value));
          handleChange({ target: { name: `${name}`, value: valuesArr } });
        } else {
          handleChange({ target: { name: `${name}`, value: e.value } });
        }
      }}
      value={selectedValue}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          borderColor: checkColor(),
        }),
      }}
    />
  );
}
