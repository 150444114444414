import { Button, Modal } from "react-bootstrap";

const TrainingModal = ({ show, handleClose }) => {
  const getTrainingData = () => {
    if (window.location.pathname.includes("products/details")) {
      return <p>Here would be the product body</p>;
    } //else if
    //else
    //etc
  };

  //you can also update the title too based on the path, but I am not sure what you had in mind so left it Training

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Training</Modal.Title>
      </Modal.Header>
      <Modal.Body>{getTrainingData()}</Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TrainingModal;
