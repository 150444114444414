import { ButtonGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Filters = ({
  isArchivedView,
  setIsArchivedView,
  handleShowModal,
  role,
}) => {
  return (
    <Row className="my-2">
      <Col sm={12} md={6} className="mb-3 mb-md-0">
        <Button
          variant={!isArchivedView && "primary"}
          className="me-2 border-primary rounded-pill"
          onClick={(e) => setIsArchivedView(false)}
        >
          Active
        </Button>
        <Button
          variant={isArchivedView && "primary"}
          className="border-primary rounded-pill"
          onClick={(e) => setIsArchivedView(true)}
        >
          Archived
        </Button>
      </Col>
      {role?.role_permissions?.find(
        (permision) => permision.area === "Organization",
      )?.create && (
        <Col sm={12} md={6} className="text-end">
          <Button
            variant="primary"
            className="me-3 rounded-0 w-sm-100"
            onClick={(e) => handleShowModal(e, {})}
          >
            + Add New Organization
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default Filters;
