import parse from "html-react-parser";

const TqQuestionCell = ({ tq }) => {
  if (!tq.question) return null;
  return (
    <div
      style={{
        maxHeight: 40,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {tq?.question && parse(tq?.question)}
    </div>
  );
};
export default TqQuestionCell;
