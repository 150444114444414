import axios from "axios";
import moment from "moment";
import { useEffect, useState, useRef, useCallback } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import ReCAPTCHA from "react-google-recaptcha";

import { getCurrentDate } from "../../../../utils/timeUtils";
import { handleAlertAndMailer } from "../../../Products/Helper/functions";
import ReactSelect from "../../../ReactSelect";

import PCMModalFileSection from "./PCMModalFileSection";

export default function ProposeInternationalProductModal({
  showModal,
  handleShowModal,
  setAlert,
  currentUser,
  refetch,
  pcm,
  setOverlayActive,
  setOverlayText,
}) {
  const [schemes, setSchemes] = useState([]);
  const [editedPcm, setEditedPcm] = useState({});
  const [validated, setValidated] = useState(false);
  const [schemeValid, setSchemeValid] = useState(false);
  const [productValid, setProductValid] = useState(true);
  // const [labValid, setLabValid] = useState(true);
  const [invalidToken, setInvalidToken] = useState(false);
  const [files, setFiles] = useState([]);
  const [formAlert, setFormAlert] = useState({ message: "", type: "" });
  const [cookies] = useCookies();
  const [ccProducts, setCCProducts] = useState([]);
  const [ccLabs, setCCLabs] = useState([]);
  const [pcmPps, setPcmPps] = useState([]);
  const [isMaintenance, setIsMaintenance] = useState(false);
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const siteKey = "6Lf29aAjAAAAAIagG9XUSNUz_nXKnwxrK_fDcGf5";
  const recaptchaRef = useRef(null);

  const loadSchemes = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/scheme/get_all_schemes`,
      )
      .then((response) => {
        let mappedSchemes = response.data
          ?.filter(
            (scheme) => scheme?.cid__code2_iso !== "US" && scheme?.producer,
          )
          ?.map((scheme) => ({ label: scheme?.cid__name, value: scheme }));
        setSchemes(mappedSchemes);
      })
      .catch((error) => console.log(error));
  }, []);

  const loadCCProductExistingSchemes = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/get_grouped_ccp_schemes`,
      )
      .then((response) => {
        let mappedSchemes = response.data?.map((scheme) => ({
          label: scheme?.cid__name,
          value: scheme,
        }));
        setSchemes(mappedSchemes);
      })
      .catch((error) => console.log(error));
  }, []);

  const getCCPortalProducts = (scheme, maintenance) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/get_cc_portal_products/?scheme=${scheme}&is_maintenance=${maintenance}`,
      )
      .then((response) => {
        setCCProducts(
          response?.data?.map((product) => {
            return {
              label: product["Name"],
              value: product,
              scheme: product["Scheme"],
            };
          }),
        );
      })
      .catch((error) =>
        console.log("Error getting CC Portal Products: ", error),
      );
  };

  const getCCPortalLabs = (scheme) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/get_cc_portal_labs/?scheme=${scheme}`,
      )
      .then((response) => {
        setCCLabs(
          response?.data?.map((lab) => {
            return { label: lab?.cctl_name, value: lab?.cctl_id };
          }),
        );
      })
      .catch((error) => console.log("Error getting CC Portal Labs: ", error));
  };

  const getPcmPps = useCallback(() => {
    if (pcm?.rid) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/pp/get_pps_by_pcm/?pcm=${pcm?.rid}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setPcmPps(response.data);
        })
        .catch((error) => setPcmPps([]));
    }
  }, [pcm?.rid]);

  useEffect(() => {
    if (showModal) {
      if (pcm?.maintenance_id !== null && pcm?.maintenance_id !== undefined) {
        loadCCProductExistingSchemes();
        setIsMaintenance(true);
        getCCPortalProducts(pcm?.nsid?.cid__code2_iso, true);
        getCCPortalLabs(pcm?.nsid?.cid__code2_iso);
      } else {
        loadSchemes();
      }
    }
  }, [showModal, pcm?.maintenance_id]);

  useEffect(() => {
    if (showModal) {
      if (editedPcm?.nsid?.cid__code2_iso) {
        getCCPortalProducts(editedPcm?.nsid?.cid__code2_iso, isMaintenance);
        getCCPortalLabs(editedPcm?.nsid?.cid__code2_iso);
      } else if (pcm?.nsid?.cid?.code2_iso) {
        getCCPortalProducts(pcm?.nsid?.cid?.code2_iso, isMaintenance);
        getCCPortalLabs(pcm?.nsid?.cid?.code2_iso);
      }
    }
  }, [showModal, pcm?.nsid, editedPcm?.nsid, isMaintenance]);

  useEffect(() => {
    if (showModal) {
      setEditedPcm({});
      setFiles([]);
      setSchemeValid(true);
      setProductValid(true);
      // setLabValid(true)
      setValidated(false);
      setFormAlert({ message: "", type: "" });
      setInvalidToken(false);
      getPcmPps();
      if (isMaintenance) {
        loadCCProductExistingSchemes();
      } else {
        loadSchemes();
      }
    }
  }, [showModal, isMaintenance]);

  const handleChange = (e) => {
    if (e.target.name === "is_maintenance") {
      setIsMaintenance(e.target.checked);
    } else {
      setEditedPcm({ ...editedPcm, [e.target.name]: e.target.value });
    }
  };

  const showCaptcha = () => {
    // Regenerate recaptcha on opening modal
    try {
      window.grecaptcha.render("form-recaptcha", {
        sitekey: siteKey,
        ref: recaptchaRef,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const sharePCMUpdateWithCustomer = async (note) => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
        {
          subject: `PCM Request - "${pcm?.ticket}" Update Shared`,
          recipients: { to: ["Customer"], cc: ["NIAP Management"] },
          note: note?.note1,
          alert_type: "PCM",
          alert_text: `PCM Request - ${pcm?.ticket} Update Shared`,
          alert_type_id: pcm?.rid,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        console.log("email sent");
      })
      .catch((error) =>
        console.log("Unable to create alert and email.", error),
      );
  };

  const handleSave = async (e) => {
    e.preventDefault();
    //only sending up changes to editted form with editedPcm
    let data = {
      ...editedPcm,
      recaptcha_token: "member_portal",
      existing_pcm: pcm?.rid,
    };
    if (editedPcm?.nsid) {
      data["nsid"] = editedPcm?.nsid;
    }
    if (editedPcm?.prod) {
      data["prod"] = editedPcm?.prod?.Name;
      data["categories"] = editedPcm?.prod?.productType;
      data["pdf_cert"] = editedPcm?.prod?.PDF_Cert;
      data["pdf_st"] = editedPcm?.prod?.PDF_ST;
    }
    if (editedPcm?.vendor_id) {
      data["vendor"] = editedPcm?.vendor_id;
    }
    if (editedPcm?.assigned_lab) {
      data["lab"] = editedPcm?.assigned_lab;
    }
    if (isMaintenance) {
      if (pcm?.ccid && !editedPcm?.prod?.ID) {
        data["maintenance_id"] = pcm?.ccid;
      } else {
        data["maintenance_id"] = editedPcm?.prod?.ID;
      }
    }
    if (editedPcm.deadline) {
      data["deadline"] = new Date(data.deadline);
    }
    await axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/create_pcm/`, data, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
        },
      })
      .then((response) => {
        if (response.data?.conid && files.length > 0) {
          handleUploadFiles(response.data?.conid);
        }
        if (editedPcm?.update) {
          axios
            .post(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/update/`,
              {
                rid: pcm?.rid,
                note1: editedPcm?.update,
                shared: editedPcm?.send_response ?? false,
                creator: currentUser?.id,
                entrydate: new Date(
                  new Date().setMinutes(
                    new Date().getMinutes() - new Date().getTimezoneOffset(),
                  ),
                ),
              },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then((response) => {
              if (editedPcm?.send_response === true) {
                sharePCMUpdateWithCustomer(response.data);
              }
            })
            .catch((error) => alert("Unable to add note: " + error));
        }
        refetch();
        handleShowModal();
        handleReset(e);
        if (setAlert) {
          setAlert({ message: "PCM Updated!", type: "success" });
        }
      })
      .catch((error) => {
        console.log("unable to set ticket", error);
        handleShowModal();
        if (setAlert) {
          setAlert({ message: "Error Updating PCM.", type: "danger" });
        }
      });
  };

  const testFormFields = () => {
    let testsPass = true;
    if (!("nsid" in editedPcm) && !pcm) {
      setSchemeValid(false);
      testsPass = false;
    } else {
      setSchemeValid(true);
    }
    if (!("prod" in editedPcm) && !pcm) {
      setProductValid(false);
      testsPass = false;
    } else {
      setProductValid(true);
    }
    // if(!('assigned_lab' in editedPcm) && !pcm) {
    //     setLabValid(false);
    //     testsPass = false;
    // } else {
    //     setLabValid(true)
    // }
    if (!currentUser && !pcm) {
      if (window.grecaptcha.getResponse() === "") {
        setInvalidToken(true);
        testsPass = false;
      } else {
        setInvalidToken(false);
      }
    }
    let missingFiles = false;

    if (!isMaintenance) {
      if (
        !files?.some(
          (file) => file.file_display_name === "Administrative Guide (AGD)",
        )
      ) {
        missingFiles = true;
      }
      if (
        !files?.some(
          (file) =>
            file.file_display_name === "Assurance Activity Report (AAR)",
        )
      ) {
        missingFiles = true;
      }

      if (missingFiles && !pcm) {
        testsPass = false;
        setFormAlert({
          message:
            "Missing required files. Please include an Administrative Guide (AGD) and Assurance Activity Report (AAR) in order to submit.",
          type: "danger",
        });
      } else {
        setFormAlert({
          message: "",
          type: "",
        });
      }
    }

    if (!testsPass) {
      return false;
    }
  };

  const handleUploadFiles = (conid) => {
    setOverlayActive(true);
    setOverlayText("Uploading Files");
    for (let f in files) {
      const submittedData = new FormData();
      if (!files[f].file_mime_type) {
        submittedData.append("file", files[f].file);
        submittedData.append("file_type_id", conid);
        submittedData.append("file_type", "ccp");
        submittedData.append("file_display_name", files[f].file_display_name);
        submittedData.append("file_name", files[f].file_name);
        submittedData.append("file_label", files[f].file_label);
      }
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/upload_frontend_files/`,
          submittedData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then((response) => {
          console.log("file uploaded");
          setOverlayActive(false);
          setOverlayText("");
        })
        .catch((error) => {
          console.log("Error");
        });
    }
  };

  const handleSubmit = async (e, update) => {
    const form = e.currentTarget;
    e.preventDefault();
    testFormFields();
    if ((form.checkValidity() === false) | (testFormFields() === false)) {
      e.stopPropagation();
    } else {
      if (update) {
        handleSave(e);
      } else {
        let data = {
          ...editedPcm,
          prod: editedPcm?.prod?.Name !== "" ? editedPcm.prod.Name : null,
          categories: editedPcm?.prod?.productType,
          pdf_cert: editedPcm?.prod?.PDF_Cert,
          pdf_st: editedPcm?.prod?.PDF_ST,
          ccid: editedPcm?.prod?.ID !== "" ? editedPcm.prod.ID : null,
          nsid: editedPcm?.nsid,
          lab: editedPcm?.assigned_lab !== "" ? editedPcm.assigned_lab : null,
          recaptcha_token: !currentUser
            ? e.target["g-recaptcha-response"].value
            : "member_portal",
          certified:
            editedPcm?.prod?.Certified !== ""
              ? new Date(editedPcm?.prod?.Certified).toISOString().split("T")[0]
              : null,
          am_date:
            editedPcm?.prod?.AM_Date !== ""
              ? new Date(editedPcm?.prod?.AM_Date)
              : null,
          vendor: editedPcm?.prod?.VendorName ?? null,
          conformance_claim: editedPcm?.prod?.PPName
            ? {
                name: editedPcm.prod.PPName,
                abbr: editedPcm?.prod?.Abbr !== "" ? editedPcm.prod.Abbr : null,
              }
            : null,
          entrydate: getCurrentDate(),
          rcvd: getCurrentDate(),
          deadline: new Date(moment().add(30, "days").format("YYYY-MM-DD")),
        };

        if (isMaintenance) {
          data["maintenance_id"] = editedPcm?.prod?.ID;
          data["pdf_am_cert"] = editedPcm?.prod?.AM_Cert;
          data["pdf_am_st"] = editedPcm?.prod?.AM_ST;
        }
        await axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/create_pcm/`,
            data,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
              },
            },
          )
          .then((response) => {
            if (response.data?.conid && files.length > 0) {
              handleUploadFiles(response.data.conid);
            }
            handleAlertAndMailer(csrfToken, authToken, {
              subject: `International Posting Request - “PCM-${moment.utc(response.data?.entrydate).format("YYYY")}-${response.data?.ticket}"`,
              recipients: { to: ["Customer"], cc: ["NIAP Management"] },
              alert_type: "PCM",
              alert_text: `New International Posting Request - "PCM-${moment.utc(response.data?.entrydate).format("YYYY")}-${response.data?.ticket}" submitted on ${moment.utc(response.data?.entrydate).format("MM-DD-YYYY")}.`,
              alert_type_id: response.data?.rid,
            });
            handleShowModal();
            setAlert({
              message: `Proposal of International Product Successfully Submitted as PCM-${moment.utc(response.data?.entrydate).format("YYYY")}-${response.data?.ticket}!`,
              type: "success",
            });
            if (refetch) {
              refetch();
            }
            handleReset(e);
          })
          .catch((error) => {
            handleShowModal();
            setAlert({
              message: "Error Submitting Proposal of International Product.",
              type: "danger",
            });
            console.log(error);
          });
      }
    }
    setValidated(true);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setEditedPcm({});
    setValidated(false);
    setInvalidToken(false);
    setSchemeValid(true);
    if (!currentUser) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <Modal
      onEntered={!currentUser && showCaptcha}
      show={showModal}
      onHide={handleShowModal}
      size="lg"
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header className="border-0" closeButton>
          {!pcm ? (
            <h4 className="text-bright-navy">
              + PROPOSE AN INTERNATIONAL PRODUCT FOR THE PCL
            </h4>
          ) : (
            <h4 className="text-bright-navy">
              EDIT PRODUCT CONCURRENCE MANAGEMENT REQUEST - {pcm?.ticket}
            </h4>
          )}
        </Modal.Header>
        <Modal.Body className="px-5">
          <Container>
            {!pcm && (
              <Row>
                <Col className="text-center">
                  <Row className="text-center">
                    <p className="fw-bold">NIAP International Posting</p>
                  </Row>
                  <Row className="text-center">
                    <p className="fw-bold">
                      Please use the form below to propose the inclusion of an
                      international product on the PCL.
                    </p>
                  </Row>
                  <Row className="text-center">
                    <p className="fst-italic">
                      PLEASE FILL OUT ALL THE INFORMATION BELOW
                    </p>
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={12}>
                <Row className="px-5">
                  <Form.Group className="mb-3" controlId="is_maintenance">
                    <Form.Check
                      type="checkbox"
                      name="is_maintenance"
                      label="Select if this is for inclusion of a maintenance release to an already listed international product."
                      onChange={handleChange}
                      defaultChecked={
                        pcm?.maintenance_id !== null &&
                        pcm?.maintenance_id !== undefined
                      }
                      id="is_maintenance"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="nsid">
                    <Form.Label>Scheme:*</Form.Label>
                    <ReactSelect
                      id={"nsid"}
                      options={schemes}
                      newObj={!editedPcm?.nsid && pcm ? pcm : editedPcm}
                      handleChange={handleChange}
                      name={"nsid"}
                      isValid={schemeValid}
                      defaultValue={schemes?.find(
                        (scheme) => scheme.value?.sid === pcm?.nsid?.sid,
                      )}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="product">
                    <Form.Label>Product:*</Form.Label>
                    <ReactSelect
                      id={"product"}
                      options={ccProducts}
                      newObj={!editedPcm?.prod && pcm ? pcm : editedPcm}
                      handleChange={handleChange}
                      name="prod"
                      isValid={productValid}
                      defaultValue={ccProducts?.find(
                        (product) => product.label === pcm?.prod,
                      )}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="assigned_lab">
                    <Form.Label>Lab:</Form.Label>
                    <ReactSelect
                      id={"assigned_lab"}
                      options={ccLabs}
                      newObj={!editedPcm?.assigned_lab && pcm ? pcm : editedPcm}
                      handleChange={handleChange}
                      name="assigned_lab"
                      isValid={true}
                      defaultValue={ccLabs?.find(
                        (lab) => lab.label === pcm?.assigned_lab?.cctl_name,
                      )}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Form.Group className="mb-3" controlId="phone1">
                    <Form.Label>Telephone Number:</Form.Label>
                    <Form.Control
                      className="form-indent-readOnly"
                      name="phone1"
                      onChange={handleChange}
                      defaultValue={pcm?.phone1}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="custemail">
                    <Form.Label>Email:*</Form.Label>
                    <Form.Control
                      className="form-indent-readOnly"
                      name="custemail"
                      onChange={handleChange}
                      defaultValue={pcm?.custemail}
                      required
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Form.Group className="mb-3" controlId="vendor_id">
                    <Form.Label>Vendor:</Form.Label>
                    <Form.Control
                      name="vendor_id"
                      disabled={true}
                      onChange={handleChange}
                      value={
                        editedPcm.prod?.VendorName
                          ? editedPcm.prod?.VendorName
                          : pcm?.vendor_id
                            ? pcm?.vendor_id.name
                            : ""
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="custuname">
                    <Form.Label>Full Name:*</Form.Label>
                    <Form.Control
                      className="form-indent-readOnly"
                      name="custuname"
                      placeholder="John Smith"
                      onChange={handleChange}
                      defaultValue={pcm?.custuname}
                      required
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Row>
                  <p>Protection Profile Claim</p>
                  {editedPcm?.prod?.PPName && (
                    <ul>
                      <li>{editedPcm?.prod?.PPName}</li>
                    </ul>
                  )}
                  {pcmPps?.length > 0 && (
                    <ul>
                      {pcmPps.map((pp, idx) => (
                        <li key={idx}>{pp?.ppid?.pp_name}</li>
                      ))}
                    </ul>
                  )}
                  <PCMModalFileSection
                    pcm={pcm}
                    files={files}
                    setFiles={setFiles}
                    isMaintenance={isMaintenance}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Form.Group controlId="additional-info">
                <Form.Label>Additional Information</Form.Label>
                <Form.Control
                  id="additional-info"
                  as="textarea"
                  rows={5}
                  name="additional_information"
                  onChange={handleChange}
                  defaultValue={pcm?.additional_information}
                />
              </Form.Group>
            </Row>
            {!currentUser && (
              <Row className="mt-4">
                <Form.Group className="mb-3" controlId="token">
                  <ReCAPTCHA
                    id="form-recaptcha"
                    sitekey={siteKey}
                    ref={recaptchaRef}
                  />
                </Form.Group>
                {invalidToken ? (
                  <p className="text-danger">Please Confirm.</p>
                ) : (
                  <Form.Text className="text-muted ">
                    Required to submit any information via this form.
                  </Form.Text>
                )}
              </Row>
            )}
            {pcm && (
              <div className="mt-3">
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Form.Group className="mb-3" controlId="deadline">
                        <Form.Label>Deadline:</Form.Label>
                        <Form.Control
                          type="date"
                          name="deadline"
                          onChange={handleChange}
                          defaultValue={moment
                            .utc(pcm?.deadline)
                            .format("YYYY-MM-DD")}
                          required
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Form.Group controlId="update_to_customer">
                    <Form.Label>Update</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="update"
                      onChange={handleChange}
                      defaultValue={pcm?.update}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col className="mt-2">
                    <Form.Group controlId="send_response">
                      <Form.Check type="checkbox">
                        <Form.Check.Input
                          type="checkbox"
                          name="send_response"
                          onChange={(e) =>
                            setEditedPcm({
                              ...editedPcm,
                              [e.target.name]: e.target.checked,
                            })
                          }
                          id="send_response"
                        />
                        <Form.Check.Label>
                          Click this box to send the note as a response to the
                          customer.
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            {formAlert.message !== "" && (
              <Row className="d-flex justify-content-center mt-3">
                <Col sm={12}>
                  <Alert
                    variant={formAlert.type}
                    dismissible
                    onClose={() => setFormAlert({ message: "", type: "" })}
                  >
                    {formAlert.message}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="d-flex justify-content-end">
              <Col xs={12} sm={6} md={2} className="mb-2 mb-sm-0">
                <Button
                  variant="outline-primary"
                  onClick={handleShowModal}
                  className="w-100"
                >
                  Cancel
                </Button>
              </Col>
              {!pcm ? (
                <>
                  <Col xs={12} sm={6} md={2} className="mb-2 mb-sm-0">
                    <Button
                      variant="warning"
                      onClick={handleReset}
                      className="w-100"
                    >
                      Reset
                    </Button>
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <Button variant="success" type="submit" className="w-100">
                      Submit
                    </Button>
                  </Col>
                </>
              ) : (
                <Col xs={12} md={2}>
                  <Button
                    variant="success"
                    onClick={(e) => handleSubmit(e, true)}
                    className="w-100"
                  >
                    Save
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
