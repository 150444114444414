import moment from "moment";

const TqFormattedDateCell = ({ date }) => {
  if (!date) return null;

  const formattedDate = moment.utc(date).format("MM/DD/YYYY");

  return <>{formattedDate}</>;
};
export default TqFormattedDateCell;
