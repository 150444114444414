import axios from "axios";
import qs from "qs";
import { useState, useEffect } from "react";
import { Container, Accordion, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Subheader from "../../Subheader";
import AccordionBody from "../AccordionBody";

export default function EventsPage() {
  const navigate = useNavigate();
  const [activeEvents, setActiveEvents] = useState([]);
  const [inactiveEvents, setInactiveEvents] = useState([]);
  const [activeOffset, setActiveOffset] = useState(0);
  const [expiredOffset, setExpiredOffset] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [expiredCount, setExpiredCount] = useState(0);

  useEffect(() => {
    document.title = "NIAP - Events";
  }, []);

  const getActiveEvents = () => {
    const params = {
      limit: 5,
      offset: activeOffset,
      current: true,
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_events_frontend/?${qs.stringify(params, { arrayFormat: "comma" })}`,
      )
      .then((response) => {
        setActiveCount(response.data.count);
        setActiveEvents(response.data.results);
      })
      .catch((error) => {
        console.log("error getting news: ", error);
      });
  };

  const getExpiredEvents = () => {
    const params = {
      limit: 5,
      offset: expiredOffset,
      previous: true,
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_events_frontend/?${qs.stringify(params, { arrayFormat: "comma" })}`,
      )
      .then((response) => {
        setExpiredCount(response.data.count);
        setInactiveEvents(response.data.results);
      })
      .catch((error) => {
        console.log("error getting news: ", error);
      });
  };

  useEffect(() => {
    getActiveEvents();
    getExpiredEvents();
  }, []);

  useEffect(() => {
    getActiveEvents();
  }, [activeOffset]);

  useEffect(() => {
    getExpiredEvents();
  }, [expiredOffset]);

  return (
    <>
      <Subheader pageName={"Events"} />
      <Container className="my-4">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate(`/`)}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Events</Breadcrumb.Item>
        </Breadcrumb>
        <div className="p-4">
          <h4 className="text-uppercase fw-bold pb-3">
            Resources -{" "}
            <span className="text-primary text-uppercase">Events</span>
          </h4>
        </div>
        <div className="pt-4 px-4 pr-4">
          <Accordion defaultActiveKey="1" alwaysOpen>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="text-primary fw-bold text-uppercase">
                  Current Events
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                <AccordionBody
                  announcements={activeEvents}
                  setOffset={setActiveOffset}
                  count={activeCount}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-4">
          <Accordion>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="text-primary fw-bold text-uppercase">
                  Previous Events
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                <AccordionBody
                  announcements={inactiveEvents}
                  setOffset={setExpiredOffset}
                  count={expiredCount}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    </>
  );
}
