import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Accordion,
  ListGroup,
  Alert,
  Form,
  Breadcrumb,
  Button,
  Modal,
} from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";

import { useInterval } from "../../hooks/useInterval";
import HTMLEditor from "../UI/HTMLEditor";
import UnauthorizedView from "../UI/UnauthorizedView";

import ECRNoteForm from "./ECRNoteForm";
import ECRNotes from "./ECRNotes";
import ECRSideInfo from "./ECRSideInfo";

export default function ECRCommentDetails({
  setOverlayActive,
  setOverlayText,
}) {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const { id } = useParams();
  const [comment, setComment] = useState({});
  const [updatedComment, setUpdatedComment] = useState({});
  const [technicalQueries, setTechnicalQueries] = useState([]);
  const [technicalDecisions, setTechnicalDecisions] = useState([]);
  const [docTypeList, setDocTypeList] = useState([]);
  const [updates, setUpdates] = useState([]);
  const user = useSelector((state) => state.user.value);
  const permissions = useSelector((state) => state.role.value);
  const [editStatus, setEditStatus] = useState("");
  const [ecrStatusOptions, setEcrStatusOptions] = useState([]);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [previous, setPrevious] = useState({});
  const [next, setNext] = useState({});
  const [notFirst, setNotFirst] = useState(false);
  const [notLast, setNotLast] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [pps, setPPs] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [editingPPs, setEditingPPs] = useState(false);
  const [existingTDs, setExistingTDs] = useState([]);
  const [selectedTDs, setSelectedTDs] = useState([]);
  const [updatedDocs, setUpdatedDocs] = useState([]);

  const loadTechDec = useCallback(() => {
    if (selectedProfile !== null) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_all_tds_by_id/?profile=${selectedProfile}`,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          const options = response.data.map((td) => ({
            label: td.identifier ? td.identifier : td.title,
            value: td.td_id,
          }));
          setExistingTDs(options);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedProfile]);
  const processLocationOptions = [
    { label: "Check In", value: "Check In" },
    { label: "Check Out", value: "Check Out" },
    { label: "Assurance Maintenance", value: "Assurance Maintenance" },
  ];
  const ecrDocOptions = [
    {
      label: "Administrative Guide (AGD)",
      value: "Administrative Guide (AGD)",
    },
    {
      label: "Assurance Vulnerability Assessment (AVA)",
      value: "Assurance Vulnerability Assessment (AVA)",
    },
    {
      label: "Entropy Assessment Report (EAR)",
      value: "Entropy Assessment Report (EAR)",
    },
    {
      label: "Key Management Description",
      value: "Key Management Description",
    },
    { label: "Validation Report", value: "Validation Report" },
    {
      label: "Assurance Activity Report (AAR)",
      value: "Assurance Activity Report (AAR)",
    },
    { label: "Check-in Package", value: "Check-in Package" },
    {
      label: "Evaluation Technical Report (ETR)",
      value: "Evaluation Technical Report (ETR)",
    },
    { label: "Security Target", value: "Security Target" },
    { label: "Other", value: "Other" },
    {
      label: "Assurance Continuity Maintenance Report (ACMR)",
      value: "Assurance Continuity Maintenance Report (ACMR)",
    },
    { label: "Check-out Package", value: "Check-out Package" },
    {
      label: "Impact Analysis Report (IAR)",
      value: "Impact Analysis Report (IAR)",
    },
    { label: "Test Reports", value: "Test Reports" },
  ];
  const ecrTypeOptions = [
    { label: "Administrative", value: "Administrative" },
    { label: "Informative", value: "Informative" },
    { label: "Substantive", value: "Substantive" },
    { label: "Critical", value: "Critical" },
  ];

  useEffect(() => {
    if (
      permissions.role_permissions.find(
        (permission) => permission.area === "ECR Comments",
      )?.update &&
      (permissions?.role_type === "NIAP" ||
        permissions?.role_type === "Validator")
    ) {
      setUserCanEdit(true);
    }

    if (permissions?.role_type === "Validator") {
      setEcrStatusOptions([
        { label: "Draft", value: "Draft" },
        { label: "Proposed", value: "Proposed" },
        { label: "Released", value: "Released" },
        { label: "On Hold", value: "On Hold" },
        { label: "Concealed", value: "Concealed" },
        { label: "Response to Lab", value: "Response to Lab" },
        { label: "Pending Final Review", value: "Pending Final Review" },
        { label: "Reviewed", value: "Reviewed" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "Resolved", value: "Resolved" },
      ]);
    } else if (permissions?.role_type === "NIAP") {
      setEcrStatusOptions([
        { label: "Draft", value: "Draft" },
        { label: "Proposed", value: "Proposed" },
        { label: "Released", value: "Released" },
        { label: "On Hold", value: "On Hold" },
        { label: "Concealed", value: "Concealed" },
        { label: "Response to Lab", value: "Response to Lab" },
        { label: "Response to Validator", value: "Response to Validator" },
        { label: "Pending Final Review", value: "Pending Final Review" },
        { label: "Reviewed", value: "Reviewed" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "Resolved", value: "Resolved" },
      ]);
    } else {
      setEcrStatusOptions([{ label: "Draft", value: "Draft" }]);
    }
  }, []);

  const getComment = useCallback(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${id}/`, {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        })
        .then((response) => {
          setComment(response.data);
          setSelectedProfile(response?.data?.pp_id?.pp_id);
          loadTechDec();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id, authToken]);

  useEffect(() => {
    setAlert({ message: "", type: "" });
  }, [id]);

  const getRelatedComments = useCallback(() => {
    if (comment?.pid) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/comments_by_vid?vid=${comment?.pid?.v_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          if (response.data.length > 1) {
            setNotFirst(true);
            setNotLast(true);
            const sortedECR = response.data;
            sortedECR.sort((a, b) => (a.ecrid < b.ecrid ? -1 : 1));
            const currentECRIndex = sortedECR.findIndex(
              (ecr) => ecr.ecrid === comment.ecrid,
            );
            let previousIndex = currentECRIndex - 1;
            let nextIndex = currentECRIndex + 1;
            if (currentECRIndex === 0) {
              previousIndex = sortedECR.length - 1;
            } else if (currentECRIndex === sortedECR.length - 1) {
              nextIndex = 0;
            }
            setPrevious(sortedECR[previousIndex]);
            setNext(sortedECR[nextIndex]);
          } else {
            setNotFirst(false);
            setNotLast(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [comment]);

  useEffect(() => {
    getRelatedComments();
    loadPPByID();
  }, [comment]);

  useInterval(() => {
    getRelatedComments();
  }, 60000); //1 min

  const getTechnicalQueries = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical_queries_by_ecrid/?ecrid=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setTechnicalQueries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken, id]);

  const getTechnicalDecisions = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical_decisions_by_ecrid/?ecrid=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setTechnicalDecisions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken, id]);

  const getDocumentTypes = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/get_all_ecr_doc_types`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setDocTypeList(response.data);
        const options = response.data.map((docs) => ({
          label: docs.dtid__doctype,
          value: docs.dtid__doctype,
        }));
        setUpdatedDocs(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  const getUpdates = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/update/updates_by_ecr/?ecrid=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setUpdates(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken, id]);

  const fetchNotes = useCallback(() => {
    getUpdates();
  }, [getComment]);

  useEffect(() => {
    getUpdates();
  }, [getUpdates]);

  useInterval(() => {
    getUpdates();
  }, 60000); //1 min

  useEffect(() => {
    getComment();
  }, [getComment]);

  useInterval(() => {
    getComment();
  }, 60000); //1 min

  useEffect(() => {
    getTechnicalQueries();
  }, [getTechnicalQueries]);

  useInterval(() => {
    getTechnicalQueries();
  }, 180000); //3 min

  useEffect(() => {
    getTechnicalDecisions();
  }, [getTechnicalDecisions]);

  useInterval(() => {
    getTechnicalDecisions();
  }, 180000); //3 min

  useEffect(() => {
    getDocumentTypes();
  }, [getDocumentTypes]);

  const handleChange = (e) => {
    if (e.target.name === "status") {
      setUpdatedComment({ ...updatedComment, [e.target.name]: e.target.value });
    } else if (e.target.name === "pp_id") {
      setUpdatedComment({ ...updatedComment, [e.target.name]: e.target.value });
      setSelectedProfile(e.target.value);
    } else {
      setUpdatedComment({ ...updatedComment, [e.target.name]: e.target.value });
    }
  };
  const handleSave = async (e) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${comment?.ecrid}/`,
        {
          ...updatedComment,
          moddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          editor: user.id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        getComment();
        setEditStatus("");
        setAlert({ message: "Successfully Updated Status!", type: "success" });
      })
      .catch((error) => {
        setAlert({ message: "Error Updating Status.", type: "danger" });
      });
  };
  const handlePPSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    handleSave();

    const deletedTdIds = technicalDecisions
      .filter(
        (item) =>
          !selectedTDs.some(
            (selectedItem) => selectedItem.value === item.td_id,
          ),
      )
      .map((item) => item.td_id);

    if (deletedTdIds.length > 0) {
      const deletePromises = deletedTdIds.map((td) => {
        return axios
          .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical-decision/get_ecr_td_by_ecrid_and_tdid/?ecrid=${comment?.ecrid}&tdid=${td}`,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            if (response.data?.length > 0) {
              return axios.delete(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical-decision/${response.data[0]?.xid}`,
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                },
              );
            }
          })
          .catch((error) => {
            console.log("Error removing technical decision", error);
          });
      });

      try {
        await Promise.all(deletePromises);
      } catch (error) {
        console.log("Error occurred while processing deletions", error);
      }
    }

    const updatedSelectedTd = selectedTDs.filter(
      (item) => !deletedTdIds.includes(item.value),
    );

    const addPromises = updatedSelectedTd.map((td) => {
      if (!technicalDecisions?.find((obj) => obj?.td_id === td?.value)) {
        const ecrTD = {
          entrydate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          ecrid: comment?.ecrid,
          td_id: td.value,
          creator: user.id,
        };

        return axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical-decision/`,
            ecrTD,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .catch((error) => console.log(error));
      }
    });

    try {
      await Promise.all(addPromises);
      await getTechnicalDecisions();
    } catch (error) {
      console.log(
        "Error occurred while processing additions or refreshing technical decisions",
        error,
      );
    }

    setEditingPPs(false);
  };
  const handleDocumentType = async () => {
    if (updatedDocs.length > 0) {
      try {
        const addPromises = updatedDocs.map((doc) => {
          if (!docTypeList?.find((obj) => obj?.dtid__doctype === doc?.value)) {
            const ecrDoc = {
              entrydate: new Date(
                new Date().setMinutes(
                  new Date().getMinutes() - new Date().getTimezoneOffset(),
                ),
              ),
              ecrid: comment?.ecrid,
              doc_type: doc.value,
              creator: user.id,
            };
            return axios
              .post(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/`,
                ecrDoc,
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .catch((error) => console.log(error));
          }
        });

        const deletePromises = docTypeList.map((doc) => {
          if (!updatedDocs.find((obj) => obj.value === doc?.dtid__doctype)) {
            return axios
              .delete(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/${doc?.xid}`,
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .catch((error) => console.log(error));
          }
        });

        // Wait for all addition and deletion requests to complete
        await Promise.all([...addPromises, ...deletePromises]);

        setEditStatus("");
        getDocumentTypes();
      } catch (error) {
        console.log("Error occurred while processing document types", error);
      }
    }
  };

  const loadPPByID = useCallback(() => {
    if (comment?.pid?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_projpp_by_product_id?product_id=${comment?.pid?.product_id}`,
        )
        .then((response) => {
          const options = response.data.map((pp) => ({
            label: pp.pp__pp_short_name ? pp.pp__pp_short_name : pp.pp__pp_name,
            value: pp.pp__pp_id,
          }));
          setPPs(options);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [comment]);

  const getPidDetailsBreadcrumb = () => {
    if (comment?.pid?.product_id) {
      return (
        <Breadcrumb.Item
          onClick={() =>
            navigate(`/community/products/details/${comment?.pid?.product_id}`)
          }
        >
          VID {comment?.pid?.v_id} Details
        </Breadcrumb.Item>
      );
    } else if (comment?.maintenance_id?.maintenance_id) {
      return (
        <Breadcrumb.Item
          onClick={() =>
            navigate(
              `/community/products/details/${comment?.maintenance_id?.product?.product_id}/assurancemaintenance/${comment?.maintenance_id?.maintenance_id}`,
            )
          }
        >
          Assurance Maintenance {comment?.maintenance_id?.maintenance_id}{" "}
          Details
        </Breadcrumb.Item>
      );
    } else {
      return <></>;
    }
  };

  const removeTD = async (td, idx) => {
    setSelectedTDs(selectedTDs.filter((fil) => fil.value !== td.value));
  };

  useEffect(() => {
    if (editingPPs && selectedProfile !== null) {
      loadTechDec();
    }
  }, [loadTechDec, selectedProfile, editingPPs]);

  useEffect(() => {
    const options = technicalDecisions.map((td) => ({
      label: td.identifier ? td.identifier : td.title,
      value: td.td_id,
    }));
    setSelectedTDs(options);
  }, [technicalDecisions]);
  return permissions?.role_permissions?.find(
    (permission) => permission.area === "ECR Comments",
  )?.read ? (
    <Container className="py-5" fluid>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate(`/`)}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/community/`)}>
          Community
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/community/ecr-comments/`)}>
          ECR Comments
        </Breadcrumb.Item>
        {getPidDetailsBreadcrumb()}
        {notFirst && (
          <Breadcrumb.Item
            onClick={() =>
              navigate(`/community/ecr-comments/details/${previous?.ecrid}`)
            }
          >
            Previous ECR
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item active>ECR COMMENT : {id}</Breadcrumb.Item>
        {notLast && (
          <Breadcrumb.Item
            onClick={() =>
              navigate(`/community/ecr-comments/details/${next?.ecrid}`)
            }
          >
            Next ECR
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <Row>
        <Col sm={12} md={8} lg={9}>
          <Row>
            <Col sm={12}>
              <h3 className="fw-bold text-primary">ECR COMMENT : {id}</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mt-3">
              {editStatus === "gist" ? (
                <div className="d-flex justify-content-end">
                  <SaveIcon
                    color="action"
                    className="clickable"
                    onClick={handleSave}
                    data-testid="ecr-save-status"
                  />
                </div>
              ) : (
                userCanEdit && (
                  <div className="d-flex justify-content-end">
                    <EditIcon
                      color="action"
                      className="clickable"
                      onClick={() => {
                        setEditStatus("gist");
                      }}
                      data-testid="ecr-edit-status"
                    />
                  </div>
                )
              )}
              {editStatus === "gist" ? (
                <Form
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                >
                  <Form.Control
                    id="gist"
                    name="gist"
                    onChange={handleChange}
                    defaultValue={comment?.gist}
                  />
                </Form>
              ) : (
                <h4 className="fw-bold text-info">{comment?.gist}</h4>
              )}
            </Col>
            <Col sm={6} className="mt-3 d-flex align-items-center">
              <div className="vr me-3"></div>
              <Col>
                <Row>
                  <h3 className="fw-bold">{comment?.pid?.product_name}</h3>
                </Row>
                <Row>
                  <p className="">
                    Creator: {comment?.creator?.first_name}{" "}
                    {comment?.creator?.last_name}
                  </p>
                </Row>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mt-3">
              {alert.message !== "" && (
                <Row className="d-flex justify-content-center mt-3">
                  <Col lg={10}>
                    <Alert
                      variant={alert.type}
                      dismissible
                      onClose={() => setAlert({ message: "", type: "" })}
                    >
                      {alert.message}
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  xs={12}
                  md={2}
                  className="border-dark border-bottom border-top p-2"
                >
                  {permissions?.role_type !== "Lab" ? (
                    <>
                      <Stack className="border-start border-secondary p-2  h-100">
                        {editStatus === "status" ? (
                          <div className="d-flex justify-content-end">
                            <SaveIcon
                              color="action"
                              className="clickable"
                              onClick={handleSave}
                              data-testid="ecr-save-status"
                            />
                          </div>
                        ) : (
                          permissions.role_permissions.find(
                            (permission) => permission.area === "ECR Comments",
                          )?.update &&
                          (userCanEdit ||
                            permissions?.role_type === "Validator") && (
                            <div className="d-flex justify-content-end">
                              <EditIcon
                                color="action"
                                className="clickable"
                                onClick={() => setEditStatus("status")}
                                data-testid="ecr-edit-status"
                              />
                            </div>
                          )
                        )}
                        <h5>Status</h5>
                        {editStatus === "status" ? (
                          <Select
                            required
                            placeholder="Select a Status"
                            options={ecrStatusOptions}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "status", value: e.value },
                              });
                            }}
                          />
                        ) : (
                          <h5 className="text-secondary">{comment?.status}</h5>
                        )}
                      </Stack>

                      <Modal
                        size="md"
                        show={editingPPs}
                        onHide={() => {
                          setEditingPPs(false);
                        }}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>+ Editing PP </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form onSubmit={handlePPSubmit}>
                            <Form.Label htmlFor="protectionProfile">
                              Protection Profile
                            </Form.Label>
                            <Select
                              id="protectionProfile"
                              placeholder="Select a Protection Profile"
                              options={pps}
                              onChange={(e) => {
                                handleChange({
                                  target: { name: "pp_id", value: e.value },
                                });
                                setSelectedTDs([]);
                              }}
                              value={
                                selectedProfile
                                  ? pps?.find(
                                      (pp) => pp.value === selectedProfile,
                                    )
                                  : null
                              }
                            />
                            <Form.Label htmlFor="technicalDecisions">
                              Technical Decisions
                            </Form.Label>
                            <Select
                              id="technicalDecisions"
                              placeholder="Select All Technical Decisions"
                              options={existingTDs}
                              onChange={(e) =>
                                setSelectedTDs([...selectedTDs, e])
                              }
                            />
                            {selectedTDs?.map((td, idx) => (
                              <Row
                                className="m-3 border-bottom text-center"
                                key={idx}
                              >
                                <Col sm={6}>{td.label}</Col>
                                <Col sm={6}>
                                  <Button
                                    variant="outline-primary"
                                    className="attachment-remove"
                                    onClick={() => removeTD(td, idx)}
                                  >
                                    X
                                  </Button>
                                </Col>
                              </Row>
                            ))}
                            <Row className="mt-2">
                              <Button type="submit">Submit</Button>
                            </Row>
                          </Form>
                        </Modal.Body>
                      </Modal>
                      <ECRNoteForm
                        show={showNotes}
                        handleClose={() => {
                          setShowNotes(false);
                          getComment();
                        }}
                        user={user}
                        comment={comment}
                        fetchNotes={fetchNotes}
                        responseType={"ECR Cancelled"}
                        vidNumber={comment?.pid?.v_id}
                      />
                    </>
                  ) : (
                    <Stack className="border-start border-secondary p-2  h-100">
                      <h5>Status</h5>
                      <h5 className="text-secondary">{comment?.status}</h5>
                    </Stack>
                  )}
                </Col>
                <Col
                  xs={12}
                  md={2}
                  className="border-dark border-bottom border-top p-2"
                >
                  <Stack className="border-start border-secondary p-2  h-100">
                    {userCanEdit && (
                      <div className="d-flex justify-content-end">
                        <EditIcon
                          color="action"
                          className="clickable"
                          onClick={() => {
                            setEditingPPs(true);
                          }}
                          data-testid="ecr-edit-status"
                        />
                      </div>
                    )}
                    <h5>PP Claim</h5>

                    <h5 className="text-secondary">
                      {comment?.pp_id?.pp_name}
                    </h5>
                  </Stack>
                </Col>
                <Col
                  xs={12}
                  md={2}
                  className="border-dark border-bottom border-top p-2"
                >
                  <Stack className="border-start border-secondary p-2  h-100">
                    {editStatus === "type" ? (
                      <div className="d-flex justify-content-end">
                        <SaveIcon
                          color="action"
                          className="clickable"
                          onClick={handleSave}
                          data-testid="ecr-save-status"
                        />
                      </div>
                    ) : (
                      userCanEdit && (
                        <div className="d-flex justify-content-end">
                          <EditIcon
                            color="action"
                            className="clickable"
                            onClick={() => {
                              setEditStatus("type");
                            }}
                            data-testid="ecr-edit-status"
                          />
                        </div>
                      )
                    )}
                    <h5>Type</h5>
                    {editStatus === "type" ? (
                      <Select
                        id="docType"
                        placeholder="Select All Document Types"
                        options={ecrTypeOptions}
                        onChange={(e) => {
                          handleChange({
                            target: { name: "type", value: e.value },
                          });
                        }}
                        defaultValue={comment?.type}
                      />
                    ) : (
                      <>
                        <h5 className="text-secondary">{comment?.type}</h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col
                  xs={12}
                  md={2}
                  className="border-dark border-bottom border-top p-2"
                >
                  <Stack className="border-start border-secondary p-2  h-100">
                    {editStatus === "docType" ? (
                      <div className="d-flex justify-content-end">
                        <SaveIcon
                          color="action"
                          className="clickable"
                          onClick={handleDocumentType}
                          data-testid="ecr-save-status"
                        />
                      </div>
                    ) : (
                      userCanEdit && (
                        <div className="d-flex justify-content-end">
                          <EditIcon
                            color="action"
                            className="clickable"
                            onClick={() => {
                              setEditStatus("docType");
                            }}
                            data-testid="ecr-edit-status"
                          />
                        </div>
                      )
                    )}
                    <h5>Documents</h5>
                    {editStatus === "docType" ? (
                      <Select
                        id="docType"
                        isMulti
                        required
                        placeholder="Select All Document Types"
                        options={ecrDocOptions}
                        onChange={(e) => {
                          setUpdatedDocs(e);
                        }}
                        value={updatedDocs}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor:
                              !updatedDocs.length > 0 ? "red" : "#cccccc",
                          }),
                        }}
                      />
                    ) : (
                      <ListGroup>
                        {docTypeList
                          .filter((dt) => dt.ecrid__ecrid === comment?.ecrid)
                          ?.map((dt) => (
                            <ListGroup.Item key={`dt${dt.dtid__dtid}`} disabled>
                              {dt.dtid__doctype}
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    )}
                  </Stack>
                </Col>
                <Col
                  xs={12}
                  md={2}
                  className="border-dark border-bottom border-top p-2"
                >
                  <Stack className="border-start border-secondary p-2  h-100">
                    <Form
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    >
                      {editStatus === "cite" ? (
                        <div className="d-flex justify-content-end">
                          <SaveIcon
                            color="action"
                            className="clickable"
                            onClick={handleSave}
                            data-testid="ecr-save-status"
                          />
                        </div>
                      ) : (
                        userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              color="action"
                              className="clickable"
                              onClick={() => {
                                setEditStatus("cite");
                              }}
                              data-testid="ecr-edit-status"
                            />
                          </div>
                        )
                      )}
                      <h5>Cite</h5>
                      {editStatus === "cite" ? (
                        <Form.Control
                          id="cite"
                          name="cite"
                          onChange={handleChange}
                          defaultValue={comment?.cite}
                        />
                      ) : (
                        <>
                          <h5 className="text-secondary">{comment?.cite}</h5>
                        </>
                      )}
                    </Form>
                  </Stack>
                </Col>
                <Col
                  xs={12}
                  md={2}
                  className="border-dark border-bottom border-top p-2"
                >
                  <Stack className="border-start border-secondary p-2  h-100">
                    {editStatus === "process_location" ? (
                      <div className="d-flex justify-content-end">
                        <SaveIcon
                          color="action"
                          className="clickable"
                          onClick={handleSave}
                          data-testid="ecr-save-status"
                        />
                      </div>
                    ) : (
                      userCanEdit && (
                        <div className="d-flex justify-content-end">
                          <EditIcon
                            color="action"
                            className="clickable"
                            onClick={() => {
                              setEditStatus("process_location");
                            }}
                            data-testid="ecr-edit-status"
                          />
                        </div>
                      )
                    )}
                    <h5>Relates To</h5>
                    {editStatus === "process_location" ? (
                      <Select
                        required
                        placeholder="Select a Process Location"
                        options={processLocationOptions}
                        defaultValue={comment?.process_location}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: "process_location",
                              value: e.value,
                            },
                          });
                        }}
                      />
                    ) : (
                      <>
                        <h5 className="text-secondary">
                          {comment?.process_location}
                        </h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col
                  xs={12}
                  md={2}
                  className="border-dark border-bottom border-top p-2"
                >
                  <Stack className="border-start border-secondary p-2  h-100">
                    <Form
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                    >
                      {editStatus === "req" ? (
                        <div className="d-flex justify-content-end">
                          <SaveIcon
                            color="action"
                            className="clickable"
                            onClick={handleSave}
                            data-testid="ecr-save-status"
                          />
                        </div>
                      ) : (
                        userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              color="action"
                              className="clickable"
                              onClick={() => {
                                setEditStatus("req");
                              }}
                              data-testid="ecr-edit-status"
                            />
                          </div>
                        )
                      )}
                      <h5>Requirement</h5>
                      {editStatus === "req" ? (
                        <Form.Control
                          id="req"
                          name="req"
                          onChange={handleChange}
                          defaultValue={comment?.req}
                        />
                      ) : (
                        <>
                          <h5 className="text-secondary">{comment?.req}</h5>
                        </>
                      )}
                    </Form>
                  </Stack>
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <h5 className="fw-bold mt-3 mb-3">ECR COMMENT</h5>

              {editStatus === "cmnt" ? (
                <>
                  <HTMLEditor
                    name="cmnt"
                    handleChange={handleChange}
                    setIsValid={() => {}}
                    isValid={true}
                    defaultValue={comment?.cmnt}
                  />
                  <div className="text-end mt-2">
                    <Button onClick={handleSave}>Save ECR Comment</Button>
                  </div>
                </>
              ) : (
                <>
                  <p className="mx-3">
                    {comment?.cmnt && parse(comment?.cmnt)}
                  </p>
                  {userCanEdit && (
                    <div className="text-end">
                      <button
                        className="btn btn-link d-inline-block"
                        style={{ minWidth: 10 }}
                        onClick={() => setEditStatus("cmnt")}
                      >
                        Edit ECR Comment
                      </button>
                    </div>
                  )}
                </>
              )}

              {/*  */}
            </Col>
            <Col sm={12}>
              {comment?.response && (
                <>
                  <h5 className="fw-bold mt-3 mb-3">LAB RESPONSE</h5>
                  <p className="mx-3">
                    {comment?.response && parse(comment?.response)}
                  </p>
                  <p className="mx-3">
                    RESPONSE SUBMITTED:&nbsp;
                    {comment?.responseauthor?.first_name}&nbsp;
                    {comment?.responseauthor?.last_name}&nbsp;
                    {comment?.responsedtg &&
                      moment
                        .utc(comment.responsedtg)
                        .format("YYYY.MM.DD [at] HHMM")}
                  </p>
                </>
              )}
            </Col>
            <Col sm={12} className="mt-3">
              {technicalDecisions?.length > 0 && (
                <Accordion className="mb-4 border-0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h6 className="fw-bold text-dark">TECHNICAL DECISIONS</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      {technicalDecisions?.map((td) => (
                        <div key={`td${td?.td_id}`}>
                          <h6 className="text-decoration-underline mx-3">
                            {td?.title} - {td?.identifier}
                          </h6>
                          <p className="mx-3">
                            {td?.description && parse(td.description)}
                          </p>
                        </div>
                      ))}

                      {userCanEdit && (
                        <div className="text-end">
                          <button
                            className="btn btn-link d-inline-block"
                            style={{ minWidth: 10 }}
                            onClick={() => {
                              setEditingPPs(true);
                            }}
                          >
                            Edit Technical Decisions
                          </button>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {technicalQueries?.length > 0 && (
                <Accordion className="my-4" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h6 className="fw-bold text-dark">TECHNICAL QUERIES</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      {technicalQueries?.map((tq) => (
                        <div key={`tq${tq?.tq_id}`}>
                          <h6 className="fw-bold">{tq.title}</h6>
                          <p>{tq.question}</p>
                          <p>{tq.response}</p>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              <Accordion className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">RATIONALE</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {editStatus === "rationale" ? (
                      <>
                        <HTMLEditor
                          name="rationale"
                          handleChange={handleChange}
                          setIsValid={() => {}}
                          isValid={true}
                          defaultValue={comment?.rationale}
                        />
                        <div className="text-end mt-2">
                          <Button onClick={handleSave}>
                            Save Rationale Comment
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {comment?.rationale && parse(comment.rationale)}
                        {userCanEdit && (
                          <div className="text-end">
                            <button
                              className="btn btn-link d-inline-block"
                              style={{ minWidth: 10 }}
                              onClick={() => setEditStatus("rationale")}
                            >
                              Edit Rationale
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <ECRNotes
                notes={updates}
                fetchNotes={fetchNotes}
                fetchComment={getComment}
                comment={comment}
                user={user}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={4} lg={3}>
          <ECRSideInfo
            fetchNotes={fetchNotes}
            comment={comment}
            vidNumber={comment?.pid?.v_id}
            user={user}
            id={id}
            setOverlayActive={setOverlayActive}
            etOverlayText={setOverlayText}
          />
        </Col>
      </Row>
    </Container>
  ) : (
    <UnauthorizedView />
  );
}
