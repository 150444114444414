import axios from "axios";
import { useState, useEffect } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";

import Subheader from "../Subheader";

import IndexedTabContent from "./IndexedTabContent";

const TermsAcronyms = () => {
  const [definitions, setDefinitions] = useState([]);

  useEffect(() => {
    document.title = "NIAP - Terms and Acronyms";
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/definitions/get_all_definitions/`,
      )
      .then((response) => {
        setDefinitions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Subheader pageName={"Terms & Acronyms"} />
      <Container className="mb-5 py-3">
        <div className="p-4">
          <h4 className="text-uppercase fw-bold pb-3">Browse the A-Z Index</h4>
          <Tabs defaultActiveKey="A" id="defintions-tabs" className="m-3">
            <Tab className="pb-5" eventKey="A" title="A">
              <IndexedTabContent index="A" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="B" title="B">
              <IndexedTabContent index="B" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="C" title="C">
              <IndexedTabContent index="C" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="D" title="D">
              <IndexedTabContent index="D" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="E" title="E">
              <IndexedTabContent index="E" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="F" title="F">
              <IndexedTabContent index="F" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="G" title="G">
              <IndexedTabContent index="G" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="H" title="H">
              <IndexedTabContent index="H" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="I" title="I">
              <IndexedTabContent index="I" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="J" title="J">
              <IndexedTabContent index="J" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="K" title="K">
              <IndexedTabContent index="K" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="L" title="L">
              <IndexedTabContent index="L" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="M" title="M">
              <IndexedTabContent index="M" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="N" title="N">
              <IndexedTabContent index="N" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="O" title="O">
              <IndexedTabContent index="O" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="P" title="P">
              <IndexedTabContent index="P" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="Q" title="Q">
              <IndexedTabContent index="Q" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="R" title="R">
              <IndexedTabContent index="R" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="S" title="S">
              <IndexedTabContent index="S" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="T" title="T">
              <IndexedTabContent index="T" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="U" title="U">
              <IndexedTabContent index="U" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="V" title="V">
              <IndexedTabContent index="V" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="W" title="W">
              <IndexedTabContent index="W" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="X" title="X">
              <IndexedTabContent index="X" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="Y" title="Y">
              <IndexedTabContent index="Y" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="Z" title="Z">
              <IndexedTabContent index="Z" data={definitions} />
            </Tab>
            <Tab className="pb-5" eventKey="All" title="All">
              <IndexedTabContent index="All" data={definitions} />
            </Tab>
          </Tabs>
        </div>
      </Container>
    </>
  );
};

export default TermsAcronyms;
