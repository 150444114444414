import { Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

const TqIdCell = ({ tq }) => {
  const { permissions } = useAuth();

  const isLab = permissions.role_type === "Lab";

  const className = isLab
    ? "text-decoration-none"
    : "text-decoration-underline";
  const style = isLab ? { cursor: "default" } : {};
  const to = isLab ? "" : `/community/technicalqueries/details/${tq.tq_id}`;

  return (
    <Link className={className} style={style} to={to}>
      {tq.tq_id}
    </Link>
  );
};
export default TqIdCell;
