import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Breadcrumb,
} from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useInterval } from "../../../hooks/useInterval";
import Subheader from "../../Subheader";

export default function AssuranceMaintenancePage({
  setOverlayActive,
  setOverlayText,
}) {
  const { ccid, id } = useParams();
  const [ccProduct, setCCProduct] = useState({});
  const [maintenance, setMaintenance] = useState({});
  const [files, setFiles] = useState([]);
  const [pcmPps, setPcmPps] = useState([]);

  useEffect(() => {
    document.title = "NIAP - PCM Assurance Maintenance Details";
  }, []);

  const fetchMaintenance = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/maintenance/${id}`)
      .then((response) => {
        setMaintenance(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    fetchMaintenance();
  }, [fetchMaintenance]);

  const getMaintenanceCCFiles = (maintenance_id) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_pcl_files_by_maintenance_id/?maintenance_id=${id}`,
      )
      .then((response) => {
        setFiles(response.data.filter((f) => f.active === true));
      })
      .catch((error) => setFiles([]));
  };

  useEffect(() => {
    getMaintenanceCCFiles(id);
  }, [id]);

  const getCCProduct = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/get_ccp_by_ccid/?ccid=${ccid}`,
      )
      .then((response) => {
        setCCProduct(response.data);
      })
      .catch((error) => {
        console.log("error fetching cc product", error);
      });
  }, []);

  useEffect(() => {
    getCCProduct();
  }, [getCCProduct]);

  useInterval(() => {
    getCCProduct();
  }, 180000); //3 min

  const getCCFiles = (rid) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=cc&file_type_id=${id}`,
      )
      .then((response) => {
        setFiles(response.data.filter((f) => f.active === true));
      })
      .catch((error) => setFiles([]));
  };

  useEffect(() => {
    if (ccProduct?.pcm?.rid) {
      getCCFiles(ccProduct?.pcm?.rid);
    }
  }, [ccProduct]);

  const getPcmPps = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/pp/get_pps_by_ccid/?ccid=${ccid}`,
      )
      .then((response) => {
        setPcmPps(response.data);
      })
      .catch((error) => setPcmPps([]));
  }, []);

  useEffect(() => {
    getPcmPps();
  }, []);

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addHttpsToVendorWebsite = (website) => {
    if (
      website &&
      !website.includes("https://") &&
      !website.includes("http://")
    ) {
      return "https://" + website;
    }
    return website;
  };

  return (
    <>
      <Subheader pageName={"Products"} />
      <Container className="p-5" fluid>
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item href={`/products/international-product/${ccid}`}>
              International Product {ccid} Details
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{maintenance?.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row>
          <Col className="d-flex">
            <h3 className="fw-bold">
              ASSURANCE CONTINUITY - {maintenance?.name}
            </h3>
          </Col>
        </Row>
        {/* <Row className="my-4">
          <Col>
            <h3 className="fw-bold">{ccProduct?.name}</h3>
          </Col>
        </Row> */}
        <Row className="gx-2">
          <Col sm={12} md={3} lg={2} className="bg-primary p-4 text-white">
            <Stack className="border-bottom pb-4">
              <Container className="p-0">
                {files?.map((file, idx) => (
                  <Row className="mt-3" key={idx}>
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        onClick={() => downloadFile(file)}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        {file?.file_display_name}
                      </Button>
                    </Stack>
                  </Row>
                ))}
              </Container>
              <Container className="p-0">
                {ccProduct?.pdf_cert && (
                  <Row className="mt-3">
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        target="_blank"
                        href={`https://www.commoncriteriaportal.org/nfs/ccpfiles/files/epfiles/${ccProduct?.pdf_cert}`}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        CC Certificate
                      </Button>
                    </Stack>
                  </Row>
                )}
                {ccProduct?.pdf_st && (
                  <Row className="mt-3">
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        target="_blank"
                        href={`https://www.commoncriteriaportal.org/nfs/ccpfiles/files/epfiles/${ccProduct?.pdf_st}`}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        CC Security Target
                      </Button>
                    </Stack>
                  </Row>
                )}
                {maintenance?.pdf_am_st && (
                  <Row className="mt-3">
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        target="_blank"
                        href={`https://www.commoncriteriaportal.org/nfs/ccpfiles/files/epfiles/${maintenance?.pdf_am_st}`}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        Maintenance Security Target
                      </Button>
                    </Stack>
                  </Row>
                )}
                {maintenance?.pdf_am_acmr && (
                  <Row className="mt-3">
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        target="_blank"
                        href={`https://www.commoncriteriaportal.org/nfs/ccpfiles/files/epfiles/${maintenance?.pdf_am_acmr}`}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        Assurance Continuity Maintenance Report
                      </Button>
                    </Stack>
                  </Row>
                )}
              </Container>
            </Stack>
            {maintenance?.am_date_completed && (
              <Stack className="border-bottom pt-4">
                <small className="pb-2">Date of Maintenance Completion</small>
                <p>
                  {moment
                    .utc(maintenance?.am_date_completed)
                    .format("MM/DD/YYYY")}
                </p>
              </Stack>
            )}
          </Col>
          <Col sm={12} md={9} lg={10} className="px-4">
            <Row className="pt-4">
              <Stack>
                <h6 className="fw-bold">Product Type:</h6>
                <p>{ccProduct?.categories}</p>
              </Stack>
            </Row>
            <Row className="pt-4">
              <h6 className="fw-bold">Conformance Claim:</h6>
              {pcmPps?.length > 0 && (
                <div className="d-flex align-items-center py-2">
                  <p>Protection Profile Compliant</p>
                </div>
              )}
            </Row>
            <Row className="pt-4">
              <h6 className="fw-bold">PP Identifier:</h6>
              {pcmPps?.map((pp, idx) => (
                <div key={idx} className="d-flex align-items-center py-2">
                  <p>{pp?.pp_name}</p>
                </div>
              ))}
            </Row>
            <Row className="pt-4">
              <Stack>
                <h6 className="fw-bold">CC Testing Lab:</h6>
                <p>{ccProduct?.assigned_lab_name}</p>
              </Stack>
            </Row>
            <Row className="pt-4">
              <Stack>
                <h6 className="fw-bold">Vendor Information:</h6>
                <p>{ccProduct?.vendor?.name}</p>
                {ccProduct?.vendor?.website && (
                  <a
                    href={addHttpsToVendorWebsite(ccProduct?.vendor?.website)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {addHttpsToVendorWebsite(ccProduct?.vendor?.website)}
                  </a>
                )}
              </Stack>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3 mx-3">
          <p>
            <span className="fw-bold">
              Readers are reminded that the certification of this product (TOE)
              is the result of maintenance, rather than an actual re-evaluation
              of the product.
            </span>{" "}
            Maintenance only considers the affect of TOE changes on the
            assurance baseline (i.e. the original evaluated TOE); maintenance is
            not intended to provide assurance in regard to the resistance of the
            TOE to new vulnerabilities or attack methods discovered since the
            date of the initial certificate. Such assurance can only be gained
            through re-evaluation.
          </p>
          <p>
            Using a security impact analysis of the changes made to the TOE,
            which was provided by the developer, the CCEVS has determined that
            the impact of changes on the TOE are considered minor and that
            independent evaluator analysis was not necessary. A summary of the
            results can be found in the Maintenance Report, which is written in
            relation to the product's original validation report and Security
            Target.
            <span className="fw-bold">
              Readers are therefore reminded to read the Security Target,
              Validation Report, and the Assurance Maintenance Report to fully
              understand the meaning of what a maintained certificate
              represents.
            </span>
          </p>
        </Row>
      </Container>
    </>
  );
}
