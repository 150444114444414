import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";

const MultipleMembersOneListForm = ({
  show,
  type,
  listName,
  onHide,
  getMembers,
  setAlert,
}) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [editedMember, setEditedMember] = useState({
    members: [{ name: "", primarySmtpAddress: "" }],
    listNames: [],
  });

  const handleReset = () => {
    setEditedMember({
      members: [{ name: "", primarySmtpAddress: "" }],
      listNames: [],
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "name") {
      const idx = event.target.idx;
      const members = editedMember.members;
      members[idx].name = event.target.value;
      setEditedMember({ ...editedMember, members: members });
    } else if (event.target.name === "email") {
      const idx = event.target.idx;
      const members = editedMember.members;
      members[idx].primarySmtpAddress = event.target.value;
      setEditedMember({ ...editedMember, members: members });
    }
  };

  const addMember = () => {
    const members = editedMember.members;
    const newMember = { name: "", primarySmtpAddress: "" };
    members.push(newMember);
    setEditedMember({ ...editedMember, members: members });
  };

  useEffect(() => {
    setEditedMember({
      members: [{ name: "", primarySmtpAddress: "" }],
      listNames: [listName],
    });
  }, [listName]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/members/`,
          {
            ...editedMember,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          },
        )
        .then((response) => {
          getMembers();
          onHide();
          setAlert({ type: "success", message: "Members added successfully!" });
        })
        .catch((error) => {
          console.log(error);
          setAlert({
            type: "danger",
            message: "Error Adding Members. Please try again.",
          });
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Mailing List Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editedMember.members?.map((member, idx) => (
            <div key={idx}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: event.target.name,
                        value: event.target.value,
                        idx: idx,
                      },
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  onChange={(event) =>
                    handleChange({
                      target: {
                        name: event.target.name,
                        value: event.target.value,
                        idx: idx,
                      },
                    })
                  }
                />
              </Form.Group>
            </div>
          ))}
          <Button
            type="button"
            variant="light"
            className="m-3"
            onClick={addMember}
          >
            <AddIcon /> Add Another Member
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button type="reset" onClick={handleReset} variant="warning">
            Reset
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="success">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default MultipleMembersOneListForm;
