import {
  Card,
  Button,
  Stack,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Products.css";
import moment from "moment";

import { latestApprovedMaintenance } from "../Helper/functions";

export default function MaintenanceCard({ card, maintenances, projpps }) {
  const getStatusColor = () => {
    if (
      card?.certification_status === "Certified" &&
      card?.status !== "Archived"
    ) {
      return "bg-green";
    } else if (card?.is_archived || card?.status === "Archived") {
      return "bg-archived";
    } else {
      return "bg-yellow";
    }
  };

  const getCardName = () => {
    if (
      card?.certification_status === "Certified" &&
      card?.status !== "Archived"
    ) {
      return "Certified";
    } else if (card?.is_archived || card?.status === "Archived") {
      return "Archived";
    } else {
      return card?.status;
    }
  };
  const latestMaintenance = latestApprovedMaintenance(maintenances);
  return (
    <Card className="shadow bg-product product-card">
      <p className={"text-center text-uppercase " + getStatusColor()}>
        {getCardName()}
      </p>
      <Card.Body>
        <Card.Text className="d-flex px-3">
          <small>{card?.vendor_id_name}</small>
        </Card.Text>
        <h4 className="px-3 pb-3">
          <strong>{card?.product_name}</strong>
        </h4>
        <div className="d-flex px-3 my-3">
          <Stack className="card-info">
            <p className="mb-0">VID</p>
            <strong>{card?.v_id}</strong>
          </Stack>
          {(card?.is_archived || card?.status === "Archived") && (
            <Stack className="card-info">
              <p className="mb-0">Archived Date</p>
              <strong>
                {card?.sunset_date ? card?.sunset_date : "Pending"}
              </strong>
            </Stack>
          )}
        </div>
        <div className="d-flex px-3 my-3">
          {card?.certification_date && (
            <Stack className="card-info">
              <p className="mb-0">Certification Date</p>
              <strong>
                {moment.utc(card?.certification_date).format("MM/DD/YYYY")}
              </strong>
            </Stack>
          )}
          {card?.status === "In Progress" && (
            <Stack className="card-info">
              <p className="mb-0">Kickoff Date</p>
              <strong>
                {moment.utc(card?.kicked_off_date).format("MM/DD/YYYY")}
              </strong>
            </Stack>
          )}
          {card?.assurance_maintenance_date && (
            <Stack className="card-info">
              <p className="mb-0">Assurance Maintenance Date</p>
              <strong>
                {moment
                  .utc(card?.assurance_maintenance_date)
                  .utc()
                  .format("MM/DD/YYYY")}
              </strong>
            </Stack>
          )}
        </div>
        <div className="d-flex px-3 my-3">
          <Stack className="card-info">
            <p className="mb-0">Conformance Claims:</p>
            {/* { projpps && projpps[card?.product_id]?.map((projpp, idx) => ( */}
            <ListGroup>
              {projpps &&
                projpps[card?.product_id]?.map((projpp, idx) => (
                  <OverlayTrigger
                    key={idx}
                    placement="left"
                    overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
                  >
                    <div key={idx}>
                      <Link to={`/protectionprofiles/${projpp?.id}`}>
                        <strong className="py-0 my-0">
                          {projpp?.pp_short_name}
                        </strong>
                      </Link>
                    </div>
                  </OverlayTrigger>
                ))}
            </ListGroup>
            {/* ))} */}
          </Stack>
        </div>
        <div className="d-flex px-3 my-3">
          <Stack className="card-info">
            <p className="mb-0">CCTL</p>
            {card?.assigned_lab_abbrv ? (
              <p>
                <strong>{card?.assigned_lab_abbrv}</strong>
              </p>
            ) : (
              <p>
                <strong>{card?.assigned_lab_name}</strong>
              </p>
            )}
          </Stack>
          {latestMaintenance &&
            !("from_cc_portal" in card) &&
            card.status !== "Archived" && (
              <Stack className="card-info">
                <p className="mb-0">Maintenance Updates:</p>
                <strong>
                  {moment
                    .utc(latestMaintenance?.completion_date)
                    .format("YYYY.MM.DD")}
                  &nbsp;
                  <Link
                    to={`/products/${card?.product_id}/assurancemaintenance/${latestMaintenance?.maintenance_id}`}
                  >
                    {latestMaintenance?.title}
                  </Link>
                </strong>
              </Stack>
            )}
          {"from_cc_portal" in card && card.status !== "Archived" && (
            <Stack className="card-info">
              <p className="mb-0">Maintenance Updates:</p>
              {maintenances?.map((maintenance, idx) => (
                <strong>
                  {moment
                    .utc(maintenance?.completion_date)
                    .format("YYYY.MM.DD")}
                  &nbsp;
                  <Link
                    to={`/products/international-product/${card?.product_id}/assurancemaintenance/${maintenance?.maintenance_id}`}
                  >
                    {maintenance?.title}
                  </Link>
                </strong>
              ))}
            </Stack>
          )}
          {latestMaintenance &&
            !("from_cc_portal" in card) &&
            card.status === "Archived" && (
              <Stack className="card-info">
                <p className="mb-0">Maintenance Updates:</p>
                <strong>
                  {moment
                    .utc(latestMaintenance?.completion_date)
                    .format("YYYY.MM.DD")}
                  &nbsp;{latestMaintenance?.title}
                </strong>
              </Stack>
            )}
          {"from_cc_portal" in card && card.status === "Archived" && (
            <Stack className="card-info">
              <p className="mb-0">Maintenance Updates:</p>
              {maintenances?.map((maintenance, idx) => (
                <strong>
                  {moment
                    .utc(maintenance?.completion_date)
                    .format("YYYY.MM.DD")}
                  &nbsp;{maintenance?.title}
                </strong>
              ))}
            </Stack>
          )}
        </div>
        <Card.Text className="d-flex flex-wrap px-3 my-3 align-items-center">
          {card?.submitting_country_id_code && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  {card?.submitting_country_id_name}
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <img
                  ref={ref}
                  {...triggerHandler}
                  src={require(
                    `../../../images/flags2/${card?.submitting_country_id_code?.toLowerCase()}.png`,
                  )}
                  className="me-5"
                  height="50px"
                  alt="Country Flag"
                />
              )}
            </OverlayTrigger>
          )}
          {card?.status !== "Archived" &&
            card?.certification_status === "Certified" && (
              <Link
                to={
                  !("from_cc_portal" in card)
                    ? `/products/${card?.product_id}`
                    : `/products/international-product/${card?.product_id}`
                }
              >
                <Button
                  variant="light"
                  className="border-dark ms-auto btn-hover"
                >
                  Full Details
                </Button>
              </Link>
            )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
