import React from "react";
import { Row, Table } from "react-bootstrap";

const ParticipationLevelsTable = () => {
  const levels = [0, 1, 10, 200, 300, 400, 500, 600];
  const descriptions = [
    "Not a Member",
    "Member, Read Only",
    "Member, Not on the Mailing List",
    "Member on the Mailing List",
    "Member with Notifications",
    "Member on the Contact List",
    "Team Admin",
    "Team Lead",
  ];

  return (
    <Row>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <p className="fw-bold mb-0">Team Participations Levels:</p>
          </tr>
        </thead>
        <tbody>
          {levels.map((level, index) => (
            <tr key={index}>
              <td>{level}</td>
              <td>{descriptions[index]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Row>
  );
};

export default ParticipationLevelsTable;
