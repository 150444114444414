import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

import HTMLEditor from "../../../UI/HTMLEditor";

export default function EarFormSection({
  product,
  editedProduct,
  ears,
  setEars,
  handleChange,
  reload,
  setJustificationValid,
  justificationValid,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];

  const removeFile = async (file, earIndex, fileIndex) => {
    if (file.file_id) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => reload())
        .catch((error) => console.log("Unable to update file", error));
    } else {
      const newEars = [...ears];
      newEars[earIndex].files.splice(fileIndex, 1);
      setEars(newEars);
    }
  };

  const removeEar = async (ear, earIndex) => {
    if (ear.earid) {
      await axios
        .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${ear.earid}/`, {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        })
        .then((response) => reload())
        .catch((error) => console.log("Unable to update ear", error));
    } else {
      const newEars = [...ears];
      newEars.splice(earIndex, 1);
      setEars(newEars);
    }
  };

  const addEar = () => {
    setEars([...ears, { id: ears.length + 1, files: [], apriori: false }]);
  };

  const handleEarChange = (index, key, value) => {
    const newEars = [...ears];
    if (key === "file") {
      const newFiles = ears[index].files ? ears[index].files : [];
      newFiles.push({
        file_display_name: ears[index]?.file_diplay_name,
        uploaded_on: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ).toISOString(),
        file_name: value[0].name,
        file_label: ears[index]?.file_label
          ? ears[index]?.file_label
          : ears[index]?.file_diplay_name,
        file: value[0],
        active: true,
        private: true,
      });
      newEars[index].files = newFiles;
    } else {
      newEars[index][key] = value;
    }
    setEars(newEars);
  };

  return (
    <Row className="mt-4">
      <Col>
        <Row>
          <p className="fw-bold form-title">ENTROPY ASSESSMENT REPORT</p>
        </Row>
        <Row className="mx-3 mt-2 mb-2">
          <Col lg={6}>
            <Form.Group className="mt-1" controlId="ear_required">
              <Form.Check
                inline
                label={<p className="m-0">This Product Requires an EAR?</p>}
                name="ear_required"
                type="checkbox"
                defaultChecked={product?.ear_required === true}
                value={false}
                onChange={handleChange}
                id="ear_required"
              />
            </Form.Group>
          </Col>
          {(editedProduct?.ear_required === true ||
            product?.ear_required === true) && (
            <>
              {ears.map((ear, earIndex) => (
                <Container
                  key={`product-ear-${ear.earid}`}
                  className="border p-3 mb-3 mt-2"
                >
                  <Row>
                    <Col lg={6}>
                      <Form.Group
                        className="mt-1"
                        controlId={`apriori_${ear.earid}`}
                      >
                        <Form.Check
                          inline
                          label={
                            <p className="m-0">
                              This EAR was previously approved?
                            </p>
                          }
                          name={`apriori_${ear.earid}`}
                          type="checkbox"
                          checked={ear.apriori}
                          onChange={(e) =>
                            handleEarChange(
                              earIndex,
                              "apriori",
                              e.target.checked,
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-end">
                      <Button
                        variant="outline-primary"
                        className="attachment-remove"
                        onClick={() => removeEar(ear, earIndex)}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Row>
                      <p className="fw-bold form-title">ATTACH EAR FILE</p>
                    </Row>
                    <Row>
                      <Col xl={6} sm={12}>
                        <Form.Group controlId={`ear_file_label_${ear.id}`}>
                          <Form.Label className="small text-secondary">
                            File Label
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`ear_file_label_${ear.id}`}
                            onChange={(e) =>
                              handleEarChange(
                                earIndex,
                                "file_label",
                                e.target.value,
                              )
                            }
                            defaultValue={"Entropy Assessment Report (EAR)"}
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={6} sm={12}>
                        <Form.Group
                          className="text-start"
                          controlId={`ear_file_public_status_${ear.id}`}
                        >
                          <Form.Label className="small text-secondary">
                            File Type
                          </Form.Label>
                          <Form.Select
                            name={`ear_file_display_name_${ear.id}`}
                            onChange={(e) =>
                              handleEarChange(
                                earIndex,
                                "file_diplay_name",
                                e.target.value,
                              )
                            }
                          >
                            <option value=""></option>
                            <option value="Entropy Assessment Report (EAR)">
                              Entropy Assessment Report (EAR)
                            </option>
                            <option value="Supplemental EAR Documentation">
                              Supplemental EAR Documentation
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg={8} sm={12} className="mt-2">
                        <Form.Group controlId={`ear_file_${ear.id}`}>
                          <Form.Label className="small text-secondary">
                            Attach EAR Files
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name={`ear_file_${ear.id}`}
                            onChange={(e) =>
                              handleEarChange(earIndex, "file", e.target.files)
                            }
                            multiple
                            required={ear?.files?.length === 0}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                  <Container className="mt-4">
                    {ear?.files?.map((file, fileIndex) => (
                      <Row
                        className="m-3 border-bottom text-center"
                        key={`product-ear-file-${file.file_id}`}
                      >
                        <Col sm={3}>{file?.file_name}</Col>
                        <Col sm={4}>{file?.file_display_name}</Col>
                        <Col sm={3}>{file?.file_label}</Col>
                        <Col sm={2}>
                          <Button
                            variant="outline-primary"
                            className="attachment-remove"
                            onClick={() =>
                              removeFile(file, earIndex, fileIndex)
                            }
                          >
                            X
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </Container>
                </Container>
              ))}
              <Button
                variant="outline-primary"
                className="mb-3"
                onClick={addEar}
              >
                + Add Another EAR
              </Button>
            </>
          )}
        </Row>
        {(product?.ear_required === false ||
          editedProduct?.ear_required === false ||
          (!editedProduct?.ear_required && !product.product_id)) && (
          <Col className="mb-3">
            <Row>
              <Form.Label className="mt-3">
                Justification for No EAR Required:*
              </Form.Label>
              <HTMLEditor
                name="no_ear_justification"
                handleChange={handleChange}
                setIsValid={setJustificationValid}
                isValid={justificationValid}
                defaultValue={product.no_ear_justification}
              />
            </Row>
          </Col>
        )}
      </Col>
    </Row>
  );
}
