import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useCookies } from "react-cookie";

const RemoveOneMemberMultipleListsForm = ({
  show,
  onHide,
  fetchMailingList,
  setAlert,
}) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [editedMember, setEditedMember] = useState({
    members: [{ name: "", primarySmtpAddress: "" }],
    listNames: [],
  });
  const [lists, setLists] = useState([]);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [loadingLists, setLoadingLists] = useState(false);

  const handleReset = () => {
    setEditedMember({
      members: [{ name: "", primarySmtpAddress: "" }],
      listNames: [],
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "name") {
      const members = editedMember.members;
      members[0].name = event.target.value;
      setEditedMember({ ...editedMember, members: members });
    } else if (event.target.name === "email") {
      const members = editedMember.members;
      members[0].primarySmtpAddress = event.target.value;
      setEditedMember({ ...editedMember, members: members });
    } else {
      const listNames = editedMember.listNames;
      listNames.push(event.target.value);
      setEditedMember({ ...editedMember, listNames: listNames });
    }
  };

  const fetchMemberLists = useCallback(() => {
    setFetchComplete(false);
    setLoadingLists(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/by_member/${encodeURI(editedMember.members[0].primarySmtpAddress)}/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setLists(response.data);
        setFetchComplete(true);
        setLoadingLists(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [editedMember.members[0].primarySmtpAddress]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/members/delete/`,
          {
            ...editedMember,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          },
        )
        .then((response) => {
          fetchMailingList();
          onHide();
          setAlert({
            variant: "success",
            message: "Member removed successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          setAlert({
            variant: "danger",
            message: "Error removing Member. Please try again.",
          });
        });
    }
  };

  useEffect(() => {
    setEditedMember({
      members: [{ name: "", primarySmtpAddress: "" }],
      listNames: [],
    });
  }, [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Mailing List Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" name="email" onChange={handleChange} />
          </Form.Group>
          <Button
            className="m-3"
            type="button"
            variant="info"
            onClick={fetchMemberLists}
          >
            Get Associated Lists
          </Button>
          {loadingLists && (
            <div>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              &nbsp;<span className="h3">Loading User Mailing Lists...</span>
            </div>
          )}
          {fetchComplete && (
            <Form.Group controlId="ccp">
              <Form.Label>Member Lists</Form.Label> <br />
              <Row>
                {lists?.map((item, idx) => (
                  <Col sm="4" key={`ccp_lists_${idx}`}>
                    <Form.Check
                      type="checkbox"
                      name="listName"
                      onChange={handleChange}
                      label={item.name}
                      value={item.name}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button type="reset" onClick={handleReset} variant="warning">
            Reset
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="success">
            Remove
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RemoveOneMemberMultipleListsForm;
