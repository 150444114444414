import parse from "html-react-parser";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import ActionsModal from "./ActionsModal";
import references from "./References.json";

const References = () => {
  const [action, setAction] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const setActionNumber = (number) => {
    setAction(number);
    setShowModal(true);
  };

  const columns = [
    {
      title: "Reference ##",
      field: "reference",
    },
    {
      title: "Name and Link",
      field: "link",
      render: (rowData) => parse(rowData.link),
      sorting: false,
    },
    {
      title: "Related Actions and Artifacts",
      field: "actions",
      render: (rowData) =>
        rowData?.actions?.map((number) => (
          <Button
            className="p-0"
            style={{ minWidth: 25 }}
            variant="link"
            onClick={() => setActionNumber(number)}
          >
            {number},
          </Button>
        )),
      sorting: false,
    },
  ];

  const options = {
    showTitle: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: false,
    pageSize: 20,
  };

  return (
    <Container fluid>
      <ActionsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        actionNumber={action}
      />
      <Row className="mt-5 mb-3">
        <Col sm={12}>
          <h3 className="text-bright-navy">Training Center</h3>
        </Col>
      </Row>
      <Row className="mt-5 mb-3">
        <Col sm={12}>
          <ResponsiveMaterialTable
            title={"References"}
            columns={columns}
            options={options}
            data={references}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default References;
