import axios from "axios";
import { useEffect, useState } from "react";
import { Form, Modal, Button, Row, Col, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { getISOCurrentDate } from "../../../utils/timeUtils";
import ReactSelect from "../../ReactSelect";

const NISTForm = ({ show, onHide, refetch }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [formInfo, setFormInfo] = useState({});
  const [nistFiles, setNistFiles] = useState([]);
  const [newNistFiles, setNewNistFiles] = useState([]);
  const [nistEditedFileLabel, setNistEditedFileLabel] = useState("");
  const [productValid, setProductValid] = useState(true);
  const [products, setProducts] = useState([]);
  const [formAlert, setFormAlert] = useState({ message: "", type: "" });

  const fileTypes = [
    "Administrative Guide (AGD)",
    "Assurance Activity Report (AAR)",
    "Draft CC Certificate",
    "Evaluation Technical Report",
    "Security Target",
    "Sync Meeting Notes",
    "Test Reports",
    "Draft Validation Report",
    "Other Documents",
  ];

  const getProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_in_eval_products/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setProducts(
          response.data.map((item) => ({
            value: item.product_id,
            label: "VID" + item.v_id,
          })),
        );
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const removeFile = async (fileInfo, idx) => {
    const newFiles = nistFiles.slice();
    newFiles.splice(idx, 1);
    setNistFiles(newFiles);
    setNewNistFiles(newFiles);
  };
  const handleCheckNistFileAdded = () => {
    if (newNistFiles?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleValidation = () => {
    handleCheckNistFileAdded();
    if (!handleCheckNistFileAdded()) {
      setFormAlert({
        message:
          "NIST Certificate Review selected as required. Please select NIST Review file.",
        type: "danger",
      });
      return false;
    } else {
      setFormAlert({ message: "", type: "" });
      return true;
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "nist_file") {
      const nistFilesArr = nistFiles ? nistFiles : [];
      const newNistFilesArr = newNistFiles ? newNistFiles : [];
      const nistFile = {
        file_type: "nist",
        file_source_type: "Certificate Review",
        file_display_name: formInfo.nist_file_display_name,
        uploaded_on: getISOCurrentDate(),
        file_name: e.target.files[0].name,
        file_label: formInfo.nist_file_label,
        active: true,
        file: e.target.files[0],
        private: formInfo.nist_file_public_status === "Public" ? false : true,
      };
      nistFilesArr.push(nistFile);
      newNistFilesArr.push(nistFile);
      setNistFiles([...nistFilesArr]);
      setNewNistFiles([...newNistFilesArr]);
    } else if (e.target.name === "nist_file_display_name") {
      setNistEditedFileLabel(e.target.value);
      setFormInfo({
        ...formInfo,
        [e.target.name]: e.target.value,
        nist_file_label: e.target.value,
      });
    } else if (e.target.name === "nist_file_label") {
      setNistEditedFileLabel(e.target.value);
      setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
    } else {
      setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
    }
  };

  const checkReactSelects = () => {
    let isValid = true;
    if (!formInfo?.pid) {
      setProductValid(false);
      isValid = false;
    } else if (handleValidation() === false) {
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    checkReactSelects();
    if (form.checkValidity() === false || checkReactSelects() === false) {
      e.stopPropagation();
    } else {
      const nistData = new FormData();
      nistData.append("pid", formInfo.pid);
      nistData.append("reqd", true);
      nistData.append("entrydate", getISOCurrentDate());
      if (newNistFiles.length > 0) {
        for (let f in newNistFiles) {
          if (!newNistFiles[f].file_mime_type) {
            nistData.append("attachments_file", newNistFiles[f].file);
            nistData.append(
              "attachments_source_type",
              newNistFiles[f].file_source_type,
            );
            nistData.append(
              "attachments_type",
              newNistFiles[f].file_display_name,
            );
            nistData.append(
              "attachments_uploaded",
              newNistFiles[f].uploaded_on,
            );

            nistData.append("private", newNistFiles[f].private);
            nistData.append("file_label", newNistFiles[f].file_label);
          }
        }
      }
      axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}nist/`, nistData, {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          onHide();
          refetch();
        })
        .catch((error) => console.log("Unable to submit new product: ", error));
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>Add Certificate Review</Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col lg={12}>
              <Form.Label htmlFor="product">Associated Product:*</Form.Label>
              <ReactSelect
                data-testid="associated_product_select"
                id="product"
                options={products}
                isValid={productValid}
                name="pid"
                newObj={formInfo}
                handleChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <h6>NIST Review Files*</h6>
            <Form.Text className="text-muted fst-italic">
              Required for Certificate Review
            </Form.Text>
            <Col xl={3} sm={12}>
              <Form.Group
                className="mb-4 text-start"
                controlId="nist_file_display_name"
              >
                <Form.Label className="small text-secondary">Type</Form.Label>
                <Form.Select
                  name="nist_file_display_name"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  {fileTypes.map((type, idx) => (
                    <option value={type} key={idx}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xl={3} sm={12}>
              <Form.Group
                className="mb-4 text-start"
                controlId="nist_file_public_status"
              >
                <Form.Label className="small text-secondary">
                  Document Visibility
                </Form.Label>
                <Form.Select
                  name="nist_file_public_status"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Public">Public</option>
                  <option value="Proprietary">Proprietary</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xl={6} sm={12}>
              <Form.Group controlId="nist_file_label">
                <Form.Label className="small text-secondary">
                  File Label
                </Form.Label>
                <Form.Control
                  value={nistEditedFileLabel}
                  type="text"
                  name="nist_file_label"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xl={6} sm={12} className="mb-3">
              <Form.Group controlId="nist_file">
                <Form.Label className="small text-secondary">
                  Attach Files
                </Form.Label>
                <Form.Control
                  type="file"
                  name="nist_file"
                  onChange={handleChange}
                  disabled={
                    !formInfo.nist_file_public_status ||
                    !formInfo.nist_file_display_name
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={12}>
              {nistFiles?.length > 0 && (
                <div className="border">
                  <p className="mx-3 mt-2 fw-bold">Attached Files</p>
                  {nistFiles
                    .filter((file) => file?.active === true)
                    ?.map((file, idx) => (
                      <Row
                        className="m-3 d-flex justify-content-around"
                        key={idx}
                      >
                        <Col sm={4}>{file.file_display_name}</Col>
                        <Col sm={4}>{file.file_label}</Col>
                        <Col sm={2}>
                          {file.private ? "Proprietary" : "Public"}
                        </Col>
                        <Col sm={1} className="d-flex justify-content-center">
                          <Button
                            variant="outline-primary"
                            className="attachment-remove"
                            onClick={() => removeFile(file, idx)}
                          >
                            X
                          </Button>
                        </Col>
                      </Row>
                    ))}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Alert variant={formAlert.type}>{formAlert.message}</Alert>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide} type="reset">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} type="submit">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NISTForm;
