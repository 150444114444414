import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import { useEffect, useCallback, useRef } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { useCookies } from "react-cookie";
import { useParams, useNavigate } from "react-router-dom";

import "./saveSignature.css";
import { getCurrentDate } from "../../../utils/timeUtils";

const SaveSignature = ({ setOverlayActive, setOverlayText }) => {
  const { packageType, id, signer } = useParams();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];

  const saveSignature = useCallback(() => {
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/save_signature/`,
        {
          product_id: id,
          packageType: packageType,
          signer: signer,
          uploaded_on: getCurrentDate(),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        setOverlayActive(false);
        if (signer !== "Vendor") {
          navigate(`/community/products/details/${id}`);
        } else {
          navigate(`/community`);
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setOverlayText("Redirecting");
    setOverlayActive(true);
    saveSignature();
  }, []);

  return (
    <Container>
      <Card className="login-card success">
        <Card.Body>
          <CheckBoxIcon color="primary" sx={{ fontSize: 100 }} />
          <Card.Title as="h4">Success</Card.Title>
          <p className="small">You are being redirected...</p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SaveSignature;
