import {
  Accordion,
  Col,
  Container,
  Row,
  Button,
  Stack,
  Alert,
  Form,
} from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import "moment-timezone";
import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { useInterval } from "../../../hooks/useInterval";

import EscalateToTDModal from "./EscalateToTDModal";
import ItcInformationModal from "./ItcInformationModal";
import TQFileUploadModal from "./TQFileUploadModal";

export default function TRRTSideInfo({
  technicalQuery,
  setAlertInfo,
  handleCloseModal,
  setOverlayActive,
  setOverlayText,
  handleChange,
  handleSave,
  trrtMember,
  updateTechnicalHistory,
}) {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const permissions = useSelector((state) => state.role.value);
  const [files, setFiles] = useState([]);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showEscalateModal, setShowEscalateModal] = useState(false);
  const [showItcModal, setShowItcModal] = useState(false);
  const [editDeadline, setEditDeadline] = useState(false);

  const handleCloseEscalateModal = () => {
    setShowEscalateModal(false);
    handleCloseModal();
  };
  const handleCloseItcModal = () => {
    setShowItcModal(false);
  };

  const fetchFiles = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=technical_query&file_type_id=${technicalQuery?.tq_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setFiles(response.data);
      })
      .catch((error) => {
        setFiles([]);
      });
  }, [technicalQuery?.tq_id]);

  useEffect(() => {
    if (technicalQuery?.tq_id) {
      fetchFiles();
    }
  }, [fetchFiles, technicalQuery?.tq_id]);

  useInterval(() => {
    if (technicalQuery?.tq_id) {
      fetchFiles();
    }
  }, 180000); //3 min

  const handleCloseFileUploadModal = () => {
    setShowFileUploadModal(false);
  };

  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          responseType: "blob",
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveFile = (file) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
        {
          active: false,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        fetchFiles();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mt-3">
      <TQFileUploadModal
        show={showFileUploadModal}
        handleClose={handleCloseFileUploadModal}
        technicalQuery={technicalQuery}
        files={files}
        handleRemoveFile={handleRemoveFile}
        refetch={fetchFiles}
      />
      <EscalateToTDModal
        show={showEscalateModal}
        handleClose={handleCloseEscalateModal}
        technicalQuery={technicalQuery}
        editTeamsView={true}
        setAlertInfo={setAlertInfo}
        updateTechnicalHistory={updateTechnicalHistory}
      />
      <ItcInformationModal
        show={showItcModal}
        handleClose={handleCloseItcModal}
        technicalQuery={technicalQuery}
        handleChange={handleChange}
        handleSave={handleSave}
      />
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Modification History</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  <Row>
                    <p className="text-secondary">Last Modified:</p>
                  </Row>
                  <Row className="border-bottom">
                    {technicalQuery?.modified_by && (
                      <p>
                        {technicalQuery?.modified_on &&
                          moment
                            .utc(technicalQuery.modified_on)
                            .format("YYYY.MM.DD [at] HH:mm")}{" "}
                        by
                        <br></br>
                        {technicalQuery?.modified_by?.first_name}{" "}
                        {technicalQuery?.modified_by?.last_name}{" "}
                      </p>
                    )}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Author Information</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  <Row>
                    <Col xs={3}>
                      <Row>
                        <p>Name:</p>
                      </Row>
                      <Row>
                        <p>Email:</p>
                      </Row>
                      <Row>
                        <p>Additional Emails:</p>
                      </Row>
                    </Col>
                    <Col xs={9}>
                      <Row>
                        <p className="text-secondary">
                          {technicalQuery?.name
                            ? technicalQuery?.name
                            : technicalQuery?.submitted_by?.first_name +
                              " " +
                              technicalQuery?.submitted_by?.last_name}
                        </p>
                      </Row>
                      <Row>
                        <p className="text-secondary text-break">
                          {technicalQuery?.email}
                        </p>
                      </Row>
                      <Row>
                        {technicalQuery?.additional_emails
                          ?.split(";")
                          ?.map((email, idx) => (
                            <p
                              key={idx}
                              className="text-secondary m-0 text-break mx-2"
                            >
                              {email}
                            </p>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Team Information</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  {permissions.role_permissions.find(
                    (permission) => permission.area === "Technical Questions",
                  )?.update &&
                    !["TD Required", "Resolved", "Proposed"].includes(
                      technicalQuery?.status,
                    ) && (
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <EditIcon
                            fontSize="small"
                            className="clickable"
                            onClick={() => setShowEscalateModal(true)}
                          />
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col xs={4} className="mt-2">
                      <Row>
                        <p>TRRT Team:</p>
                      </Row>
                      <Row>
                        <p>Extended Team(s):</p>
                      </Row>
                    </Col>
                    <Col xs={8}>
                      <Row className="p-3">
                        <div className="d-flex justify-content-between">
                          <p className="text-secondary m-0">
                            {technicalQuery?.team?.team}
                          </p>
                        </div>
                      </Row>
                      <Row className="p-3">
                        {technicalQuery?.trrt_team?.map((team, idx) => (
                          <div
                            key={idx}
                            className="d-flex justify-content-between"
                          >
                            <p className="text-secondary m-0">{team.team}</p>
                          </div>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Important Dates</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  {(permissions.role_type === "NIAP" || trrtMember) &&
                    !editDeadline && (
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <EditIcon
                            fontSize="small"
                            className="clickable"
                            onClick={() => setEditDeadline(true)}
                          />
                        </Col>
                      </Row>
                    )}
                  {(permissions.role_type === "NIAP" || trrtMember) &&
                    editDeadline && (
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <SaveIcon
                            fontSize="small"
                            className="clickable"
                            onClick={() => {
                              handleSave("Updated Deadline Date");
                              setEditDeadline(false);
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  <Row className="me-2">
                    <Col xs={6}>
                      <Row>
                        <p>Submission Date:</p>
                      </Row>
                      <Row>
                        <p>Deadline Date:</p>
                      </Row>
                      <Row>
                        <p>Completion or Termination Date:</p>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <p className="text-secondary">
                          {moment
                            .utc(technicalQuery?.submitted_on)
                            .format("MM/DD/YYYY")}
                        </p>
                      </Row>
                      <Row>
                        {editDeadline ? (
                          <Form.Group controlId="deadline">
                            <Form.Label className="visually-hidden">
                              Edit Deadline Date
                            </Form.Label>
                            <Form.Control
                              name="deadline"
                              type="date"
                              defaultValue={moment
                                .utc(technicalQuery.deadline)
                                .format("YYYY-MM-DD")}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        ) : (
                          <p className="text-secondary">
                            {moment
                              .utc(technicalQuery?.deadline)
                              .format("MM/DD/YYYY")}
                          </p>
                        )}
                      </Row>
                      <Row>
                        {(technicalQuery?.status === "Resolved" ||
                          technicalQuery?.status === "Rejected") && (
                          <p className="text-secondary">
                            {technicalQuery?.response_on
                              ? moment
                                  .utc(technicalQuery?.response_on)
                                  .format("MM/DD/YYYY")
                              : "No Date"}
                          </p>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">
              <span className="text-lowercase">i</span>
              TC INFORMATION
            </h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  {permissions.role_permissions.find(
                    (permission) => permission.area === "Technical Questions",
                  )?.update &&
                    !["TD Required", "Resolved", "Proposed"].includes(
                      technicalQuery?.status,
                    ) && (
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <EditIcon
                            fontSize="small"
                            className="clickable"
                            onClick={() => setShowItcModal(true)}
                          />
                        </Col>
                      </Row>
                    )}
                  <Row>
                    <Col xs={6}>
                      <Row>
                        <p>Interpretation Team Status:</p>
                      </Row>
                      <Row>
                        <p>Date Sent:</p>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <p className="text-secondary p-2">
                          {technicalQuery?.team_status
                            ? technicalQuery?.team_status
                            : "None"}
                        </p>
                      </Row>
                      <Row>
                        <p className="text-secondary p-2">
                          {technicalQuery?.date_sent
                            ? moment
                                .utc(technicalQuery?.date_sent)
                                .format("MM/DD/YYYY")
                            : "None"}
                        </p>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion flush className="border border-dark mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">TRRT File Area</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  {permissions.role_permissions.find(
                    (permission) => permission.area === "Technical Questions",
                  )?.update &&
                    !["TD Required", "Resolved", "Proposed"].includes(
                      technicalQuery?.status,
                    ) && (
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <EditIcon
                            fontSize="small"
                            className="clickable"
                            onClick={() => setShowFileUploadModal(true)}
                          />
                        </Col>
                      </Row>
                    )}
                  {files
                    ?.filter((file) => file.active === true)
                    ?.map((file, idx) => (
                      <Stack
                        direction="horizontal"
                        className="d-flex justify-content-left align-items-start mt-3"
                        key={idx}
                      >
                        {file.file_mime_type === "application/pdf" && (
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            color="red"
                            size="xl"
                          />
                        )}
                        {file.file_mime_type !== "application/pdf" && (
                          <FontAwesomeIcon
                            icon={faFileLines}
                            color="gray"
                            size="xl"
                          />
                        )}
                        <div className="ms-2 text-break">
                          <Button
                            style={{ overflowWrap: "break-word" }}
                            className="text-start m-0 p-0"
                            variant="link"
                            onClick={() => downloadFile(file)}
                          >
                            {file.file_label
                              ? file.file_label
                              : file.file_new_name
                                ? file.file_new_name
                                : file.file_name}
                          </Button>
                          <p className="mb-0 ms-3">
                            {file.file_new_name
                              ? file.file_new_name
                              : file.file_name}
                          </p>
                          <p className="mb-0 ms-3">
                            Attached by {file?.uploaded_by?.first_name}{" "}
                            {file?.uploaded_by?.last_name} on{" "}
                            {file?.uploaded_on &&
                              moment
                                .utc(file?.uploaded_on)
                                .format("YYYY.MM.DD [at] HHmm")}
                          </p>
                        </div>
                        <Button
                          variant="outline-primary"
                          className="attachment-remove"
                          onClick={() => handleRemoveFile(file)}
                        >
                          X
                        </Button>
                      </Stack>
                    ))}
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
