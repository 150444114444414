import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";

const OneMemberMultipleListsForm = ({
  show,
  lists,
  onHide,
  fetchMailingList,
  setAlert,
}) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  let authToken = cookies["auth_token"];
  const [editedMember, setEditedMember] = useState({
    members: [{ name: "", primarySmtpAddress: "" }],
    listNames: [],
  });

  const handleReset = () => {
    setEditedMember({
      members: [{ name: "", primarySmtpAddress: "" }],
      listNames: [],
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "name") {
      const members = editedMember.members;
      members[0].name = event.target.value;
      setEditedMember({ ...editedMember, members: members });
    } else if (event.target.name === "email") {
      const members = editedMember.members;
      members[0].primarySmtpAddress = event.target.value;
      setEditedMember({ ...editedMember, members: members });
    } else {
      const listNames = editedMember.listNames;
      listNames.push(event.target.value);
      setEditedMember({ ...editedMember, listNames: listNames });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/members/`,
          {
            ...editedMember,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          },
        )
        .then((response) => {
          fetchMailingList();
          onHide();
          setAlert({
            variant: "success",
            message: "Member added successfully",
          });
        })
        .catch((error) => {
          console.log(error);
          setAlert({
            variant: "danger",
            message: "Error adding Member. Please try again.",
          });
        });
    }
  };

  useEffect(() => {
    setEditedMember({
      members: [{ name: "", primarySmtpAddress: "" }],
      listNames: [],
    });
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Mailing List Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" name="email" onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="ccp">
            <Form.Label>
              <b>Common Critera Portal Lists</b>
            </Form.Label>{" "}
            <br />
            <Row>
              {lists
                ?.filter((item) => item?.displayName?.includes("ccp_"))
                .map((item, idx) => (
                  <Col sm="4" key={`ccp_lists_${idx}`}>
                    <Form.Check
                      type="checkbox"
                      name="listName"
                      onChange={handleChange}
                      label={item.name}
                      value={item.name}
                    />
                  </Col>
                ))}
            </Row>
          </Form.Group>
          <Form.Group controlId="niap">
            <Form.Label>
              <b>NIAP-CCEVS Lists</b>
            </Form.Label>{" "}
            <br />
            <Row>
              {lists
                ?.filter((item) => !item?.displayName?.includes("ccp_"))
                .map((item, idx) => (
                  <Col sm="4" key={`niap_lists_${idx}`}>
                    <Form.Check
                      type="checkbox"
                      name="listName"
                      onChange={handleChange}
                      label={item.name}
                      value={item.name}
                    />
                  </Col>
                ))}
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button type="reset" onClick={handleReset} variant="warning">
            Reset
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="success">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default OneMemberMultipleListsForm;
