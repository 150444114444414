import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Stack, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Products/Products.css";
import moment from "moment";

export default function PPListItem({ listItem }) {
  const statusColors = {
    Archived: "bg-orange",
    Development: "bg-yellow",
    Planning: "bg-yellow",
    Initiation: "bg-yellow",
    Publishing: "bg-green",
  };

  return (
    <Card className="shadow bg-product">
      <Row>
        <Col sm={1}>
          <div
            className={
              "list-card-bar " +
              (listItem?.sunset_date &&
              moment(listItem?.sunset_date).isBefore(moment())
                ? statusColors["Archived"]
                : statusColors[listItem?.status])
            }
          ></div>
        </Col>
        <Col xs={7} sm={8}>
          <Card.Body>
            <h4 className="fw-bold pt-3">{listItem?.pp_name}</h4>
            <div className="d-flex px-3 my-4">
              <Stack className="card-info">
                <p className="mb-0 text-decoration-underline">Short Name</p>
                <Link to={`/protectionprofiles/${listItem?.pp_id}`}>
                  <strong className="text-decoration-none">
                    {listItem?.pp_short_name}
                  </strong>
                </Link>
              </Stack>
              <Stack className="card-info">
                <p className="mb-0 text-decoration-underline">
                  Technology Type
                </p>
                <strong>{listItem?.tech_type}</strong>
              </Stack>
            </div>
            <div className="d-flex px-3 my-4">
              {listItem?.pp_date && (
                <Stack className="card-info">
                  <p className="mb-0 text-decoration-underline">
                    Approval Date
                  </p>
                  <strong>
                    {moment.utc(listItem?.pp_date).format("MM/DD/YYYY")}
                  </strong>
                </Stack>
              )}
              <Stack className="card-info">
                <p className="mb-0 text-decoration-underline">CC Version</p>
                <strong>{listItem?.cc_version}</strong>
              </Stack>
            </div>
            <div className="d-flex px-3 my-4">
              {!moment(listItem?.sunset_date).isBefore(moment()) && (
                <Stack className="card-info">
                  <p className="mb-0 text-decoration-underline">Proceeded By</p>
                  <strong>{listItem?.predecessor_id?.pp_short_name}</strong>
                </Stack>
              )}
              {listItem?.sunset_date && (
                <Stack className="card-info">
                  <p className="mb-0 text-decoration-underline">Sunset Date</p>
                  <strong>
                    {moment.utc(listItem?.sunset_date).format("MM/DD/YYYY")}
                  </strong>
                </Stack>
              )}
              <Stack className="card-info">
                <p className="mb-0 text-decoration-underline">Sponsor</p>
                <strong>{listItem?.pp_sponsor_id}</strong>
              </Stack>
            </div>
          </Card.Body>
        </Col>
        <Col xs={3} sm={2} className="d-flex flex-column align-items-end">
          <h6 className="text-end my-3">
            <FontAwesomeIcon
              icon={faCircle}
              className="me-1"
              color={
                listItem?.sunset_date &&
                moment(listItem?.sunset_date).isBefore(moment())
                  ? "var(--bs-orange)"
                  : listItem?.status === "Publishing"
                    ? "var(--bs-green)"
                    : "var(--bs-yellow)"
              }
              size="xs"
            />
            {listItem?.sunset_date &&
            moment(listItem?.sunset_date).isBefore(moment())
              ? "Archived"
              : "Approved"}
          </h6>
          {/* {!moment(listItem?.sunset_date).isBefore(moment()) && listItem?.status === "Publishing" && ( */}
          <Link to={`/protectionprofiles/${listItem?.pp_id}`}>
            <Button variant="light" className="border-dark btn-hover">
              Full Details
            </Button>
          </Link>
          {/* )} */}
        </Col>
      </Row>
    </Card>
  );
}
