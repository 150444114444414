import { useEffect, useState } from "react";
import { Container, Accordion, Row, Col, Button } from "react-bootstrap";

import govCustomersImg from "../../images/niap_ccevs/gov_customers.png";
import industryCustomersImg from "../../images/niap_ccevs/industry_customers.png";
import intPartnersImg from "../../images/niap_ccevs/int_partners.png";
import Subheader from "../Subheader";

import GovCustomersModal from "./StepsModals/GovCustomers";
import IndustryCustomersModal from "./StepsModals/IndustryCustomers";
import IntPartnersModal from "./StepsModals/IntPartners";
import "./niapCcevs.css";

export default function NiapCcevs() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [removeLine, setRemoveLine] = useState(false);

  const [showIndCustomersModal, setShowIndCustomersModal] = useState(false);
  const [showGovCustomersModal, setShowGovCustomersModal] = useState(false);
  const [showIntPartnersModal, setShowIntPartnersModal] = useState(false);

  useEffect(() => {
    document.title = "NIAP - About Us";
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth <= 992) {
      setRemoveLine(true);
    } else {
      setRemoveLine(false);
    }
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <>
      <IndustryCustomersModal
        show={showIndCustomersModal}
        setShowIndCustomersModal={setShowIndCustomersModal}
      />
      <GovCustomersModal
        show={showGovCustomersModal}
        setShowGovCustomersModal={setShowGovCustomersModal}
      />
      <IntPartnersModal
        show={showIntPartnersModal}
        setShowIntPartnersModal={setShowIntPartnersModal}
      />
      <Subheader pageName={"About Us"} />
      <Container fluid>
        <Row className="px-5">
          <Col>
            <Accordion flush className="remove-top-border">
              <Accordion.Item>
                <Accordion.Header>
                  <h5 className="text-uppercase fw-bold pb-3">
                    About Us -{" "}
                    <span className="text-uppercase fw-bold text-bright-navy">
                      What is NIAP/CCEVS
                    </span>
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The National Information Assurance Partnership (NIAP)
                    oversees a national program to evaluate Commercial
                    Off-The-Shelf (COTS) Information Technology (IT) products
                    for conformance to the international Common Criteria. This
                    program includes the NIAP-managed Common Criteria Evaluation
                    and Validation Scheme (CCEVS or Scheme), a national program
                    for developing Protection Profile, evaluation methodologies,
                    and policies that ensures achievable, repeatable, and
                    testable security requirements.
                  </p>
                  <p>
                    The CCEVS is a partnership between the public and private
                    sectors to provide COTS IT products that meet consumer needs
                    and to help manufacturers of those products gain acceptance
                    in the global marketplace. Successful evaluations benefit
                    industry product developers/vendors and government procurers
                    by validating that the products meet security requirements
                    for U.S. national security system procurement. Because NIAP
                    is a member of the international 31-nation Common Criteria
                    Recognition Arrangement (CCRA), NIAP-validated products are
                    also available to procurers in the CCRA member nations.
                  </p>
                  <p>
                    IT security testing is conducted by NIST-accredited and
                    NIAP-approved commercial testing labs. A product vendor
                    chooses an approved lab to complete the product evaluation
                    against a selected applicable Protection Profile. A
                    Protection Profile is an implementation-independent set of
                    security requirements for a particular technology that
                    enables achievable, repeatable, and testable evaluation
                    activities for each evaluation.
                  </p>
                  <p>
                    All products evaluated within the Scheme must demonstrate
                    exact compliance to the applicable technology Protection
                    Profile. NIAP assesses the results of the security
                    evaluation conducted by the lab and, if the evaluation is
                    successful, issues a validation certificate and lists the
                    product on the U.S. NIAP Product Compliant List and the
                    international CCRA Certified Products List. U.S. Customers
                    (designated approving authorities, authorizing officials,
                    integrators, etc.) may treat these mutually-recognized
                    evaluation results as complying with the Committee on
                    National Security Systems Policy (CNSSP) 11, National Policy
                    Governing the Acquisition of Cybersecurity and
                    Cybersecurity-Enabled Information Technology Products -
                    dated June 2013 (
                    <a
                      style={{ wordWrap: "break-word" }}
                      href="https://www.cnss.gov/CNSS/issuances/Policies.cfm"
                    >
                      https://www.cnss.gov/CNSS/issuances/Policies.cfm
                    </a>
                    ).
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h5 className="text-uppercase text-bright-navy fw-bold pb-3">
                    Timeline and Costs
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The entire{" "}
                    <a href="/evaluation-process">evaluation process</a> can be
                    completed in just 90 days but may take up to 6 months. NIAP
                    does not charge for services. IT product vendors/developers
                    contract independently with an approved{" "}
                    <a href="/cctl">
                      Common Criteria Testing Laboratory (CCTL)
                    </a>{" "}
                    for evaluation services. Vendors are encouraged to shop
                    around for the services that best meet their needs, as the
                    expertise, experience, and prices vary. Only approved labs
                    are authorized to provide product evaluations under the
                    CCEVS and CCRA scheme.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion flush>
              <Accordion.Item>
                <Accordion.Header>
                  <h5 className="text-uppercase text-bright-navy fw-bold pb-3">
                    Focus Areas
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <Row className="mb-5">
                    <Col md={4} lg={2}>
                      <div
                        className="clickable"
                        onClick={() => setShowIndCustomersModal(true)}
                      >
                        <div className="d-flex justify-content-center icon-border">
                          <img
                            src={industryCustomersImg}
                            alt="industry customers image"
                            className="w-auto"
                            height="120px"
                            onClick={() => setShowIndCustomersModal(true)}
                          />
                        </div>
                        <div className="text-center">
                          <h6 className="text-uppercase fw-bold mt-3 mb-3 text-primary">
                            Industry Customers
                          </h6>
                          <Button variant="warning">Learn More</Button>
                        </div>
                      </div>
                    </Col>
                    {!removeLine && (
                      <Col>
                        <div className="horizontal-line"></div>
                      </Col>
                    )}
                    <Col md={4} lg={2}>
                      <div
                        className="clickable"
                        onClick={() => setShowGovCustomersModal(true)}
                      >
                        <div className="d-flex justify-content-center icon-border">
                          <img
                            src={govCustomersImg}
                            alt="gov customers image"
                            className="w-auto"
                            height="120px"
                            onClick={() => setShowGovCustomersModal(true)}
                          />
                        </div>
                        <div className="text-center">
                          <h6 className="text-uppercase fw-bold mt-3 mb-3 text-primary">
                            U.S. Government Customers
                          </h6>
                          <Button variant="warning">Learn More</Button>
                        </div>
                      </div>
                    </Col>
                    {!removeLine && (
                      <Col>
                        <div className="horizontal-line"></div>
                      </Col>
                    )}
                    <Col md={4} lg={2}>
                      <div
                        className="clickable"
                        onClick={() => setShowIntPartnersModal(true)}
                      >
                        <div className="d-flex justify-content-center icon-border">
                          <img
                            src={intPartnersImg}
                            alt="internation partners image"
                            className="w-auto"
                            height="120px"
                          />
                        </div>
                        <div className="text-center">
                          <h6 className="text-uppercase fw-bold mt-3 text-primary">
                            International Partners
                          </h6>
                          <Button variant="warning">Learn More</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
}
