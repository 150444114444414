import ConstructionIcon from "@mui/icons-material/Construction";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const DailyDeployBanner = () => {
  return (
    <Container
      fluid
      className="border border-5 border-danger text-danger p-3 align-content-center"
    >
      <Row>
        <Col sm={12} className="text-center">
          <ConstructionIcon fontSize="large" />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="text-center">
          <h3>
            There will be a site outage starting Saturday October 5th at 5:00PM
            Eastern Standard Time.
          </h3>
          <h4>
            We apologize for any inconvenience this may cause and appreciate
            your understanding as we work to ensure a great user experience!
          </h4>
        </Col>
      </Row>
    </Container>
  );
};

export default DailyDeployBanner;
