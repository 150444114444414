import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../UI/UnauthorizedView";

import ECRCommentsTable from "./ECRCommentsTable";
import ECRDetails from "./ECRDetails";

export default function ECRManager({ setOverlayActive, setOverlayText }) {
  const permissions = useSelector((state) => state.role.value);

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "ECR Comments",
    )?.read
  ) {
    <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<ECRCommentsTable filterable />} />
      <Route path="/:vid" element={<ECRCommentsTable filterable />} />
      <Route
        path="details/:id"
        element={
          <ECRDetails
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
    </Routes>
  );
}
