import axios from "axios";
import FileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useCookies } from "react-cookie";

import HTMLEditor from "../../UI/HTMLEditor";

export default function LabgramForm({
  show,
  handleCloseModal,
  labgram,
  currentUser,
  isEditingLabgram,
  setOverlayActive,
  setOverlayText,
}) {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [validated, setValidated] = useState(false);
  const [files, setFiles] = useState([]);
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [editedLabgram, setEditedLabgram] = useState({ ...labgram });
  const [link, setLink] = useState("");
  const [allLinks, setAllLinks] = useState([]);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const handleChange = async (e) => {
    if (e.target.name === "file") {
      const newFiles = files ? files : [];
      newFiles.push({
        file_type: "labgram",
        file_source_type: "Other",
        file_display_name: editedLabgram.file_display_name,
        uploaded_on: new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        ),
        file_name: e.target.files[0].name,
        file_label: editedLabgram.file_label,
        active: true,
        file: e.target.files[0],
      });
      setFiles([...newFiles]);
    } else if (e.target.name === "file_type") {
      setEditedFileLabel(e.target.value);
      setEditedLabgram({
        ...editedLabgram,
        [e.target.name]: e.target.value,
        file_label: e.target.value,
      });
    } else if (e.target.name === "file_label") {
      setEditedFileLabel(e.target.value);
      setEditedLabgram({ ...editedLabgram, [e.target.name]: e.target.value });
    } else if (e.target.name == "links") {
      setLink(e.target.value);
    } else {
      setEditedLabgram({ ...editedLabgram, [e.target.name]: e.target.value });
    }
  };
  const addLink = () => {
    let prevLinks = allLinks;
    if (link) {
      prevLinks.push(link);
    }
    setLink("");
    setAllLinks(prevLinks);
  };

  const removeFile = async (file, idx) => {
    if (file.file_id) {
      let currentTime = new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      );
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
          {
            ...file,
            active: false,
            removed_on: currentTime,
            removed_by: currentUser.id,
            parent: file.parent?.file_id,
            uploaded_by: file.uploaded_by?.id,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => console.log("file removed"))
        .catch((error) => console.log("Unable to remove file", error));
      fetchFiles();
    } else {
      const newFiles = files.slice();
      newFiles.splice(idx, 1);
      setFiles([...newFiles]);
    }
  };

  const removeLink = (index) => {
    const newLinks = allLinks.slice();
    newLinks.splice(index, 1);
    setAllLinks([...newLinks]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      let currentTime = new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      );
      if (editedLabgram.labgram_id) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/${editedLabgram.labgram_id}/`,
            {
              ...editedLabgram,
              creator: editedLabgram?.creator?.id,
              editor: currentUser?.id,
              moddate: currentTime,
              links: allLinks,
            },
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
                "X-CSRFToken": csrfToken,
              },
            },
          )
          .then(() => {
            uploadFiles(editedLabgram.labgram_id);
            handleCloseModal();
            setValidated(true);
          })
          .catch((error) => {
            console.log("Unable to update labgram: " + error);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/`,
            {
              ...editedLabgram,
              creator: currentUser.id,
              entrydate: currentTime,
              links: allLinks,
            },
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
                "X-CSRFToken": csrfToken,
              },
            },
          )
          .then((response) => {
            uploadFiles(response.data.labgram_id);
            handleCloseModal();
            setValidated(true);
          })
          .catch((error) => {
            console.log("Unable to create labgram: " + error);
          });
      }
    }
    setValidated(true);
  };
  const uploadFiles = (file_type_id) => {
    files.forEach((file) => {
      if (!file.file_id) {
        const submittedData = new FormData();
        submittedData.append("file_type", file?.file_type);
        submittedData.append("file_type_id", file_type_id);
        submittedData.append("file_display_name", editedLabgram?.file_type);
        submittedData.append(`file`, file?.file);
        submittedData.append("file_name", file?.file_name);
        submittedData.append("file_label", file?.file_label);
        submittedData.append(
          "file_location",
          `/${file?.file_type}/${file_type_id}/`,
        );
        submittedData.append(
          "uploaded_on",
          new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ).toISOString(),
        );
        submittedData.append("file_source_type", "Other");
        submittedData.append("active", JSON.stringify(true));
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {})
          .catch((error) => console.log("Error " + error));
      }
    });
  };
  const downloadFile = (file) => {
    setOverlayText("Downloading");
    setOverlayActive(true);
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        setOverlayActive(false);
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setEditedLabgram({ ...labgram });
    setValidated(false);
    setEditedFileLabel("");
    fetchFiles();
  };

  const fetchFiles = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=labgram&file_type_id=${labgram?.labgram_id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => setFiles(response.data))
      .catch(() => setFiles([]));
  };

  useEffect(() => {
    setValidated(false);
    if (labgram?.links) {
      setAllLinks(labgram.links);
    }
    setEditedLabgram({ ...labgram });
    fetchFiles();
  }, [show]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleCloseModal}
      enforceFocus={enforceFocusModal}
    >
      <Form
        onSubmit={handleSubmit}
        onReset={handleReset}
        validated={validated}
        noValidate
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditingLabgram ? `EDIT LABGRAM` : `+ NEW LAB GRAM`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col>
              <Form.Group className="mb-3" controlId="author">
                <Form.Label>Author:</Form.Label>
                <Form.Control
                  type="text"
                  name="author"
                  value={editedLabgram?.author ?? ""}
                  onChange={handleChange}
                  placeholder="Author"
                  autoFocus
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="labgram_date">
                <Form.Label>Date:</Form.Label>
                <Form.Control
                  type="date"
                  name="labgram_date"
                  defaultValue={labgram?.labgram_date?.substring(0, 10)}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Label>Archived:</Form.Label>
          <Form.Group controlId="active">
            <Form.Check
              type="radio"
              className="form-check-inline"
              name="archived"
              label="Yes"
              value={true}
              onChange={handleChange}
              checked={
                editedLabgram.archived === true ||
                editedLabgram.archived === "true"
              }
              id="archived1"
            />
            <Form.Check
              type="radio"
              className="form-check-inline"
              name="archived"
              label="No"
              value={false}
              onChange={handleChange}
              checked={
                editedLabgram.archived === false ||
                editedLabgram.archived === "false"
              }
              id="archived2"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="subject">
            <Form.Label>Subject:</Form.Label>
            <Form.Control
              type="text"
              name="subject"
              value={editedLabgram?.subject ?? ""}
              onChange={handleChange}
              placeholder="Subject"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="labgram">
            <Form.Label>Labgram:</Form.Label>
            <HTMLEditor
              name="labgram"
              handleChange={handleChange}
              setIsValid={() => {}}
              isValid={true}
              defaultValue={labgram?.labgram}
              setEnforceFocusModal={setEnforceFocusModal}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="links">
            <Form.Label>Links:</Form.Label>
            <Row>
              <Col>
                {" "}
                <Form.Control
                  type="link"
                  name="links"
                  onChange={handleChange}
                  value={link}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    addLink();
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
            {allLinks?.map((link, index) => (
              <Row key={index}>
                <Col>
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                </Col>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="attachment-remove"
                    onClick={() => removeLink(index)}
                  >
                    X
                  </Button>
                </Col>
              </Row>
            ))}
          </Form.Group>
          <Row className="pt-2">
            <Col xl={3} sm={12}>
              <Form.Group className="text-start" controlId="file_type">
                <Form.Label className="small text-secondary">Type</Form.Label>
                <Form.Select
                  name="file_type"
                  value={editedLabgram?.file_type ?? ""}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="labgram">Labgram</option>
                  <option value="other">other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xl={3} sm={12}>
              <Form.Group className="text-start" controlId="file_public_status">
                <Form.Label className="small text-secondary">
                  Document Visibility
                </Form.Label>
                <Form.Select
                  name="file_public_status"
                  value={editedLabgram?.file_public_status ?? ""}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Public">Public</option>
                  <option value="Proprietary">Proprietary</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xl={5} sm={12}>
              <Form.Group className="text-start" controlId="file_label">
                <Form.Label className="small text-secondary">
                  File Label
                </Form.Label>
                <Form.Control
                  value={editedFileLabel}
                  type="text"
                  name="file_label"
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xl={5} sm={12}>
              <Form.Group controlId="file">
                <Form.Label className="small text-secondary">
                  Attach Files
                </Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  onChange={handleChange}
                  disabled={
                    !editedLabgram.file_type ||
                    !editedLabgram.file_public_status ||
                    !editedLabgram.file_label
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          {isEditingLabgram
            ? files
                ?.filter((file) => file.active === true)
                .map((file, idx) => (
                  <Row className="m-3 border-bottom text-center" key={idx}>
                    <Col sm={5}>
                      <Button
                        variant="link"
                        className="text-bright-navy text-decoration-underline"
                        onClick={() => downloadFile(file)}
                      >
                        {file.file_name}
                      </Button>
                    </Col>
                    <Col sm={5}>{file.file_label}</Col>
                    <Col sm={2}>
                      <Button
                        variant="outline-primary"
                        className="attachment-remove"
                        onClick={() => removeFile(file, idx)}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                ))
            : files
                ?.filter((file) => file.active === true)
                .map((file, idx) => (
                  <Row className="m-3 border-bottom text-center" key={idx}>
                    <Col sm={5}>{file.file_name}</Col>
                    <Col sm={5}>{file.file_label}</Col>
                    <Col sm={2}>
                      <Button
                        variant="outline-primary"
                        className="attachment-remove"
                        onClick={() => removeFile(file, idx)}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button type="reset" variant="warning">
            Reset
          </Button>
          <Button variant="warning" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
