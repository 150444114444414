import axios from "axios";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";

const RenameObject = ({ show, handleClose, reloadDocuments, object }) => {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const [newName, setNewName] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [alert, setAlert] = useState({ message: "", type: "" });

  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  const getFileNames = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file_names/?file_type_id=${object?.file_type_id}&file_type=${object?.file_type}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setFileNames(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (show) {
      getFileNames();
    }
  }, [show]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    if (fileNames.includes(newName)) {
      setAlert({
        message: "File name already exists. Please select a different name.",
        type: "danger",
      });
      return;
    } else {
      setAlert({});
    }
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${object?.file_id}/`,
        { file_new_name: newName },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        reloadDocuments();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReset = () => {
    setNewName("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Rename {object?.isFolder ? "Folder" : "Document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert?.message && (
            <Alert variant={alert?.type}>{alert?.message}</Alert>
          )}
          <Form.Group controlId="file_name">
            <Form.Label>
              Rename the {object?.isFolder ? "Folder" : "Document"}
            </Form.Label>
            <Form.Control
              type="text"
              name="file_new_name"
              onChange={handleChange}
              defaultValue={
                object?.file_new_name
                  ? object?.file_new_name
                  : object?.file_name
              }
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button variant="primary" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RenameObject;
