import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "../UI/UnauthorizedView";

import OrganizationManagerView from "./OrganizationManagerView";

export default function OrganizationManager() {
  const permissions = useSelector((state) => state.role.value);

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Organization",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<OrganizationManagerView />} />
    </Routes>
  );
}
