import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { updateProductProgressPoint } from "../../UI/Functions/progressFunctions";
import { handleAlertAndMailer } from "../Helper/functions";

const ValidatorKickoffModal = ({ show, onHide, product, refetch }) => {
  const [cookies] = useCookies();
  const authToken = cookies["auth_token"];
  const csrfToken = cookies["csrftoken"];
  const currentUser = useSelector((state) => state.user.value);
  const [updatedData, setUpdatedData] = useState({});

  const handleChange = (e) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product?.product_id}/`,
          {
            ...updatedData,
            kickoff_status: "Recommended for Kickoff",
            kickoff_recommended_by: currentUser.id,
            kickoff_recommended_date: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            rejected_rationale: null,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(async (response) => {
          await updateProductProgressPoint(
            response.data.product_id,
            2,
            "In Progress",
            cookies,
          );
          handleSendAlertAndMail(response.data);
          refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSendAlertAndMail = (product) => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: product?.product_id,
      alert_type: "Product",
      subject: `Kickoff Recommendation for VID${product?.v_id}`,
      recipients: { to: ["NIAP Management"], cc: [] },
      alert_text: `Kickoff Recommendation for VID${product?.v_id}`,
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <h3 className="mb-0">Recommending for Kickoff</h3>
        </Modal.Header>
        <Modal.Body>
          {product?.rejected_rationale && (
            <div className="border-bottom mb-3">
              <h4 className="mt-2">Rejected Rationale:</h4>
              <p className="fw-bold">{product?.rejected_rationale}</p>
            </div>
          )}
          <Form.Group controlId="sync_radios">
            <Form.Label>Sync Required?</Form.Label>
            <Form.Check
              type="radio"
              name="sync_required"
              label="Yes"
              value={true}
              onChange={handleChange}
              id={"sync_required1"}
              required
            />
            <Form.Check
              type="radio"
              name="sync_required"
              label="No"
              value={false}
              onChange={handleChange}
              id={"sync_required2"}
              required
            />
          </Form.Group>
          {updatedData?.sync_required && (
            <Form.Group controlId="sync_justification">
              <Form.Label>Justification</Form.Label>
              <Form.Control
                as="textarea"
                name="sync_justification"
                onChange={handleChange}
                required={updatedData?.sync_required === "true"}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" type="clear" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="success" type="submit" onClick={handleSubmit}>
            Recommend for Kickoff
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ValidatorKickoffModal;
