import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import AlertsModule from "../AlertsModule";
import ValidatorProductsModule from "../ProductsModule/ValidatorProductsModule";

export default function ValidatorDashboard({
  setOverlayActive,
  setOverlayText,
}) {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col lg={12}>
              <ValidatorProductsModule
                setOverlayActive={setOverlayActive}
                setOverlayText={setOverlayText}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col lg={12}>
              <AlertsModule />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
