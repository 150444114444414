import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import FileTable from "../../ProjectFiles";
import UnauthorizedView from "../../UI/UnauthorizedView";

import PCMDetails from "./PCMDetails";
import PCMTable from "./PCMTable";

export default function PCMManager({ setOverlayActive, setOverlayText }) {
  const permissions = useSelector((state) => state.role.value);

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PCMTable
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
      <Route
        path="details/:id"
        element={
          <PCMDetails
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
      <Route
        path="details/:id/:type/:ccid"
        element={
          <FileTable
            setOverlayActive={setOverlayActive}
            setOverlayText={setOverlayText}
          />
        }
      />
    </Routes>
  );
}
