import moment from "moment";
import { Accordion, Row, Container, Col } from "react-bootstrap";

export default function ModificationHistoryAccordion({ product }) {
  return (
    <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
      <Accordion.Item eventKey="1">
        <Accordion.Header className="bg-secondary">
          <h6 className="sidebar-title">Modification History</h6>
        </Accordion.Header>
        <Accordion.Body className="bg-light p-1">
          <Container>
            <Row>
              <Col>
                <Row>
                  <p className="text-secondary">Created On:</p>
                </Row>
                <Row className="border-bottom">
                  <p>
                    <span className="me-2">
                      {product?.created_on &&
                        moment(product?.created_on).format(
                          "YYYY.MM.DD [at] HHmm",
                        )}
                    </span>
                    by
                    <br></br>
                    {`${product?.created_by?.first_name}  ${product?.created_by?.last_name}
                                        (${product?.created_by?.user_role?.role_type})`}
                  </p>
                </Row>
                <Row>
                  <p className="text-secondary">Last Modified:</p>
                </Row>
                <Row className="border-bottom">
                  {product?.edited_on && (
                    <p>
                      <span className="me-2">
                        {product?.edited_on &&
                          moment(product.edited_on).format(
                            "YYYY.MM.DD [at] HHmm",
                          )}
                      </span>
                      by
                      <br></br>
                      {`${product?.edited_by?.first_name}  ${product?.edited_by?.last_name}
                                            (${product?.edited_by?.user_role?.role_type})`}
                    </p>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
