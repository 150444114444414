import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import QRCode from "react-qr-code";
import { useSearchParams, useNavigate } from "react-router-dom";

const MFADisplayQR = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const qrCode = searchParams.get("qr-code");

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">
          Scan this Code with your Authentication Application
        </Card.Title>
        <QRCode value={qrCode} />
        <Button
          className="my-4"
          variant="warning"
          onClick={() => navigate("/login/mfa-confirm/?mfa-method=app")}
        >
          Next
        </Button>
      </Card.Body>
    </Card>
  );
};

export default MFADisplayQR;
