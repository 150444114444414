import moment from "moment";
import { Modal, Container, Row, Form, Button } from "react-bootstrap";

export default function ItcInformationModal({
  show,
  handleClose,
  technicalQuery,
  handleChange,
  handleSave,
}) {
  const handleUpdateiTC = () => {
    handleClose();
    handleSave("Updated iTC Information");
  };

  let teamStatusTypes = [
    "Not Applicable",
    "Needs To Be Sent",
    "Sent For Resolution",
    "Resolved",
  ];

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title as="h3" className="text-primary">
          Edit iTC Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className="pt-2">
            <Form.Group className="text-start" controlId="date_sent">
              <Form.Label className="small text-secondary">
                Interpretation Team Status:
              </Form.Label>

              <Form.Select
                name="team_status"
                onChange={handleChange}
                defaultValue={
                  technicalQuery?.team_status
                    ? technicalQuery?.team_status
                    : null
                }
              >
                <option value=""></option>
                {teamStatusTypes.map((status, idx) => (
                  <option value={status} key={idx}>
                    {status}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="text-start" controlId="date_sent">
              <Form.Label className="small text-secondary">
                Date Sent
              </Form.Label>
              <Form.Control
                type="date"
                name="date_sent"
                defaultValue={moment
                  .utc(technicalQuery?.date_sent)
                  .format("YYYY-MM-DD")}
                onChange={handleChange}
              ></Form.Control>
            </Form.Group>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleUpdateiTC}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
