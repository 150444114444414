import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Button, Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { handleAlertAndMailer } from "../../Products/Helper/functions";
import HTMLEditor from "../../UI/HTMLEditor";

export default function FinalizePCMModal({
  show,
  handleShow,
  pcm,
  statuses,
  refetch,
  setAlert,
  pcmPps,
}) {
  const [reject, setReject] = useState(false);
  const [approve, setApprove] = useState(false);
  const [note, setNote] = useState("");
  const [matchingCountryId, setMatchingCountryId] = useState("");
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  let csrfToken = cookies["csrftoken"];
  const currentUser = useSelector((state) => state.user.value);
  const [amDate, setAmDate] = useState("");
  const [sendPostingAdvice, setSendPostingAdvice] = useState(true);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);

  const rejectionTemplate = `
            <div>
                <body>
                    <p>${pcm?.custuname},</p>
                    <p>Your request to have the following product listed on the NIAP PCL has been rejected: <strong>${pcm?.prod}</strong>.</p>
                    <p>The reason your request was rejected is <strong>[****** ****** ****** ****** ****** ******]</strong>.</p>
                    <p>Please include request number PCM-${new Date().getFullYear()}-${pcm?.ticket} in any correspondence relating to your request. Thank you.</p>
                    <p>********************************************************************************</p>
                    <p>&mdash; This email and any files transmitted with it are intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error, please notify the sender.</p>
                    <p>&mdash; The information contained herein is for the exclusive use of Government and Contractor personnel with a need-to-know for NIAP/CCEVS information. Such information is specifically prohibited from posting on blogs or any other public/semi-public access applications.</p>
                    <p>********************************************************************************</p>
                </body>
            </div>
    `;
  const approveTemplate = `
            <div>
                <body>
                    <p>${pcm?.custuname},</p>
                    <p>The product listed in request number PCM-${new Date().getFullYear()}-${pcm?.ticket} has been posted to the <a href="https://www.niap-ccevs.org/products/international-product/${pcm?.ccid}">NIAP PCL</a>: <strong>${pcm?.prod}</strong>.</p>
                    <p>Thank you.</p>
                    <p>********************************************************************************</p>
                    <p>&mdash; This email and any files transmitted with it are intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error, please notify the sender.</p>
                    <p>&mdash; The information contained herein is for the exclusive use of Government and Contractor personnel with a need-to-know for NIAP/CCEVS information. Such information is specifically prohibited from posting on blogs or any other public/semi-public access applications.</p>
                    <p>********************************************************************************</p>
                </body>
            </div>
    `;

  const getMatchingCountry = (pcm) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}address/country/get_all_countries/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const matchingCountry = response.data.find(
          (country) => country.code2_iso === pcm?.nsid?.cid?.code2_iso,
        );
        setMatchingCountryId(matchingCountry?.cid);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (show) {
      setApprove(false);
      setReject(false);
      setSendPostingAdvice(true);
      if (pcm) {
        getMatchingCountry(pcm);
      }
    }
  }, [show]);

  const getTextBox = () => {
    if (approve) {
      return (
        <Row className="mt-3">
          <Form.Label className="fw-bold">
            Posting Advice to the Customer:
          </Form.Label>
          <HTMLEditor
            name="note"
            handleChange={(e) => setNote(e.target.value)}
            setIsValid={() => {}}
            isValid={true}
            defaultValue={approveTemplate}
            setEnforceFocusModal={setEnforceFocusModal}
          />
        </Row>
      );
    } else if (reject) {
      return (
        <Row>
          <Form.Label>Rejection Rationale:</Form.Label>
          <HTMLEditor
            name="note"
            handleChange={(e) => setNote(e.target.value)}
            setIsValid={() => {}}
            isValid={true}
            defaultValue={rejectionTemplate}
            setEnforceFocusModal={setEnforceFocusModal}
          />
        </Row>
      );
    } else {
      return;
    }
  };

  const sendFinalizingEmail = async () => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: pcm?.rid,
      alert_type: "PCM",
      subject: `PCM Request - Product Posted on the PCL per PCM-${new Date().getFullYear()}-${pcm?.ticket}`,
      recipients: { to: ["Customer"], cc: ["NIAP Management"] },
      alert_text: `PCM Request - Product Posted on the PCL per PCM-${new Date().getFullYear()}-${pcm?.ticket}`,
      note: note,
    });
  };

  const getAmDate = () => {
    //if pcm has certain type of pp, add 5 years, else add 2 years
    if (
      pcmPps?.find(
        (pp) =>
          pp?.ppid?.pp_name ===
          "Protection Profile for Peripheral Sharing Device Version 4.0",
      )
    ) {
      return moment.utc().add(5, "years").format("YYYY-MM-DD");
    } else if (pcm?.maintenance_id !== null) {
      return moment
        .utc(pcm?.maintenance_id?.am_date)
        .add(2, "years")
        .format("YYYY-MM-DD");
    } else {
      return moment.utc().add(2, "years").format("YYYY-MM-DD");
    }
  };

  const handleUpdateConcur = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}concur/update_concurs_by_ccid/`,
        {
          ccid: pcm?.ccid,
          lab: pcm?.assigned_lab ? pcm.assigned_lab.cctl_name : pcm?.custorg,
          sunset: amDate ? amDate : getAmDate(),
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        /** console.log("Added product CC") */
      })
      .catch((error) => {
        console.log("unable to update productcc", error);
      });
  };

  const handleAddProductCC = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/`,
        {
          ccid: pcm?.ccid,
          name: pcm?.prod,
          country: matchingCountryId,
          pcm: pcm?.rid,
          lab: pcm?.assigned_lab?.cctl_id,
          vendor: pcm?.vendor_id?.org_id,
          categories: pcm?.categories,
          certified: pcm?.certified,
          am_date: amDate !== "" ? amDate : getAmDate(),
          archived: moment.utc().add(2, "years").format("YYYY-MM-DD"),
          pdf_cert: pcm?.pdf_cert,
          pdf_st: pcm?.pdf_st,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        handleUpdateConcur(amDate);
      })
      .catch((error) => {
        console.log("unable to update productcc", error);
      });
  };

  const handleUpdateProductCC = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/update_productcc_for_maintenance/`,
        {
          ccid: pcm?.ccid,
          am_date: amDate !== "" ? amDate : getAmDate(),
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        handleUpdateConcur(amDate);
      })
      .catch((error) => {
        console.log("unable to update productcc for maintenance", error);
      });
  };

  const updatePcm = async (e) => {
    e.preventDefault();
    let status = "";
    if (approve) {
      status = statuses?.find(
        (status) => status?.label.toLowerCase() === "posted",
      )?.value;
    } else if (reject) {
      status = statuses?.find(
        (status) => status?.label.toLowerCase() === "rejected",
      )?.value;
    }
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/${pcm?.rid}/`,
        {
          stid: status,
          agent: currentUser.id,
          am_date: amDate !== "" ? amDate : getAmDate(),
          enddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          moddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          editor: currentUser.id,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        if (approve) {
          if (pcm?.maintenance_id !== null) {
            handleUpdateProductCC(); //we want to update the product cc if approved and maintenance
          } else {
            handleAddProductCC(); //we only want to add product to product cc if approved and not maintenance
          }
        }
        if (sendPostingAdvice) {
          sendFinalizingEmail();
        }
        refetch();
        handleShow();
        setAlert({ message: "PCM Updated!", type: "success" });
      })
      .catch((error) => {
        console.log("unable to update pcm", error);
        setAlert({ message: "Error Updating PCM.", type: "danger" });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleShow}
      size="lg"
      enforceFocus={enforceFocusModal}
    >
      <Modal.Header className="border-0" closeButton>
        <h3 className="text-bright-navy">FINALIZE PCM - {pcm?.ticket}</h3>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-4">
            <Col className="d-flex justify-content-center">
              <h4 className="text-bright-navy">{pcm?.prod}</h4>
            </Col>
          </Row>
          <Row>
            <Form.Group controlId="team_due_date">
              <Form.Label>
                <p className="fw-bold">
                  Please Select Assurance Maintenance Date:
                </p>
              </Form.Label>
              <Form.Control
                className="mb-3 w-50"
                type="date"
                name="am_date"
                onChange={(e) => setAmDate(e.target.value)}
                defaultValue={getAmDate()}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <p className="fw-bold">
              Please Select the Resolution for this Item:
            </p>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md={3}>
                  <Button
                    variant={reject ? "danger" : "outline-danger"}
                    onClick={() => {
                      setReject(true);
                      setApprove(false);
                    }}
                  >
                    Reject Request
                  </Button>
                </Col>
                <Col>
                  <p>
                    Choose "Reject Request" if the PCM Request is to be
                    cancelled.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Row>
                <Col md={3}>
                  <Button
                    variant={approve ? "success" : "outline-success"}
                    onClick={() => {
                      setApprove(true);
                      setReject(false);
                    }}
                  >
                    Mark as Posted
                  </Button>
                </Col>
                <Col>
                  <p>
                    Choose “Mark as Posted” if the PCM Request is fully resolved
                    and the item and associated documents are accepted for
                    inclusion on the NIAP PCL.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <p className="fw-bold">Send Posting Advice to the Customer?</p>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md={2}>
                  <Button
                    variant={!sendPostingAdvice ? "danger" : "outline-danger"}
                    onClick={() => {
                      setSendPostingAdvice(false);
                    }}
                  >
                    No
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    variant={sendPostingAdvice ? "success" : "outline-success"}
                    onClick={() => {
                      setSendPostingAdvice(true);
                    }}
                  >
                    Yes
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {sendPostingAdvice && getTextBox()}
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Container>
          <Row className="d-flex justify-content-end">
            <Col sm={2}>
              <Button variant="outline-primary" onClick={handleShow}>
                Cancel
              </Button>
            </Col>
            <Col sm={3}>
              <Button
                variant="primary"
                onClick={updatePcm}
                disabled={approve === false && reject === false}
              >
                Save and Send
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
