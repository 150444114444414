import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useEffect, useState, useRef } from "react";
import { Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";

import ResponsiveMaterialTable from "../../UI/MaterialTable/ResponsiveMaterialTable";

import FAQCategoryModal from "./FAQCategoryModal";

export default function FAQCategoriesTable() {
  const [cookies] = useCookies();
  let authToken = cookies["auth_token"];
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState({});
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  const columns = [
    { title: "Category Name", field: "cat" },
    { title: "Active", field: "active" },
    {
      title: "Creator",
      field: "creator",
      render: (rowData) => {
        return `${rowData?.creator?.first_name} ${rowData?.creator?.last_name}`;
      },
    },
    {
      title: "Entry Date",
      field: "entrydate",
      render: (rowData) => {
        return moment.utc(rowData?.entrydate).format("MM/DD/YYYY");
      },
    },
    {
      title: "Editor",
      field: "editor",
      render: (rowData) => {
        if (rowData?.editor === null) {
          return "N/A";
        } else {
          return `${rowData?.editor?.first_name} ${rowData?.editor?.last_name}`;
        }
      },
    },

    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <Button
            variant="primary"
            className="me-3 rounded-0"
            onClick={() => {
              setCategoryToEdit(rowData);
              setShowCategoryModal(true);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const options = {
    showTitle: false,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: false,
    pageSize: 20,
  };

  return (
    <Container fluid>
      <FAQCategoryModal
        show={showCategoryModal}
        handleClose={() => {
          setShowCategoryModal(false);
          setCategoryToEdit({});
        }}
        categoryToEdit={categoryToEdit}
        setAlert={setAlert}
        refetch={() => {
          setRefresh(!refresh);
        }}
      />
      <Row>
        {alert.message !== "" && (
          <Row className="d-flex justify-content-center mt-3">
            <Col sm={8}>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Col>
          <Row className="mt-5 mb-3">
            <Col sm={12} md={6}>
              <h3 className="text-bright-navy">FAQ CATEGORIES</h3>
            </Col>
            <Col sm={12} md={6} className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="account-btn w-sm-100"
                onClick={() => {
                  setShowCategoryModal(true);
                }}
              >
                +Add Categories
              </Button>
            </Col>
          </Row>
          <Row>
            <ResponsiveMaterialTable
              columns={columns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                  } = query;

                  let newFilters = filters.map((filter) => {
                    let value = "";
                    if (Array.isArray(filter.value)) {
                      value = filter.value;
                    } else {
                      value = filter.value.replace(/['"]+/g, "");
                    }
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                  };
                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/cat/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then((response) => {
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              }
              tableRef={tableRef}
              options={options}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
