import Input from "@mui/material/Input";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import MaterialTable from "material-table";
import qs from "qs";
import { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";

import Subheader from "../Subheader";

import BecomeInternationalTcForm from "./BecomeInternationalTcForm";
import BecomeUsTcForm from "./BecomeUsTcForm";
import TechCommCards from "./TechCommModals";

export default function TechCommunities() {
  const [showUsTcModal, setShowUsTcModal] = useState(false);
  const [showInternationalTcModal, setShowInternationalTcModal] =
    useState(false);
  const handleShowUsTcModal = () => setShowUsTcModal(true);
  const handleShowInternationalTcModal = () =>
    setShowInternationalTcModal(true);
  const [techCommunties, setTechCommunities] = useState([]);

  const loadTechCommunities = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}tech/communities/`)
      .then((response) => {
        setTechCommunities(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    document.title = "NIAP - Technical Communities";
    loadTechCommunities();
  }, []);

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: false,
    columnsButton: true,
    pageSize: 20,
  };

  const columns = [
    { title: "Name", field: "community_name" },
    {
      title: "Contact",
      field: "contact_email",
      render: (rowData) => (
        <a
          href={`mailto:${rowData.contact_email}`}
          target="_blank"
          rel="noreferrer"
        >
          {rowData.contact_email}
        </a>
      ),
    },
  ];

  const internamtional_columns = [
    { title: "Name", field: "community_name" },
    {
      title: "URL",
      field: "url",
      render: (rowData) => (
        <a href={`${rowData.url}`} target="_blank" rel="noreferrer">
          Common Criteria Website
        </a>
      ),
    },
  ];

  return (
    <>
      <Subheader pageName={"Technical Communities"} />
      <Container className="py-3">
        <Row>
          <Col>
            <h4 className="text-uppercase fw-bold pb-3">
              Protection Profiles -{" "}
              <span className="text-uppercase fw-bold text-primary">
                Technical Communities
              </span>
            </h4>
            <p>
              The National Information Assurance Partnership (NIAP) is
              introducing an initiative to form Technical Communities (TCs) for
              the development of Protection Profiles (PPs). A key goal for the
              TCs is to ensure that PPs are generated as the result of
              collaboration between Government, industry, and academia.
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <h5 className="text-uppercase fw-bold text-primary pb-3">
            Participants
          </h5>
          <p>
            The Technical Communities are comprised of representatives from a
            broad range of affiliations such that the resultant PPs benefit from
            knowledge contributions that come from a variety of perspectives to
            produce effective and relevant technology content.
          </p>
          <p className="fw-bold text-primary">
            The guiding principles of the organization (SC and TCs) are:
          </p>
          <Row className="mx-3">
            <Col>
              <ul>
                <li>Consistency</li>
                <li>Transparency</li>
                <li>Collaboration</li>
                <li>Scalability</li>
              </ul>
            </Col>
            <Col>
              <ul>
                <li>Improved "Time to Market"</li>
                <li>Leverages Industry Expertise</li>
                <li>International Participation</li>
                <li>Collective ownership of the process</li>
              </ul>
            </Col>
          </Row>
          <p>
            Calls for participants for each Technical Community (TC) are sent to
            industry, government, end users, academic institutions, and labs as
            announcements posted on the NIAP website. All interested parties
            wanting to participate in any TC should provide the following
            information to the aliases below:
          </p>
          <Row className="mx-3">
            <Col>
              <ul>
                <li>Name</li>
                <li>
                  Affiliation (Vendor/CCTL/ Academic Institution/Scheme/Other)
                </li>
                <li>Address</li>
              </ul>
            </Col>
            <Col>
              <ul>
                <li>Telephone Number</li>
                <li>Email Address</li>
                <li>
                  A brief statement of the qualifications for participation in
                  the TC
                </li>
              </ul>
            </Col>
          </Row>
        </Row>
        <TechCommCards />
        <div className="p-4">
          <div>
            <Button
              className="btn-warning rounded-pill fw-bold p-3 my-2 me-3"
              onClick={handleShowUsTcModal}
            >
              BECOME A US TECHNICAL COMMUNITY PARTICIPANT
            </Button>
            <Button
              className="btn-warning rounded-pill fw-bold p-3 my-2"
              onClick={handleShowInternationalTcModal}
            >
              BECOME AN INTERNATIONAL TC PARTICIPANT
            </Button>
          </div>
        </div>
        <div className="mb-3">
          <MaterialTable
            title={"Communities"}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                  page,
                  pageSize,
                  search,
                  filters,
                  orderBy,
                  orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                  is_international: "false",
                };
                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}tech/communities/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                  )
                  .then((response) => {
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={options}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <label htmlFor="Communities rowsPerPage">
                      Rows Per Page
                    </label>
                  }
                  SelectProps={{
                    input: <Input id="Communities rowsPerPage" />,
                  }}
                />
              ),
            }}
          />
        </div>
        <div className="mb-3">
          <MaterialTable
            title={"International Communities"}
            columns={internamtional_columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                  page,
                  pageSize,
                  search,
                  filters,
                  orderBy,
                  orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }
                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                  is_international: "true",
                };
                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}tech/communities/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                  )
                  .then((response) => {
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={options}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <label htmlFor="International Communities rowsPerPage">
                      Rows Per Page
                    </label>
                  }
                  SelectProps={{
                    input: <Input id="International Communities rowsPerPage" />,
                  }}
                />
              ),
            }}
          />
        </div>
      </Container>
      <BecomeUsTcForm show={showUsTcModal} setShowModal={setShowUsTcModal} />
      <BecomeInternationalTcForm
        show={showInternationalTcModal}
        setShowModal={setShowInternationalTcModal}
      />
    </>
  );
}
