import { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

function ProductPagination({ setOffset, offset, total, PAGE_SIZE }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [showAllPages, setShowAllPages] = useState(false);

  const totalPages = Math.ceil(total / PAGE_SIZE);

  const handlePageClick = (pageNum) => {
    setCurrentPage(pageNum);
    setOffset((pageNum - 1) * PAGE_SIZE);
  };

  useEffect(() => {
    setCurrentPage(offset / PAGE_SIZE + 1);
  }, [offset, PAGE_SIZE]);

  return (
    <Pagination className="pagination-controls">
      <Pagination.First
        onClick={() => handlePageClick(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {[...Array(totalPages).keys()].map((page) => {
        // Display pages based on the condition
        if (showAllPages || page < 3 || page === totalPages - 1) {
          return (
            <Pagination.Item
              key={page}
              active={page + 1 === currentPage}
              onClick={() => handlePageClick(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          );
        } else if (page === 3) {
          // Display ellipsis
          return (
            <Pagination.Ellipsis
              key={page}
              onClick={() => setShowAllPages(!showAllPages)}
            />
          );
        } else {
          return null;
        }
      })}
      <Pagination.Next
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={() => handlePageClick(totalPages)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
}

export default ProductPagination;
