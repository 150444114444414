import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";

const NewUser = ({ show, handleCloseModal }) => {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  const [user, setUser] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [notEqual, setNotEqual] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "phone_number") {
      setErrors({
        ...errors,
        [e.target.name]:
          !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            e.target.value,
          ),
      });
    }
    setUser({ ...user, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      if (!e.target.value || e.target.value === "") {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
      if (e.target.value !== user.confirm_password) {
        setNotEqual(true);
      } else {
        setNotEqual(false);
      }
    } else if (e.target.name === "confirm_password") {
      if (!e.target.value || e.target.value === "") {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
      if (e.target.value !== user.password) {
        setNotEqual(true);
      } else {
        setNotEqual(false);
      }
    }
  };

  const checkPhones = () => {
    if (
      user?.phone_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        user?.phone_number,
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (
      form.checkValidity() === false ||
      user.password !== user.confirm_password ||
      !checkPhones()
    ) {
      e.stopPropagation();
      if (
        !user.password ||
        user.password === "" ||
        !user.confirm_password ||
        user.confirm_password === ""
      ) {
        setIsEmpty(true);
      }
      if (user.password !== user.confirm_password) {
        setNotEqual(true);
      }
    } else {
      user["username"] = user.email;
      user["external_user"] = true;
      axios
        .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/`, user, {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
          },
        })
        .then((response) => {
          setValidated(false);
          setUser({});
          handleCloseModal();
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }
    setValidated(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal size="lg" show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h4">Create Account</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col sm={12}>
                <h5 className="text-uppercase">Personal Information</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <Form.Group className="mb-3" controlId="first_name">
                  <Form.Label>First Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    defaultValue={user.first_name}
                    onChange={handleChange}
                    placeholder="First Name"
                    autoFocus
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="last_name">
                  <Form.Label>Last Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    defaultValue={user.last_name}
                    onChange={handleChange}
                    placeholder="Last Name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={12}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone_number"
                    defaultValue={user.phone_number}
                    onChange={handleChange}
                    placeholder="111-111-1111"
                    isInvalid={errors.phone_number}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h5 className="text-uppercase">Account Information</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    defaultValue={user.email}
                    onChange={handleChange}
                    placeholder="email@example.com"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password *</Form.Label>
                  <Input
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    id="password"
                    className="form-control"
                    name="password"
                    disableUnderline={true}
                    error={isEmpty || notEqual}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                          <span className="visually-hidden">
                            Toggle Visiblity
                          </span>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <p className="small text-secondary">
                    Must be at least 6 characters, using a combination of
                    letters, numbers and symbols.
                  </p>
                </Form.Group>
                <Form.Group className="mb-3" controlId="confirm_password">
                  <Form.Label>Confirm Password *</Form.Label>
                  <Input
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    id="confirm_password"
                    className="form-control"
                    name="confirm_password"
                    error={isEmpty || notEqual}
                    disableUnderline={true}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                          <span className="visually-hidden">
                            Toggle Visiblity
                          </span>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h5 className="text-uppercase">Terms and Conditions*</h5>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3" controlId="terms">
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      name="terms"
                      value="accepted"
                      onChange={handleChange}
                      required
                      id="accepted"
                    />
                    <Form.Check.Label className="form-check-label">
                      By clicking this button, you agree to our{" "}
                      <a href="#">Terms of Use</a> and{" "}
                      <a href="#">Privacy Policy</a>.
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="warning" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewUser;
