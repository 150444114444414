import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import { Accordion, Button, Stack } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

import {
  downloadFile,
  downloadAllFiles,
  getFilesBySourceAndId,
  getPclFiles,
} from "../../../UI/Functions/fileFunctions";

export default function SideFileAccordion({
  product,
  setOverlayActive,
  setOverlayText,
}) {
  const [cookies] = useCookies();
  let csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const [projectPersonnel, setProjectPersonnel] = useState([]);
  // const user = useSelector((state) => state.user.value);
  // const permissions = useSelector((state) => state.role.value);
  const [checkInFiles, setCheckinFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [valCheckoutFiles, setValCheckoutFiles] = useState([]);
  const [evalCheckoutFiles, setEvalCheckoutFiles] = useState([]);
  const [pclFiles, setPclFiles] = useState({});
  const [earFiles, setEarFiles] = useState([]);

  const fetchEarFiles = useCallback(() => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/current_product_ears_files/?product_id=${product?.product_id}&get_latest=true`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setEarFiles(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [product.product_id]);

  useEffect(() => {
    fetchEarFiles();
  }, [fetchEarFiles]);
  const fileInfoButton = (file) => {
    return (
      <Stack
        direction="horizontal"
        className="d-flex justify-content-left align-items-start"
        key={file?.file_id}
      >
        {file.file_mime_type === "application/pdf" && (
          <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
        )}
        {file.file_mime_type !== "application/pdf" && (
          <FontAwesomeIcon icon={faFileLines} color="gray" size="xl" />
        )}
        <div className="ms-2 text-break">
          <Button
            style={{ overflowWrap: "break-word" }}
            className="text-start m-0 p-0"
            variant="link"
            onClick={() =>
              downloadFile(file, authToken, setOverlayActive, setOverlayText)
            }
          >
            {file.file_display_name
              ? file.file_display_name
              : file.file_label
                ? file.file_label
                : file.file_new_name
                  ? file.file_new_name
                  : file.file_name}
          </Button>
          <p className="mb-0 ms-3">
            {file.file_new_name ? file.file_new_name : file.file_name}
          </p>
          <p className="mb-0 ms-3">
            Attached by {file?.uploaded_by?.first_name}{" "}
            {file?.uploaded_by?.last_name} on{" "}
            {file?.uploaded_on &&
              moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
          </p>
        </div>
      </Stack>
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (product?.product_id && authToken) {
        try {
          const evalFiles = await getFilesBySourceAndId(
            product.product_id,
            "Evaluator Check Out",
            authToken,
          );
          const valFiles = await getFilesBySourceAndId(
            product.product_id,
            "Validator Check Out",
            authToken,
          );
          const checkInFiles = await getFilesBySourceAndId(
            product.product_id,
            "Check In",
            authToken,
          );
          const otherFiles = await getFilesBySourceAndId(
            product.product_id,
            "Other",
            authToken,
          );
          const pclFilesData = await getPclFiles(product.product_id, authToken);
          setEvalCheckoutFiles(evalFiles);
          setValCheckoutFiles(valFiles);
          setCheckinFiles(checkInFiles);
          setOtherFiles(otherFiles);
          setPclFiles(pclFilesData);
        } catch (error) {
          console.error("Failed to fetch files", error);
          setEvalCheckoutFiles([]);
          setValCheckoutFiles([]);
          setCheckinFiles([]);
          setOtherFiles([]);
          setPclFiles({});
        }
      }
    };
    fetchData();
  }, [authToken, product?.product_id]);

  return (
    <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
      <Accordion.Item eventKey="1">
        <Accordion.Header className="bg-secondary">
          <h6 className="sidebar-title">Project Files</h6>
        </Accordion.Header>
        <Accordion.Body className="bg-light p-1">
          {["Certified", "Finalizing", "In Assurance Maintenance"]?.includes(
            product?.status,
          ) && (
            <Accordion className="mb-1">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="bg-secondary">
                  <h6 className="text-white">PCL Files</h6>
                </Accordion.Header>
                <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                  {pclFiles?.eval_files?.map((file) => fileInfoButton(file))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          <Accordion className="mb-1" defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header className="bg-secondary">
                <h6 className="text-white">All Files</h6>
              </Accordion.Header>
              <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                <Button
                  as={Link}
                  to={`/community/products/details/${product?.product_id}/product_files`}
                  variant="primary"
                >
                  View All
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="mb-1">
            <Accordion.Item eventKey="1">
              <Accordion.Header className="bg-secondary">
                <h6 className="text-white">Evaluator Checkout Files</h6>
              </Accordion.Header>
              <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                {evalCheckoutFiles?.map((file) => fileInfoButton(file))}
                <Button
                  variant="primary"
                  onClick={() =>
                    downloadAllFiles(
                      product?.product_id,
                      "Evaluator Check Out",
                      authToken,
                      setOverlayActive,
                      setOverlayText,
                    )
                  }
                >
                  Download All
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="mb-1">
            <Accordion.Item eventKey="1">
              <Accordion.Header className="bg-secondary">
                <h6 className="text-white">Validator Checkout Files</h6>
              </Accordion.Header>
              <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                {valCheckoutFiles?.map((file) => fileInfoButton(file))}
                <Button
                  variant="primary"
                  onClick={() =>
                    downloadAllFiles(
                      product?.product_id,
                      "Validator Check Out",
                      authToken,
                      setOverlayActive,
                      setOverlayText,
                    )
                  }
                >
                  Download All
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="mb-1">
            <Accordion.Item eventKey="1">
              <Accordion.Header className="bg-secondary">
                <h6 className="text-white">Evaluator Check-In Files</h6>
              </Accordion.Header>
              <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                {checkInFiles?.map((file) => fileInfoButton(file))}
                {earFiles?.map((file) => fileInfoButton(file))}
                <Button
                  variant="primary"
                  onClick={() =>
                    downloadAllFiles(
                      product?.product_id,
                      "Check In",
                      authToken,
                      setOverlayActive,
                      setOverlayText,
                    )
                  }
                >
                  Download All
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="mb-1">
            <Accordion.Item eventKey="1">
              <Accordion.Header className="bg-secondary">
                <h6 className="text-white">Other Files</h6>
              </Accordion.Header>
              <Accordion.Body style={{ background: "var(--bs-gray-300)" }}>
                {otherFiles?.map((file) => fileInfoButton(file))}
                <Button
                  variant="primary"
                  onClick={() =>
                    downloadAllFiles(
                      product?.product_id,
                      "Other",
                      authToken,
                      setOverlayActive,
                      setOverlayText,
                    )
                  }
                >
                  Download All
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
